import React, { useRef, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Translate, withLocalize } from 'react-localize-redux';
import { Button, Checkbox, Form, Message, Modal } from 'semantic-ui-react';
import { RentalUnitType } from '../../core/constants';
import { ErrorMessage } from '../ErrorMessage';

const AccommodationEdit = ({
  accommodation,
  accommodations,
  onClickCancel,
  onClickSave,
  lodgeNumberOfBeds,
  roomsNumberOfBeds,
  accommodationTypes,
  priceCategories,
  errorMessage,
  loading,
  translate
}) => {
  const { register, handleSubmit, errors, control, setValue } = useForm({
    submitFocusError: true,
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const currentAccommodationTypeId = useWatch({
    control,
    name: 'accommodationTypeId',
    defaultValue: accommodation.type.id
  });

  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      setValue('priceCategoryId', null);
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccommodationTypeId]);

  const onSubmit = data => {
    onClickSave({
      ...accommodation,
      ...data
    });
  };

  const numberOfBeds = currentAccommodationTypeId === RentalUnitType.ROOMS.id ? roomsNumberOfBeds : lodgeNumberOfBeds;

  return (
    <>
      <Modal open>
        <Modal.Header>
          {accommodation.id
            ? `${translate('accommodation.update')} ${accommodation.name}`
            : translate('accommodation.add-new')}
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Field error={!!errors.name}>
                <label>
                  <Translate id="accommodation.name" />
                </label>
                <input
                  type="text"
                  name="name"
                  defaultValue={accommodation.name}
                  placeholder={translate('accommodation.name')}
                  ref={register({ required: true, minLength: 4 })}
                />
              </Form.Field>
              <Form.Field>
                <label>
                  <Translate id="accommodation.parent" />
                </label>
                <Controller
                  name="parentAccommodationId"
                  control={control}
                  defaultValue={accommodation.parentAccommodationId || null}
                  render={({ onChange, value: nValue }) => (
                    <Form.Select
                      fluid
                      required={false}
                      clearable
                      placeholder={translate('accommodation.parent')}
                      options={accommodations
                        .filter(acc => acc.id !== accommodation.id)
                        .map(acc => ({
                          value: acc.id,
                          text: acc.name
                        }))}
                      value={nValue}
                      onChange={(_e, data) => onChange(data.value)}
                    />
                  )}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field error={!!errors.accommodationTypeId}>
                <label>
                  <Translate id="accommodation.type" />
                </label>
                <Controller
                  name="accommodationTypeId"
                  control={control}
                  defaultValue={accommodation.type.id}
                  rules={{ required: true }}
                  render={({ onChange, value: nValue }) => (
                    <Form.Select
                      fluid
                      placeholder={translate('accommodation.type')}
                      options={accommodationTypes}
                      value={nValue}
                      onChange={(_e, data) => onChange(data.value)}
                    />
                  )}
                />
              </Form.Field>
              <Form.Field error={!!errors.priceCategoryId}>
                <label>
                  <Translate id="accommodation.category" />
                </label>
                <Controller
                  name="priceCategoryId"
                  control={control}
                  defaultValue={accommodation.priceCategory?.id || null}
                  rules={{ required: true }}
                  render={({ onChange, value: nValue }) => (
                    <Form.Select
                      fluid
                      placeholder={translate('accommodation.category')}
                      options={priceCategories.filter(p => p.accommodationtypeid === currentAccommodationTypeId)}
                      value={nValue}
                      onChange={(_e, data) => onChange(data.value)}
                    />
                  )}
                />
              </Form.Field>
            </Form.Group>
            {currentAccommodationTypeId === RentalUnitType.CAMPING.id && (
              <Form.Group inline>
                <Form.Field>
                  <Controller
                    name="hasElectricity"
                    control={control}
                    defaultValue={accommodation.hasElectricity || false}
                    render={({ onChange, value }) => (
                      <Checkbox
                        label={translate('accommodation.electricity')}
                        checked={value}
                        onChange={(_e, data) => onChange(data.checked)}
                      />
                    )}
                  />
                </Form.Field>
                <Form.Field>
                  <Controller
                    name="hasDrain"
                    control={control}
                    defaultValue={accommodation.hasDrain || false}
                    render={({ onChange, value }) => (
                      <Checkbox
                        label={translate('accommodation.drainage')}
                        checked={value}
                        onChange={(_e, data) => onChange(data.checked)}
                      />
                    )}
                  />
                </Form.Field>
              </Form.Group>
            )}
            {(currentAccommodationTypeId === RentalUnitType.LODGE.id ||
              currentAccommodationTypeId === RentalUnitType.ROOMS.id) && (
              <Form.Group widths="equal">
                <Form.Field error={!!errors.numberOfBeds}>
                  <label>
                    <Translate id="accommodation.number-of-beds" />
                  </label>
                  <Controller
                    name="numberOfBeds"
                    control={control}
                    defaultValue={accommodation.numberOfBeds}
                    rules={{ required: true }}
                    render={({ onChange, value: nValue }) => (
                      <Form.Select
                        fluid
                        placeholder={translate('accommodation.number-of-beds')}
                        options={numberOfBeds}
                        value={nValue}
                        onChange={(_e, data) => onChange(data.value)}
                      />
                    )}
                  />
                </Form.Field>
                {/* <Form.Field>
                  <label>
                    <Translate id="accommodation.max-guests" />
                  </label>
                  <Controller
                    name="maxGuests"
                    control={control}
                    defaultValue={accommodation.maxGuests}
                    rules={{ required: true, min: 1 }}
                    render={({ onChange, value: nValue }) => (
                      <Form.Input
                        type="number"
                        fluid
                        placeholder={translate('accommodation.max-guests')}
                        value={nValue}
                        onChange={(_e, data) => onChange(data.value)}
                      />
                    )}
                  />
                </Form.Field> */}
              </Form.Group>
            )}
            <Form.Group widths="equal">
              <Form.Field error={!!errors.info}>
                <label>
                  <Translate id="accommodation.info" />{' '}
                  <span style={{ fontSize: '0.9em' }}>({translate('accommodation.widget-display')})</span>
                </label>
                <textarea
                  type="text"
                  name="info"
                  defaultValue={accommodation.info}
                  placeholder={translate('accommodation.info')}
                  ref={register()}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field error={!!errors.secret}>
                <label>
                  {/* <Translate id="accommodation.secret" /> */}
                  Information <span style={{ fontSize: '0.9em' }}>({translate('accommodation.email-display')})</span>
                </label>
                <input
                  type="text"
                  name="secret"
                  defaultValue={accommodation.secret}
                  // placeholder={translate('accommodation.secret')}
                  ref={register()}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Controller
                  name="adminBookingOnly"
                  control={control}
                  defaultValue={accommodation.adminBookingOnly || false}
                  render={({ onChange, value }) => (
                    <Checkbox
                      label={translate('accommodation.admin-only')}
                      checked={value}
                      onChange={(_e, data) => onChange(data.checked)}
                    />
                  )}
                />
              </Form.Field>
              <Form.Field>
                <label>
                  <Translate id="accommodation.advance-days" />
                </label>
                <input
                  type="number"
                  name="advanceDays"
                  defaultValue={accommodation.advanceDays}
                  placeholder={translate('accommodation.advance-days')}
                  ref={register()}
                />
              </Form.Field>
            </Form.Group>
          </Form>
          <Message>{translate('accommodation.general-information')}</Message>
        </Modal.Content>
        <Modal.Actions>
          <ErrorMessage error={errorMessage} />
          <Button color="green" size="small" onClick={handleSubmit(onSubmit)} loading={loading}>
            <Translate id="default.save" />
          </Button>
          <Button size="small" onClick={onClickCancel} disabled={loading}>
            <Translate id="default.cancel" />
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default withLocalize(AccommodationEdit);
