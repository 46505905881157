import moment from 'moment';
import React from 'react';
import { Button, List, Modal, Label } from 'semantic-ui-react';
import { StatusColor } from '../../core/constants';
import { getMaxDate, getTimeHHMM } from '../../utils';

function BookingsSelection({ bookings, onClose, onItemClick, translate, onCreateNewBooking }) {
  const [firstBooking] = bookings;

  const createNewBooking = () => {
    if (bookings.length > 1) {
      const dates = bookings.map(d => moment(d.endDatetime));
      const maxDate = getMaxDate(dates);
      const booking = bookings.find(a => a.endDatetime === maxDate.format('YYYY-MM-DD HH:mm'));
      onCreateNewBooking(maxDate, booking);
    } else {
      onCreateNewBooking(bookings[0].endDatetime, bookings[0]);
    }
  };

  return (
    <Modal open size="large" className="modal-compact">
      <>
        <Modal.Header>{firstBooking?.rentalUnit?.name}</Modal.Header>
        <Modal.Content>
          <List selection divided verticalAlign="middle" size="large">
            {bookings.map(booking => {
              const startTime = getTimeHHMM(booking.startDatetime);
              const endTime = getTimeHHMM(booking.endDatetime);
              return (
                <>
                  <List.Item key={booking.id} onClick={() => onItemClick(booking)}>
                    <List.Content>
                      <List.Header>
                        {booking.bookingUser?.name} - {startTime} - {endTime}
                        {/* {booking.startDatetime} - {booking.endDatetime} */}
                      </List.Header>
                      <Label style={{ backgroundColor: booking?.statusColor || StatusColor.DEFAULT }}>
                        {translate(`booking-status.${booking.status.toLowerCase()}`)}
                      </Label>
                    </List.Content>
                  </List.Item>
                </>
              );
            })}
          </List>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={onClose}>
            {translate('default.close')}
          </Button>
          <Button color="green" onClick={createNewBooking}>
            {translate('rental-unit.booking-create')}
          </Button>
        </Modal.Actions>
      </>
    </Modal>
  );
}

export default BookingsSelection;
