// this value is based on db seed data for RentalUnitType

export const RentalUnitType = {
  CAMPING: {
    id: 1,
    name: 'Camping'
  },
  LODGE: {
    id: 2,
    name: 'Lodge'
  },
  GOLF_CART: {
    id: 3,
    name: 'Golf cart'
  },
  GOLF_BIKE: {
    id: 6,
    name: 'Golf bike'
  },
  ROOMS: {
    id: 7,
    name: 'Rooms'
  }
};

export const RentalUnitTypeIdsForAccommodations = [
  RentalUnitType.CAMPING.id,
  RentalUnitType.LODGE.id,
  RentalUnitType.ROOMS.id
];

export const RentalUnitTypeIdsForEquipments = [RentalUnitType.GOLF_CART.id, RentalUnitType.GOLF_BIKE.id];
