import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { Container, Segment, Loader, Dimmer, Icon, Grid, Form } from 'semantic-ui-react';
import moment from 'moment';
import { connect } from 'react-redux';
import { PriceType, Source, BookingStatus, ActiveRequest } from '../core/enums';
import { getGolfClubs } from '../containers/RentalUnitSheet/ducks';
import { ErrorMessage } from '../components/ErrorMessage';
import RentalUnitRow from '../components/BookingOverView/RentalUnitRow';
import { CellHeader } from '../components/BookingOverView/Cell';
import { getAvailableRentalUnit } from '../utils';
import { rentalUnitBookingService, processOrder } from '../core/services';
import { NO_PRICING, ONGOING_WIDGET_BOOKING, StatusColor } from '../core/constants';
import BookingModalViewRentals from '../components/BookingOverView/BookingModalViewRentals';
import BookingsSelection from '../components/BookingOverView/BookingsSelection';
import { customChain } from '../utils/custom-lodash-function';

/**
 * Returns a booking object color coded depnding on status
 *
 * @param {object} booking Booking object.
 */
const colorCodeStatus = booking => {
  const now = moment();
  const startDatetime = moment(booking.startDatetime);
  const endDatetime = moment(booking.endDatetime);

  if (booking.status === BookingStatus.PREBOOKED) {
    // Status prebook, yellow color if current user "owns" the booking, otherwise orange color
    return booking.currentUserPrebooking ? StatusColor.PREBOOKED_SAME_USER : StatusColor.PREBOOKED_DIFFERENT_USER;
  }
  if (startDatetime.isSameOrAfter(now) && booking.paid) {
    // Green color if check out date has not passed and booking is paid.
    // This is an active booking where everything (marked as checked in and paid) is ok
    return StatusColor.ACTIVE_BOOKING;
  }
  if (startDatetime.isSameOrAfter(now, 'day')) {
    // Blue color if check in date has not passed. In other words, booking is in the future
    return StatusColor.FUTURE_BOOKING;
  }
  if (startDatetime.isBefore(now) && !booking.paid) {
    // Red color if check in date has passed but booking is not marked as checked in or not as paid
    // Ongoing booking that not checked in or not paid
    return StatusColor.PASSED_INCOMPLETE_BOOKING;
  }
  if (endDatetime.isBefore(now) && booking.paid) {
    // Gray color if check out date has passed and booking is checked in and paid.
    // Booking is ok and completed, check out date has passed.
    return StatusColor.PASSED_COMPLETE_BOOKING;
  }

  // Purple color - We shouldn't end up here but just in case otherwise the are white and can be missed in the scheme
  return StatusColor.DEFAULT;
};

/**
 * Returns a "smaller" booking object
 *
 * @param {object} booking Booking object.
 */
const minBooking = (booking, golfClubCurrency) => {
  if (booking.bookings.length > 1) {
    booking.bookings.forEach(x => {
      x.statusColor = colorCodeStatus(x);
    });
    const [firstBooking] = booking.bookings;
    const statusColor = booking.bookings.some(x => x.statusColor === StatusColor.PASSED_INCOMPLETE_BOOKING)
      ? StatusColor.PASSED_INCOMPLETE_BOOKING
      : firstBooking.statusColor;

    return {
      statusColor,
      bookings: booking.bookings,
      info: <div>{booking.bookings.length} bokningar</div>,
      name: booking.bookings.length,
      isMultipleBookings: true
    };
  }

  const [currentBooking] = booking.bookings;

  return {
    id: currentBooking.id,
    rentalUnitId: currentBooking.rentalUnit.id,
    name: currentBooking.bookingUser?.name,
    email: currentBooking.bookingUser?.email,
    startDatetime: currentBooking.startDatetime,
    endDatetime: currentBooking.endDatetime,
    priceInclVat: currentBooking.priceInclVat,
    status: currentBooking.status,
    currentUserPrebooking: currentBooking.currentUserPrebooking,
    statusColor: colorCodeStatus(currentBooking),
    info: (
      <>
        <p>{currentBooking?.bookingUser?.email}</p>
        <div>
          <div>
            <b>Start: </b>
            <span> {`${currentBooking?.startDatetime}`}</span>
          </div>
          <div>
            <b>End:</b>
            <span> {`${currentBooking?.endDatetime}`}</span>
          </div>
          <div>
            <b>Betald:</b>
            <span>
              {currentBooking.paid ? <Icon name="check" color="green" /> : <Icon name="close" color="red" />}{' '}
              {currentBooking.priceInclVat} {golfClubCurrency}
            </span>
          </div>
        </div>
      </>
    )
  };
};

/**
 * Returns extracted data for easy population of the overview schedule
 *
 * @param {object} availability Availability object fetched from backend.
 */

const extractOverviewData = (availability, sDateMonth, eDateMonth, translate, golfClubCurrency) => {
  const startDateMonth = moment(sDateMonth);
  const endDateMonth = moment(eDateMonth).subtract(1, 'day'); // Request to backend is first day in next month. Step back to last day in current month

  const nrOfDaysInMonth = startDateMonth.daysInMonth();

  return availability?.rentalUnits.map(rentalUnit => {
    let scheme = [];
    let currentDate = startDateMonth;
    const bookingWithStartDate = rentalUnit.bookings.map(x => ({
      ...x,
      startDate: moment(x.startDatetime).format('YYYY-MM-DD')
    }));
    const groupedBookings = customChain(bookingWithStartDate)
      .groupBy('startDate')
      .map((value, key) => ({ bookings: value, startDatetime: key }))
      .value();
    const bookingsAndBlockPeriod =
      rentalUnit.blockedPeriod?.length > 0 ? [...groupedBookings, ...rentalUnit.blockedPeriod] : [...groupedBookings];

    bookingsAndBlockPeriod.forEach(x => {
      // blocked period use startDate and endDate, treated as booking
      if (x.startDate && x.endDate) {
        x.startDatetime = x.startDate;
        x.endDatetime = x.endDate;
        x.isBlockedPeriod = true;
      }
    });

    if (bookingsAndBlockPeriod.length === 0) {
      scheme = scheme.concat([
        {
          day: parseInt(currentDate.format('DD'), 10),
          nrOfDays: endDateMonth.diff(currentDate, 'day') + 1
        }
      ]);
    } else {
      bookingsAndBlockPeriod
        .sort((a, b) => moment(a.startDatetime).diff(b.startDatetime))
        .forEach(booking => {
          const currentBooking = booking.isBlockedPeriod ? booking : booking.bookings[0];

          const startDatetime = moment(currentBooking.startDatetime).clone().startOf('day');
          const endDatetime = moment(currentBooking.endDatetime).clone().startOf('day');
          const startDate = startDatetime.isBefore(startDateMonth) ? startDateMonth : startDatetime;
          // Add 1 day makes sure bookings that start last day in month is included
          const endDate = endDatetime.isAfter(endDateMonth)
            ? endDateMonth.clone().add(1, 'day')
            : startDatetime.format('YYYY-MM-DD') === endDatetime.format('YYYY-MM-DD')
            ? endDatetime.clone().add(1, 'day')
            : endDatetime;

          const nrOfDays = startDate.diff(currentDate, 'day');
          scheme = scheme.concat([{ day: parseInt(currentDate.format('DD'), 10), nrOfDays }]);
          currentDate = currentDate.clone().add(nrOfDays, 'day');
          scheme = scheme.concat([
            {
              day: parseInt(currentDate.format('DD'), 10),
              nrOfDays: endDate.diff(startDate, 'day'),
              booking: currentBooking.isBlockedPeriod ? currentBooking : minBooking(booking, golfClubCurrency),
              startDatetimeInPrevMonth: startDatetime.isBefore(startDateMonth)
            }
          ]);
          currentDate = endDate;
        });

      scheme = scheme.filter(s => s.nrOfDays > 0);

      const lastObj = scheme[scheme.length - 1];
      const lastDay = parseInt(lastObj.day, 10) + lastObj.nrOfDays;
      if (lastDay <= nrOfDaysInMonth) {
        const nrOfDays = endDateMonth.diff(currentDate, 'day') + 1;
        scheme = scheme.concat([{ day: parseInt(currentDate.format('DD'), 10), nrOfDays }]);
      }
    }

    return {
      id: rentalUnit.rentalUnit.id,
      name: rentalUnit.rentalUnit.name,
      rentalUnitTypeId: rentalUnit.rentalUnit.type.id,
      info: <>{translate(`accommodation.${rentalUnit.rentalUnit.type.name}`)}</>,
      scheme
    };
  });
};

/**
 * Returns formatted checkin and checkout dates in format YYYY-MM-DD
 *
 * @param {object} dates {startDay, endDay}.
 * @param {string} year
 */
const formatDates = (yearMonth, { startDay, endDay }) => {
  const tmpStartDay = parseInt(startDay, 10);
  const yearMonthEnd = yearMonth.clone();

  const startDatetime = `${yearMonth.format('YYYY')}-${yearMonth.format('MM')}-${
    tmpStartDay < 10 ? `0${tmpStartDay}` : tmpStartDay
  } 00:00`;
  const endDatetime = `${yearMonthEnd.format('YYYY')}-${yearMonthEnd.format('MM')}-${
    tmpStartDay < 10 ? `0${tmpStartDay}` : tmpStartDay
  } 04:00`;
  const nominalStart = startDatetime;

  return { startDatetime, endDatetime, nominalStart };
};

/**
 * Normalize a booking to ensure we compare correct values
 *
 * @param {object} booking A booking object.
 */
const normalizeBooking = booking => ({
  ...booking,
  days: moment(booking.endDatetime).diff(booking.startDatetime, 'day'),
  paid: !!booking.paid,
  checkedIn: !!booking.checkedIn,
  bookingUser: {
    userId: booking.bookingUser.userId || '',
    name: booking.bookingUser.name || '',
    email: booking.bookingUser.email || '',
    golfId: booking.bookingUser.golfId || '',
    phone: booking.bookingUser.phone || '',
    plateNumber: booking.bookingUser.plateNumber || ''
  }
});

const cellWidth = 30;
let monthAvailability = null;
let bookingHasChanged = false;
let allOverviewData = null;

function RentalUnitOverview({ golfclub, daysInMonth, selectedYearMonth, dateRange, translate, rentalUnitTypes }) {
  const [overViewData, setOverViewData] = useState(null);
  const [activeBooking, setActiveBooking] = useState(null);
  const [bookingsSelection, setBookingsSelection] = useState(null);
  const [activeRequest, setActiveRequest] = useState(null);
  const [error, setError] = useState('');
  const [pricings, setPricings] = useState(null);
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    if (dateRange && golfclub) {
      const { dateFrom, dateTo } = dateRange;
      fetchRentalUnitAvailability({ dateFrom, dateTo, setAvailability: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  // TODO: refer to calender and see difference
  const updateBooking = async (status, booking, customer = null) => {
    setActiveRequest(status);
    setError('');

    const bStatus = status === BookingStatus.UPDATED ? BookingStatus.CONFIRMED : status;

    const confirmBooking = {
      ...booking,
      rentalUnitBookingDiscounts: [],
      bookingUser: customer,
      status: bStatus,
      source: Source.ADMIN
    };

    const { error: bookingError, data: availability } = await rentalUnitBookingService.create(confirmBooking);

    if (bookingError) {
      if (bookingError.errorCode === 'conflict') {
        setError('Tiden är upptagen av en annan bokning, hämta data på nytt för att se tillkomna bokningar');
      } else if (bookingError.error) {
        setError(bookingError.error);
      } else {
        setError(translate(`booking-status-error.${status?.toLowerCase()}`));
      }
    } else {
      bookingHasChanged = true;
      const { availableRentalUnit } = getAvailableRentalUnit({
        rentalUnitId: booking.rentalUnit.id,
        defaultAvailability: monthAvailability,
        availability
      });

      const confirmedBooking = availability.bookingId
        ? availableRentalUnit.bookings.find(x => x.id === availability.bookingId)
        : availableRentalUnit.bookings[0];

      if (bStatus === BookingStatus.CANCELLED) {
        onCloseBookingView();
        return;
      }

      if (bStatus === BookingStatus.CONFIRMED) {
        await processOrder({
          rentalUnitBookingId: confirmBooking.id,
          customer,
          golfClubId: golfclub.id,
          isProcessCharge: false,
          isOrderConfirmed: true
        });
      }

      if (confirmedBooking) {
        setActiveBooking(normalizeBooking(confirmedBooking));
      }
    }
    setActiveRequest(null);
  };

  const fetchRentalUnitAvailability = async ({
    dateFrom = null,
    dateTo = null,
    currentRentalUnitBookingId = null,
    setAvailability = false
  } = {}) => {
    allOverviewData = setAvailability ? null : allOverviewData;
    setActiveRequest(ActiveRequest.FETCHING_OBJECTS);
    setOverViewData(null);
    setError(null);

    const { error: fetchError, data: availability } = await rentalUnitBookingService.getAvailableUnits(
      golfclub.id,
      dateFrom,
      dateTo,
      false,
      currentRentalUnitBookingId
    );

    if (fetchError) {
      setError(fetchError.error || 'Tillgänliga objekt kunde inte hämtas, vänligen försök på nytt.');
      setActiveRequest(null);
    } else {
      setPricings(availability.pricings);
      if (setAvailability) {
        const extractedOverviewData = extractOverviewData(availability, dateFrom, dateTo, translate, golfclub.currency);
        setOverViewData(extractedOverviewData);
        monthAvailability = availability;
        allOverviewData = extractedOverviewData;
      } else {
        setOverViewData(allOverviewData);
      }
    }
    setActiveRequest(null);

    return availability;
  };

  const onCloseBookingsSelection = () => {
    setBookingsSelection(null);
  };

  const onClickMultipleBookings = bookings => {
    // show modal for selection, show each status
    setBookingsSelection(bookings);
  };

  const onClickOccupied = clickedBooking => {
    if (bookingsSelection) {
      setBookingsSelection(null);
      clickedBooking.rentalUnitId = clickedBooking.rentalUnit.id;
    }
    const booking = monthAvailability.rentalUnits
      .find(a => a.rentalUnit.id === clickedBooking.rentalUnitId)
      .bookings.find(b => b.id === clickedBooking.id);

    if (booking.status === BookingStatus.PREBOOKED && Number(booking.source) === Source.WIDGET) {
      setError(translate(ONGOING_WIDGET_BOOKING));
    } else {
      if (!activeBooking) {
        setBookingsSelection([
          {
            ...booking,
            statusColor: clickedBooking.statusColor
          }
        ]);
        setActiveBooking(null);
      }
      if (bookingsSelection && !activeBooking) {
        setBookingsSelection(null);
        setActiveBooking(normalizeBooking(booking));
      }
    }
  };

  const onClickAvailable = async (rentalUnitId, dayPeriod) => {
    const { startDatetime, endDatetime, nominalStart } = formatDates(selectedYearMonth, dayPeriod);

    if (moment(startDatetime).isBefore(moment(), 'day')) {
      return;
    }

    setActiveRequest(true);
    setError('');
    const { availableRentalUnit, pricing } = getAvailableRentalUnit({
      rentalUnitId,
      defaultAvailability: monthAvailability
    });

    if (!pricing.pricing) {
      setError(translate(NO_PRICING));
      setActiveRequest(false);
      return;
    }

    const booking = {
      rentalUnit: availableRentalUnit.rentalUnit,
      golfClubId: golfclub.id,
      startDatetime,
      endDatetime,
      priceType: PriceType.SELL,
      nominalStart
    };

    updateBooking(BookingStatus.PREBOOKED, booking);
  };

  const createNewBooking = async booking => {
    // set active booking null, then call update booking
    setActiveBooking(null);
    setBookingsSelection(null);
    await updateBooking(BookingStatus.PREBOOKED, booking);
  };

  const displayNewBookingModal = (endDateTime, booking) => {
    const startDatetime = moment(endDateTime).add(1, 'hour').format('YYYY-MM-DD HH:mm');
    const endDatetime = moment(startDatetime).add(4, 'hours').format('YYYY-MM-DD HH:mm');
    const nominalStart = startDatetime;
    createNewBooking({
      rentalUnit: booking.rentalUnit,
      golfClubId: booking.golfClubId,
      startDatetime,
      endDatetime,
      priceType: booking.priceType,
      nominalStart
    });
  };

  const onCloseBookingView = async () => {
    setActiveRequest('fetch');
    setActiveBooking(null);
    setError('');
    if (activeBooking.status === BookingStatus.PREBOOKED) {
      await updateBooking(BookingStatus.PREBOOK_CANCELLED, activeBooking);
    }

    if (bookingHasChanged) {
      const { dateFrom, dateTo } = dateRange;
      await fetchRentalUnitAvailability({ dateFrom, dateTo, setAvailability: true });
      bookingHasChanged = false;
    }

    setActiveRequest(null);
  };
  const filterRentalUnits = (filter, data) => {
    let filteredRentalUnits = data;
    if (Object.keys(filter).length) {
      if (filter.rentalUnitTypeId) {
        filteredRentalUnits = filteredRentalUnits.filter(a => a.rentalUnitTypeId === filter.rentalUnitTypeId);
      }
    }
    return filteredRentalUnits;
  };

  const filterAndShowRentalUnits = (_e, data) => {
    if (_e === undefined) {
      setOverViewData(filterRentalUnits(filterData, allOverviewData));
    } else {
      let newFilterData = {};
      const { name, value, checked } = data;
      if (name === 'rentalUnitTypeId') {
        if (value && value !== filterData.rentalUnitTypeId) {
          newFilterData = { [name]: value };
        }
        setFilterData(newFilterData);
        setOverViewData(filterRentalUnits(newFilterData, allOverviewData));
      } else {
        newFilterData = { ...filterData, [name]: checked || value || false };
        setFilterData(newFilterData);
        setOverViewData(filterRentalUnits(newFilterData, allOverviewData));
      }
    }
    setActiveRequest(null);
  };

  return (
    <>
      <div className="overview-schedule" style={{ marginTop: 20, marginBottom: 20 }}>
        <Container>
          <Segment>
            <Dimmer active={!!activeRequest} inverted>
              <Loader />
            </Dimmer>
            <div className="overview">
              <Grid>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <h3 className="information">{translate('club-admin.rental-units')}</h3>
                  </Grid.Column>

                  <Grid.Column width={8}>
                    {/* TODO: consider what filter to show here. check calendar components */}
                    <div className="mb-1 d-flex" style={{ alignItems: 'center' }}>
                      <Form.Select
                        name="rentalUnitTypeId"
                        placeholder="Filtrera på typ"
                        clearable
                        options={rentalUnitTypes || []}
                        onChange={filterAndShowRentalUnits}
                        style={{ marginRight: '10px' }}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <div className="information mb-1 d-flex">
                <ErrorMessage error={error} />
              </div>
              <div className="d-flex">
                <div className="d-flex accommodationNameCell" style={{ width: '130px', maxWidth: '130px' }}>
                  &nbsp;
                </div>
                {daysInMonth.map(d => (
                  <div key={d.day}>
                    <CellHeader label={d.day} isWeekend={d.isWeekend} cellWidth={cellWidth} />
                  </div>
                ))}
              </div>
              {overViewData?.map(d => (
                <RentalUnitRow
                  key={d.id}
                  cellWidth={cellWidth}
                  rentalUnit={d}
                  daysInMonth={selectedYearMonth.daysInMonth()}
                  onClickAvailable={onClickAvailable}
                  onClickOccupied={onClickOccupied}
                  onClickMultipleBookings={onClickMultipleBookings}
                  overViewDate={selectedYearMonth}
                  translate={translate}
                />
              ))}
            </div>
          </Segment>
        </Container>
      </div>
      {activeBooking && (
        <BookingModalViewRentals
          booking={activeBooking}
          rentalUnits={overViewData}
          updateBooking={updateBooking}
          golfclub={golfclub}
          onClose={onCloseBookingView}
          activeRequest={activeRequest}
          setActiveRequest={request => setActiveRequest(request)}
          error={error}
          setError={setError}
          isWidgetRequest={false}
          fetchRentalUnitAvailability={fetchRentalUnitAvailability}
          pricings={pricings}
          onClickNewBooking={createNewBooking}
        />
      )}
      {bookingsSelection && (
        <BookingsSelection
          bookings={bookingsSelection}
          translate={translate}
          onClose={onCloseBookingsSelection}
          onItemClick={onClickOccupied}
          onCreateNewBooking={displayNewBookingModal}
        />
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    golfClubs: getGolfClubs(state)
  };
}

export default withLocalize(connect(mapStateToProps, null)(RentalUnitOverview));
