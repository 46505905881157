import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Translate, withLocalize } from 'react-localize-redux';

import { Grid, Form, Message } from 'semantic-ui-react';

import { resetPasswordAction } from './ducks';
import { emailRegex } from '../../utils';

class ResetPasswordForm extends React.Component {
  constructor() {
    super();

    this.state = {
      user: { email: '' },
      valid: {
        email: true
      }
    };
  }

  UNSAFE_componentWillMount = () => {
    const { login } = this.props;
    if (login.isAuthenticated && login.token) {
      this.props.history.push('/');
    }
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    const { login } = nextProps;
    if (login.token !== this.props.login.token && login.isAuthenticated && login.token) {
      const { state } = this.props.location;
      const { from } = state || {};
      const { pathname } = from || { pathname: '/' };
      this.props.history.push(pathname);
    }
  };

  validateEmail = e => {
    const email = e.target.value;
    const { user, valid } = this.state;

    user.email = email;
    valid.email = emailRegex.test(email);

    this.setState({
      user,
      valid
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.resetPasswordAction(this.state.user);
  };

  render() {
    const { valid } = this.state;
    const { translate } = this.props;

    return (
      <Grid columns="1" stackable>
        <Grid.Column className="bg-white">
          <div className="p-3">
            <h1>
              <Translate id="password.forgot" />
            </h1>
            {this.props.passwordResetDone && <Message success content={translate('password.email-instructions')} />}
            {!this.props.passwordResetDone && (
              <div>
                <p className="text-muted">
                  <Translate id="password.enter-email" />
                </p>
                <Form onSubmit={this.handleSubmit} loading={this.props.activeRequest} error={!!this.props.error}>
                  <Form.Field className="mb-3">
                    <Form.Input
                      label={translate('default.email')}
                      type="email"
                      name="email"
                      placeholder={translate('default.email')}
                      onChange={this.validateEmail}
                      error={!valid.email}
                    />
                  </Form.Field>
                  <Message error content={this.props.error} />
                  <Form.Button fluid color="teal" disabled={!valid.email}>
                    <Translate id="default.submit" />
                  </Form.Button>
                </Form>
              </div>
            )}
          </div>
        </Grid.Column>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    error: state.resetPassword.error,
    activeRequest: state.resetPassword.activeRequest,
    passwordResetDone: state.resetPassword.passwordResetDone
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetPasswordAction: user => dispatch(resetPasswordAction(user))
  };
}

export default withLocalize(withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm)));
