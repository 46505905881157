import React from 'react';
import { Translate } from 'react-localize-redux';

import { Button, Form, List, Label, Message, Loader } from 'semantic-ui-react';
import ViewEditDeleteComponent from './ViewEditDeleteComponent';

import moment from 'moment';
// import 'moment/locale/sv';
// moment.locale('sv');
moment.updateLocale('en', {
  week: {
    dow: 1 // Monday is the first day of the week.
  }
});

const emptyIfNull = str => {
  return str ? str : '';
};

class AccommodationBookingView extends React.Component {
  getAvailabilityStatus = acc => {
    const bookings = acc.bookings.filter(x => x.status === 'Confirmed' || x.status === 'Prebooked');
    if (acc.available) {
      return 'Available';
    } else if (bookings.length > 0) {
      if (bookings.length === 1 && bookings[0].status === 'Prebooked') {
        return 'Prebooked';
      }
      return 'Booked';
    } else if (acc.blockedPeriod) {
      return 'Unavailable';
    }
    return '';
  };

  getAccomodationPrice = (accommodationTypeName, pricings) => {
    // console.log('getAccomodationPrice', accommodationTypeName, pricings);
    return pricings?.find(x => x.priceCategory.id === accommodationTypeName)?.sellPriceInclVat;
  };

  render() {
    const acc = this.props.data;
    const { pricing, golfClubCurrency } = this.props;
    const status = this.getAvailabilityStatus(acc);
    const price = this.getAccomodationPrice(acc.accommodation.priceCategoryId, pricing[0].pricings);

    // console.log('acc', acc);
    // console.log('price', price);
    return (
      <Translate>
        {({ translate }) => (
          <div style={{ margin: '5px' }}>
            <h4>
              {acc.accommodation.name} ({translate(`accommodation.${acc.accommodation.priceCategory.name}`)}) - {price}{' '}
              {golfClubCurrency}
            </h4>
            {(status !== '' && (
              <Label color={status === 'Available' ? 'green' : 'red'} size="medium">
                <Translate id={`booking-availability.${status.toLowerCase()}`} />
              </Label>
            )) || <Loader inline active />}
          </div>
        )}
      </Translate>
    );
  }
}
class AccommodationViewItem extends React.Component {
  render() {
    return (
      <>
        <Translate>
          {({ translate }) => (
            <List>
              <List.Item>
                <Label size="medium">
                  <Translate id="accommodation.name" />:<Label.Detail>{this.props.data.name}</Label.Detail>
                </Label>
              </List.Item>
              <List.Item>
                <Label size="medium">
                  <Translate id="accommodation.type" />:
                  <Label.Detail>{translate(`accommodation.${this.props.data.type.name}`)}</Label.Detail>
                </Label>
                <Label size="medium">
                  <Translate id="accommodation.category" />:
                  <Label.Detail>{translate(`accommodation.${this.props.data.priceCategory.name}`)}</Label.Detail>
                </Label>
                {this.props.data.numberOfBeds && (
                  <Label size="medium">
                    <Translate id="accommodation.number-of-beds" />:
                    <Label.Detail>{this.props.data.numberOfBeds}</Label.Detail>
                  </Label>
                )}
                <Label size="medium">
                  <Translate id="default.info" />:<Label.Detail>{this.props.data.info}</Label.Detail>
                </Label>
              </List.Item>
            </List>
          )}
        </Translate>
      </>
    );
  }
}
class AccommodationEditItem extends React.Component {
  state = {
    accommodation: {
      name: '',
      info: '',
      numberOfBeds: null,
      type: { name: '' },
      priceCategory: { name: '' }
    },
    valid: {
      all: true,
      name: true,
      numberOfBeds: true
    },
    selectedAccommodationsTypeIndex: 0,
    selectedPriceCategoryIndex: 0
  };

  componentDidMount = () => {
    const { data } = this.props;
    let selectedAccommodationsTypeIndex = 0;
    let selectedPriceCategoryIndex = 0;
    if (data && data.type) {
      const i = this.props.accommodationTypes.findIndex(x => x.id === data.type.id);
      selectedAccommodationsTypeIndex = i >= 0 ? i : selectedAccommodationsTypeIndex;
    }
    if (data && data.priceCategory) {
      const i = this.props.priceCategories
        .filter(x => x.accommodationTypeId === data.type.id)
        .findIndex(x => x.id === data.priceCategory.id);
      selectedPriceCategoryIndex = i >= 0 ? i : selectedPriceCategoryIndex;
    }
    this.setState({ accommodation: data, selectedAccommodationsTypeIndex, selectedPriceCategoryIndex });
    // if (!this.props.data) {
    //   return;
    // }
    // const { data } = this.props;
    // this.validateDescription(null, {value : data.description})
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { descriptionValid, blockedTimespansValid } = this.state;
    if (
      prevState.descriptionValid !== this.state.descriptionValid ||
      prevState.blockedTimespansValid !== this.state.blockedTimespansValid
    ) {
      this.props.dataIsValid(descriptionValid && blockedTimespansValid);
    }
  };

  validateField = (e, validator) => {
    const val = e.target.value;
    const field = e.target.name;
    const { accommodation, valid } = this.state;

    accommodation[field] = val;
    valid[field] = validator(val, e);
    valid.all = valid.name === true && valid.numberOfBeds === true;

    this.setState({
      accommodation,
      valid
    });
    this.props.dataIsValid(valid.all);
  };

  validateName = e => this.validateField(e, val => val.length > 0);

  validateNumberOfBeds = e =>
    this.validateField(
      e,
      val => (val === '' && this.isCamping()) || (!isNaN(parseInt(val, 10)) && isFinite(val) && val <= 20)
    );

  validateInfo = e => this.validateField(e, val => val);

  subcomponentStateUpdated = (blockedTimespans, blockedTimespansValid) => {
    this.setState({ blockedTimespans, blockedTimespansValid });
  };

  saveItem = () => {
    const { accommodation } = this.state;
    const data = accommodation;
    data.golfClubId = this.props.data.golfClubId;
    data.type = this.props.accommodationTypes[this.state.selectedAccommodationsTypeIndex];
    data.priceCategory = this.props.priceCategories.filter(x => x.accommodationTypeId === data.type.id)[
      this.state.selectedPriceCategoryIndex
    ];
    if (this.isCamping()) {
      data.numberOfBeds = null;
    }

    if (Object.prototype.hasOwnProperty.call(this.props.data, 'id')) {
      data.id = this.props.data.id;
    }
    this.props.saveAction(data.golfClubId, data, this.props.onSubmit);
  };

  isCamping = () => {
    return this.props.accommodationTypes[this.state.selectedAccommodationsTypeIndex].name
      .toLowerCase()
      .includes('camping');
  };

  accommodationTypeChanged = (e, d) => {
    this.setState({ selectedAccommodationsTypeIndex: d.value });
    this.validateField(e, _val => true);
  };

  priceCategoryChanged = (e, d) => {
    this.setState({ selectedPriceCategoryIndex: d.value });
    this.validateField(e, _val => true);
  };

  getPriceCategories = () => {
    const selectedAccommodationType = this.props.accommodationTypes[this.state.selectedAccommodationsTypeIndex];
    // console.log('selectedAccommodationType', selectedAccommodationType);
    return this.props.priceCategories.filter(x => x.accommodationTypeId === selectedAccommodationType.id);
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <Form id={this.props.id} onSubmit={this.saveItem}>
            <List>
              <List.Item>
                <Form.Field>
                  <Form.Input
                    required
                    label={translate('default.name')}
                    type="text"
                    name="name"
                    placeholder={translate('default.name')}
                    onChange={this.validateName}
                    value={this.state.accommodation.name}
                    error={!this.state.valid.name}
                  />
                </Form.Field>
                {this.props.accommodationTypes && this.props.accommodationTypes.length > 0 && (
                  <Form.Select
                    fluid
                    label={translate('accommodation.type')}
                    onChange={this.accommodationTypeChanged}
                    options={this.props.accommodationTypes.map((x, i) => ({
                      text: translate(`accommodation.${x.name}`),
                      value: i
                    }))}
                    placeholder={translate('accommodation.type')}
                    value={this.state.selectedAccommodationsTypeIndex}
                  />
                )}
                {this.props.priceCategories && this.props.priceCategories.length > 0 && (
                  <Form.Select
                    fluid
                    label={translate('accommodation.category')}
                    onChange={this.priceCategoryChanged}
                    options={this.getPriceCategories().map((x, i) => ({
                      text: translate(`accommodation.${x.name}`),
                      value: i
                    }))}
                    placeholder={translate('accommodation.category')}
                    value={this.state.selectedPriceCategoryIndex}
                  />
                )}
                <Form.Field>
                  <Form.Input
                    label={translate('accommodation.number-of-beds')}
                    type="number"
                    name="numberOfBeds"
                    disabled={this.isCamping()}
                    min={1}
                    max={20}
                    placeholder={translate('accommodation.number-of-beds')}
                    onChange={this.validateNumberOfBeds}
                    error={!this.state.valid.numberOfBeds}
                    value={emptyIfNull(this.state.accommodation.numberOfBeds)}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    label={translate('accommodation.info')}
                    type="text"
                    name="info"
                    // disabled={this.isCamping()}
                    placeholder={translate('accommodation.info')}
                    onChange={this.validateInfo}
                    // error={!this.state.valid.numberOfBeds}
                    value={emptyIfNull(this.state.accommodation.info)}
                  />
                </Form.Field>
              </List.Item>

              {!this.state.valid.name && (
                <Message color="orange" visible content={translate('accommodation.enter-name')} />
              )}
            </List>
          </Form>
        )}
      </Translate>
    );
  }
}

class AccommodationBulkButton extends React.Component {
  render() {
    return (
      <Button onClick={this.props.bulkClicked}>
        <Translate id="accommodation.multiple" />
      </Button>
    );
  }
}

class AccommodationBulkComponent extends React.Component {
  render() {
    return (
      <ViewEditDeleteComponent
        deleteButtonText
        id={'accommodationBulkAdd'}
        data={this.props.emptyItem}
        isEditing={true}
        saveAction={this.props.saveAction}
        cancelCallback={this.props.bulkClicked}
        viewComponent={<p>Nothing</p>}
        editComponent={
          <AccommodationBulkEditComponent
            golfClubId={this.props.golfClubId}
            priceCategories={this.props.priceCategories}
            accommodationTypes={this.props.accommodationTypes}
          />
        }
      />
    );
  }
}

class AccommodationBulkEditComponent extends React.Component {
  state = {
    accommodation: {
      numberOfAccommodations: 1,
      numberOfBeds: null,
      type: { name: '' },
      priceCategory: { name: '' }
    },
    deleteExisting: false,
    valid: {
      all: true,
      numberOfAccommodations: true,
      numberOfBeds: true
    },
    selectedAccommodationsTypeIndex: 0,
    selectedPriceCategoryIndex: 0
  };

  componentDidMount() {
    this.props.dataIsValid(this.state.valid.all);
  }

  validateField = (e, validator) => {
    const val = e.target.value;
    const field = e.target.name;
    const { accommodation, valid } = this.state;

    accommodation[field] = val;
    valid[field] = validator(val, e);
    valid.all = valid.numberOfAccommodations === true && valid.numberOfBeds === true;

    this.setState({
      accommodation,
      valid
    });
    this.props.dataIsValid(valid.all);
  };

  validateName = e => this.validateField(e, val => val.length > 0);
  validateNumberOfBeds = e =>
    this.validateField(e, val => val === '' || (!isNaN(parseInt(val, 10)) && isFinite(val) && val <= 20));
  validateNumberOfAccommodations = e =>
    this.validateField(e, val => val === '' || (!isNaN(parseInt(val, 10)) && isFinite(val) && val < 200));

  subcomponentStateUpdated = (blockedTimespans, blockedTimespansValid) => {
    this.setState({ blockedTimespans, blockedTimespansValid });
  };

  saveItem = () => {
    const { accommodation, deleteExisting } = this.state;
    const accommodations = [...Array(parseInt(accommodation.numberOfAccommodations, 10))].map((_, i) => {
      const type = this.props.accommodationTypes[this.state.selectedAccommodationsTypeIndex];
      const priceCategories = this.props.priceCategories.filter(x => x.accommodationTypeId === type.id);
      const priceCategory = priceCategories[this.state.selectedPriceCategoryIndex];
      const newAcc = {
        golfClubId: this.props.golfClubId,
        name: `${type.name} ${i + 1}`,
        type,
        priceCategory,
        numberOfBeds: this.isCamping() ? null : accommodation.numberOfBeds
      };
      return newAcc;
    });
    const data = { accommodations, deleteExisting };
    this.props.saveAction(this.props.golfClubId, data, this.props.onSubmit);
  };

  isCamping = () => {
    return this.props.accommodationTypes[this.state.selectedAccommodationsTypeIndex].name
      .toLowerCase()
      .includes('camping');
  };

  accommodationTypeChanged = (_e, d) => {
    this.setState({ selectedAccommodationsTypeIndex: d.value, selectedPriceCategoryIndex: 0 });
  };

  getPriceCategories = () => {
    const selectedAccommodationType = this.props.accommodationTypes[this.state.selectedAccommodationsTypeIndex];
    return this.props.priceCategories.filter(x => x.accommodationTypeId === selectedAccommodationType.id);
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <Form id={this.props.id} onSubmit={this.saveItem}>
            <List>
              <List.Item>
                <Form.Field>
                  <Form.Input
                    label={translate('accommodation.accommodations')}
                    type="number"
                    name="numberOfAccommodations"
                    min={1}
                    max={200}
                    placeholder={translate('accommodation.accommodations')}
                    onChange={this.validateNumberOfAccommodations}
                    error={!this.state.valid.numberOfAccommodations}
                    value={emptyIfNull(this.state.accommodation.numberOfAccommodations)}
                  />
                </Form.Field>
                {this.props.accommodationTypes && this.props.accommodationTypes.length > 0 && (
                  <Form.Select
                    fluid
                    label={translate('accommodation.type')}
                    onChange={this.accommodationTypeChanged}
                    options={this.props.accommodationTypes.map((x, i) => ({ text: x.name, value: i }))}
                    placeholder={translate('accommodation.type')}
                    value={this.state.selectedAccommodationsTypeIndex}
                  />
                )}
                {this.props.priceCategories && this.props.priceCategories.length > 0 && (
                  <Form.Select
                    fluid
                    label={translate('accommodation.category')}
                    onChange={(_e, d) => this.setState({ selectedPriceCategoryIndex: d.value })}
                    options={this.getPriceCategories().map((x, i) => ({ text: x.name, value: i }))}
                    placeholder={translate('accommodation.category')}
                    value={this.state.selectedPriceCategoryIndex}
                  />
                )}
                <Form.Field>
                  <Form.Input
                    label={translate('accommodation.number-of-beds')}
                    type="number"
                    name="numberOfBeds"
                    disabled={this.isCamping()}
                    min={1}
                    max={20}
                    placeholder={translate('accommodation.number-of-beds')}
                    onChange={this.validateNumberOfBeds}
                    error={!this.state.valid.numberOfBeds}
                    value={emptyIfNull(this.state.accommodation.numberOfBeds)}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Checkbox
                    inline
                    label={translate('accommodation.delete')}
                    onChange={(e, { checked }) => {
                      this.setState({ deleteExisting: checked });
                    }}
                    checked={this.state.deleteExisting}
                  />
                </Form.Field>
              </List.Item>
            </List>
          </Form>
        )}
      </Translate>
    );
  }
}

export {
  AccommodationBookingView,
  AccommodationViewItem,
  AccommodationEditItem,
  AccommodationBulkButton,
  AccommodationBulkComponent
};
