import React from 'react';
import { Translate } from 'react-localize-redux';
import { Segment, Grid, Table } from 'semantic-ui-react';
import { isValidDate, startOfMonth, endOfMonth, todaysDate } from '../../../utils';
import { getTransactions } from '../../../core/services';
import { ReportDateComponent, ReportDateValidityChange } from '../ReportDateComponent';
import { CSVLink } from 'react-csv';

interface TransactionSummaryReportTabProps {
  active: boolean;
  golfClubs: any[];
  translate: any;
}

interface TransactionItem {
  id: string;
  parentTransactionId: string;
  paymentIntentId: string;
  sourceId: string;
  stripeFee: number;
  commission: number;
  amount: number;
  status: string;
  type: string;
  orderId: number;
  dateCreated: Date;
}

export class TransactionsSummaryReportTab extends React.Component<TransactionSummaryReportTabProps> {
  state = {
    startDate: startOfMonth(todaysDate()),
    startDateValid: true,
    endDate: endOfMonth(todaysDate()),
    endDateValid: true,
    loading: false,
    transactions: [] as TransactionItem[],
    sumAmount: 0,
    sumCommission: 0,
    sumFee: 0
  };

  transactionHeaders = [
    { label: 'Id', key: 'id' },
    { label: this.props.translate('club-admin.report-tabs.parent-id'), key: 'parentTransactionId' },
    { label: this.props.translate('club-admin.report-tabs.transaction-created'), key: 'dateCreated' },
    { label: this.props.translate('club-admin.report-tabs.transaction-type'), key: 'type' },
    { label: this.props.translate('club-admin.report-tabs.transaction-status'), key: 'status' },
    { label: this.props.translate('club-admin.report-tabs.order-id'), key: 'orderId' },
    { label: this.props.translate('club-admin.report-tabs.transaction-amount'), key: 'amount' },
    { label: this.props.translate('club-admin.report-tabs.transaction-commission'), key: 'commission' },
    { label: this.props.translate('club-admin.report-tabs.transaction-fee'), key: 'stripeFee' },
    { label: this.props.translate('club-admin.report-tabs.source-id'), key: 'sourceId' }
  ];

  getGolfClubId = () => {
    return this.props.golfClubs && this.props.golfClubs.length > 0 ? this.props.golfClubs[0].id : 0;
  };

  getGolfClubCurrency = () => {
    return this.props.golfClubs && this.props.golfClubs.length > 0 ? this.props.golfClubs[0].currency : 'N/A';
  };

  fetchTransactions = async () => {
    const { startDate, endDate, startDateValid, endDateValid } = this.state;
    if (startDateValid && endDateValid) {
      const golfClubId = this.getGolfClubId();

      this.setState({ loading: true });
      try {
        const trans = await getTransactions(golfClubId, startDate, endDate);

        if ('data' in trans) {
          const transactionsData = trans.data;
          let sumAmount: number = 0;
          let sumCommission: number = 0;
          let sumFee: number = 0;

          transactionsData.forEach((element: TransactionItem) => {
            element.type = this.props.translate(`transactions.type-${element.type}`);
            element.status = this.props.translate(`transactions.status-${element.status}`);

            sumAmount += Number(element.amount.toString().replace(',', '.'));
            sumCommission += Number(element.commission.toString().replace(',', '.'));
            sumFee += Number(element.stripeFee.toString().replace(',', '.'));
          });

          this.setState({
            loading: false,
            transactions: trans.data,
            sumAmount: sumAmount,
            sumCommission: sumCommission,
            sumFee: sumFee
          });
        }
      } catch (error) {
        this.setState({ loading: false });
      }
    }
  };

  validateDatetimeChange = (_e: any, values: ReportDateValidityChange) => {
    let { startDate, endDate, startDateValid, endDateValid } = this.state;

    if (values.name === 'startDate') {
      startDate = values.value;
      startDateValid = isValidDate(startDate);
      if (startDateValid) {
        endDate = endOfMonth(startDate);
      }
    } else {
      endDate = values.value;
    }
    endDateValid = isValidDate(endDate) && endDate >= startDate;
    this.setState({ startDate, endDate, startDateValid, endDateValid });
  };

  render() {
    const {
      startDate,
      endDate,
      startDateValid,
      endDateValid,
      loading,
      transactions,
      sumAmount,
      sumCommission,
      sumFee
    } = this.state;

    const sumAmountRounded = Math.round(sumAmount * 100) / 100;
    const sumCommissionRounded = Math.round(sumCommission * 100) / 100;
    const sumFeeRounded = Math.round(sumFee * 100) / 100;

    const transactionsCsvReport = {
      data: transactions,
      headers: this.transactionHeaders,
      filename: `${startDate} to ${endDate} ${this.props.translate('club-admin.report-tabs.transactions')}.csv`,
      separator: ';'
    };

    const golfClubCurrency = this.getGolfClubCurrency();

    if (this.props.active) {
      return (
        <Translate>
          {({ translate }) => (
            <Segment>
              <div className="p-1">
                <ReportDateComponent
                  startDate={startDate}
                  startDateValid={startDateValid}
                  endDate={endDate}
                  endDateValid={endDateValid}
                  loading={loading}
                  fetchBookings={this.fetchTransactions}
                  validateDatetimeChange={this.validateDatetimeChange}
                />

                <Grid>
                  <Grid.Column floated="left" width="5">
                    <Translate id="club-admin.report-tabs.transactions" />
                  </Grid.Column>
                  {transactions?.length > 0 && (
                    <Grid.Column floated="right" width="5" textAlign="right">
                      <CSVLink {...transactionsCsvReport}>{translate('club-admin.export-to-csv')}</CSVLink>
                    </Grid.Column>
                  )}
                </Grid>

                <Segment loading={loading}>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Id</Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="club-admin.report-tabs.transaction-created" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="club-admin.report-tabs.transaction-type" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="club-admin.report-tabs.transaction-status" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="club-admin.report-tabs.order-id" />
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          <Translate id="club-admin.report-tabs.transaction-amount" />
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          <Translate id="club-admin.report-tabs.transaction-commission" />
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          <Translate id="club-admin.report-tabs.transaction-fee" />
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {transactions?.map((transaction: TransactionItem, i: number) => {
                        return (
                          <Table.Row key={i}>
                            <Table.Cell>
                              {transaction.id}{' '}
                              {transaction.parentTransactionId ? `(${transaction.parentTransactionId})` : ''}
                            </Table.Cell>
                            <Table.Cell>{transaction.dateCreated}</Table.Cell>
                            <Table.Cell>{transaction.type}</Table.Cell>
                            <Table.Cell>{transaction.status}</Table.Cell>
                            <Table.Cell>{transaction.orderId}</Table.Cell>
                            <Table.Cell textAlign="right">{`${transaction.amount} ${golfClubCurrency}`}</Table.Cell>
                            <Table.Cell textAlign="right">{`${transaction.commission} ${golfClubCurrency}`}</Table.Cell>
                            <Table.Cell textAlign="right">{`${transaction.stripeFee} ${golfClubCurrency}`}</Table.Cell>
                          </Table.Row>
                        );
                      })}
                      {transactions?.length > 0 && (
                        <Table.Row style={{ fontWeight: 700 }}>
                          <Table.Cell>{translate('club-admin.sum')}</Table.Cell>
                          <Table.Cell />
                          <Table.Cell />
                          <Table.Cell />
                          <Table.Cell />
                          <Table.Cell textAlign="right">
                            {sumAmountRounded.toFixed(2).toString().replace('.', ',')} {golfClubCurrency}
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            {sumCommissionRounded.toFixed(2).replace('.', ',')} {golfClubCurrency}
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            {sumFeeRounded.toFixed(2).replace('.', ',')} {golfClubCurrency}
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Table.Body>
                  </Table>
                </Segment>
              </div>
            </Segment>
          )}
        </Translate>
      );
    } else {
      return <React.Fragment />;
    }
  }
}
