import React, { Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Translate, withLocalize } from 'react-localize-redux';

import { Menu, Segment, Message, Button, Dropdown } from 'semantic-ui-react';

import { clubAdminConfigActions } from './ducks';
import { ErrorMessage, WarningMessage } from '../../components/ErrorMessage';
import { EditableListComponent, EditableAccordionListComponent } from '../../components/EditableListComponent';
import {
  UnavailableTeetimeViewItem,
  UnavailableTeetimeEditItem,
  blockedTimespanEmpty
} from '../../components/UnavailableTeetime';
import {
  TeetimeScheduleViewTitleItem,
  TeetimeScheduleViewContentItem,
  TeetimeScheduleViewItem,
  TeetimeScheduleEditItem,
  pricePeriodEmpty,
  teeSlotPeriodEmpty
} from '../../components/TeetimeSchedule';
import { GolfClubViewItem, GolfClubEditItem } from '../../components/GolfClub';
import {
  GolfClubPlayerViewItem,
  GolfClubPlayerEditItem,
  FilterPlayersComponent
} from '../../components/GolfClubPlayer';
import { golfCourseEmpty } from '../../components/GolfCourseListComponent';
import { GolfCourseSelector } from '../../components/GolfCourseSelector';
import {
  AccommodationScheduleViewTitleItem,
  AccommodationScheduleViewContentItem,
  AccommodationScheduleEditItem,
  accWeekSetupEmpty,
  accPricePeriodEmpty
} from '../../components/AccommodationSchedule';
import {
  UnavailableAccommodationViewItem,
  UnavailableAccommodationEditItem
} from '../../components/UnavailableAccommodation';
import { todaysDate, endOfThisYearDate, isSuperAdmin } from '../../utils';

import { getRequest } from '../../utils/fetch';
import * as api from '../../utils/api';
import Discounts from '../../components/Discounts/Discounts';
import Accomodations from '../../components/Accommodations/Accomodations';
import RentalUnits from '../../components/RentalUnits/RentalUnits';
import { BOOKING_IN_BLOCK_PERIOD, IFrameType, OVERLAPPING_BLOCK_PERIOD } from '../../core/constants';
import ProductAddonsSettingsList from '../../components/ProductAddons/ProductAddonsSettingsList';
import PackagesList from '../../components/Packages/PackagesList';
import { CompanyEditItem, CompanyViewItem } from '../../components/GolfClub/Company';
import EmailsConfigTab from '../../components/GolfClub/EmailsConfigTab';

class ClubAdminRegisterForm extends React.Component {
  golfClub = 'Golf club';
  teetimeSchedule = 'Teetime schedule';
  unavailableTeetimes = 'Unavailable teetimes';
  admins = 'Administrators';
  golfClubPlayers = 'Golf club players';
  accommodations = 'Rentals';
  token = 'Token';
  companyInfo = 'CompanyInfo';
  emails = 'Emails';

  state = {
    activeItem: this.golfClub
  };

  componentDidMount() {
    this.props.fetchGolfClubsAction();
    this.props.fetchHolidaysAction();
    this.props.fetchCategoriesAction();
    this.props.fetchTeetimeSchedulesAction();
    this.props.fetchUnavailableTeetimesAction();
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const { translate } = this.props;
    const { activeItem } = this.state;
    const golfCourses =
      this.props.golfClubs && this.props.golfClubs[0] && this.props.golfClubs[0].golfCourses
        ? this.props.golfClubs[0].golfCourses
        : [];
    return (
      <>
        <Menu className="p-05" pointing secondary>
          <Menu.Item
            content={translate('default.golfclub')}
            name={this.golfClub}
            active={activeItem === this.golfClub}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            content={translate('golfclub.company-header')}
            name={this.companyInfo}
            active={activeItem === this.companyInfo}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            content={translate('golfclub.emails-header')}
            name={this.emails}
            active={activeItem === this.emails}
            onClick={this.handleItemClick}
          />
          {isSuperAdmin(this.props) && (
            <>
              <Menu.Item
                content={translate('club-admin.teetimes-schedule')}
                name={this.teetimeSchedule}
                active={activeItem === this.teetimeSchedule}
                onClick={this.handleItemClick}
              />
              <Menu.Item
                content={translate('club-admin.teetimes-unavailable')}
                name={this.unavailableTeetimes}
                active={activeItem === this.unavailableTeetimes}
                onClick={this.handleItemClick}
              />
              {false && (
                <Menu.Item
                  content={translate('club-admin.players')}
                  name={this.golfClubPlayers}
                  active={activeItem === this.golfClubPlayers}
                  onClick={this.handleItemClick}
                />
              )}
            </>
          )}
          <Menu.Item
            content={translate('club-admin.rentals')}
            name={this.accommodations}
            active={activeItem === this.accommodations}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            content="Token"
            name={this.token}
            active={activeItem === this.token}
            onClick={this.handleItemClick}
          />
        </Menu>
        <GolfClubConfigTab
          golfClubs={this.props.golfClubs}
          error={this.props.golfClubsError}
          loading={this.props.golfClubsLoading}
          saveItemAction={this.props.saveGolfClubAction}
          active={activeItem === this.golfClub}
          login={this.props.login}
        />

        <CompanyInfoConfigTab
          golfClubs={this.props.golfClubs}
          error={this.props.golfClubsError}
          loading={this.props.golfClubsLoading}
          saveItemAction={this.props.saveGolfClubAction}
          active={activeItem === this.companyInfo}
          login={this.props.login}
        />

        <EmailsConfigTab
          golfClubs={this.props.golfClubs}
          error={this.props.golfClubsError}
          loading={this.props.golfClubsLoading}
          saveItemAction={this.props.saveGolfClubAction}
          active={activeItem === this.emails}
          login={this.props.login}
        />

        <GolfCourseSelector
          data={this.props.teetimeSchedules}
          golfCourses={golfCourses}
          active={activeItem === this.teetimeSchedule}
        >
          <TeetimeScheduleTab
            login={this.props.login}
            products={this.props.products}
            saveItemAction={this.props.saveTeetimeScheduleAction}
            deleteItemAction={this.props.deleteTeetimeScheduleAction}
            error={this.props.teetimeSchedulesError}
            teetimeSchedulesValidationError={this.props.teetimeSchedulesValidationError}
            loading={this.props.teetimeSchedulesLoading}
            active={activeItem === this.teetimeSchedule}
          />
        </GolfCourseSelector>
        <GolfCourseSelector
          data={this.props.unavailableTeetimes}
          golfCourses={golfCourses}
          active={activeItem === this.unavailableTeetimes}
        >
          <UnavailableTeetimesTab
            saveItemAction={this.props.saveUnavailableTeetimeAction}
            deleteItemAction={this.props.deleteUnavailableTeetimeAction}
            error={this.props.unavailableTeetimesError}
            loading={this.props.unavailableTeetimesLoading}
            active={activeItem === this.unavailableTeetimes}
          />
        </GolfCourseSelector>
        <GolfClubPlayersTab
          golfClubs={this.props.golfClubs}
          error={this.props.golfClubsError}
          loading={this.props.golfClubsLoading}
          saveItemAction={this.props.saveGolfClubPlayerAction}
          deleteItemAction={this.props.deleteGolfClubPlayerAction}
          active={activeItem === this.golfClubPlayers}
        />
        <TokenTab
          golfClubs={this.props.golfClubs}
          //error={this.props.golfClubsError}
          //loading={this.props.golfClubsLoading}
          active={activeItem === this.token}
          accommodationTypes={this.props.accommodationTypes}
          rentalUnitTypes={this.props.rentalUnitTypes}
        />
        {activeItem === this.accommodations && (
          <>
            <AccommodationsMenuTab allProps={this.props} active={activeItem === this.accommodations} />
          </>
        )}
      </>
    );
  }
}
class GolfClubConfigTab extends React.Component {
  emptyItem = () => {
    return {
      name: '',
      golfCourses: [golfCourseEmpty()],
      admins: [],
      players: []
    };
  };

  render() {
    const { golfClubs, active } = this.props;

    if (active) {
      const emptyItem = this.emptyItem();

      return (
        <Translate>
          {({ translate }) => (
            <Segment loading={this.props.loading}>
              <EditableListComponent
                singleList
                emptyItem={emptyItem}
                data={this.props.golfClubs}
                itemDesc="golfclubs"
                emptyDescription={
                  golfClubs.length > 0 ? translate('club-admin.info') : translate('club-admin.info-welcome')
                }
                saveItemAction={this.props.saveItemAction}
                login={this.props.login}
                viewComponent={<GolfClubViewItem />}
                editComponent={<GolfClubEditItem error={this.props.error} />}
              />
            </Segment>
          )}
        </Translate>
      );
    }
    return null;
  }
}

class CompanyInfoConfigTab extends React.Component {
  emptyItem = () => {
    return {
      name: '',
      golfCourses: [golfCourseEmpty()],
      admins: [],
      players: []
    };
  };

  render() {
    const { active } = this.props;

    if (active) {
      const emptyItem = this.emptyItem();

      return (
        <Translate>
          {({ translate }) => (
            <Segment loading={this.props.loading}>
              <EditableListComponent
                singleList
                emptyItem={emptyItem}
                data={this.props.golfClubs}
                itemDesc="golfclubs"
                saveItemAction={this.props.saveItemAction}
                login={this.props.login}
                viewComponent={<CompanyViewItem />}
                editComponent={<CompanyEditItem error={this.props.error} />}
              />
            </Segment>
          )}
        </Translate>
      );
    }
    return null;
  }
}

class TokenTab extends React.Component {
  state = {
    token: '',
    iframe: '',
    loading: false,
    options: [],
    rentalUnitTypeAccommodationIds: [],
    rentalUnitTypeEquipmentIds: [],
    requestTokenError: null
  };

  clientUrl = process.env.REACT_APP_CLIENT_URL || 'https://onteex.ontee.com';

  componentDidMount = () => {
    let optionsValue = this.props.accommodationTypes?.map(x => x.id) || [];
    this.setState({ rentalUnitTypeAccommodationIds: optionsValue });
    optionsValue = this.props.rentalUnitTypes?.map(x => x.id) || [];
    this.setState({ rentalUnitTypeEquipmentIds: optionsValue });
  };

  handleChange = (_e, { name, value }) => {
    const requestTokenError = value.length < 1 ? 'club-admin.no-rental-type-selected' : null;
    if (name === IFrameType.Accommodations) {
      this.setState({ rentalUnitTypeAccommodationIds: value, requestTokenError });
    } else {
      this.setState({ rentalUnitTypeEquipmentIds: value, requestTokenError });
    }
  };

  requestWidgetToken = async type => {
    if (this.state.requestTokenError) {
      return;
    }

    const { golfClubs } = this.props;
    let rentaUnitTypeIds;
    if (type === IFrameType.Accommodations) {
      rentaUnitTypeIds = this.state.rentalUnitTypeAccommodationIds.join(',');
    } else {
      rentaUnitTypeIds = this.state.rentalUnitTypeEquipmentIds.join(',');
    }

    this.setState(prevState => ({
      ...prevState,
      loading: true
    }));

    const result = await getRequest('/Accounts/Token');
    if (result.code === 200) {
      const widgetUrl =
        type === IFrameType.Accommodations
          ? `${this.clientUrl}/accommodations/?widget=1&rental-unit-type=${rentaUnitTypeIds}&id=${golfClubs[0].id}&hash=${result.data.token}`
          : `${this.clientUrl}/rentalunits/?widget=1&rental-unit-type=${rentaUnitTypeIds}&id=${golfClubs[0].id}&hash=${result.data.token}`;

      this.setState(prevState => ({
        ...prevState,
        loading: false,
        token: result.data.token,
        iframe: `<script type="text/javascript">
            var parentUrl = encodeURIComponent(window.location.href);
            document.write('<iframe src="${widgetUrl}&parent-url='+parentUrl+'" width="100%" height="1000px"></iframe>');
          </script>`,
        iframeType: type
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        loading: true
      }));
    }
  };

  render() {
    const { active } = this.props;
    const hasGolfClub = this.props.golfClubs && this.props.golfClubs.length > 0;

    if (active) {
      return (
        <Translate>
          {({ translate }) => (
            <Segment loading={this.state.loading}>
              {!hasGolfClub && <Message color="orange" content={translate('club-admin.error-create-golfclub')} />}
              {hasGolfClub && (
                <>
                  <ErrorMessage
                    error={
                      this.props.error || this.state.requestTokenError ? translate(this.state.requestTokenError) : null
                    }
                  />

                  <h3>{translate('accommodation.accommodation-type')}:</h3>
                  <Dropdown
                    fluid
                    multiple
                    selection
                    name={IFrameType.Accommodations}
                    options={
                      this.props.accommodationTypes?.map(x => {
                        const option = { key: x.id, value: x.id, text: translate(`accommodation.${x.name}`) };
                        return option;
                      }) || []
                    }
                    value={this.state.rentalUnitTypeAccommodationIds}
                    onChange={this.handleChange}
                  />

                  <Button
                    fluid
                    type="button"
                    disabled={!!this.state.requestTokenError}
                    onClick={() => this.requestWidgetToken(IFrameType.Accommodations)}
                    style={{ marginTop: 10 }}
                  >
                    Efterfråga token till widget för {translate('accommodation.accommodation-type')}
                  </Button>
                  {this.state.iframe && this.state.iframeType === IFrameType.Accommodations && (
                    <>
                      <h1>Widget implementering för boende/ställplatser</h1>
                      <p>
                        <strong>Klistra in nedanstående kod på er hemsida där ni önskar widgeten ska synas</strong>
                      </p>
                      <p style={{ overflowWrap: 'break-word' }}>{this.state.iframe}</p>
                    </>
                  )}

                  <h3>Golfbilar/Golfbikes:</h3>
                  <Dropdown
                    fluid
                    multiple
                    selection
                    name={IFrameType.Equipments}
                    options={
                      this.props.rentalUnitTypes?.map(x => {
                        const option = { key: x.id, value: x.id, text: translate(`accommodation.${x.name}`) };
                        return option;
                      }) || []
                    }
                    value={this.state.rentalUnitTypeEquipmentIds}
                    onChange={this.handleChange}
                  />

                  <Button
                    fluid
                    type="button"
                    disabled={!!this.state.requestTokenError}
                    onClick={() => this.requestWidgetToken(IFrameType.Equipments)}
                    style={{ marginTop: 10 }}
                  >
                    Efterfråga token till widget Golfbilar/Golfbikes
                  </Button>
                  {this.state.iframe && this.state.iframeType === IFrameType.Equipments && (
                    <>
                      <h1>Widget implementering för boende/ställplatser</h1>
                      <p>
                        <strong>Klistra in nedanstående kod på er hemsida där ni önskar widgeten ska synas</strong>
                      </p>
                      <p style={{ overflowWrap: 'break-word' }}>{this.state.iframe}</p>
                    </>
                  )}
                </>
              )}
            </Segment>
          )}
        </Translate>
      );
    }
    return null;
  }
}

class TeetimeScheduleTab extends React.Component {
  emptyItem = golfCourse => ({
    isValid: false,
    isActivated: true,
    teeSlotPeriods: [teeSlotPeriodEmpty()],
    pricePeriods: [pricePeriodEmpty()],
    golfCourse
  });

  render() {
    if (this.props.active) {
      const { golfCourse } = this.props;
      const emptyItem = this.emptyItem(golfCourse);
      const warning = this.props.teetimeSchedulesValidationError;
      const { login } = this.props;

      return (
        <Translate>
          {({ translate }) => (
            <Segment loading={this.props.loading}>
              {!golfCourse && <Message color="orange" content={translate('club-admin.error-create-golfclub')} />}
              {golfCourse && (
                <>
                  {warning && warning.length > 0 && <WarningMessage error={warning} />}
                  <ErrorMessage error={this.props.error} />
                  <EditableAccordionListComponent
                    buttonsLocation="bottom"
                    emptyItem={emptyItem}
                    data={this.props.data}
                    itemDesc="teeslot schedules"
                    emptyDescription={translate('club-admin.teetime-info')}
                    saveItemAction={this.props.saveItemAction}
                    deleteItemAction={this.props.deleteItemAction}
                    viewComponent={<TeetimeScheduleViewItem />}
                    viewTitleComponent={<TeetimeScheduleViewTitleItem />}
                    viewContentComponent={<TeetimeScheduleViewContentItem login={login} />}
                    editComponent={<TeetimeScheduleEditItem login={login} products={this.props.products} />}
                    login={login}
                  />
                </>
              )}
            </Segment>
          )}
        </Translate>
      );
    }
    return null;
  }
}

class UnavailableTeetimesTab extends React.Component {
  emptyItem = golfCourse => ({
    description: '',
    blockedTimespans: [blockedTimespanEmpty()],
    golfCourse
  });

  render() {
    if (this.props.active) {
      const { golfCourse } = this.props;
      const emptyItem = this.emptyItem(golfCourse);
      return (
        <Translate>
          {({ translate }) => (
            <Segment loading={this.props.loading}>
              {!golfCourse && <Message color="orange" content={translate('club-admin.error-create-golfclub')} />}
              {golfCourse && (
                <Fragment>
                  <ErrorMessage error={this.props.error} />
                  <EditableListComponent
                    emptyItem={emptyItem}
                    data={this.props.data}
                    itemDesc="unavailable teetimes"
                    emptyDescription={translate('club-admin.booking-unavailable')}
                    saveItemAction={this.props.saveItemAction}
                    deleteItemAction={this.props.deleteItemAction}
                    viewComponent={<UnavailableTeetimeViewItem />}
                    editComponent={<UnavailableTeetimeEditItem />}
                  />
                </Fragment>
              )}
            </Segment>
          )}
        </Translate>
      );
    }
    return null;
  }
}

class GolfClubPlayersTab extends React.Component {
  emptyItem = () => {
    return {
      golfClub: this.props.golfClubs ? this.props.golfClubs[0] : null,
      player: {
        firstName: '',
        lastName: '',
        streetAddress1: '',
        streetAddress2: '',
        postCode: '',
        city: '',
        country: '',
        email: '',
        phoneNumber: '',
        golfid: '',
        handicap: '',
        userSub: ''
      },
      isClubMember: true
    };
  };

  render() {
    if (this.props.active) {
      const emptyItem = this.emptyItem();
      const hasGolfClub = this.props.golfClubs && this.props.golfClubs.length > 0;
      return (
        <Translate>
          {({ translate }) => (
            <Segment loading={this.props.loading}>
              {!hasGolfClub && <Message color="orange" content={translate('club-admin.error-create-golfclub')} />}
              {hasGolfClub && (
                <Fragment>
                  <ErrorMessage error={this.props.error} />
                  <FilterPlayersComponent data={this.props.golfClubs[0].players}>
                    <EditableListComponent
                      emptyItem={emptyItem}
                      itemDesc="golf club players"
                      saveItemAction={this.props.saveItemAction}
                      deleteItemAction={this.props.deleteItemAction}
                      hideDeleteWhenNotEditing={true}
                      viewComponent={<GolfClubPlayerViewItem />}
                      editComponent={<GolfClubPlayerEditItem golfClub={this.props.golfClubs[0]} />}
                    />
                  </FilterPlayersComponent>
                </Fragment>
              )}
            </Segment>
          )}
        </Translate>
      );
    }
    return null;
  }
}

class AccommodationsMenuTab extends React.Component {
  accommodations = 'Accommodations';

  rentalUnits = 'Rental units';

  accommodationSchedules = 'Schedule';

  unavailableAccommodations = 'Unavailable units';

  discounts = 'Discounts';

  productAddonSettings = 'Product Addons';

  packages = 'packages';

  state = {
    activeItem: this.accommodations
  };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const { activeItem } = this.state;
    const hasGolfClub = this.props.allProps.golfClubs && this.props.allProps.golfClubs.length > 0;

    return (
      <Translate>
        {({ translate }) => (
          <>
            {!hasGolfClub && <Message color="orange" content={translate('club-admin.error-create-golfclub')} />}
            {hasGolfClub && (
              <>
                <Menu className="p-05" pointing secondary>
                  <Menu.Item
                    content={translate('club-admin.accommodations')}
                    name={this.accommodations}
                    active={activeItem === this.accommodations}
                    onClick={this.handleItemClick}
                  />
                  {/* {isSuperAdmin(this.props.allProps) && ( */}
                  <Menu.Item
                    content={translate('club-admin.rental-units')}
                    name={this.rentalUnits}
                    active={activeItem === this.rentalUnits}
                    onClick={this.handleItemClick}
                  />
                  {/* )} */}
                  <Menu.Item
                    content={translate('club-admin.schedule')}
                    name={this.accommodationSchedules}
                    active={activeItem === this.accommodationSchedules}
                    onClick={this.handleItemClick}
                  />
                  <Menu.Item
                    content={translate('club-admin.discounts')}
                    name={this.discounts}
                    active={activeItem === this.discounts}
                    onClick={this.handleItemClick}
                  />
                  <Menu.Item
                    content={translate('club-admin.product-addons')}
                    name={this.productAddonSettings}
                    active={activeItem === this.productAddonSettings}
                    onClick={this.handleItemClick}
                  />
                  <Menu.Item
                    content={translate('club-admin.packages')}
                    name={this.packages}
                    active={activeItem === this.packages}
                    onClick={this.handleItemClick}
                  />
                  <Menu.Item
                    content={translate('club-admin.accommodations-unavailable')}
                    name={this.unavailableAccommodations}
                    active={activeItem === this.unavailableAccommodations}
                    onClick={this.handleItemClick}
                  />
                </Menu>
                {activeItem === this.accommodations && (
                  <>
                    <Accomodations />
                    {/* <AccommodationsTab
                  data={this.props.allProps.accommodations}
                  schedules={this.props.allProps.accommodationSchedules}
                  golfClubs={this.props.allProps.golfClubs}
                  priceCategories={this.props.allProps.priceCategories}
                  accommodationTypes={this.props.allProps.accommodationTypes}
                  error={this.props.allProps.accommodationsError}
                  loading={this.props.allProps.accommodationsLoading}
                  loaded={this.props.allProps.accommodationsLoaded}
                  saveItemAction={this.props.allProps.saveAccommodationAction}
                  saveAccommodationBulkAction={this.props.allProps.saveAccommodationBulkAction}
                  deleteItemAction={this.props.allProps.deleteAccommodationAction}
                  fetchAccommodationsAction={this.props.allProps.fetchAccommodationsAction}
                  fetchRentalUnitsAction={this.props.allProps.fetchRentalUnitsAction}
                  active={activeItem === this.accommodations}
                /> */}
                  </>
                )}
                {activeItem === this.rentalUnits && (
                  <>
                    <RentalUnits />
                    {/* <RentalUnitsTab
                  data={this.props.allProps.rentalUnits}
                  schedules={this.props.allProps.accommodationUnitSchedules}
                  golfClubs={this.props.allProps.golfClubs}
                  rentalUnitPriceCategories={this.props.allProps.rentalUnitPriceCategories}
                  rentalUnitTypes={this.props.allProps.rentalUnitTypes}
                  error={this.props.allProps.rentalUnitsError}
                  loading={this.props.allProps.rentalUnitsLoading}
                  loaded={this.props.allProps.rentalUnitsLoaded}
                  saveItemAction={this.props.allProps.saveRentalUnitAction}
                  saveRentalUnitBulkAction={this.props.allProps.saveRentalUnitBulkAction}
                  deleteItemAction={this.props.allProps.deleteRentalUnitAction}
                  active={activeItem === this.rentalUnits}
                /> */}
                  </>
                )}
                {activeItem === this.accommodationSchedules && (
                  <AccommodationSchedulesTab
                    data={this.props.allProps.accommodationSchedules}
                    accommodations={this.props.allProps.accommodations}
                    golfClubs={this.props.allProps.golfClubs}
                    priceCategories={this.props.allProps.priceCategories}
                    rentalUnitPriceCategories={this.props.allProps.rentalUnitPriceCategories}
                    error={this.props.allProps.accommodationSchedulesError}
                    loading={this.props.allProps.accommodationSchedulesLoading}
                    loaded={this.props.allProps.accommodationSchedulesLoaded}
                    saveItemAction={this.props.allProps.saveAccommodationScheduleAction}
                    deleteItemAction={this.props.allProps.deleteAccommodationScheduleAction}
                    fetchAccommodationSchedulesAction={this.props.allProps.fetchAccommodationSchedulesAction}
                    accommodationSchedulesValidationError={this.props.allProps.accommodationSchedulesValidationError}
                    active={activeItem === this.accommodationSchedules}
                    login={this.props.allProps.login}
                  />
                )}
                {activeItem === this.unavailableAccommodations && (
                  <UnavailableAccommodationsTab
                    data={this.props.allProps.unavailableAccommodations}
                    accommodations={this.props.allProps.accommodations}
                    rentalUnits={this.props.allProps.rentalUnits}
                    golfClubs={this.props.allProps.golfClubs}
                    error={this.props.allProps.unavailableAccommodationsError}
                    loading={this.props.allProps.unavailableAccommodationsLoading}
                    loaded={this.props.allProps.unavailableAccommodationsLoaded}
                    saveItemAction={this.props.allProps.saveUnavailableAccommodationAction}
                    deleteItemAction={this.props.allProps.deleteUnavailableAccommodationAction}
                    fetchUnavailableAccommodationsAction={this.props.allProps.fetchUnavailableAccommodationsAction}
                    active={activeItem === this.unavailableAccommodations}
                    translate={translate}
                  />
                )}
                {activeItem === this.discounts && <Discounts />}
                {activeItem === this.productAddonSettings && <ProductAddonsSettingsList />}
                {activeItem === this.packages && <PackagesList />}
              </>
            )}
          </>
        )}
      </Translate>
    );
  }
}

// AccommodationsTab and RentalUnitsTab removed

class AccommodationSchedulesTab extends React.Component {
  state = {
    fetchCalled: false
  };

  componentDidMount() {
    this.setState({ fetchCalled: false });
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.state.fetchCalled /*&& !this.props.loaded*/) {
      const golfClub = this.props.golfClubs && this.props.golfClubs[0] ? this.props.golfClubs[0] : null;
      if (golfClub !== null) {
        this.props.fetchAccommodationSchedulesAction(golfClub.id);
        this.setState({ fetchCalled: true });
      }
    }
  };

  emptyItem = golfClub => ({
    isValid: false,
    isActivated: true,
    startDate: todaysDate(),
    endDate: endOfThisYearDate(),
    weekSetups: [accWeekSetupEmpty()],
    pricePeriods: [accPricePeriodEmpty()],
    golfClubId: golfClub.id
  });

  render() {
    if (this.props.active) {
      // const { translate } = this.props;
      const { login } = this.props;
      const golfClub = this.props.golfClubs && this.props.golfClubs[0] ? this.props.golfClubs[0] : null;
      const emptyItem = golfClub ? this.emptyItem(golfClub) : null;
      const warning = this.props.accommodationSchedulesValidationError;
      const allPriceCategories = this.props.priceCategories.concat(this.props.rentalUnitPriceCategories);
      return (
        <Translate>
          {({ translate }) => (
            <Segment loading={this.props.loading}>
              {!golfClub && <Message color="orange" content={translate('club-admin.error-create-golfclub')} />}

              {/* {(!this.props.accommodations || this.props.accommodations.length === 0) &&
                this.props.data &&
                this.props.data.schedules && <WarningMessage error={translate('club-admin.accommodations-no')} />} */}

              {warning && warning.length > 0 && <WarningMessage error={warning} />}
              {golfClub && (
                <>
                  <ErrorMessage error={this.props.error} />
                  <EditableAccordionListComponent
                    login={this.props.login}
                    buttonsLocation="bottom"
                    emptyItem={emptyItem}
                    data={this.props.data && this.props.data.schedules ? this.props.data.schedules : []}
                    itemDesc="accommodations"
                    emptyDescription={translate('club-admin.schedule-create')}
                    saveItemAction={this.props.saveItemAction}
                    deleteParams={golfClub.id}
                    deleteItemAction={this.props.deleteItemAction}
                    viewComponent={<AccommodationScheduleViewContentItem />}
                    viewTitleComponent={<AccommodationScheduleViewTitleItem />}
                    viewContentComponent={<AccommodationScheduleViewContentItem />}
                    editComponent={
                      <AccommodationScheduleEditItem
                        priceCategories={allPriceCategories}
                        accommodationTypes={this.props.accommodationTypes}
                        login={login}
                      />
                    }
                  />
                </>
              )}
            </Segment>
          )}
        </Translate>
      );
    }
    return null;
  }
}
class UnavailableAccommodationsTab extends React.Component {
  state = {
    fetchCalled: false,
    accommodations: [],
    rentalUnits: [],
    serverErrorMessage: null
  };

  emptyItem = golfClub => ({
    description: '',
    golfClubId: golfClub.id,
    startDate: todaysDate(),
    endDate: todaysDate(),
    allBlocked: true,
    rentalUnits: []
  });

  async componentDidMount() {
    this.setState({ fetchCalled: false });
    // For now, this should be done better
    const golfClub = this.props.golfClubs && this.props.golfClubs[0] ? this.props.golfClubs[0] : null;
    const { data: accommodations } = await api.fetchAccommodations(golfClub.id);
    const { data: rentalUnits } = await api.fetchRentalUnits(golfClub.id);
    this.setState({ accommodations, rentalUnits });
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.state.fetchCalled /*&& !this.props.loaded*/) {
      const golfClub = this.props.golfClubs && this.props.golfClubs[0] ? this.props.golfClubs[0] : null;
      if (golfClub !== null) {
        this.props.fetchUnavailableAccommodationsAction(golfClub.id);
        this.setState({ fetchCalled: true });
      }
    }
  };

  setBlockPeriodServerMessage = async error => {
    let serverErrorMessage = '';
    if (error.message === OVERLAPPING_BLOCK_PERIOD) {
      serverErrorMessage = `${this.props.translate('error.overlapping-block-period')} ${error.data.description}`;
    }
    if (error.message === BOOKING_IN_BLOCK_PERIOD) {
      const accommodation = error.data.conflictedAccommodation.map(x => x.accommodation.name).join(',');
      const rentalUnits = error.data.conflictedRentalUnit.map(x => x.rentalUnit.name).join(',');
      serverErrorMessage = `${this.props.translate('error.booking-in-block-period')} ${accommodation} ${rentalUnits}`;
    }
    await new Promise(resolve => setTimeout(resolve, 500));
    this.setState({ serverErrorMessage });
  };

  render() {
    if (this.props.active) {
      // const { translate } = this.props;
      const golfClub = this.props.golfClubs && this.props.golfClubs[0] ? this.props.golfClubs[0] : null;
      const emptyItem = golfClub ? this.emptyItem(golfClub) : null;
      if (this.props.error?.errorData) {
        this.setBlockPeriodServerMessage(this.props.error.errorData);
      }
      return (
        <Translate>
          {({ translate }) => (
            <Segment loading={this.props.loading}>
              {!golfClub && <Message color="orange" content={translate('club-admin.error-create-golfclub')} />}
              {golfClub && (
                <>
                  <ErrorMessage error={this.props.error} customText={this.state.serverErrorMessage} />
                  <EditableListComponent
                    emptyItem={emptyItem}
                    data={this.props.data}
                    itemDesc="accommodations"
                    deleteParams={golfClub.id}
                    emptyDescription={translate('club-admin.acc-rent-add')}
                    saveItemAction={this.props.saveItemAction}
                    deleteItemAction={this.props.deleteItemAction}
                    viewComponent={
                      <UnavailableAccommodationViewItem
                        accommodations={this.state.accommodations}
                        rentalUnits={this.state.rentalUnits}
                      />
                    }
                    editComponent={
                      <UnavailableAccommodationEditItem
                        accommodations={this.state.accommodations}
                        rentalUnits={this.state.rentalUnits}
                      />
                    }
                  />
                </>
              )}
            </Segment>
          )}
        </Translate>
      );
    }
    return null;
  }
}

function mapStateToProps(state) {
  return {
    golfClubs: state.clubAdminConfig.golfClubs,
    golfClubsLoading: state.clubAdminConfig.golfClubsLoading,
    golfClubsError: state.clubAdminConfig.golfClubsError,
    teetimeSchedules: state.clubAdminConfig.teetimeSchedules,
    teetimeSchedulesLoading: state.clubAdminConfig.teetimeSchedulesLoading,
    teetimeSchedulesError: state.clubAdminConfig.teetimeSchedulesError,
    teetimeSchedulesValidationError: state.clubAdminConfig.teetimeSchedulesValidationError,
    unavailableTeetimes: state.clubAdminConfig.unavailableTeetimes,
    unavailableTeetimesLoading: state.clubAdminConfig.unavailableTeetimesLoading,
    unavailableTeetimesError: state.clubAdminConfig.unavailableTeetimesError,
    products: state.clubAdminConfig.products,
    priceCategories: state.clubAdminConfig.priceCategories,
    rentalUnitPriceCategories: state.clubAdminConfig.rentalUnitPriceCategories,
    accommodationTypes: state.clubAdminConfig.accommodationTypes,
    rentalUnitTypes: state.clubAdminConfig.rentalUnitTypes,
    accommodations: state.clubAdminConfig.accommodations,
    accommodationsLoading: state.clubAdminConfig.accommodationsLoading,
    accommodationsLoaded: state.clubAdminConfig.accommodationsLoaded,
    accommodationsError: state.clubAdminConfig.accommodationsError,
    rentalUnits: state.clubAdminConfig.rentalUnits,
    rentalUnitsLoading: state.clubAdminConfig.rentalUnitsLoading,
    rentalUnitsLoaded: state.clubAdminConfig.rentalUnitsLoaded,
    rentalUnitsError: state.clubAdminConfig.rentalUnitsError,
    accommodationSchedules: state.clubAdminConfig.accommodationSchedules,
    accommodationSchedulesLoading: state.clubAdminConfig.accommodationSchedulesLoading,
    accommodationSchedulesLoaded: state.clubAdminConfig.accommodationSchedulesLoaded,
    accommodationSchedulesError: state.clubAdminConfig.accommodationSchedulesError,
    accommodationSchedulesValidationError: state.clubAdminConfig.accommodationSchedulesValidationError,
    unavailableAccommodations: state.clubAdminConfig.unavailableAccommodations,
    unavailableAccommodationsLoading: state.clubAdminConfig.unavailableAccommodationsLoading,
    unavailableAccommodationsLoaded: state.clubAdminConfig.unavailableAccommodationsLoaded,
    unavailableAccommodationsError: state.clubAdminConfig.unavailableAccommodationsError,
    login: state.login
  };
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(clubAdminConfigActions, dispatch)
});

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(withRouter(ClubAdminRegisterForm)));
