import React from 'react';
import { Translate } from 'react-localize-redux';
import { Segment, Grid, List, Button, Confirm } from 'semantic-ui-react';

class ViewEditDeleteComponent extends React.Component {
  state = {
    isEditing: false,
    confirmDeleteShowing: false,
    dataIsValid: false
  };

  componentDidMount() {
    this.setState({ isEditing: this.props.isEditing });
  }

  componentWillUnmount() {
    if (this.props.setCurrentlyEditingId) {
      this.props.setCurrentlyEditingId(null);
    }
  }

  showHideEdit = () => {
    const isEditing = !this.state.isEditing;
    this.setState({ isEditing });
    if (this.props.setCurrentlyEditingId) {
      this.props.setCurrentlyEditingId(isEditing ? this.props.data.id : null);
    }
    if (this.props.cancelCallback) {
      this.props.cancelCallback();
    }
  };

  openConfirmDelete = () => this.setState({ confirmDeleteShowing: true });

  closeConfirmDelete = () => this.setState({ confirmDeleteShowing: false });

  deleteItem = () => {
    this.closeConfirmDelete();
    const { data, deleteParams } = this.props;
    data.status = 'Cancelled';
    const deleteParameters = deleteParams ? [deleteParams, data] : [data];
    this.props.deleteAction(...deleteParameters);
  };

  dataIsValid = valid => {
    this.setState({ dataIsValid: valid });
  };

  render() {
    const viewComponent = React.cloneElement(this.props.viewComponent, {
      data: this.props.data,
      login: this.props.login
    });
    const editComponent = React.cloneElement(this.props.editComponent, {
      ...this.props,
      onSubmit: this.showHideEdit,
      dataIsValid: this.dataIsValid,
      login: this.props.login
    });
    const deleteText = this.props.deleteButtonText ? 'default.real-delete' : 'default.delete';
    const deleteThisText = this.props.deleteButtonText ? 'default.real-delete-this' : 'default.delete-this';

    if (this.props.currentlyEditingId && this.props.data.id !== this.props.currentlyEditingId) {
      return null;
    }

    return (
      <Translate>
        {({ translate }) => (
          <Segment>
            <Grid>
              <Grid.Row>
                {this.props.buttonsLocation !== 'bottom' && (
                  <Grid.Column width={3}>
                    <List>
                      {!this.state.isEditing && (
                        <List.Item>
                          <Button fluid onClick={() => this.showHideEdit()}>
                            <Translate id="default.edit" />
                          </Button>
                        </List.Item>
                      )}
                      {this.state.isEditing && [
                        <List.Item key="Cancel">
                          <Button fluid type="button" onClick={() => this.showHideEdit()}>
                            <Translate id="default.cancel" />
                          </Button>
                        </List.Item>,
                        <List.Item key="Save">
                          <Button
                            fluid
                            type="submit"
                            form={this.props.id}
                            disabled={!this.state.dataIsValid}
                            color="green"
                          >
                            <Translate id="default.save" />
                          </Button>
                        </List.Item>
                      ]}
                      {this.props.deleteAction !== undefined && this.state.isEditing && (
                        <List.Item>
                          <Button color="red" fluid onClick={() => this.openConfirmDelete()}>
                            <Translate id={deleteText} />
                          </Button>

                          <Confirm
                            header={translate(deleteThisText)}
                            content={null}
                            confirmButton={translate(deleteText)}
                            open={this.state.confirmDeleteShowing}
                            onCancel={this.closeConfirmDelete}
                            onConfirm={this.deleteItem}
                          />
                        </List.Item>
                      )}
                    </List>
                  </Grid.Column>
                )}
                <Grid.Column width={this.props.buttonsLocation !== 'bottom' ? 12 : null}>
                  {this.state.isEditing ? editComponent : viewComponent}
                  {this.props.buttonsLocation === 'bottom' && (
                    <div style={{ marginTop: 15, marginBottom: 0 }}>
                      {!this.state.isEditing && !this.props.accommodation && (
                        <Button color="teal" size="tiny" onClick={() => this.showHideEdit()}>
                          <Translate id="default.edit" />
                        </Button>
                      )}
                      {this.state.isEditing && (
                        <>
                          <Button key="Cancel" type="button" size="tiny" onClick={() => this.showHideEdit()}>
                            <Translate id="default.cancel" />
                          </Button>
                          <Button
                            key="Save"
                            type="submit"
                            size="tiny"
                            form={this.props.id}
                            disabled={!this.state.dataIsValid}
                            color="green"
                          >
                            <Translate id="default.save" />
                          </Button>
                        </>
                      )}
                      {this.props.deleteAction !== undefined && this.state.isEditing && (
                        <>
                          <Button key="DeleteButton" color="red" size="tiny" onClick={() => this.openConfirmDelete()}>
                            <Translate id={deleteText} />
                          </Button>

                          <Confirm
                            key="DeleteConfirm"
                            header={translate(deleteThisText)}
                            content={null}
                            confirmButton={translate(deleteText)}
                            cancelButton={translate('default.cancel')}
                            open={this.state.confirmDeleteShowing}
                            onCancel={this.closeConfirmDelete}
                            onConfirm={this.deleteItem}
                          />
                        </>
                      )}
                    </div>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        )}
      </Translate>
    );
  }
}

export default ViewEditDeleteComponent;
