import { put } from 'redux-saga/effects';

import { SIGN_IN_REQUEST_SUCCESS, SIGN_IN_REQUEST_FAILED } from '../containers/LoginForm/ducks';
import { userLogin } from '../utils/api';

export function* backendServiceCallWithLogin(action) {
  try {
    const { data, backendFunction, callSuccessType, callFailedType } = action;
    const response = yield backendFunction(...data);
    if (response.code === 200) {
      localStorage.authToken = 'Bearer ' + response.data.access_token;
      yield put({ type: SIGN_IN_REQUEST_SUCCESS, token: response.data.access_token, role: response.data.role });
      yield put({ type: callSuccessType });
    } else {
      yield put({ type: callFailedType, error: response.error || 'Error' });
    }
  } catch (error) {
    // console.log('Sage error', error);
    // yield put({ type: LOGIN_FAILED, error: error.message });
  }
}

export function* requestLogin(action) {
  try {
    const user = { ...action.user };
    user.client = process.env.REACT_APP_AUTH_CLIENT;
    user.clientSecret = process.env.REACT_APP_AUTH_CLIENT_SECRET;
    user.rememberLogin = true;

    const response = yield userLogin(user);
    if (response.code === 200) {
      localStorage.authToken = 'Bearer ' + response.data.access_token;
      yield put({ type: SIGN_IN_REQUEST_SUCCESS, token: response.data.access_token, role: response.data.role });
    } else {
      yield put({ type: SIGN_IN_REQUEST_FAILED, error: response.error || 'Error' });
    }
  } catch (error) {
    // console.log('Sage error', error);
    // yield put({ type: LOGIN_FAILED, error: error.message });
  }
}
