/** Possible charge types for an addon. */
export enum ProductAddonChargeType {
  /** Addon gets charged per person. */
  PerPerson = 1,
  /** Addon gets charged per day. */
  PerDay = 2,
  /** Addon gets charged per person and day. */
  PerPersonAndDay = 3,
  /** Addon gets charged per booking. */
  PerBooking = 4
}
