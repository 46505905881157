import React from 'react';
import { Button, Checkbox, Form, List, Modal, Popup, Icon } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { Translate, withLocalize } from 'react-localize-redux';
import { useForm, Controller } from 'react-hook-form';
import { kebabCase } from 'lodash-es';
import { DiscountTypes } from '../../core/enums';
import './discount.css';
import { ErrorMessage } from '../ErrorMessage';

const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
let discountTypes = null;

function DiscountEdit({ discount, onClickCancel, onClickSave, accommodationTypes, loading, errorMessage, translate }) {
  const { register, handleSubmit, errors, control } = useForm({
    submitFocusError: true,
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  if (!discountTypes) {
    discountTypes = Object.entries(DiscountTypes).map(([key, value]) => ({
      text: translate(`discount.${kebabCase(key)}`),
      value
    }));
  }

  const onSubmit = data => {
    onClickSave({
      ...discount,
      ...data
    });
  };

  return (
    <Modal open className="position-unset">
      <Modal.Header>
        {discount.id
          ? `${translate('discount.update-modal-title')} ${discount.code}`
          : translate('discount.add-modal-title')}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <List>
            <List.Item>
              <Form.Group widths="equal">
                <Form.Field error={!!errors.name}>
                  <label>
                    <Translate id="default.name" />
                  </label>
                  <input
                    type="text"
                    name="name"
                    defaultValue={discount.name}
                    placeholder={translate('default.name')}
                    ref={register()}
                  />
                </Form.Field>
                <Form.Field error={!!errors.name}>
                  <label>
                    <Translate id="discount.code" />
                  </label>
                  <input
                    type="text"
                    name="code"
                    defaultValue={discount.code}
                    placeholder={translate('discount.code')}
                    ref={register({ required: true, minLength: 2 })}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field error={!!errors.publishStart}>
                  <label>
                    <Translate id="discount.publish-start" />
                    <Popup
                      trigger={<Icon name="info circle" />}
                      // eslint-disable-next-line react/no-danger
                      content={
                        <p>
                          <Translate id="discount.publish-information" />
                        </p>
                      }
                    />
                  </label>
                  <Controller
                    name="publishStart"
                    control={control}
                    defaultValue={discount.publishStart?.substring(0, 10)}
                    rules={{ required: true, pattern: dateRegex }}
                    render={({ onChange, value }) => (
                      <DateInput
                        closable
                        dateFormat="YYYY-MM-DD"
                        localization="sv"
                        placeholder={translate('discount.publish-start-placeholder')}
                        value={value}
                        onChange={(_e, data) => onChange(data.value)}
                      />
                    )}
                  />
                </Form.Field>
                <Form.Field error={!!errors.publishEnd}>
                  <label>
                    <Translate id="discount.publish-end" />
                    <Popup
                      trigger={<Icon name="info circle" />}
                      // eslint-disable-next-line react/no-danger
                      content={
                        <p>
                          <Translate id="discount.publish-information" />
                        </p>
                      }
                    />
                  </label>
                  <Controller
                    name="publishEnd"
                    control={control}
                    defaultValue={discount.publishEnd?.substring(0, 10)}
                    rules={{ required: true, pattern: dateRegex }}
                    render={({ onChange, value }) => (
                      <DateInput
                        closable
                        dateFormat="YYYY-MM-DD"
                        localization="sv"
                        placeholder={translate('discount.publish-end-placeholder')}
                        value={value}
                        onChange={(_e, data) => onChange(data.value)}
                      />
                    )}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field error={!!errors.applyStart}>
                  <label>
                    <Translate id="discount.apply-start" />
                    <Popup
                      trigger={<Icon name="info circle" />}
                      // eslint-disable-next-line react/no-danger
                      content={
                        <p>
                          <Translate id="discount.apply-information" />
                        </p>
                      }
                    />
                  </label>
                  <Controller
                    name="applyStart"
                    control={control}
                    defaultValue={discount.applyStart?.substring(0, 10)}
                    rules={{ required: true, pattern: dateRegex }}
                    render={({ onChange, value }) => (
                      <DateInput
                        closable
                        dateFormat="YYYY-MM-DD"
                        localization="sv"
                        placeholder={translate('discount.apply-start-placeholder')}
                        value={value}
                        onChange={(_e, data) => onChange(data.value)}
                      />
                    )}
                  />
                </Form.Field>
                <Form.Field error={!!errors.applyEnd}>
                  <label>
                    <Translate id="discount.apply-end" />
                    <Popup
                      trigger={<Icon name="info circle" />}
                      // eslint-disable-next-line react/no-danger
                      content={
                        <p>
                          <Translate id="discount.apply-information" />
                        </p>
                      }
                    />
                  </label>
                  <Controller
                    name="applyEnd"
                    control={control}
                    defaultValue={discount.applyEnd?.substring(0, 10)}
                    rules={{ required: true, pattern: dateRegex }}
                    render={({ onChange, value }) => (
                      <DateInput
                        closable
                        dateFormat="YYYY-MM-DD"
                        localization="sv"
                        placeholder={translate('discount.apply-end-placeholder')}
                        value={value}
                        onChange={(_e, data) => onChange(data.value)}
                      />
                    )}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field error={!!errors.discountType}>
                  <label>
                    <Translate id="discount.type" />
                  </label>
                  <Controller
                    name="discountType"
                    control={control}
                    defaultValue={discount.discountType}
                    rules={{ required: true }}
                    render={({ onChange, value: nValue }) => (
                      <Form.Select
                        fluid
                        placeholder={translate('discount.type')}
                        options={discountTypes}
                        value={nValue}
                        onChange={(_e, data) => onChange(data.value)}
                      />
                    )}
                  />
                </Form.Field>

                <Form.Field error={!!errors.amount}>
                  <label>
                    <Translate id="discount.amount" />
                  </label>
                  <input
                    type="number"
                    name="amount"
                    defaultValue={discount.amount}
                    placeholder={translate('discount.amount')}
                    ref={register({ required: true, valueAsNumber: true })}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Field error={!!errors.discountType}>
                <label>
                  <Translate id="discount.rental-type" />
                </label>
                <Controller
                  name="rentalUnitTypeIds"
                  control={control}
                  defaultValue={discount.rentalUnitTypeIds}
                  render={({ onChange, value: nValue }) => (
                    <Form.Select
                      clearable
                      multiple
                      placeholder={translate('discount.type')}
                      options={accommodationTypes}
                      value={nValue}
                      onChange={(_e, data) => onChange(data.value)}
                    />
                  )}
                />
              </Form.Field>
              <Form.Group inline>
                <Form.Field>
                  <Controller
                    name="active"
                    control={control}
                    defaultValue={discount.active}
                    render={({ onChange, value }) => (
                      <Checkbox
                        label={translate('discount.active')}
                        checked={value}
                        onChange={(_e, data) => onChange(data.checked)}
                      />
                    )}
                  />
                </Form.Field>
                <Form.Field>
                  <Controller
                    name="canBeCombined"
                    control={control}
                    defaultValue={discount.canBeCombined}
                    render={({ onChange, value }) => (
                      <Checkbox
                        label={translate('discount.can-be-combined')}
                        checked={value}
                        onChange={(_e, data) => onChange(data.checked)}
                      />
                    )}
                  />
                </Form.Field>
                <Form.Field>
                  <Controller
                    name="isSecret"
                    control={control}
                    defaultValue={discount.isSecret}
                    render={({ onChange, value }) => (
                      <Checkbox
                        label={translate('discount.is-secret')}
                        checked={value}
                        onChange={(_e, data) => onChange(data.checked)}
                      />
                    )}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field error={!!errors.info}>
                  <label>
                    <Translate id="default.info" />
                  </label>
                  <textarea name="info" placeholder={translate('discount.info')} ref={register()} />
                </Form.Field>
              </Form.Group>
            </List.Item>
          </List>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <ErrorMessage error={errorMessage} />
        <Button color="green" size="small" onClick={handleSubmit(onSubmit)} loading={loading} disabled={loading}>
          <Translate id="default.save" />
        </Button>
        <Button size="small" onClick={onClickCancel} disabled={loading}>
          <Translate id="default.cancel" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default withLocalize(DiscountEdit);
