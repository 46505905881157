import axios from 'axios';
import { timeout } from '.';

const instance = axios.create({
  baseURL: process.env.REACT_APP_TEESHEET_BACKEND_URL,
  timeout: 100000
});
// Alter defaults after instance has been created
// instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;

const createRequest = async (method, url, payload, isMultipartRequest = false) => {
  if (isMultipartRequest) {
    if (localStorage.authToken) {
      instance.defaults.headers.common.Authorization = `${localStorage.authToken}`;
    } else if (sessionStorage.authToken && !sessionStorage.authToken.includes('undefined')) {
      instance.defaults.headers.common.Authorization = `${sessionStorage.authToken}`;
    }

    const response = await instance.request({
      method,
      url,
      data: payload,
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return Promise.resolve({ response: response.data });
  }

  try {
    const response = await axiosRequest(method, url, payload);
    const { status, data } = response;
    return Promise.resolve({ code: status, data });
  } catch (error) {
    let errorResponse = {};

    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      const { status, data, statusText } = error.response;

      if (status === 401) {
        const isWidgetRequest = window.location.search.includes('widget');
        const nor = window.location.search.includes('nor');
        if (isWidgetRequest && !nor) {
          // just refresh the page once
          const newLocation = `${window.location.href}&nor=1`;
          window.location.href = newLocation;
        } else {
          // sign out user so he can login again
          window.location.href = '/sign-out';
        }
      } else if (typeof data === 'object') {
        errorResponse = { code: status, error: data.error, errorCode: data.errorCode, errorData: data };
      } else {
        errorResponse = { code: status, error: data || statusText || 'Error' };
      }
    } else if (error.code === 'ECONNABORTED') {
      errorResponse = { error: 'Förfrågan tog för lång tid, vänligen försök på nytt', errorCode: 'timeout' };
    } else {
      const notOnlineMessage = !navigator.onLine ? ' säkerställ att att ni har nätverksuppkoppling och ' : '';
      errorResponse = {
        error: `Förfrågan misslyckades, ${notOnlineMessage}vänligen försök på nytt`,
        errorCode: 'failed'
      };
    }

    return Promise.resolve({ error: errorResponse });
  }
};

const axiosRequest = async (method, url, payLoad) => {
  let headers = localStorage.authToken ? { Authorization: localStorage.authToken } : null;

  if (sessionStorage.authToken && !sessionStorage.authToken.includes('undefined')) {
    headers = { Authorization: sessionStorage.authToken };
  }

  switch (method) {
    case 'post':
    case 'delete':
    case 'put':
      return instance.request({ url, method, data: payLoad, headers });
    case 'get':
    default:
      return instance.request({ url, method, params: payLoad, headers });
  }
};

export const getRequest = (url, params) => createRequest('get', url, params);
export const postRequest = (url, data) => createRequest('post', url, data);
export const postRequestMultipart = (url, data) => createRequest('post', url, data, true);
export const deleteRequest = (url, data) => createRequest('delete', url, data);
export const putRequest = (url, data) => createRequest('put', url, data);

export const retryRequest = async (func, { isSuccess, condition, retryAttempt, interval }) => {
  let response = null;

  while (!isSuccess && retryAttempt > 0) {
    // eslint-disable-next-line no-await-in-loop
    await timeout(interval);
    // eslint-disable-next-line no-await-in-loop
    response = await func();

    isSuccess = condition(response.data);
    retryAttempt -= 1;
  }
  return { isSuccess, response };
};
