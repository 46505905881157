import React from 'react';
import { Helmet as RHelmet } from 'react-helmet';

const Helmet = ({ title, description }) => (
  <RHelmet>
    <title>{title || 'OnTee'}</title>
    <meta name="description" content={description || 'OnTee'} />
  </RHelmet>
);

export default Helmet;
