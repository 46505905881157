import React, { useEffect, useState } from 'react';
import {
  Loader,
  Button,
  Modal,
  Form,
  TextArea,
  Dropdown,
  Input,
  Search,
  Label,
  Grid,
  Icon,
  Checkbox,
  Message,
  Select
} from 'semantic-ui-react';
import { Translate, withLocalize } from 'react-localize-redux';
import moment from 'moment';
import { debounce } from 'lodash-es';
import { DateInput } from 'semantic-ui-calendar-react';
import { ErrorMessage } from '../ErrorMessage';
import {
  emailRegex,
  getAvailablePriceTypes,
  getDiscountedPrice,
  getOriginalPrice,
  getPriceExclVat,
  getPriceToUse,
  golfIdRegex
} from '../../utils';
import TimePicker from '../DateTimePickers/TimePicker';
import { ActiveRequest, BookingStatus, PriceType, Source, TransactionType } from '../../core/enums';
import {
  processOrder,
  refundTransaction,
  fetchRentalUnitOrder,
  cancelInvoice,
  resendInvoice,
  rentalUnitBookingService
} from '../../core/services';
import PaymentActions from '../Orders/PaymentActions';
import Transactions from '../Orders/Transactions';
import { CONFLICT_ERROR_MESSAGE, PAYMENT_ERROR, RentalUnitType } from '../../core/constants';
import DiscountSelection from '../Discounts/DiscountSelection';
import PriceSelection from '../PriceSelection';
import LanguageSwitcher from '../LanguageSwitcher';

let searchablePlayers = [];

const hasBookingChanged = (parentBooking, activeBooking, customer, parentBookingDiscount, bookingDiscount) =>
  parentBooking.nominalStart !== activeBooking.nominalStart ||
  parentBooking.rentalUnit.id !== activeBooking.rentalUnit.id ||
  parentBooking.comment !== activeBooking.comment ||
  parentBooking.numberOfHoles !== activeBooking.numberOfHoles ||
  parentBooking.isGolfCardUser !== activeBooking.isGolfCardUser ||
  parentBooking.startDatetime !== activeBooking.startDatetime ||
  parentBooking.endDatetime !== activeBooking.endDatetime ||
  parentBooking.bookingUser.name !== customer.name ||
  parentBooking.bookingUser.email !== customer.email ||
  parentBooking.bookingUser.golfId !== customer.golfId ||
  parentBooking.bookingUser.phone !== customer.phone ||
  parentBookingDiscount?.discount.id !== bookingDiscount?.discount.id ||
  parentBooking.priceType !== activeBooking.priceType;

const getBookingChanges = (parentBooking, activeBooking, customer) => {
  const [parentBookingDiscount] = parentBooking.rentalUnitBookingDiscounts;
  const [bookingDiscount] = activeBooking.rentalUnitBookingDiscounts;

  let message = '<div><p>Följande ändringar kommer inte sparas</p><ul>';
  message += parentBooking.nominalStart !== activeBooking.nominalStart ? '<li>Starttid</li>' : '';
  message += parentBooking.rentalUnit.id !== activeBooking.rentalUnit.id ? '<li>Objekttyp</li>' : '';
  message += parentBookingDiscount?.discount.id !== bookingDiscount?.discount.id ? '<li>Rabattkod</li>' : '';
  message += parentBooking.comment !== activeBooking.comment ? '<li>Kommentar</li>' : '';
  message += parentBooking.numberOfHoles !== activeBooking.numberOfHoles ? '<li>Antal hål</li>' : '';
  message += parentBooking.isGolfCardUser !== activeBooking.isGolfCardUser ? '<li>Golfhäftet användare</li>' : '';
  message +=
    parentBooking.bookingUser.name !== customer.name ||
    parentBooking.bookingUser.email !== customer.email ||
    parentBooking.bookingUser.golfId !== customer.golfId ||
    parentBooking.bookingUser.phone !== customer.phone
      ? '<li>Kundinformation</li>'
      : '';
  message += '</ul></div>';
  return message;
};

function BookingModalViewRentals({
  golfclub,
  booking: parentBooking,
  rentalUnits,
  updateBooking,
  onClose,
  activeRequest,
  setError,
  setActiveRequest,
  error,
  isWidgetRequest,
  translate,
  fetchRentalUnitAvailability: fetchRentalUnitSchedule,
  pricings,
  onClickNewBooking,
  isCalendarView
}) {
  const [activeBooking, setActiveBooking] = useState(parentBooking);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [bookingChanged, setBookingChanged] = useState(false);
  const [confirmCancelBooking, setConfirmCancelBooking] = useState(false);
  const [confirmCloseWhenChanged, setConfirmCloseWhenChanged] = useState(null);
  const [customer, setCustomer] = useState({ userId: '', name: '', email: '', golfId: '', phone: '' });
  const [customerError, setCustomerErrors] = useState({ name: false, email: false, golfId: false });
  const [order, setOrder] = useState(null);
  const [openActionsModal, setOpenActionsModal] = useState(false);
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [isCancellingBooking, setIsCancellingBooking] = useState(false);
  const [hasScheduleError, setHasScheduleError] = useState(false);
  const [showDiscounts, setShowDiscounts] = useState(false);
  const [availablePriceTypes, setAvailablePriceType] = useState([]);

  useEffect(() => {
    searchablePlayers = golfclub.players.map(p => ({
      key: p.id,
      title: `${p.player.firstName} ${p.player.lastName}`,
      description: p.player.email
    }));

    const updatedBooking = {
      ...parentBooking,
      discountIds: parentBooking.rentalUnitBookingDiscounts.map(d => d.discount.id),
      sourceText: Object.keys(Source)
        .find(key => Source[key] === parentBooking.source)
        ?.toLowerCase(),
      rentalUnit: { ...parentBooking.rentalUnit, rentalUnitTypeId: parentBooking.rentalUnit.typeId }
    };

    // Make sure updated booking from parent is updated
    setActiveBooking(updatedBooking);
    setActiveRequest(ActiveRequest.FETCHING_OBJECTS);

    const { bookingUser } = parentBooking;
    if (bookingUser?.userId) {
      setCustomer({ ...bookingUser });
    }

    const initializeRentalUnitAndOrder = async () => {
      let { data: orderData } = await fetchRentalUnitOrder(parentBooking.id);

      if (!orderData && updatedBooking.status === BookingStatus.CONFIRMED) {
        orderData = await processOrder({
          rentalUnitBookingId: updatedBooking.id,
          golfCLubId: golfclub.id,
          customer: bookingUser,
          isProcessCharge: false
        });
      }
      setOrder(orderData);

      const orderTransactionDiffExists = orderData?.orderTotal !== orderData?.transactionTotal;
      const showPaymentView = !isWidgetRequest && orderTransactionDiffExists && golfclub.useOnlinePayment;
      setOpenActionsModal(showPaymentView);
      setActiveRequest(null);
    };
    initializeRentalUnitAndOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentBooking]);

  useEffect(() => {
    validateBookingChanged({ booking: activeBooking });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBooking, customer]);

  useEffect(() => {
    validateCustomer(customer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  useEffect(() => {
    if (error) {
      setActiveRequest(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (pricings && parentBooking.rentalUnit.priceCategory.id) {
      setAvailablePriceType(getAvailablePriceTypes(pricings, parentBooking.rentalUnit.priceCategory.id, translate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricings, parentBooking]);

  const updateDiscountedPrice = discounts => {
    // TODO: implement multiple discount selection
    const originalPrice = getOriginalPrice(activeBooking);
    const { discount, discountedPrice } = getDiscountedPrice(originalPrice, discounts);

    const priceInclVat = discountedPrice || originalPrice;
    const updatedBooking = {
      ...activeBooking,
      priceInclVat,
      originalPrice,
      discountIds: discount ? [discount.id] : [],
      rentalUnitBookingDiscounts: discount ? [{ discount }] : [],
      priceExclVat: getPriceExclVat(priceInclVat, activeBooking.rentalUnitTypeId, golfclub)
    };

    setActiveBooking(updatedBooking);
  };

  const addDiscount = discount => {
    // TODO: validation when in CanBeCombined is selected or existing in selectedDiscount
    updateDiscountedPrice([discount]);
  };

  const removeDiscount = () => {
    updateDiscountedPrice([]);
  };

  const onStartTimeClosed = async ({ selectedTime }) => {
    if (
      selectedTime.format('YYYY-MM-DDTHH:mm:00') === moment(activeBooking.startDatetime).format('YYYY-MM-DDTHH:mm:00')
    ) {
      return;
    }

    setError(null);
    setHasScheduleError(false);

    const startDatetime = moment(activeBooking.startDatetime)
      .clone()
      .hours(selectedTime.hours())
      .minutes(selectedTime.minutes())
      .seconds(0);

    let endDateTime = moment(activeBooking.endDatetime);

    if (endDateTime.isSame(startDatetime) || endDateTime.isBefore(startDatetime)) {
      endDateTime = startDatetime.clone().add(4, 'hours');
    }

    const startDateTimeString = startDatetime.format('YYYY-MM-DD HH:mm');
    const endDateTimeString = endDateTime.format('YYYY-MM-DD HH:mm');
    const updatedBooking = {
      ...activeBooking,
      startDatetime: startDateTimeString,
      endDatetime: endDateTimeString,
      nominalStart: startDateTimeString
    };

    setActiveBooking(updatedBooking);
    await validateSchedule(startDatetime, updatedBooking);
  };

  const onEndTimeClosed = async ({ selectedTime }) => {
    if (
      selectedTime.format('YYYY-MM-DDTHH:mm:00') === moment(activeBooking.endDatetime).format('YYYY-MM-DDTHH:mm:00')
    ) {
      return;
    }

    setError(null);
    setHasScheduleError(false);

    const endDatetime = moment(activeBooking.endDatetime)
      .clone()
      .hours(selectedTime.hours())
      .minutes(selectedTime.minutes())
      .seconds(0);

    let startDatetime = moment(activeBooking.startDatetime);

    if (endDatetime.isSame(startDatetime) || endDatetime.isBefore(startDatetime)) {
      startDatetime = endDatetime.clone().subtract(4, 'hours');
    }

    const startDateTimeString = startDatetime.format('YYYY-MM-DD HH:mm');
    const endDateTimeString = endDatetime.format('YYYY-MM-DD HH:mm');

    const updatedBooking = {
      ...activeBooking,
      startDatetime: startDateTimeString,
      endDatetime: endDateTimeString,
      nominalStart: startDateTimeString
    };

    setActiveBooking(updatedBooking);
    await validateSchedule(startDatetime, updatedBooking);
  };

  const onDateChanged = async (_e, { value }) => {
    setError(null);
    setHasScheduleError(false);

    const selectedDate = moment(value);

    const startDatetime = moment(activeBooking.nominalStart)
      .clone()
      .year(selectedDate.year())
      .month(selectedDate.month())
      .date(selectedDate.date());

    let endDateTime = moment(activeBooking.endDatetime);

    if (endDateTime.isSame(startDatetime) || endDateTime.isBefore(startDatetime)) {
      endDateTime = startDatetime.clone().add(4, 'hours');
    }

    const priceTypes = getAvailablePriceTypes(pricings, activeBooking.rentalUnit.priceCategory.id, translate);

    const startDateTimeString = startDatetime.format('YYYY-MM-DD HH:mm');
    const endDateTimeString = endDateTime.format('YYYY-MM-DD HH:mm');

    const updatedBooking = {
      ...activeBooking,
      startDatetime: startDateTimeString,
      nominalStart: startDateTimeString,
      endDatetime: endDateTimeString,
      priceType: priceTypes.length > 1 ? activeBooking.priceType : PriceType.SELL
    };

    setActiveBooking(updatedBooking);
    await validateSchedule(startDatetime, updatedBooking);
  };

  const validateSchedule = async (startDateTime, booking) => {
    await fetchRentalUnitSchedule({
      dateFrom: moment(startDateTime).format('YYYY-MM-DD'),
      dateTo: moment(booking.endDatetime).format('YYYY-MM-DD'),
      currentRentalUnitBookingId: booking.id
    });
    await validateBookingAvailability(booking);
  };

  const validateBookingAvailability = async booking => {
    const { error: availabilityError } = await rentalUnitBookingService.checkAvailability(booking);
    if (availabilityError?.errorCode === 'conflict') {
      setError(translate(CONFLICT_ERROR_MESSAGE));
      setHasScheduleError(true);
    }
  };

  const onRentalUnitChange = async (_e, { value: rentalUnitId }) => {
    setError(null);
    setHasScheduleError(false);

    const rentalUnit = rentalUnits.find(ao => ao.id === rentalUnitId);

    // TODO: resets priceType to SELL until common logic in the two booking component unified
    const updatedBooking = {
      ...activeBooking,
      priceInclVat: rentalUnit.price,
      originalPrice: rentalUnit.price,
      priceExclVat: getPriceExclVat(rentalUnit.price, rentalUnit.rentalUnitTypeId, golfclub),
      rentalUnit,
      discountIds: [],
      rentalUnitBookingDiscounts: [],
      priceType: PriceType.SELL
    };

    setActiveBooking(updatedBooking);
    await validateBookingAvailability(updatedBooking);
  };

  const validateBookingChanged = data => {
    const booking = data?.booking || activeBooking;
    const cust = data?.customer || customer;
    const [parentBookingDiscount] = parentBooking.rentalUnitBookingDiscounts;
    const [bookingDiscount] = booking.rentalUnitBookingDiscounts;

    setBookingChanged(hasBookingChanged(parentBooking, booking, cust, parentBookingDiscount, bookingDiscount));
  };

  const validateCustomer = cust => {
    setCustomerErrors({
      name: cust.name?.length < 3,
      email: !emailRegex.test(cust.email),
      // golfId: !noGolfId && (!cust.golfId || !golfIdRegex.test(cust.golfId))
      golfId: isWidgetRequest && (!cust.golfId || !golfIdRegex.test(cust.golfId))
    });
  };

  const onCustomerNameChange = (_e, { value }) => {
    const customers = searchablePlayers.filter(p => p.title.includes(value));
    const updatedCustomer = { ...customer, name: value, userId: null };
    setCustomer(updatedCustomer);
    setFilteredCustomers(customers);
  };

  const onCustomerEmailChange = (_e, { value }) => {
    const customers = searchablePlayers.filter(p => p.description.includes(value));
    const updatedCustomer = { ...customer, email: value, userId: null };
    setCustomer(updatedCustomer);
    setFilteredCustomers(customers);
  };

  const customerSelected = (_e, { result }) => {
    const selectedCustomer = golfclub.players.find(p => p.id === result.key);
    setCustomer({
      userId: selectedCustomer.userId,
      name: `${selectedCustomer.player.firstName} ${selectedCustomer.player.lastName}`,
      email: selectedCustomer.player.email || '',
      golfId: selectedCustomer.player.golfId || '',
      phone: selectedCustomer.player.phone || '',
      plateNumber: selectedCustomer.player.plateNumber || ''
    });
  };

  const customerDataChanged = (_e, { name, value }) => {
    setCustomer({ ...customer, [name]: value });
  };

  const commentChanged = async (_e, { value }) => {
    setActiveBooking({ ...activeBooking, comment: value });
  };

  const priceTypeChange = priceType => {
    const [pricing] = pricings;
    const price = pricing.pricings.find(x => x.priceCategory.id === activeBooking.rentalUnit.priceCategory.id);
    const originalPrice = getPriceToUse(priceType, price);

    const { discountedPrice } = getDiscountedPrice(originalPrice, activeBooking.rentalUnitBookingDiscounts);
    const priceInclVat = discountedPrice || originalPrice;

    const updatedBooking = {
      ...activeBooking,
      originalPrice,
      priceInclVat,
      priceExclVat: getPriceExclVat(priceInclVat, activeBooking.rentalUnitTypeId, golfclub),
      priceType
    };

    setActiveBooking(updatedBooking);
  };

  const btnCloseClicked = () => {
    if (!bookingChanged) {
      onClose();
      return;
    }
    const message = getBookingChanges(parentBooking, activeBooking, customer);
    setConfirmCloseWhenChanged(message);
  };

  const golfCardUserChange = (_e, { checked }) => {
    setActiveBooking({
      ...activeBooking,
      isGolfCardUser: checked
    });
  };

  const numberOfHolesChange = (_e, { value }) => {
    setActiveBooking({
      ...activeBooking,
      numberOfHoles: value
    });
  };

  const onProcessChargeClick = async ({ transactionType, refundTransactionObj, invoicePriceIncreaseCancel }) => {
    setActiveRequest(ActiveRequest.BOOKING_UPDATE);
    if (isCancellingBooking) {
      const result = await refundTransaction({ ...refundTransactionObj, isCancellingBooking });
      const orderData = result?.data?.data;

      if (orderData) {
        setOrder(orderData);
        if (orderData.transactionTotal === 0) {
          resetBookingCancellation();
          cancelBooking();
        }
      } else {
        resetBookingCancellation();
        setError(translate(PAYMENT_ERROR));
      }
    } else if (transactionType === TransactionType.ManualRefund || transactionType === TransactionType.StripeRefund) {
      const result = await refundTransaction(refundTransactionObj);
      const orderData = result?.data?.data;

      if (orderData) {
        setOrder(orderData);
        if (orderData.transactionTotal === orderData.orderTotal) {
          setOpenActionsModal(false);
        }
      } else {
        setError(translate(PAYMENT_ERROR));
        setOpenActionsModal(false);
      }
    } else {
      if (invoicePriceIncreaseCancel) {
        await refundTransaction(invoicePriceIncreaseCancel);
      }

      const updatedOrder = await processOrder({
        rentalUnitBookingId: activeBooking.id,
        golfCLubId: golfclub.id,
        customer,
        order,
        transactionType
      });

      if (updatedOrder) {
        setOrder(updatedOrder);
      } else {
        setError(translate(PAYMENT_ERROR));
      }
      setOpenActionsModal(false);
    }
    setActiveRequest(null);
  };

  const resetBookingCancellation = () => {
    setConfirmCancelBooking(false);
    setOpenActionsModal(false);
    setIsCancellingBooking(false);
  };

  const onCancelBookingClick = () => {
    if (order.transactionTotal > 0) {
      setIsCancellingBooking(true);
      setOpenActionsModal(true);
    } else {
      cancelBooking();
    }
  };

  const cancelBooking = () => {
    setActiveRequest(ActiveRequest.BOOKING_CANCEL);
    setConfirmCancelBooking(false);
    updateBooking(BookingStatus.CANCELLED, activeBooking);
  };

  const onCancelInvoice = async id => {
    setActiveRequest(ActiveRequest.BOOKING_UPDATE);
    const { data } = await cancelInvoice(id);
    if (data.isSuccess) {
      const { data: orderData } = await fetchRentalUnitOrder(activeBooking.id);
      setOrder(orderData);
      if (!isWidgetRequest && orderData && orderData.orderTotal !== orderData.transactionTotal) {
        setOpenActionsModal(true);
      }
    }
    setActiveRequest(null);
    setOpenTransactionModal(false);
  };

  const onResendInvoice = async id => {
    setActiveRequest(ActiveRequest.BOOKING_UPDATE);
    await resendInvoice(id);
    setOpenTransactionModal(false);
    setActiveRequest(null);
  };

  const hasActiveRequest = !!activeRequest;
  const customerDataIsInValid = customerError.name || customerError.email || customerError.golfId;
  const discountApplied = activeBooking?.originalPrice > activeBooking?.priceInclVat;

  const bookingStartDatetime = moment(activeBooking.startDatetime).clone();
  const bookingDate = bookingStartDatetime.format('YYYY-MM-DD');

  const bookingEndDatetime = moment(activeBooking.endDatetime).clone();

  return (
    <>
      {activeBooking && (
        <>
          <Modal
            onClose={onClose}
            open
            size="large"
            centered={false}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            className="modal-compact"
          >
            <>
              <Modal.Header>
                <Grid>
                  <Grid.Column width={16}>
                    <Translate id="default.booking" /> -{' '}
                    {translate(`booking-status.${activeBooking.status.toLowerCase()}`)}
                    <p style={{ fontSize: '16px', fontWeight: 'normal' }}>
                      <Translate id="edit-booking.modal-info-rental" options={{ renderInnerHtml: true }} />
                    </p>
                  </Grid.Column>
                </Grid>
              </Modal.Header>
              <Modal.Content>
                <Form>
                  <Grid>
                    <Grid.Column mobile={8} computer={3}>
                      <Form.Field>
                        <label>
                          <Translate id="default.start-date" />
                        </label>
                        <DateInput
                          closable
                          dateFormat="YYYY-MM-DD"
                          name="nominalStart"
                          placeholder={translate('default.start-date')}
                          value={bookingDate}
                          error={hasScheduleError}
                          onChange={onDateChanged}
                          localization="sv"
                          disabled={hasActiveRequest}
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column mobile={8} computer={3}>
                      <TimePicker
                        label={translate('default.start-time')}
                        error={hasScheduleError}
                        value={bookingStartDatetime}
                        disabledTimes={rentalUnits?.find(ao => ao.id === activeBooking.rentalUnit.id)?.disabledTimes}
                        onClose={onStartTimeClosed}
                        buttonText={translate('rental-unit.teetime-confirm')}
                      />
                    </Grid.Column>
                    {/* start end date time field */}
                    {!isWidgetRequest && (
                      <>
                        <Grid.Column mobile={8} computer={3}>
                          <TimePicker
                            label={translate('default.end-time')}
                            error={hasScheduleError}
                            value={bookingEndDatetime}
                            disabledTimes={
                              rentalUnits?.find(ao => ao.id === activeBooking.rentalUnit.id)?.disabledTimes
                            }
                            onClose={onEndTimeClosed}
                            placeholder={translate('rental-unit.teetime-select')}
                            buttonText={translate('rental-unit.teetime-confirm')}
                          />
                        </Grid.Column>
                      </>
                    )}
                    {/* end date time field */}
                    <Grid.Column mobile={8} computer={4}>
                      {isWidgetRequest ? (
                        <Form.Field
                          label={translate('default.object')}
                          control={Input}
                          value={activeBooking.rentalUnit.name}
                          readOnly
                          // error={!objectAvailable}
                        />
                      ) : (
                        <Form.Field>
                          <label>
                            <Translate id="default.object" />
                          </label>
                          <Dropdown
                            disabled={hasActiveRequest}
                            loading={activeRequest === ActiveRequest.FETCHING_OBJECTS}
                            selection
                            error={hasScheduleError}
                            value={activeBooking.rentalUnit.id}
                            onChange={onRentalUnitChange}
                            options={
                              rentalUnits?.map(ao => ({
                                value: ao.id,
                                text: ao.name,
                                disabled: !ao.available
                              })) || []
                            }
                            // error={!objectAvailable}
                          />
                        </Form.Field>
                      )}
                    </Grid.Column>
                    <Grid.Column mobile={8} computer={4}>
                      {availablePriceTypes?.length > 1 && !isWidgetRequest ? (
                        <PriceSelection
                          label={translate('default.select-price')}
                          value={activeBooking.priceType}
                          onChange={priceTypeChange}
                          options={availablePriceTypes}
                          loading={activeRequest === ActiveRequest.FETCHING_OBJECTS}
                          golfClubCurrency={golfclub.currency}
                        />
                      ) : (
                        <Form.Field>
                          <label>{translate('default.price')}</label>
                          <p>
                            {activeBooking.priceInclVat} {golfclub.currency}
                            {discountApplied && (
                              <span
                                style={
                                  discountApplied
                                    ? { color: 'red', textDecoration: 'line-through', marginLeft: '5px' }
                                    : {}
                                }
                              >
                                {activeBooking.originalPrice} {golfclub.currency}
                              </span>
                            )}
                          </p>
                        </Form.Field>
                      )}
                    </Grid.Column>
                  </Grid>

                  {/* {!objectAvailable && (
                    <ErrorMessage
                      error="Valt objekt är inte tillgänligt för valda datum"
                      style={{ marginTop: '10px' }}
                    />
                  )} */}
                  {activeBooking?.rentalUnit?.rentalUnitTypeId === RentalUnitType.GOLF_CART.id && (
                    <Grid>
                      <Grid.Column mobile={16} desktop={8} className="pt-0 pb-0">
                        <Button color="green" size="mini" onClick={() => setShowDiscounts(!showDiscounts)}>
                          <Icon name="add" />
                          <Translate id="discount.add-discount-code" />
                        </Button>
                      </Grid.Column>
                      <Grid.Column mobile={16} desktop={8}>
                        {(showDiscounts || activeBooking.rentalUnitBookingDiscounts?.length > 0) && (
                          <DiscountSelection
                            golfClubId={golfclub.id}
                            isAdminAccess={!isWidgetRequest}
                            appliedDiscounts={activeBooking.rentalUnitBookingDiscounts}
                            checkinDate={activeBooking.startDatetime}
                            onSelect={addDiscount}
                            onClear={removeDiscount}
                            rentalUnitTypeId={activeBooking?.rentalUnit?.rentalUnitTypeId}
                            isWidgetRequest={isWidgetRequest}
                          />
                        )}
                      </Grid.Column>
                    </Grid>
                  )}
                  <h3>
                    <Translate id="edit-booking.customer-information" />
                  </h3>

                  <Grid stackable columns={4}>
                    <Grid.Column>
                      {isWidgetRequest ? (
                        <Form.Field
                          control={Input}
                          required
                          type="text"
                          name="name"
                          label={`${translate('default.name')}`}
                          placeholder={`${translate('default.name')}`}
                          onChange={customerDataChanged}
                          error={!!customer.name && customerError.name}
                          value={customer.name}
                          disabled={hasActiveRequest}
                        />
                      ) : (
                        <Form.Field
                          control={Search}
                          required
                          type="text"
                          name="name"
                          mincharacters={2}
                          label={translate('default.name')}
                          placeholder={translate('default.name')}
                          onSearchChange={debounce(onCustomerNameChange, 500, { leading: true })}
                          onResultSelect={customerSelected}
                          results={filteredCustomers}
                          error={!!customer.name && customerError.name}
                          value={customer.name}
                          disabled={hasActiveRequest}
                        />
                      )}
                    </Grid.Column>
                    <Grid.Column>
                      {isWidgetRequest ? (
                        <Form.Field
                          control={Input}
                          required
                          type="text"
                          name="email"
                          label={`${translate('default.email')}`}
                          placeholder={`${translate('default.email')}`}
                          onChange={customerDataChanged}
                          error={!!customer.email && customerError.email}
                          value={customer.email}
                          disabled={hasActiveRequest}
                        />
                      ) : (
                        <Form.Field
                          control={Search}
                          required
                          type="email"
                          name="email"
                          mincharacters={2}
                          label={translate('default.email')}
                          placeholder={translate('default.email')}
                          onSearchChange={debounce(onCustomerEmailChange, 500, { leading: true })}
                          onResultSelect={customerSelected}
                          results={filteredCustomers}
                          error={!!customer.email && customerError.email}
                          value={customer.email}
                          disabled={hasActiveRequest}
                        />
                      )}
                    </Grid.Column>

                    <Grid.Column>
                      <Form.Field
                        control={Input}
                        required={isWidgetRequest}
                        type="text"
                        name="golfId"
                        label={`${translate('default.golf-id')}`}
                        placeholder={translate('default.golf-id')}
                        onChange={customerDataChanged}
                        error={!!customer.golfId && customerError.golfId}
                        value={customer.golfId}
                        disabled={hasActiveRequest}
                      />
                    </Grid.Column>

                    <Grid.Column>
                      <Form.Field
                        control={Input}
                        type="phone"
                        name="phone"
                        label={translate('default.phone')}
                        placeholder={translate('default.phone')}
                        onChange={customerDataChanged}
                        value={customer.phone}
                        disabled={hasActiveRequest}
                      />
                    </Grid.Column>
                  </Grid>

                  {activeBooking.isGolfCardUser && (
                    <Message info content={translate('default.golfhäftet-user-notice')} />
                  )}

                  <Grid doubling>
                    {activeBooking.rentalUnit.type.id === RentalUnitType.GOLF_BIKE.id && (
                      <Grid.Column width={3}>
                        <Form.Field>
                          <Checkbox
                            name="golfCardUser"
                            onChange={golfCardUserChange}
                            checked={!!activeBooking.isGolfCardUser}
                            label={translate('default.golfhäftet-user')}
                          />
                        </Form.Field>
                      </Grid.Column>
                    )}
                    {!isWidgetRequest && (
                      <>
                        <Grid.Column width={2}>
                          <Form.Field
                            control={Select}
                            name="numberOfHoles"
                            type="number"
                            value={activeBooking.numberOfHoles}
                            options={[
                              { key: 0, value: '', text: '' },
                              { key: 2, value: 9, text: 9 },
                              { key: 9, value: 18, text: 18 }
                              // { key: 9, value: 9, text: 9 }
                            ]}
                            onChange={numberOfHolesChange}
                            placeholder={translate('default.number-of-holes')}
                            label={translate('default.number-of-holes')}
                          />
                        </Grid.Column>
                        <Grid.Column width={16}>
                          <Form.Field>
                            <TextArea
                              name="comment"
                              onChange={commentChanged}
                              value={activeBooking.comment || ''}
                              placeholder={translate('accommodation-sheet.comment')}
                              style={{ minHeight: 100, fontFamily: 'Lato' }}
                              disabled={hasActiveRequest}
                            />
                          </Form.Field>
                        </Grid.Column>
                      </>
                    )}
                  </Grid>

                  {activeBooking.status && (
                    <Grid>
                      <Grid.Column mobile={16}>
                        {!isWidgetRequest && (
                          <>
                            <Label>
                              <Translate id="default.created" />:
                              <Label.Detail>
                                {moment.utc(parentBooking.created).local().format('YYYY-MM-DD HH:mm')}
                              </Label.Detail>
                            </Label>
                            {activeBooking.sourceText && (
                              <Label>
                                <Translate id="default.source" />:
                                <Label.Detail>{translate(`default.${activeBooking.sourceText}`)}</Label.Detail>
                              </Label>
                            )}
                          </>
                        )}
                        <Label color="green">
                          Status: {translate(`booking-status.${activeBooking.status.toLowerCase()}`)} -{' '}
                          {translate('edit-booking.booking-number')}: {activeBooking.id}
                          {order && <span>- Order: {order.id}</span>}
                        </Label>

                        <Label color="green">
                          {translate('edit-booking.price')}: {activeBooking.originalPrice}
                          {activeBooking.originalPrice > activeBooking.priceInclVat && (
                            <>
                              {' '}
                              / {translate('edit-booking.discount-price')}: {activeBooking.priceInclVat}
                            </>
                          )}
                        </Label>
                        {order && (
                          <>
                            {!!order.pendingPayment && (
                              <Label color="orange">
                                {translate('default.pending-payment')}: {order.pendingPayment}
                              </Label>
                            )}
                            {!order.pendingPayment && order.orderTotal > order.transactionTotal && (
                              <Label color="orange">
                                {translate('default.payment-is-due')}: {order.orderTotal - order.transactionTotal}
                              </Label>
                            )}
                            {order.orderTotal < order.transactionTotal && (
                              <Label color="orange">
                                {translate('default.refund-is-due')}: {order.transactionTotal - order.orderTotal}
                              </Label>
                            )}
                            {order.orderTotal === order.transactionTotal && (
                              <Label color="green">{translate('default.paid-in-full')}</Label>
                            )}
                          </>
                        )}
                        {activeBooking.status === BookingStatus.CONFIRMED && bookingChanged && (
                          <Label color="yellow" style={{ marginTop: '10px' }}>
                            <Translate id="edit-booking.confirmed-booking-changed" />
                          </Label>
                        )}
                      </Grid.Column>
                      {activeRequest === ActiveRequest.FETCHING_ORDER && (
                        <Grid.Column computer={16} textAlign="right">
                          <Loader
                            active
                            inline
                            size="tiny"
                            className="loader-bug-fix"
                            style={{ marginRight: '.5rem' }}
                          />
                          <span>
                            <Translate id="edit-booking.checking-payment-status" />
                          </span>
                        </Grid.Column>
                      )}
                    </Grid>
                  )}
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <ErrorMessage error={error} />
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={2} textAlign="left" verticalAlign="middle">
                      {isWidgetRequest && <LanguageSwitcher />}
                    </Grid.Column>
                    <Grid.Column width={14}>
                      {customerDataIsInValid && (
                        <p>
                          <Translate id="edit-booking.fill-customer-information" />
                        </p>
                      )}
                      <Button color="black" onClick={btnCloseClicked}>
                        <Translate id="default.close" />
                      </Button>

                      {activeBooking.status === BookingStatus.PREBOOKED && (
                        <Button
                          onClick={() => updateBooking(BookingStatus.CONFIRMED, activeBooking, customer)}
                          positive
                          disabled={hasActiveRequest || customerDataIsInValid}
                        >
                          {hasActiveRequest && (
                            <Loader inline size="tiny" inverted style={{ display: 'inline', marginRight: '10px' }} />
                          )}
                          <Translate id="default.create-booking" /> ({activeBooking.priceInclVat} {golfclub.currency})
                        </Button>
                      )}
                      {activeBooking.status === BookingStatus.CONFIRMED && (
                        <>
                          <Button onClick={() => setConfirmCancelBooking(true)} negative disabled={hasActiveRequest}>
                            {hasActiveRequest && (
                              <Loader inline size="tiny" inverted style={{ display: 'inline', marginRight: '10px' }} />
                            )}
                            <Translate id="default.cancel-booking" />
                          </Button>
                          <Button
                            onClick={() => updateBooking(BookingStatus.UPDATED, activeBooking, customer)}
                            positive
                            disabled={hasScheduleError || hasActiveRequest || customerDataIsInValid || !bookingChanged}
                          >
                            {hasActiveRequest && (
                              <Loader inline size="tiny" inverted style={{ display: 'inline', marginRight: '10px' }} />
                            )}
                            <Translate id="default.update-booking" /> ({activeBooking.priceInclVat} {golfclub.currency})
                          </Button>
                        </>
                      )}

                      <Modal
                        onClose={() => setOpenActionsModal(false)}
                        onOpen={() => setOpenActionsModal(true)}
                        open={openActionsModal}
                        trigger={
                          order?.orderTotal !== order?.transactionTotal &&
                          !isCancellingBooking &&
                          !isWidgetRequest && (
                            <Button
                              disabled={hasActiveRequest || customerDataIsInValid || bookingChanged}
                              loading={activeRequest === ActiveRequest.BOOKING_UPDATE}
                            >
                              <Translate id="default.show-actions" />
                            </Button>
                          )
                        }
                      >
                        <Modal.Header>
                          {' '}
                          <Translate id="default.select-an-action" />
                        </Modal.Header>
                        <Modal.Content>
                          <PaymentActions
                            isCancellingBooking={isCancellingBooking}
                            useOnlinePayment={golfclub.useOnlinePayment && false} // disable invoice payment for rental units
                            golfClubCurrency={golfclub.currency}
                            order={order}
                            disableButton={hasActiveRequest || customerDataIsInValid}
                            loading={activeRequest === ActiveRequest.BOOKING_UPDATE}
                            onProcessChargeClick={onProcessChargeClick}
                            onClose={() => setOpenActionsModal(false)}
                          />
                        </Modal.Content>
                        <Modal.Actions>
                          <Button color="black" onClick={() => setOpenActionsModal(false)}>
                            <Translate id="default.close" />
                          </Button>
                        </Modal.Actions>
                      </Modal>

                      {order?.transactions?.length > 0 && !isWidgetRequest && (
                        <Modal
                          onClose={() => setOpenTransactionModal(false)}
                          onOpen={() => setOpenTransactionModal(true)}
                          open={openTransactionModal}
                          trigger={
                            <Button
                              disabled={hasActiveRequest || customerDataIsInValid}
                              loading={activeRequest === ActiveRequest.BOOKING_UPDATE}
                            >
                              <Translate id="default.show-transactions" />
                            </Button>
                          }
                        >
                          <Modal.Header>Transactions</Modal.Header>
                          <Modal.Content>
                            <Transactions
                              order={order}
                              onCancel={onCancelInvoice}
                              onResend={onResendInvoice}
                              activeRequest={activeRequest}
                              setActiveRequest={setActiveRequest}
                              golfClubCurrency={golfclub.currency}
                            />
                          </Modal.Content>
                          <Modal.Actions>
                            <Button color="black" onClick={() => setOpenTransactionModal(false)}>
                              <Translate id="default.close" />
                            </Button>
                          </Modal.Actions>
                        </Modal>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Modal.Actions>
            </>
          </Modal>

          <MyConfirm
            open={confirmCancelBooking}
            size="mini"
            onCancel={() => {
              setConfirmCancelBooking(false);
              setIsCancellingBooking(false);
            }}
            onConfirm={() => {
              onCancelBookingClick();
            }}
            header={translate('default.cancel-rentalbookings-header')}
            content={translate('default.cancel-rentalbookings-content')}
            cancelButton={translate('default.no')}
            confirmButton={translate('default.yes')}
          />

          <MyConfirm
            open={!!confirmCloseWhenChanged}
            size="tiny"
            onCancel={() => setConfirmCloseWhenChanged(null)}
            onConfirm={() => {
              setConfirmCloseWhenChanged(null);
              onClose();
            }}
            header={translate('edit-booking.close-without-saving')}
            content={confirmCloseWhenChanged}
            cancelButton={translate('edit-booking.close-no')}
            confirmButton={translate('edit-booking.close-yes')}
          />

          <MyConfirm
            open={activeBooking.isConfirmed && !(isWidgetRequest && golfclub.useOnlinePayment)}
            size="tiny"
            onCancel={btnCloseClicked}
            onConfirm={() => {
              setActiveBooking({ ...activeBooking, isConfirmed: false });
            }}
            header={
              <>
                <Icon name="check circle outline" color="green" size="large" />
                <span className="green">{translate('default.booking-confirmed')}</span>
              </>
            }
            content={`
              <table><tbody>
                <tr><td><b>${translate('default.booked-by')}:</b></td><td>${activeBooking.bookingUser.name}</td></tr>
                <tr><td><b>${translate('edit-booking.booking-number')}:</b></td><td>${activeBooking.id}</td></tr>
                <tr><td><b>${translate('default.product')}:</b></td><td>${activeBooking.rentalUnit.name}</td></tr>
                <tr><td><b>${translate('default.time')}:</b></td><td>${activeBooking.nominalStart}</td></tr>
                <tr><td><b>${translate('edit-booking.price')}:</b></td><td>${activeBooking.priceInclVat} ${
              golfclub.currency
            }</td></tr>
                <tr><td colspan="2" class="pt-1">${translate('default.confirmation-sent-to')} <b>${
              activeBooking.bookingUser.email
            }</b></td></tr>
              </tbody></table>`}
            cancelButton={translate('default.close')}
            confirmButton={translate('default.edit-booking')}
            confirmButtonColor="green"
          />
        </>
      )}
    </>
  );
}

export default withLocalize(BookingModalViewRentals);

function MyConfirm({
  header,
  content,
  open,
  size,
  onCancel,
  onConfirm,
  cancelButton,
  confirmButton,
  confirmButtonColor
}) {
  return (
    <Modal open={open} size={size}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel}>{cancelButton}</Button>
        <Button onClick={onConfirm} color={confirmButtonColor || 'red'}>
          {confirmButton}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
