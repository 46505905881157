/** Handled product addon types */
export enum ProductAddonTypes {
  CleaningCabins,
  BedLinen,
  Breakfast,
  Dinner,
  DinnerPackage,
  Lunch,
  LunchWeekday,
  LunchWeekend,
  Greenfee,
  GreenfeeWeekday,
  GreenfeeWeekend
}
