import React from 'react';
import { Message } from 'semantic-ui-react';

// TODO: refactor this and all dependents
const ErrorMessage = ({ error, customText = null }) => {
  const text = getFormattedText(error, customText);

  return error ? <Message visible error content={error && text} /> : null;
};

const getFormattedText = (error, customText) => {
  if (customText) {
    return (
      <Message.List>
        <Message.Item>{customText}</Message.Item>
      </Message.List>
    );
  }

  if (!error) {
    return '';
  }
  if (typeof error === 'object' && error.errors) {
    return (
      <Message.List>
        {Object.values(error.errors).map((x, i) => (
          <Message.Item key={i}>{x}</Message.Item>
        ))}
      </Message.List>
    );
  }
  if (typeof error === 'object') {
    return (
      <Message.List>
        {Object.values(error)
          .filter(x => x && typeof x !== 'object')
          .map((x, i) => (
            <Message.Item key={i}>{x}</Message.Item>
          ))}
      </Message.List>
    );
  }
  return (
    <Message.List>
      <Message.Item>{error}</Message.Item>
    </Message.List>
  );
};

const WarningMessage = ({ error }) => {
  const text = getFormattedText(error);

  return error ? <Message color="orange" content={text} /> : null;
};

export { ErrorMessage, WarningMessage };
