import React from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { Segment, Grid, Table, Form, Button } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { CSVLink } from 'react-csv';
import { getGuestList } from '../../../core/services/golfclub.service';
import { isValidDate, todaysDate, startOfYear, endOfYear } from '../../../utils';

interface GuestListReportTabProps extends LocalizeContextProps {
  active: boolean;
  golfClubs: any[];
  translate: any;
}

interface GuestListItem {
  id: number;
  guestName: string;
  email: string;
  accommodationName: string;
  checkInDate: string;
  checkOutDate: string;
}

class GuestListReportTab extends React.Component<GuestListReportTabProps> {
  state = {
    loading: false,
    guestList: [],
    startDate: startOfYear(todaysDate()),
    startDateValid: true,
    endDate: endOfYear(todaysDate()),
    endDateValid: true
  };

  guestListHeaders = [
    { label: this.props.translate('default.checkin'), key: 'checkInDate' },
    { label: this.props.translate('default.checkout'), key: 'checkOutDate' },
    { label: this.props.translate('packages.headers.accommodation'), key: 'accommodationName' },
    { label: this.props.translate('default.customer'), key: 'guestName' },
    { label: this.props.translate('default.email'), key: 'email' }
  ];

  validateDatetimeChange = (_e: any, { name, value }: any) => {
    let { startDate, endDate, startDateValid, endDateValid } = this.state;

    if (name === 'startDate') {
      startDate = value;
      startDateValid = isValidDate(startDate);
    } else {
      endDate = value;
    }
    endDateValid = isValidDate(endDate) && endDate >= startDate;
    this.setState({ startDate, endDate, startDateValid, endDateValid });
  };

  fetchGuestList = async () => {
    const { startDate, endDate, startDateValid, endDateValid } = this.state;
    if (startDateValid && endDateValid) {
      this.setState({ loading: true });
      try {
        const guestListData = await getGuestList(startDate, endDate);

        if ('data' in guestListData) {
          const listData = guestListData.data.map((item: GuestListItem) => ({
            id: item.id,
            accommodationName: item.accommodationName,
            checkInDate: item.checkInDate.substring(0, 10),
            checkOutDate: item.checkOutDate.substring(0, 10),
            email: item.email,
            guestName: item.guestName
          }));

          this.setState({
            loading: false,
            guestList: listData
          });
        }
      } catch (error) {
        this.setState({ loading: false });
      }
    }
  };

  render() {
    const { startDate, endDate, startDateValid, endDateValid, loading, guestList } = this.state;
    const { translate } = this.props;

    const guestListCsvReport = {
      data: guestList,
      headers: this.guestListHeaders,
      filename: `${this.props.translate('club-admin.report-tabs.guestlist')}.csv`,
      separator: ';'
    };

    if (this.props.active) {
      return (
        <Segment>
          <div className="p-1">
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>
                    <Translate id="default.start-date" />
                  </label>
                  <DateInput
                    closable
                    dateFormat="YYYY-MM-DD"
                    name="startDate"
                    placeholder={translate('default.start-date')}
                    value={startDate}
                    error={!startDateValid}
                    onChange={this.validateDatetimeChange}
                    localization="sv"
                  />
                </Form.Field>
                <Form.Field>
                  <label>
                    <Translate id="default.end-date" />
                  </label>
                  <DateInput
                    closable
                    dateFormat="YYYY-MM-DD"
                    minDate={startDate}
                    name="endDate"
                    placeholder={translate('default.end-date')}
                    value={endDate}
                    error={!endDateValid}
                    onChange={this.validateDatetimeChange}
                    localization="sv"
                  />
                </Form.Field>
                <Form.Field>
                  <div style={{ position: 'absolute', bottom: '0px' }}>
                    <Button onClick={this.fetchGuestList} disabled={loading}>
                      {translate('default.fetch')}
                    </Button>
                  </div>
                </Form.Field>
              </Form.Group>
            </Form>
            <Grid>
              <Grid.Column floated="left" width="5">
                <Translate id="club-admin.report-tabs.guestlist" />
              </Grid.Column>
              {guestList?.length > 0 && (
                <Grid.Column floated="right" width="5" textAlign="right">
                  <CSVLink {...guestListCsvReport}>{translate('club-admin.export-to-csv')}</CSVLink>
                </Grid.Column>
              )}
            </Grid>

            <Segment loading={loading}>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      <Translate id="default.checkin" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Translate id="default.checkout" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Translate id="packages.headers.accommodation" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Translate id="default.customer" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Translate id="default.email" />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {guestList?.map((stat: GuestListItem, i: number) => (
                    <Table.Row key={i}>
                      <Table.Cell>{stat.checkInDate}</Table.Cell>
                      <Table.Cell>{stat.checkOutDate}</Table.Cell>
                      <Table.Cell>{stat.accommodationName}</Table.Cell>
                      <Table.Cell>{stat.guestName}</Table.Cell>
                      <Table.Cell>{stat.email}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Segment>
          </div>
        </Segment>
      );
    }

    return <></>;
  }
}

export default withLocalize(GuestListReportTab);
