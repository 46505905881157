import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import qs from 'qs';
import { Dimmer, Loader, Message, Segment } from 'semantic-ui-react';
import { fetchAccommodationBookingOrder } from '../core/services';
import { normalizeBookingDirect } from './AccommodationOverView';
import BookingModalView from '../components/BookingOverView/BookingModalView';
import { WarningMessage } from '../components/ErrorMessage';

function Booking({ translate }) {
  const [activeBooking, setActiveBooking] = useState(null);
  const [activeOrder, setActiveOrder] = useState(null);
  const [bookingId, setBookingId] = useState(null);
  const [golfClubId, setGolfClubId] = useState(null);
  const [customerEmail, setCustomerEmail] = useState(null);
  const [orderDeleteId, setOrderDeleteId] = useState(null);
  const [orderCancelled, setOrderCancelled] = useState(false);
  const [isActiveRequest, setIsActiveRequest] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    const { id, gcid, email, odid } = params;
    setBookingId(id);
    setOrderDeleteId(odid);
    setCustomerEmail(email);
    setGolfClubId(gcid);
  }, []);

  useEffect(() => {
    if (bookingId && customerEmail && golfClubId) {
      setIsActiveRequest(true);
      fetchAccommodationBookingOrder(bookingId, golfClubId, customerEmail, orderDeleteId).then(result => {
        if ('data' in result && result.data) {
          setActiveOrder(result.data);

          const orderItem =
            result.data.orderItems && result.data.orderItems.length > 0 ? result.data.orderItems[0] : null;

          if (orderItem) {
            normalizeBookingDirect(orderItem.accommodationBooking);
            setActiveBooking(orderItem.accommodationBooking);
          }
        } else {
          // error fetching order
          setError('Requested booking not found.');
        }

        setIsActiveRequest(false);
      });
    }
  }, [bookingId, customerEmail, golfClubId, orderDeleteId]);

  return (
    <>
      <Segment className="pb-2" style={{ height: 300 }}>
        <Dimmer active={isActiveRequest} inverted>
          <Loader />
          <div style={{ color: '#000000', marginTop: 75, fontWeight: 'bold' }}>{translate('order.searching')}</div>
        </Dimmer>

        {orderCancelled && <Message color="green" content={translate('order.cancelled')} />}
        <WarningMessage error={error} />
      </Segment>

      {activeBooking && (
        <BookingModalView
          activeBooking={activeBooking}
          activeOrder={activeOrder}
          error={error}
          golfclub={{ players: [], currency: 'SEK' }}
          publicHolidays={[]}
          isWidgetRequest
          isCustomerDeleteRequest
          onClose={isCancelled => {
            setActiveBooking(null);
            setActiveOrder(null);
            setOrderCancelled(isCancelled);
          }}
          pendingTransaction={undefined}
          setActiveOrder={setActiveOrder}
          setError={setError}
          switchBooking={() => {}}
          translate={translate}
          updateBooking={() => {}}
        />
      )}
    </>
  );
}

export default withLocalize(Booking);
