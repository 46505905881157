import React, { Fragment } from 'react';
import { Translate } from 'react-localize-redux';

import { Form, List, Label } from 'semantic-ui-react';
class ProductViewItem extends React.Component {
  render() {
    const product = this.props.data;
    return (
      <Fragment>
        <List>
          <List.Item>
            <Label size="medium">
              <Translate id="product.description" />:<Label.Detail>{product.description}</Label.Detail>
            </Label>
          </List.Item>
          <List.Item>
            <Label size="medium">
              <Translate id="default.nr-players" />:<Label.Detail>{product.numberOfPlayers}</Label.Detail>
            </Label>
          </List.Item>
          <List.Item>
            <Label size="medium">
              <Translate id="product.zero-price" />:<Label.Detail>{product.isZeroPrice ? 'Yes' : 'No'}</Label.Detail>
            </Label>
          </List.Item>
          <List.Item>
            <Label size="medium">
              <Translate id="product.sort-order" />
              <Label.Detail>{product.sortOrder}</Label.Detail>
            </Label>
          </List.Item>
        </List>
      </Fragment>
    );
  }
}
class ProductEditItem extends React.Component {
  state = {
    product: {
      id: null,
      description: '',
      numberOfPlayers: 1,
      isZeroPrice: false,
      sortOrder: 999
    },
    valid: {
      all: null,
      description: true,
      numberOfPlayers: true,
      isZeroPrice: true,
      sortOrder: true
    }
  };

  componentDidMount = () => {
    if (!this.props.data) {
      return;
    }
    const { data } = this.props;
    this.validate(data);
    this.props.dataIsValid(this.state.valid.all);
  };

  componentDidUpdate = (_prevProps, prevState) => {
    const { valid } = this.state;
    if (prevState.valid.all !== valid.all) {
      this.props.dataIsValid(valid.all);
    }
  };

  saveItem = () => {
    const { product } = this.state;
    if (this.props.data && Object.prototype.hasOwnProperty.call(this.props.data, 'id')) {
      product.id = this.props.data.id;
    }
    this.props.saveAction(product, this.props.onSubmit);
  };

  validate = data => {
    if (data) {
      this.validateDescription({ target: { name: 'description', value: data.description } });
      this.validateNumberOfPlayers({ target: { name: 'numberOfPlayers', value: data.numberOfPlayers } });
      this.validateSortOrder({ target: { name: 'sortOrder', value: data.sortOrder } });
      this.setState({ product: data });
    }
  };

  validateField = (e, validator) => {
    const val = e.target.value;
    const field = e.target.name;
    const { product, valid } = this.state;

    product[field] = val;
    valid[field] = validator(val, e);
    valid.all =
      valid.description === true &&
      valid.numberOfPlayers === true &&
      valid.isZeroPrice === true &&
      valid.sortOrder === true;

    this.setState({
      product,
      valid
    });
    this.props.dataIsValid(valid.all);
  };

  validateDescription = e => this.validateField(e, val => val.length > 0);
  validateSortOrder = e =>
    this.validateField(e, val => val === '' || (!isNaN(parseFloat(val)) && isFinite(val) && val >= 0));
  validateNumberOfPlayers = e =>
    this.validateField(e, val => val === '' || (!isNaN(parseFloat(val)) && isFinite(val) && val >= 0));
  validateGenericField = e => this.validateField(e, _val => true);

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <Fragment>
            <Form id={this.props.id} onSubmit={this.saveItem}>
              <List>
                <List.Item>
                  <Form.Field>
                    <Form.Input
                      required
                      label={translate('product.description')}
                      type="text"
                      name={'description'}
                      placeholder={translate('product.description')}
                      onChange={this.validateDescription}
                      error={!this.state.valid.description}
                      value={this.state.product.description}
                    />
                  </Form.Field>
                  <Form.Input
                    label={translate('default.nr-players')}
                    type="number"
                    name={'numberOfPlayers'}
                    placeholder={translate('default.nr-players')}
                    onChange={this.validateNumberOfPlayers}
                    error={!this.state.valid.numberOfPlayers}
                    value={this.state.product.numberOfPlayers}
                  />
                  <Form.Field>
                    <Form.Checkbox
                      inline
                      label={translate('product.zero-price')}
                      onChange={(_e, { checked }) => {
                        const { product } = this.state;
                        product.isZeroPrice = checked;
                        this.setState({ product });
                      }}
                      checked={this.state.product.isZeroPrice}
                    />
                  </Form.Field>
                  <Form.Input
                    label={translate('product.sort-order')}
                    type="number"
                    name={'sortOrder'}
                    placeholder={translate('product.sort-order')}
                    onChange={this.validateSortOrder}
                    error={!this.state.valid.sortOrder}
                    value={this.state.product.sortOrder}
                  />
                </List.Item>
              </List>
            </Form>
          </Fragment>
        )}
      </Translate>
    );
  }
}

export { ProductViewItem, ProductEditItem };
