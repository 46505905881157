import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { LocalizeProvider } from 'react-localize-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { persistor, store } from './store/index';
import App from './App';
import * as serviceWorker from './registerServiceWorker';

// if (process.env.REACT_APP_ENV === 'prod') {
Sentry.init({
  dsn: 'https://9bb977920c4a49dfb9f88691a38977c3@o486889.ingest.sentry.io/5958233',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.1,
  environment: process.env.REACT_APP_ENV,
  // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/16
  ignoreErrors: ['Non-Error promise rejection captured with value:']
});
// }

// if (process.env.NODE_ENV !== 'production') {
//   const {whyDidYouUpdate} = require('why-did-you-update');
//   whyDidYouUpdate(React, { exclude: [/^Link|MenuItem|Route|Router/] });
// }

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <LocalizeProvider>
      <PersistGate loading={<div />} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </LocalizeProvider>
  </Provider>
);

serviceWorker.unregister();
