import { BookingStatus, PriceType } from '../core/enums';

export const getPriceToUse = (priceType, pricing) => {
  if (priceType === PriceType.RACK && pricing.rackPriceInclVat > 0) {
    return pricing.rackPriceInclVat;
  }
  if (priceType === PriceType.MEMBER && pricing.memberPriceInclVat >= 0) {
    return pricing.memberPriceInclVat;
  }

  return pricing.sellPriceInclVat;
};

export const getBookingPrice = ({
  availability,
  priceCategoryId,
  numberOfGuests = null,
  priceType = PriceType.SELL
}) => {
  let price = 0;
  let pricing = null;
  let rackPriceExists = false;

  availability.pricings.forEach(p => {
    const nrOfDays = p.dates.length; // Only dates within asked range is returned so we can multiply the length (nr of days) with the price
    pricing = p.pricings
      .sort((a, b) => b.numberOfGuests - a.numberOfGuests)
      .find(
        availabilityPricing =>
          availabilityPricing.priceCategory.id === priceCategoryId &&
          (!!availabilityPricing.numberOfGuests && !!numberOfGuests
            ? numberOfGuests >= availabilityPricing.numberOfGuests
            : true)
      );

    if (pricing) {
      if (!rackPriceExists) {
        rackPriceExists = pricing.rackPriceInclVat > 0;
      }
      const priceToUse = getPriceToUse(priceType, pricing);

      price += nrOfDays * priceToUse;
    }
  });

  return { price, pricing, isRackPrice: rackPriceExists };
};

export const getAvailableAccommodation = ({
  accommodationId,
  defaultAvailability,
  priceType = PriceType.SELL,
  availability = null,
  numberOfGuests = null
}) => {
  const availabilityData = availability || defaultAvailability;
  const availableAccommodation = availabilityData?.accommodations?.find(a => a.accommodation.id === accommodationId);
  const pricing = getBookingPrice({
    availability: availabilityData,
    priceCategoryId: availableAccommodation?.accommodation?.priceCategoryId,
    priceType,
    numberOfGuests
  });
  return { availableAccommodation, pricing };
};

export const getAvailablePriceTypes = (pricings, priceCategoryId, translate) => {
  const availablePriceTypes = [{ key: 1, text: `${translate('default.sale-price')}`, value: PriceType.SELL }];
  const rackPriceExists = pricings.reduce((acc, p) => {
    const foundPricing = p.pricings.find(pr => pr.priceCategory.id === priceCategoryId);
    return acc + foundPricing?.rackPriceInclVat > 0;
  }, 0);
  const memberPriceExists = pricings.reduce((acc, p) => {
    const foundPricing = p.pricings.find(pr => pr.priceCategory.id === priceCategoryId);
    return acc + (foundPricing?.memberPriceInclVat !== null && foundPricing?.memberPriceInclVat !== undefined ? 1 : 0);
  }, 0);

  if (rackPriceExists) {
    availablePriceTypes.push({ key: 2, text: `${translate('default.rack-price')}`, value: PriceType.RACK });
  }

  if (memberPriceExists) {
    availablePriceTypes.push({ key: 3, text: `${translate('default.member-price')}`, value: PriceType.MEMBER });
  }

  return availablePriceTypes;
};

export const isSuccessfulBooking = data => data && data.status === BookingStatus.CONFIRMED && data.paid;

export const getAvailableRentalUnit = ({
  rentalUnitId,
  defaultAvailability,
  priceType = PriceType.SELL,
  availability = null,
  numberOfGuests = null
}) => {
  const availabilityData = availability || defaultAvailability;
  const availableRentalUnit = availabilityData.rentalUnits.find(a => a.rentalUnit.id === rentalUnitId);
  const pricing = getBookingPrice({
    availability: availabilityData,
    priceCategoryId: availableRentalUnit.rentalUnit.priceCategory.id,
    priceType,
    numberOfGuests
  });
  return { availableRentalUnit, pricing };
};
