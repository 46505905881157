import React, { Component } from 'react';
import { Container } from 'semantic-ui-react';
import Helmet from '../components/Helmet';
import ClubSwitch from '../components/ClubSwitch';

class SwitchClubPage extends Component {
  render() {
    return (
      <div className="app app-login">
        <Helmet title="OnTeeX switch" description="Switch!" />
        <Container>
          <ClubSwitch />
        </Container>
      </div>
    );
  }
}

export default SwitchClubPage;
