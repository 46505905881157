import React, { useEffect, useState } from 'react';
import Helmet from '../components/Helmet';
import {
  Button,
  ButtonGroup,
  ButtonOr,
  Container,
  Dimmer,
  Form,
  FormField,
  Loader,
  Radio,
  Rating,
  Segment,
  TextArea
} from 'semantic-ui-react';
import { withLocalize } from 'react-localize-redux';
import qs from 'qs';
import { accommodationBookingService } from '../core/services';

function Survey({ translate }) {
  const [onTeeApp, setOnTeeApp] = useState(null);
  const [visitInfo, setVisitInfo] = useState(null);
  const [bookingType, setBookingType] = useState('');
  const [recommendRating, setRecommendRating] = useState(null);
  const [overallRating, setOverallRating] = useState(null);
  const [missing, setMissing] = useState('');
  const [improve, setImprove] = useState('');
  const [token, setToken] = useState(null);
  const [bookingId, setBookingId] = useState(null);
  const [activeRequest, setActiveRequest] = useState(null);
  const [surveyData, setSurveyData] = useState(null);
  const [surveyError, setSurveyError] = useState(null);
  const [surveySuccess, setSurveySuccess] = useState(null);
  const [forceSurvey, setForceSurvey] = useState(false);
  const [optOutSuccess, setOptOutSuccess] = useState(false);

  useEffect(() => {
    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    const token = params['token'];
    const id = params['id'];
    const isOptOut = params['opt-out'] && params['opt-out'] === '1';

    if (token && id) {
      setToken(token);
      setBookingId(params['id']);

      if (isOptOut && !forceSurvey) {
        setActiveRequest('opt-out');
      } else {
        setActiveRequest('fetch-data');

        // fetch booking data
        accommodationBookingService
          .getSurvey(id, token)
          .then(result => {
            if (result.error) {
              setSurveyError(result.error.errorData.message);
            } else {
              setSurveyData(result.data.data);
            }
          })
          .finally(() => {
            setActiveRequest(null);
          });
      }
    }
  }, [forceSurvey]);

  const handleChange = (e, { value }) => {
    setBookingType(value);

    if (value === 'OnTee.com' || value === 'OnTee App') {
      setOnTeeApp('Y');
    }
  };

  const handleRecommendRate = (e, { rating }) => setRecommendRating(rating);

  const handleOverallRate = (e, { rating }) => setOverallRating(rating);

  const saveSurvey = async () => {
    setActiveRequest('save-data');

    const saveData = {
      id: bookingId,
      token,
      bookingType,
      overallRating,
      visitInfo,
      missing,
      onTeeApp,
      recommendRating,
      improve
    };

    const result = await accommodationBookingService.createSurvey(saveData);

    if (result.error) {
      setSurveyError(result.error?.errorData?.message || 'An error occured submitting the survey');
    } else {
      setSurveySuccess(true);
    }

    setActiveRequest(null);
  };

  const optOut = async () => {
    setActiveRequest('save-data');

    const result = await accommodationBookingService.optOutSurvey(bookingId, token);

    if (result.error) {
      setSurveyError(result.error?.errorData?.message || 'An error occured trying to opt-out the email address');
      setActiveRequest('opt-out');
    } else {
      setOptOutSuccess(true);
      setActiveRequest(null);
    }
  };

  return (
    <div className="app app-change-password">
      <Helmet title="OnTee survey" description="OnTee after stay survey" />
      <Container className="mw-900">
        {activeRequest && (activeRequest === 'fetch-data' || activeRequest === 'save-data') && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        )}
        {optOutSuccess && (
          <Segment>
            {translate('survey.opt-out-success')}
            <p className="mt-2">{translate('survey.opt-out-success-footer')}</p>
          </Segment>
        )}
        {surveyError && (
          <Segment raised color="red">
            {surveyError}
          </Segment>
        )}
        {surveySuccess && (
          <Segment raised color="green">
            {translate('survey.thank-you')}
          </Segment>
        )}

        {activeRequest && activeRequest === 'opt-out' && (
          <>
            <Segment>{translate('survey.opt-out-title')}</Segment>
            <div style={{ textAlign: 'center' }}>
              <Button negative onClick={optOut}>
                {translate('survey.opt-out-confirm')}
              </Button>
              <Button onClick={() => setForceSurvey(true)}>{translate('survey.opt-out-reject')}</Button>
            </div>
          </>
        )}

        {surveyData && !surveySuccess && (
          <>
            <Segment raised secondary>
              <h3 className="center">
                {translate('survey.title')} {surveyData.golfClubName}, {surveyData.checkInDate} -{' '}
                {surveyData.checkOutDate}
              </h3>
            </Segment>
            <Segment>
              <h4>{translate('survey.question-booking-type')}</h4>
              <Form>
                <FormField>
                  <Radio
                    label={translate('survey.question-booking-club')}
                    name="radioGroup"
                    value="club"
                    checked={bookingType === 'club'}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField>
                  <Radio
                    label={translate('survey.question-booking-widget')}
                    name="radioGroup"
                    value="widget"
                    checked={bookingType === 'widget'}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField>
                  <Radio
                    label={translate('survey.question-booking-ontee-web')}
                    name="radioGroup"
                    value="OnTee.com"
                    checked={bookingType === 'OnTee.com'}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField>
                  <Radio
                    label={translate('survey.question-booking-ontee-app')}
                    name="radioGroup"
                    value="OnTee App"
                    checked={bookingType === 'OnTee App'}
                    onChange={handleChange}
                  />
                </FormField>
              </Form>
            </Segment>
            <Segment>
              <h4>{translate('survey.question-booking-overall')}</h4>
              <div style={{ display: 'inline-flex', alignItems: 'center', fontWeight: 'bold' }}>
                1 <Rating icon="heart" defaultRating={null} maxRating={10} size="massive" onRate={handleOverallRate} />{' '}
                10
              </div>
            </Segment>
            <Segment>
              <h4>{translate('survey.question-booking-info')}</h4>
              <ButtonGroup>
                <Button positive={visitInfo === 'Y'} toggle onClick={() => setVisitInfo('Y')}>
                  {translate('default.yes')}
                </Button>
                <ButtonOr text={translate('default.or')} />
                <Button negative={visitInfo === 'N'} toggle onClick={() => setVisitInfo('N')}>
                  {translate('default.no')}
                </Button>
              </ButtonGroup>
            </Segment>
            <Segment>
              <h4>{translate('survey.question-booking-missing')}</h4>
              <Form>
                <TextArea placeholder="Tell us more" value={missing} onChange={(e, { value }) => setMissing(value)} />
              </Form>
            </Segment>
            <Segment>
              <h4>{translate('survey.question-booking-ontee')}</h4>
              <ButtonGroup>
                <Button positive={onTeeApp === 'Y'} toggle onClick={() => setOnTeeApp('Y')}>
                  {translate('default.yes')}
                </Button>
                <ButtonOr text={translate('default.or')} />
                <Button negative={onTeeApp === 'N'} toggle onClick={() => setOnTeeApp('N')}>
                  {translate('default.no')}
                </Button>
              </ButtonGroup>
            </Segment>
            <Segment>
              <h4>{translate('survey.question-booking-recommend')}</h4>
              <div style={{ display: 'inline-flex', alignItems: 'center', fontWeight: 'bold' }}>
                1{' '}
                <Rating icon="heart" defaultRating={null} maxRating={10} size="massive" onRate={handleRecommendRate} />{' '}
                10
              </div>
            </Segment>
            {recommendRating && recommendRating < 9 && (
              <Segment>
                <h4>{translate('survey.question-booking-improve')}</h4>
                <Form>
                  <TextArea placeholder="Tell us more" value={improve} onChange={(e, { value }) => setImprove(value)} />
                </Form>
              </Segment>
            )}
            <div style={{ textAlign: 'center' }}>
              <Button positive onClick={saveSurvey}>
                {translate('default.submit')}
              </Button>
            </div>
          </>
        )}
      </Container>
    </div>
  );
}

export default withLocalize(Survey);
