import React from 'react';

import { Icon } from 'semantic-ui-react';

const Sidebar = ({ children, visible, style, closeClick }) => (
  <div>
    <div className={`ot-sidebar ${visible && 'visible'}`}>
      <div className="ot-sidebar-close">
        <Icon name="close" link size="big" onClick={closeClick} />
      </div>
      <div className={`ot-sidebar-content ${style}`}>{children}</div>
    </div>
    <div className={`ot-sidebar-mask ${visible && 'visible'}`}></div>
  </div>
);

export default Sidebar;
