import React, { Component } from 'react';
import Helmet from '../components/Helmet';
import AccommodationSheet from '../containers/AccommodationSheet';

class AccommodationBooking extends Component {
  render() {
    return (
      <div className="app app-accommodation" style={{ paddingTop: this.props.isWidgetRequest ? 0 : '8em' }}>
        <Helmet title="OnTee accommodation booking" description="OnTee teesheet on steroid!" />
        <AccommodationSheet isWidgetRequest={this.props.isWidgetRequest} />
      </div>
    );
  }
}

export default AccommodationBooking;
