import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { Translate, withLocalize } from 'react-localize-redux';

import { Link } from 'react-router-dom';
import { Grid, Form } from 'semantic-ui-react';
import { ErrorMessage } from '../../components/ErrorMessage';

import { clubAdminConfigActions } from '../ClubAdminConfigureForm/ducks';

import { requestLogin } from './ducks';

class LoginForm extends React.Component {
  constructor() {
    super();

    this.emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    this.state = {
      user: {
        email: '',
        password: ''
      },
      valid: {
        email: true,
        password: true
      }
    };
  }

  componentDidMount = () => {
    const { login } = this.props;
    if (login.isAuthenticated && login.token) {
      this.props.history.push('/');
    }
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    const { login } = nextProps;
    if (login.token !== this.props.login.token && login.isAuthenticated && login.token) {
      const { state } = this.props.location;
      const { from } = state || {};
      const { pathname } = from || { pathname: '/' };
      this.props.fetchGolfClubsAction();
      this.props.fetchHolidaysAction();
      this.props.history.push(pathname);
    }
  };

  validateEmail = e => {
    const email = e.target.value;
    const { user, valid } = this.state;

    user.email = email;
    valid.email = this.emailRex.test(email);

    this.setState({
      user,
      valid
    });
  };

  validatePassword = e => {
    const password = e.target.value;
    const { user, valid } = this.state;

    user.password = password;
    valid.password = !!password;

    this.setState({
      user,
      valid
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.requestLogin(this.state.user);
  };

  render() {
    const { valid } = this.state;
    const { translate } = this.props;

    return (
      <Grid columns="1" stackable>
        <Grid.Column className="bg-white">
          <div className="p-3">
            <h1>
              <Translate id="login.sign-in" />
            </h1>
            {/* <p className="text-muted">
              <Translate id="login.sign-in-info" />
            </p> */}
            <Form onSubmit={this.handleSubmit} loading={this.props.activeRequest} error={!!this.props.login.error}>
              <Form.Field className="mb-3">
                <Form.Input
                  label={translate('default.email')}
                  required
                  type="email"
                  name="email"
                  placeholder={translate('default.email')}
                  onChange={this.validateEmail}
                  error={!valid.email}
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  label={translate('default.password')}
                  required
                  type="password"
                  name="password"
                  placeholder={translate('default.password')}
                  onChange={this.validatePassword}
                  error={!valid.password}
                />
              </Form.Field>
              <ErrorMessage error={this.props.login.error} />
              <Form.Button fluid color="teal" disabled={!valid.email || !valid.password}>
                <Translate id="login.sign-in" />
              </Form.Button>
              <Link to="/reset-password">
                <Translate id="login.forgot-password" />?
              </Link>
            </Form>
          </div>
        </Grid.Column>
        {/* <Grid.Column color="green">
          <div className="p-3 text-center text-white">
            <Link to="/sign-up">
              <Button color="blue" size="massive" className="mt-3 px-5">
                <Translate id="login.sign-up" />
              </Button>
            </Link>
          </div>
        </Grid.Column> */}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    activeRequest: state.login.activeRequest
  };
}

function mapDispatchToProps(dispatch) {
  return {
    requestLogin: user => dispatch(requestLogin(user)),
    ...bindActionCreators(clubAdminConfigActions, dispatch)
  };
}

export default withLocalize(withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm)));
