import React, { useEffect, useState } from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Button, Grid, Icon, Segment, Table } from 'semantic-ui-react';
import { getGolfClubs } from '../../containers/AccommodationSheet/ducks';
import { IGolfClubDetailItem } from '../../core/services/golfclub.service';
import { activatePackage, deletePackage, getPackages, IPackage } from '../../core/services/product.service';
import { endOfYear, startOfYear, todaysDate } from '../../utils';
import PackageEdit from './PackageEdit';
import PackageListItem from './PackageListItem';
import { fetchCategories } from '../../utils/api';

export interface PackagesListProps extends LocalizeContextProps {
  golfClubs: any[];
}

export interface PriceCategory {
  accommodationTypeId: number;
  text: string;
  value: number;
}

let golfclub: IGolfClubDetailItem | null = null;

function PackagesList(props: PackagesListProps) {
  const [apiLoading, setApiLoading] = useState(false);
  const [addonPackage, setAddonPackage] = useState<IPackage | null>(null);
  const [packages, setPackages] = useState<IPackage[]>([]);
  const [priceCategories, setPriceCategories] = useState<PriceCategory[]>([]);

  [golfclub] = props.golfClubs;

  const { translate } = props;

  useEffect(() => {
    const initializeData = async () => {
      const fetchPriceCategories = async () => {
        const result = await fetchCategories();
        if ('data' in result) {
          const pc = result.data.priceCategories.map((a: any) => ({
            value: a.id,
            text: translate(`accommodation.${a.name}`),
            accommodationTypeId: a.accommodationTypeId
          }));

          setPriceCategories(pc);
        }
      };

      const fetchPackages = async () => {
        const data = await getPackages();

        data.forEach(item => {
          // eslint-disable-next-line no-param-reassign
          item.validFrom = item.validFrom.substring(0, 10);
          // eslint-disable-next-line no-param-reassign
          item.validTo = item.validTo.substring(0, 10);
          // eslint-disable-next-line no-param-reassign
          item.highSeasonEnd = item.highSeasonEnd?.substring(0, 10);
          // eslint-disable-next-line no-param-reassign
          item.highSeasonStart = item.highSeasonStart?.substring(0, 10);
        });

        setPackages(data);
      };

      setApiLoading(true);

      Promise.all([fetchPriceCategories(), fetchPackages()]).then(() => {
        setApiLoading(false);
      });
    };

    initializeData();
  }, [translate]);

  const onActivateClick = async (packageToActivate: IPackage) => {
    setApiLoading(true);

    try {
      await activatePackage(packageToActivate.dbId);

      const data = await getPackages();
      setPackages(data);
    } finally {
      setApiLoading(false);
    }
  };

  const onEditClick = (editPackage: IPackage) => {
    setAddonPackage(editPackage);
  };

  const onDeleteClick = async (packageToDelete: IPackage) => {
    setApiLoading(true);

    try {
      await deletePackage(packageToDelete.dbId);

      const data = await getPackages();
      setPackages(data);
    } finally {
      setApiLoading(false);
    }
  };

  const onAddClick = () => {
    if (golfclub) {
      setAddonPackage({
        dbId: -1,
        golfClubId: golfclub.id,
        name: '',
        description: '',
        isActivated: false,
        chargeType: 2,
        priceCategories: [],
        productAddons: [],
        pricings: [],
        validFrom: startOfYear(todaysDate()),
        validTo: endOfYear(todaysDate()),
        highSeasonEnd: '',
        highSeasonStart: ''
      });
    }
  };

  const onAddonPackageSaved = (addonPackages: IPackage[]) => {
    addonPackages.forEach(item => {
      // eslint-disable-next-line no-param-reassign
      item.validFrom = item.validFrom.substring(0, 10);
      // eslint-disable-next-line no-param-reassign
      item.validTo = item.validTo.substring(0, 10);
      // eslint-disable-next-line no-param-reassign
      item.highSeasonEnd = item.highSeasonEnd?.substring(0, 10);
      // eslint-disable-next-line no-param-reassign
      item.highSeasonStart = item.highSeasonStart?.substring(0, 10);
    });

    setPackages(addonPackages);
    setAddonPackage(null);
  };

  return (
    <Segment loading={apiLoading}>
      <Grid colums={2}>
        <Grid.Row>
          <Grid.Column width={8} textAlign="right">
            &nbsp;
          </Grid.Column>
          <Grid.Column width={8} textAlign="right">
            <Button color="green" onClick={onAddClick}>
              <Icon name="add" />
              <Translate id="discount.add" />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>
              <Translate id="packages.headers.name" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Translate id="rental-units.category" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Translate id="product-addons.valid-from" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Translate id="product-addons.valid-to" />
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {packages?.map((packageItem: IPackage) => (
            <PackageListItem
              onActivateClick={onActivateClick}
              addonPackage={packageItem}
              currency={golfclub?.currency}
              key={packageItem.dbId}
              priceCategories={priceCategories}
              onDeleteClick={onDeleteClick}
              onEditClick={onEditClick}
            />
          ))}
        </Table.Body>
      </Table>

      {addonPackage && (
        <PackageEdit
          currency={golfclub?.currency || 'SEK'}
          editPackage={addonPackage}
          priceCategories={priceCategories}
          onCancelClick={() => setAddonPackage(null)}
          onSaved={onAddonPackageSaved}
        />
      )}
    </Segment>
  );
}

function mapStateToProps(state: any) {
  return {
    golfClubs: getGolfClubs(state)
  };
}

export default withLocalize(connect(mapStateToProps, null)(PackagesList));
