export const ActiveRequest = {
  BOOKING_UPDATE: 'BOOKING_UPDATE',
  BOOKING_CANCEL: 'BOOKING_CANCEL',
  FETCHING_OBJECTS: 'FETCHING_OBJECTS',
  FETCHING_ORDER: 'FETCHING_ORDER',
  FETCHING_TRANSACTION: 'FETCHING_TRANSACTION',
  PROCESSING_ORDER: 'PROCESSING_ORDER',
  ORDER_CANCEL: 'ORDER_CANCEL',
  WAITING_SWISH_PAYMENT: 'WAITING_SWISH_PAYMENT'
};
Object.freeze(ActiveRequest);
