import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { withLocalize } from 'react-localize-redux';
import { Button, Icon, Label, Popup, Table } from 'semantic-ui-react';
import { Validity } from '../../core/enums';

function DiscountViewItem({
  discount,
  onClickEdit,
  onClickDelete,
  btnsDisabled,
  loading,
  translate,
  accomodationTypes,
  golfClubCurrency
}) {
  const isDiscountActive = discount.active && discount.validity === Validity.CURRENT;
  const [discountDates, setDiscountDates] = useState({ applyStart: '', applyEnd: '' });
  useEffect(() => {
    setDiscountDates({
      applyStart: moment(discount.applyStart).format('YYYY-MM-DD'),
      applyEnd: moment(discount.applyEnd).format('YYYY-MM-DD')
    });
  }, [discount]);

  return (
    <Table.Row>
      <Table.Cell>
        {isDiscountActive && (
          <>
            <Popup
              trigger={
                <Label size="mini" color="green" style={{ marginRight: '3px' }}>
                  <Icon name="check" style={{ marginRight: 0 }} />
                </Label>
              }
              content={translate('discount.active')}
              position="top left"
            />
          </>
        )}
        {discount.code}
      </Table.Cell>
      <Table.Cell>
        {discount.name}
        {discount.info && <Popup trigger={<Icon name="info circle" />}>{discount.info}</Popup>}
      </Table.Cell>
      {/* <Table.Cell>{translate(`discount.${kebabCase(discountTypeKey)}`)}</Table.Cell> */}
      <Table.Cell>{discount.amount + (discount.discountType === 1 ? '%' : golfClubCurrency)}</Table.Cell>
      <Table.Cell>{`${discountDates.applyStart} - ${discountDates.applyEnd}`}</Table.Cell>
      <Table.Cell>
        {discount.rentalUnitTypeIds.map((id, index) => {
          const acName = accomodationTypes.find(t => t.value === id);

          return (
            <React.Fragment key={id}>
              {acName.text}
              {discount.rentalUnitTypeIds.length !== index + 1 ? ', ' : ''}
            </React.Fragment>
          );
        })}
      </Table.Cell>
      <Table.Cell textAlign="right">
        <Button color="green" size="mini" icon onClick={() => onClickEdit(discount)} disabled={btnsDisabled}>
          <Icon name="edit outline" />
        </Button>
        <Popup
          trigger={
            <Button color="red" size="mini" icon disabled={btnsDisabled}>
              <Icon name="trash alternate" />
            </Button>
          }
          content={
            <Button
              color="red"
              content="Bekräfta borttagning?"
              onClick={() => onClickDelete(discount)}
              loading={loading}
            />
          }
          on="click"
          position="top right"
        />
      </Table.Cell>
    </Table.Row>
  );
}

export default withLocalize(DiscountViewItem);
