//import { delay } from 'redux-saga';
import { put } from 'redux-saga/effects';

import { types } from '../containers/Teesheet/ducks';
import { UNAUTHORIZED } from '../containers/LoginForm/ducks';

import * as Api from '../utils/api';
import { rentalUnitBookingService } from '../core/services';

export function* fetchTeeTimes(action) {
  try {
    const { selectedDate, golfCourseId } = action;
    const response = yield Api.fetchTeeTimes(selectedDate, golfCourseId);
    // console.log('selectedDate', selectedDate);
    // console.log('response', response);

    if (response.code === 200) {
      yield put({
        type: types.FETCH_TEETIMES_SUCCESS,
        teetime: { date: selectedDate, times: response.data.teetimes, golfCourse: response.data.golfCourse }
      });
    } else if (response.code === 401) {
      yield put({ type: UNAUTHORIZED });
    } else {
      yield put({ type: types.FETCH_TEETIMES_FAILED, error: response.error || 'Fel' });
    }
  } catch (error) {
    // console.log('Sage error', error);
    // yield put({ type: LOGIN_FAILED, error: error.message });
  }
}

export function* createBooking(action) {
  yield* createBookingInternal(action, Api.createBooking);
}

function* createBookingInternal(action, apiFunction) {
  const getActionTypeSuccess = action => {
    return action.type + '_SUCCESS';
  };

  const getActionTypeFailed = action => {
    return action.type + '_FAILED';
  };

  try {
    const { booking, onSubmit } = action;
    const response = yield apiFunction(...booking);
    // console.log('booking', booking);
    // console.log('response', response);

    if (onSubmit) {
      onSubmit();
    }
    if (response.code === 200) {
      yield put({ type: getActionTypeSuccess(action), teetime: response.data });
    } else if (response.code === 401) {
      yield put({ type: UNAUTHORIZED });
    } else if (response.code === 409) {
      yield put({ type: getActionTypeFailed(action), error: response.error.error, teetime: response.error.teetime });
    } else {
      yield put({ type: getActionTypeFailed(action), error: response.error.error || 'Fel' });
    }
  } catch (error) {
    // console.log('Sage error', error);
    // yield put({ type: LOGIN_FAILED, error: error.message });
  }
}

export function* cancelPrebooking(action) {
  if (action.booking) {
    yield* createBooking(action);
  }
}

export function* createAccommodationBooking(action) {
  if (action.booking[1]) {
    yield* createAccommodationBookingInternal(action);
  }
}

export function* createAccommodationBookingInternal(action) {
  const getActionTypeSuccess = action => {
    return action.type + '_SUCCESS';
  };

  const getActionTypeFailed = action => {
    return action.type + '_FAILED';
  };

  try {
    const { booking, onSubmit } = action;
    const response = yield rentalUnitBookingService.createAccommodationBooking(...booking);
    // console.log('booking', booking);
    // console.log('response', response);

    if (onSubmit) {
      onSubmit();
    }
    if (response.code === 200) {
      yield put({ type: getActionTypeSuccess(action), accommodation: response.data });
    } else if (response.code === 401) {
      yield put({ type: UNAUTHORIZED });
    } else if (response.code === 409) {
      yield put({
        type: getActionTypeFailed(action),
        error: response.error.error,
        accommodation: response.error.accommodation
      });
    } else {
      yield put({ type: getActionTypeFailed(action), error: response.error.error || response.error || 'Fel' });
    }
  } catch (error) {
    // console.log('Sage error', error);
    // yield put({ type: LOGIN_FAILED, error: error.message });
  }
}

export function* cancelAccommodationPrebooking(action) {
  if (action.booking[1]) {
    yield* createAccommodationBookingInternal(action);
  }
}

export function* createRentalUnitBooking(action) {
  if (action.booking[1]) {
    yield* createRentalUnitBookingInternal(action);
  }
}

export function* createRentalUnitBookingInternal(action) {
  const getActionTypeSuccess = action => {
    return action.type + '_SUCCESS';
  };

  const getActionTypeFailed = action => {
    return action.type + '_FAILED';
  };

  try {
    const { booking, onSubmit } = action;
    const response = yield rentalUnitBookingService.create(...booking);
    // console.log('booking', booking);
    // console.log('response', response);

    if (onSubmit) {
      onSubmit();
    }
    if (response.code === 200) {
      yield put({ type: getActionTypeSuccess(action), rentalUnit: response.data });
    } else if (response.code === 401) {
      yield put({ type: UNAUTHORIZED });
    } else if (response.code === 409) {
      yield put({
        type: getActionTypeFailed(action),
        error: response.error.error,
        rentalUnit: response.error.rentalUnit
      });
    } else {
      yield put({ type: getActionTypeFailed(action), error: response.error.error || response.error || 'Fel' });
    }
  } catch (error) {
    // console.log('Sage error', error);
    // yield put({ type: LOGIN_FAILED, error: error.message });
  }
}

export function* cancelRentalUnitPrebooking(action) {
  if (action.booking[1]) {
    yield* createRentalUnitBookingInternal(action);
  }
}
