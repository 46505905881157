import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { Button, Dimmer, Loader, Modal, Table } from 'semantic-ui-react';
import { IBookingHistory, getBookingHistory } from '../../core/services/product.service';

export interface BookingModalViewProps {
  id: number;
  golfClubCurrency: string;
  golfClubId: number;
  show: boolean;
  onClose: () => void;
}

function BookingHistoryModal(props: BookingModalViewProps) {
  const [activeRequest, setActiveRequest] = useState<boolean>(false);
  const [bookingChanges, setBookingChanges] = useState<IBookingHistory[]>();

  useEffect(() => {
    if (props.show) {
      setActiveRequest(true);

      getBookingHistory(props.id, props.golfClubId).then(results => {
        setBookingChanges(results);

        setActiveRequest(false);
      });
    }
  }, [props.golfClubId, props.id, props.show]);

  return (
    <Translate>
      {({ translate }) => (
        <Modal centered open={props.show} className="modal-compact" size="large">
          <Modal.Header>{translate('edit-booking.history')}</Modal.Header>
          <Modal.Content>
            <Dimmer active={activeRequest} inverted>
              <Loader />
            </Dimmer>

            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <Translate id="default.date" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                  <Table.HeaderCell>
                    <Translate id="default.accommodation" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Translate id="default.status" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Translate id="default.checkin" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Translate id="default.checkout" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Translate id="default.price" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Translate id="default.paid-in-full" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Translate id="edit-booking.checked-in" />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {bookingChanges &&
                  bookingChanges.map(log => (
                    <Table.Row className="pt-0 pb-0" key={Math.random().toString()}>
                      <Table.Cell>{log.dateUpdated}</Table.Cell>
                      <Table.Cell>{log.userName}</Table.Cell>
                      <Table.Cell>{log.accommodationName}</Table.Cell>
                      <Table.Cell>{translate(`booking-status.${log.status.toLowerCase()}`)}</Table.Cell>
                      <Table.Cell>{log.checkInDate}</Table.Cell>
                      <Table.Cell>{log.checkOutDate}</Table.Cell>
                      <Table.Cell>
                        {log.price} {props.golfClubCurrency}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: 'center' }}>{log.paid ? '✔' : '✖'}</Table.Cell>
                      <Table.Cell style={{ textAlign: 'center' }}>{log.checkedIn ? '✔' : '✖'}</Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Modal.Content>
          <Modal.Actions>
            <Button color="black" onClick={props.onClose}>
              <Translate id="default.close" />
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Translate>
  );
}

export default BookingHistoryModal;
