import React, { useEffect, useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import {
  Container,
  Segment,
  Loader,
  Dimmer,
  Icon,
  Grid,
  Form,
  Checkbox,
  Button,
  Modal,
  Table
} from 'semantic-ui-react';
import moment from 'moment';
import { connect } from 'react-redux';
import { PriceType, Source, BookingStatus, ActiveRequest } from '../core/enums';
import { getGolfClubs, getPublicHolidays } from '../containers/AccommodationSheet/ducks';
import { ErrorMessage } from '../components/ErrorMessage';
import BookingModalView from '../components/BookingOverView/BookingModalView';
import Row from '../components/BookingOverView/Row';
import { CellHeader } from '../components/BookingOverView/Cell';
import { CaravanIcon, getAvailableAccommodation } from '../utils';
import { accommodationBookingService, closeOpenOrder, fetchAccommodationOrder, processOrder } from '../core/services';
import { NO_PRICING, ONGOING_WIDGET_BOOKING, StatusColor } from '../core/constants';
import { OrderSearchResults } from './OrderSearchResults';
import { IBookingProductAddon, IPackageSelect } from '../core/services/product.service';
import { SimpleConfirm } from '../components/SimpleConfirm';

export interface IBooking {
  accommodationId?: number;
  accommodationName?: string;
  accommodation: IAccommodation;
  accommodationBookingDiscounts?: any[];
  additionalGuests?: any[];
  bookingUser: IBookingUser;
  userName?: string;
  userEmail?: string;
  hasCleaning?: boolean;
  hasAddonsAvailable?: boolean;
  hasPackagesAvailable?: boolean;
  hasErrors: boolean;
  checkedIn: boolean;
  checkInDate: any;
  checkOutDate: any;
  comment: string;
  created?: any;
  currentUserPrebooking?: boolean;
  days?: any;
  discountIds?: any;
  golfClubId?: number;
  id?: any;
  numberOfGuests?: any;
  originalPrice?: number;
  paid?: boolean;
  priceExclVat?: number;
  priceInclVat?: number;
  priceType?: any;
  productAddons: IBookingProductAddon[];
  source?: any;
  sourceText?: string;
  status?: string;
  isChanged: boolean;
  closeAllBookings: boolean;
  packages: IPackageSelect[];
}

export interface IBookingUser {
  userId?: any;
  email: string;
  golfId: string;
  name: string;
  plateNumber: string;
  phone: string;
  noGolfId: boolean;
}

export interface IAvailableAccommodation {
  accommodation: IAccommodation;
  available: boolean;
  blockedPeriod: any[];
  bookings: any[];
}

export interface IAccommodation {
  golfClubId: number;
  hasDrain: boolean;
  hasElectricity: boolean;
  id: number;
  info: string;
  maxGuests: any;
  name: string;
  numberOfBeds: number | undefined;
  priceCategory: any;
  priceCategoryId: any;
  secret: string;
  type: any;
  typeId: number;
  typeName: string;
  adminBookingOnly: boolean;
  advanceDays: number;
}

interface IUpdateBookingParams {
  status: any;
  booking: IBooking;
  customer?: any;
  keepOrder?: boolean;
  isOrderConfirmed: boolean;
  isUpdatingOrder: boolean;
  bookingChangeInfo?: any;
}

interface IFilterData {
  accommodationTypeId: number;
  hasDrain: boolean;
  hasElectricity: boolean;
  numberOfBeds: any;
}

interface IDropData {
  accommodationId: number;
  accommodationName: string;
  bookingId: number;
  newDate: string;
  checkinDate: string;
  oldAccommodationName: string;
  user: string;
}

/**
 * Returns a booking object color code based on booking status
 *
 * @param {IBooking} booking Booking object.
 */
const getStatusColor = (booking: IBooking, activeOrder: any): string => {
  const now = moment();
  const checkInDate = moment(booking.checkInDate);
  const checkOutDate = moment(booking.checkOutDate);

  // if there is an active order, display all the bookings of the order in one color
  if (
    activeOrder &&
    activeOrder.orderItems &&
    activeOrder.orderItems.length > 0 &&
    activeOrder.orderItems.find(
      (orderItem: any) =>
        orderItem.accommodationBookingId === booking.id || orderItem.rentalUnitBookingId === booking.id
    )
  ) {
    return StatusColor.ACTIVE_ORDER_BOOKING;
  }

  if (booking.status === BookingStatus.PREBOOKED) {
    // Status prebook, yellow color if current user "owns" the booking, otherwise orange color
    return booking.currentUserPrebooking ? StatusColor.PREBOOKED_SAME_USER : StatusColor.PREBOOKED_DIFFERENT_USER;
  }
  if (checkOutDate.isSameOrAfter(now) && booking.checkedIn && booking.paid) {
    // Green color if check out date has not passed and booking is checked in and paid.
    // This is an active booking where everything (marked as checked in and paid) is ok
    return StatusColor.ACTIVE_BOOKING;
  }
  if (checkInDate.isSameOrAfter(now, 'day') && !booking.checkedIn && booking.paid) {
    // Blue color for paid future boookings that are not checked in yet
    return StatusColor.PAID_FUTURE_BOOKING;
  }
  if (checkInDate.isSameOrAfter(now, 'day')) {
    // Blue color if check in date has not passed. In other words, booking is in the future
    return StatusColor.FUTURE_BOOKING;
  }
  if (checkInDate.isBefore(now) && (!booking.checkedIn || !booking.paid)) {
    // Red color if check in date has passed but booking is not marked as checked in or not as paid
    // Ongoing booking that not checked in or not paid
    return StatusColor.PASSED_INCOMPLETE_BOOKING;
  }
  if (checkOutDate.isBefore(now) && booking.checkedIn && booking.paid) {
    // Gray color if check out date has passed and booking is checked in and paid.
    // Booking is ok and completed, check out date has passed.
    return StatusColor.PASSED_COMPLETE_BOOKING;
  }

  // Purple color - We shouldn't end up here but just in case otherwise the are white and can be missed in the scheme
  return StatusColor.DEFAULT;
};

/**
 * Returns a "smaller" booking object
 *
 * @param {object} booking Booking object.
 */
const minBooking = (booking: IBooking, activeOrder: any, golfClubCurrency: string, translate: any) => ({
  id: booking.id,
  accommodationId: booking.accommodationId,
  name: booking.userName,
  email: booking.userEmail,
  checkInDate: booking.checkInDate,
  checkOutDate: booking.checkOutDate,
  priceInclVat: booking.priceInclVat,
  status: booking.status,
  currentUserPrebooking: booking.currentUserPrebooking,
  hasCleaning: booking.hasCleaning,
  comment: booking.comment,
  statusColor: getStatusColor(booking, activeOrder),
  info: (
    <>
      {booking.userEmail ? (
        <React.Fragment>
          {booking.userEmail}
          <br />
        </React.Fragment>
      ) : (
        ''
      )}
      <br />
      <b>Period:</b> {`${booking.checkInDate} - ${booking.checkOutDate}`}
      <br /> <b>{translate('product-addons.headers.days')}:</b>{' '}
      {moment(booking.checkOutDate).diff(moment(booking.checkInDate), 'days')} <br />
      <b>{translate('default.paid-in-full')}:</b>{' '}
      {booking.paid ? <Icon name="check" color="green" /> : <Icon name="close" color="red" />}
      {booking.priceInclVat} {golfClubCurrency}
      <br />
      <b>{translate('edit-booking.checked-in')}:</b>{' '}
      {booking.checkedIn ? <Icon name="check" color="green" /> : <Icon name="close" color="red" />}
      <br />
      {
        <React.Fragment>
          <b>{translate('default.booked-by')}:</b>{' '}
          {Number(booking.source) === 0 ? '3-parts' : Number(booking.source) === 1 ? 'Admin' : 'Kund'}
        </React.Fragment>
      }
      {booking.comment && (
        <React.Fragment>
          <div style={{ fontStyle: 'italic', marginTop: 10 }}>{booking.comment}</div>
        </React.Fragment>
      )}
    </>
  )
});

/**
 * Returns extracted data for easy population of the overview schedule
 *
 * @param {object} availability Availability object fetched from backend.
 */

const extractOverviewData = (
  availability: any,
  sDateMonth: any,
  eDateMonth: any,
  translate: any,
  golfClubCurrency: string,
  activeOrder: any
) => {
  const startDateMonth = moment(sDateMonth);
  const endDateMonth = moment(eDateMonth).subtract(1, 'day'); // Request to backend is first day in next month. Step back to last day in current month
  const nrOfDaysInMonth = startDateMonth.daysInMonth();

  return availability?.accommodations.map((accommodation: IAvailableAccommodation) => {
    let scheme: any[] = [];
    let currentDate = startDateMonth;
    const bookingsAndBlockPeriod =
      accommodation.blockedPeriod?.length > 0
        ? [...accommodation.bookings, ...accommodation.blockedPeriod]
        : accommodation.bookings;

    bookingsAndBlockPeriod.forEach((x: any) => {
      // blocked period use startDate and endDate, treated as booking
      if (x.startDate && x.endDate) {
        x.checkInDate = x.startDate;
        x.checkOutDate = x.endDate;
        x.isBlockedPeriod = true;
      }
    });

    if (bookingsAndBlockPeriod.length === 0) {
      scheme = scheme.concat([
        {
          day: parseInt(currentDate.format('DD'), 10),
          nrOfDays: endDateMonth.diff(currentDate, 'day')
        }
      ]);
    } else {
      bookingsAndBlockPeriod
        .sort((a: any, b: any) => moment(a.checkInDate).diff(b.checkInDate))
        .forEach((booking: any) => {
          const checkInDate = moment(booking.checkInDate);
          const checkOutDate = moment(booking.checkOutDate);
          const nCheckInDate = checkInDate.isBefore(startDateMonth) ? startDateMonth : checkInDate;
          // Add 1 day makes sure bookings that start last day in month is included
          const nCheckOutDate = checkOutDate.isAfter(endDateMonth) ? endDateMonth.clone().add(1, 'day') : checkOutDate;

          const nrOfDays = nCheckInDate.diff(currentDate, 'day');
          scheme = scheme.concat([{ day: parseInt(currentDate.format('DD'), 10), nrOfDays }]);
          currentDate = currentDate.clone().add(nrOfDays, 'day');
          scheme = scheme.concat([
            {
              day: parseInt(currentDate.format('DD'), 10),
              nrOfDays: nCheckOutDate.diff(nCheckInDate, 'day'),
              booking: booking.isBlockedPeriod
                ? booking
                : minBooking(booking, activeOrder, golfClubCurrency, translate),
              checkInDateInPrevMonth: checkInDate.isBefore(startDateMonth)
            }
          ]);
          currentDate = nCheckOutDate;
        });

      scheme = scheme.filter(s => s.nrOfDays > 0);

      const lastObj = scheme[scheme.length - 1];
      const lastDay = parseInt(lastObj.day, 10) + lastObj.nrOfDays;
      if (lastDay <= nrOfDaysInMonth) {
        const nrOfDays = endDateMonth.diff(currentDate, 'day');
        scheme = scheme.concat([{ day: parseInt(currentDate.format('DD'), 10), nrOfDays }]);
      }
    }

    return {
      id: accommodation.accommodation.id,
      name: accommodation.accommodation.name,
      hasElectricity: accommodation.accommodation.hasElectricity,
      hasDrain: accommodation.accommodation.hasDrain,
      numberOfBeds: accommodation.accommodation.numberOfBeds,
      accommodationTypeId: accommodation.accommodation.typeId,
      adminBookingOnly: accommodation.accommodation.adminBookingOnly,
      info: (
        <>
          {accommodation.accommodation.typeId === 1 ? <CaravanIcon /> : <Icon name="home" />}
          {translate(`accommodation.${accommodation.accommodation.typeName}`)}

          {accommodation.accommodation.hasElectricity && (
            <>
              <br />
              <Icon name="plug" /> Elektricitet
            </>
          )}
          {accommodation.accommodation.hasDrain && (
            <>
              <br />
              <Icon name="tint" /> Avlopp
            </>
          )}
          {accommodation.accommodation.numberOfBeds && (
            <>
              <br />
              <Icon name="bed" /> {accommodation.accommodation.numberOfBeds} bäddar
            </>
          )}
        </>
      ),
      // accommodation: t.accommodation,
      scheme
    };
  });
};

/**
 * Returns formatted checkin and checkout dates in format YYYY-MM-DD
 *
 * @param {object} dates {startDay, endDay}.
 * @param {string} year
 */
const formatDates = (yearMonth: any, { startDay, endDay }: any) => {
  const tmpStartDay = parseInt(startDay, 10);
  let tmpEndDay = parseInt(endDay, 10);

  const yearMonthNew = yearMonth.clone();

  const lastDayInMonth = parseInt(yearMonthNew.endOf('month').format('DD'), 10);
  const yearMonthEnd = yearMonth.clone();

  if (tmpEndDay > lastDayInMonth) {
    yearMonthEnd.add(1, 'months');
    // eslint-disable-next-line no-param-reassign
    tmpEndDay = 1;
  }

  const checkInDate = `${yearMonthNew.format('YYYY')}-${yearMonthNew.format('MM')}-${
    tmpStartDay < 10 ? `0${tmpStartDay}` : tmpStartDay
  }`;
  const checkOutDate = `${yearMonthEnd.format('YYYY')}-${yearMonthEnd.format('MM')}-${
    tmpEndDay < 10 ? `0${tmpEndDay}` : tmpEndDay
  }`;

  return { checkInDate, checkOutDate };
};

/**
 * Directly normalizes a booking to ensure we compare correct values
 *
 * @param {IBooking} booking A booking object.
 */
export const normalizeBookingDirect = (booking: IBooking) => {
  booking.days = moment(booking.checkOutDate).diff(booking.checkInDate, 'day');
  booking.paid = !!booking.paid;
  booking.comment = booking.comment || '';

  if (booking.bookingUser) {
    booking.bookingUser.userId = booking.bookingUser?.userId || '';
    booking.bookingUser.name = booking.bookingUser?.name || '';
    booking.bookingUser.email = booking.bookingUser?.email || '';
    booking.bookingUser.golfId = booking.bookingUser?.golfId || '';
    booking.bookingUser.phone = booking.bookingUser?.phone || '';
    booking.bookingUser.plateNumber = booking.bookingUser?.plateNumber || '';
  }
};

/*
const normalizeBooking = (booking: IBooking): IBooking => ({
  ...booking,
  days: moment(booking.checkOutDate).diff(booking.checkInDate, 'day'),
  paid: !!booking.paid,
  checkedIn: !!booking.checkedIn,
  bookingUser: {
    userId: booking.bookingUser?.userId || '',
    name: booking.bookingUser?.name || '',
    email: booking.bookingUser?.email || '',
    golfId: booking.bookingUser?.golfId || '',
    phone: booking.bookingUser?.phone || '',
    plateNumber: booking.bookingUser?.plateNumber || ''
  }
});
*/
/**
 * Filters all objects in data based on filters in filter object
 *
 * @param {object} filter.
 * @param {array} data.
 */
const filterAccommodations = (filter: any, data: any) => {
  let filteredAccommodations = data;
  if (Object.keys(filter).length) {
    if (filter.accommodationTypeId) {
      filteredAccommodations = filteredAccommodations.filter(
        (a: any) => a.accommodationTypeId === filter.accommodationTypeId
      );
    }
    if (filter.hasDrain) {
      filteredAccommodations = filteredAccommodations.filter((a: any) => a.hasDrain === filter.hasDrain);
    }
    if (filter.hasElectricity) {
      filteredAccommodations = filteredAccommodations.filter((a: any) => a.hasElectricity === filter.hasElectricity);
    }
    if (filter.numberOfBeds) {
      filteredAccommodations = filteredAccommodations.filter((a: any) => a.numberOfBeds === filter.numberOfBeds);
    }
  }

  return filteredAccommodations;
};

const cellWidth = 30;
let monthAvailability: any = null;
let bookingHasChanged = false;
let allOverviewData: any = null;

const nrOfBeds = Array.from(Array(10), (_, i) => i + 1).map(i => ({ value: i, text: i }));

function AccommodationOverview({
  golfclub,
  publicHolidays,
  daysInMonth,
  selectedYearMonth,
  accommodationTypes,
  dateRange,
  translate,
  setActiveOrder,
  activeOrder,
  onCancelOrderClick,
  isStickyHeader,
  hasRentalUnitTypes,
  login
}: any) {
  const [activeBooking, setActiveBooking] = useState<any>(null);
  const [activeRequest, setActiveRequest] = useState<any>(null);
  const [confirmCancelOrder, setConfirmCancelOrder] = useState(false);
  const [error, setError] = useState('');
  const [filterData, setFilterData] = useState<IFilterData>({} as IFilterData);
  const [isSearchResultVisible, setIsSearchResultVisible] = useState(false);
  const [confirmDropData, setConfirmDropData] = useState<IDropData | null>(null);
  const [moveBookingError, setMoveBookingError] = useState<any>(null);
  const [overViewData, setOverViewData] = useState<any>(null);
  const [searchOrderData, setSearchOrderData] = useState('');

  useEffect(() => {
    if (dateRange && golfclub) {
      setError(''); // clear the error

      const { dateFrom, dateTo } = dateRange;
      fetchAccommodationAvailability(dateFrom, dateTo, activeOrder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {}, [activeOrder]);

  const fetchAccommodationAvailability = async (checkInDate = null, checkOutDate = null, activeOrderData = null) => {
    allOverviewData = null;
    setActiveRequest('fetch');
    setOverViewData(null);

    const results = await accommodationBookingService.getAvailableAccommodationsOverview(
      golfclub.id,
      checkInDate,
      checkOutDate,
      false
    );

    /*
    const { error: fetchError, data: availability } = await accommodationBookingService.getAvailableAccommodations(
      golfclub.id,
      checkInDate,
      checkOutDate,
      false
    );
    */

    if ('error' in results) {
      setError(error);
      setActiveRequest(null);
    } else if ('data' in results) {
      allOverviewData = extractOverviewData(
        results.data,
        checkInDate,
        checkOutDate,
        translate,
        golfclub.currency,
        activeOrderData
      );

      monthAvailability = results.data;

      filterAndShowAccommodations(undefined, undefined);

      return results.data;
    }
  };

  /**
   *
   * @param {any} booking booking to update or create
   * @returns
   */
  const updateBooking = async ({
    status,
    booking,
    isOrderConfirmed,
    customer = null,
    keepOrder = false,
    isUpdatingOrder = false,
    bookingChangeInfo = undefined
  }: IUpdateBookingParams) => {
    setActiveRequest(status);
    setError('');

    const bStatus = status === BookingStatus.UPDATED ? BookingStatus.CONFIRMED : status;
    const confirmBooking = {
      ...booking,
      accommodationBookingDiscounts: [],
      status: bStatus,
      source: Source.ADMIN
    };

    // console.log('set booking status to ' + bStatus);

    const { error: bookingError, data: availabilityData }: any = await accommodationBookingService.create(
      confirmBooking
    );

    if (bookingError) {
      if (bookingError.errorCode === 'conflict') {
        setError('Tiden är upptagen av en annan bokning, hämta data på nytt för att se tillkomna bokningar');
      } else if (bookingError.error) {
        setError(bookingError.error);
      } else {
        setError(translate(`booking-status-error.${status?.toLowerCase()}`));
      }
    } else {
      bookingHasChanged = true;

      let confirmedBooking = availabilityData.booking; // availableAccommodation.bookings[0];
      if (confirmedBooking && isOrderConfirmed) {
        confirmedBooking.status = BookingStatus.CONFIRMED;
      }

      if (bStatus === BookingStatus.CANCELLED || bStatus === BookingStatus.PREBOOK_CANCELLED) {
        // console.log(`booking ${booking.id} ${bStatus}`);

        let newActiveOrder = null;

        // remove the booking from activeOrder if it exists
        if (activeOrder && activeOrder.orderItems) {
          const bookingId = Number(booking.id);

          // find the booking order itm
          const itemIndex = activeOrder.orderItems.findIndex(
            (item: any) => item.accommodationBookingId === bookingId || item.rentalUnitBookingId === bookingId
          );

          if (itemIndex !== -1) {
            // create a copy of the order
            newActiveOrder = Object.assign({}, activeOrder);

            // deduct booking amount from the order to display correct totals
            newActiveOrder.orderTotal -= newActiveOrder.orderItems[itemIndex].priceInclVat;

            // remove the booking from the order
            newActiveOrder.orderItems.splice(itemIndex, 1);

            // clear and close the active order if there are no bookings left or cancelling the order
            if ((activeOrder.orderItems.length === 0 || bStatus === BookingStatus.CANCELLED) && !isUpdatingOrder) {
              newActiveOrder = null;

              await closeOpenOrder(golfclub.id);
            }
          } else {
            // it's not in the order, it's the prebooked booking we are removing
            newActiveOrder = activeOrder;
          }
        }

        // close modal
        if (!isUpdatingOrder) {
          setActiveBooking(null);
        }

        // change active order AFTER closing modal
        setActiveOrder(newActiveOrder);

        if ((newActiveOrder === null || bStatus === BookingStatus.CANCELLED) && !isUpdatingOrder) {
          // refresh data when not updating order
          const { dateFrom, dateTo } = dateRange;
          await fetchAccommodationAvailability(dateFrom, dateTo);
        }

        return;
      }

      if (bStatus === BookingStatus.CONFIRMED || keepOrder || isOrderConfirmed) {
        const resultData = await processOrder({
          accommodationBookingId: confirmBooking.id,
          customer,
          golfClubId: golfclub.id,
          order: { ...activeOrder, isOpen: keepOrder },
          isProcessCharge: false,
          isOrderConfirmed: isOrderConfirmed,
          bookingChangeInfo
        });

        if (keepOrder) {
          // we need to close the booking window to be able to create a new booking
          // but we don't update the booking as it has just been updated
          onCloseBookingView(false, resultData);

          if (bStatus === BookingStatus.CONFIRMED) {
            // need to set all active order bookings status to CONFIRMED
            // console.log(resultData);

            resultData.orderItems?.forEach((item: any) => {
              if (item.accommodationBooking) {
                item.accommodationBooking.status = BookingStatus.CONFIRMED;
              } else if (item.rentalUnitBooking) {
                item.rentalUnitBooking.status = BookingStatus.CONFIRMED;
              }
            });

            // console.log(resultData);
          }
        } /* else if (activeOrder) {
          // update booking in active order
          const itemIndex = activeOrder.orderItems?.findIndex((item: any) => item.accommodationBooking.id === confirmBooking.id);
          if (itemIndex !== -1) {
            alert(itemIndex);
            activeOrder.orderItems[itemIndex].accommodationBooking.status = bStatus;
          }
        } */

        // now we have the order data, need to keep track of it
        setActiveOrder(resultData);

        const newActiveBooking = resultData.orderItems.find(
          (item: any) => item.accommodationBookingId === activeBooking.id
        ).accommodationBooking;
        if (newActiveBooking) {
          confirmedBooking = newActiveBooking;
        }
      }

      if ((confirmedBooking || isOrderConfirmed) && !keepOrder) {
        // set the booking as active to keep window open with updated data
        /* console.log('setActiveBooking');
        console.log(normalizeBooking(confirmedBooking));
        console.log(activeOrder);
        */
        normalizeBookingDirect(confirmedBooking);
        setActiveBooking(confirmedBooking);
      }
    }
    setActiveRequest(null);
  };

  const onClickOccupied = async (clickedBooking: any) => {
    setError('');
    setActiveRequest('fetch');

    const { error: fetchError, data: booking }: any = await accommodationBookingService.getById(clickedBooking.id);

    if (fetchError) {
      setError(fetchError.error);
      setActiveRequest(null);
    } else {
      if (
        activeOrder &&
        activeOrder.orderItems &&
        activeOrder.orderItems.length > 0 &&
        !activeOrder.orderItems.find(
          (orderItem: any) =>
            orderItem.accommodationBookingId === clickedBooking.id ||
            orderItem.rentalUnitBookingId === clickedBooking.id
        )
      ) {
        alert('not included in active order');
        setActiveRequest(null);
      } else if (booking.status === BookingStatus.PREBOOKED && Number(booking.source) === Source.WIDGET) {
        setError(translate(ONGOING_WIDGET_BOOKING));
        setActiveRequest(null);
      } else if (booking.status === BookingStatus.PREBOOKED && Number(booking.source) === Source.ADMIN) {
        // Always renew pre booked bookings
        updateBooking({ status: BookingStatus.PREBOOKED, booking, isOrderConfirmed: false, isUpdatingOrder: false });
        setActiveRequest(null);
      } else {
        fetchAccommodationOrder(booking.id).then((result: any) => {
          if ('data' in result && result.data) {
            setActiveOrder(result.data);

            // we have an active order, find the booking in the order
            const orderItem = result.data.orderItems.find((item: any) => item.accommodationBookingId === booking.id);
            if (orderItem) {
              // console.log('setting an active booking from order item');
              normalizeBookingDirect(orderItem.accommodationBooking);
              setActiveBooking(orderItem.accommodationBooking);
            }
          } else {
            // console.log('setting a normalized active booking');
            // no active order, normalize the booking
            normalizeBookingDirect(booking);
            setActiveBooking(booking);
          }
          setActiveRequest(null);
        });
      }
    }
  };

  const onDrop = async (dropData: IDropData) => {
    setMoveBookingError(null);
    setConfirmDropData(dropData);
  };

  /***
   * Handles click in the grid on "free" cells => available for rent.
   * Immediately creates a "prebooked" booking and displays a booking
   * modal window.
   */
  const onClickAvailable = async (accommodationId: number, dayPeriod: any) => {
    const { checkInDate, checkOutDate } = formatDates(selectedYearMonth, dayPeriod);

    if (moment(checkInDate).isBefore(moment(), 'day')) {
      return;
    }

    setActiveRequest(true);
    setError('');
    const { availableAccommodation, pricing } = getAvailableAccommodation({
      accommodationId,
      defaultAvailability: monthAvailability
    });

    if (!pricing.pricing) {
      setError(translate(NO_PRICING));
      setActiveRequest(false);
      return;
    }

    const booking = {
      accommodation: availableAccommodation.accommodation,
      golfClubId: golfclub.id,
      checkInDate,
      checkOutDate,
      priceType: PriceType.SELL,
      productAddons: [] as IBookingProductAddon[],
      isChanged: false,
      closeAllBookings: false,
      checkedIn: false,
      comment: '',
      hasErrors: false,
      packages: [],
      bookingUser: {
        email: '',
        golfId: '',
        name: '',
        plateNumber: '',
        phone: '',
        noGolfId: false
      }
    };

    updateBooking({ status: BookingStatus.PREBOOKED, booking, isOrderConfirmed: false, isUpdatingOrder: false });
  };

  const onCloseBookingView = async (updatePrebookedBooking: boolean = true, activeOrderData: any = null) => {
    setActiveRequest('fetch');

    setActiveBooking(null);
    let isClosingConfirmedOrder = false;

    if (activeBooking?.status === BookingStatus.CONFIRMED) {
      // closing confirmed booking, must be a confirmed order, remove it from being active
      setActiveOrder(null);

      isClosingConfirmedOrder = true;
    }

    setError('');

    if (activeBooking?.status === BookingStatus.PREBOOKED && updatePrebookedBooking) {
      await updateBooking({
        status: BookingStatus.PREBOOK_CANCELLED,
        booking: activeBooking,
        isOrderConfirmed: false,
        isUpdatingOrder: false
      });
    }

    // do not refresh when closing prebookings
    if (bookingHasChanged && activeBooking?.status !== BookingStatus.PREBOOKED) {
      const { dateFrom, dateTo } = dateRange;
      await fetchAccommodationAvailability(
        dateFrom,
        dateTo,
        activeOrderData || (isClosingConfirmedOrder ? null : activeOrder)
      );
      bookingHasChanged = false;
    }

    setActiveRequest(null);
  };

  const filterAndShowAccommodations = (_e: any, data: any) => {
    if (_e === undefined) {
      setOverViewData(filterAccommodations(filterData, allOverviewData));
    } else {
      let newFilterData = {} as IFilterData;
      const { name, value, checked } = data;
      if (name === 'accommodationTypeId') {
        if (value && value !== filterData.accommodationTypeId) {
          (newFilterData as any)[name] = value; // = { [name]: value } as IFilterData;
        }
        setFilterData(newFilterData);
        setOverViewData(filterAccommodations(newFilterData, allOverviewData));
      } else {
        newFilterData = { ...filterData, [name]: checked || value || false };
        setFilterData(newFilterData);
        setOverViewData(filterAccommodations(newFilterData, allOverviewData));
      }
    }
    setActiveRequest(null);
  };

  const handleQueryChange = (e: any) => {
    setSearchOrderData(e.target.value);
  };

  const handleQueryKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      setIsSearchResultVisible(true);
    }
  };

  const onCloseSearchResults = () => {
    setIsSearchResultVisible(false);
  };

  const switchBooking = (booking: IBooking) => {
    setActiveBooking(booking);
  };

  const onConfirmMove = async () => {
    if (confirmDropData) {
      setMoveBookingError(null);
      setActiveRequest('move');

      const confirmMoveData = {
        id: confirmDropData.bookingId,
        accommodationId: confirmDropData.accommodationId,
        checkInDate: confirmDropData.newDate
      };

      const { error: moveError }: any = await accommodationBookingService.moveBooking(confirmMoveData);

      if (moveError) {
        // display the error
        setMoveBookingError(moveError);
        setActiveRequest(null);
      } else {
        setConfirmDropData(null);

        // reload the grid
        if (dateRange && golfclub) {
          const { dateFrom, dateTo } = dateRange;
          fetchAccommodationAvailability(dateFrom, dateTo, activeOrder);
        }
      }
    }
  };

  /*
  const updateOrderBooking = (booking: IBooking) => {
    if (activeOrder && booking) {
      let oldIndex = activeOrder.orderItems.findIndex((item: any) => item.accommodationBookingId === booking.id);

      if (oldIndex !== -1) {
        let newOrderItem = { ...activeOrder.orderItems[oldIndex], accommodationBooking: booking };

        // replace the orderItem in the state with a new value
        activeOrder.orderItems.splice(oldIndex, 1, newOrderItem);

        setActiveOrder({ ...activeOrder });
      }
    }
  };
*/

  return (
    <React.Fragment>
      <div className="overview-schedule">
        <Container>
          <Segment style={{ paddingTop: isStickyHeader ? 64 : '' }}>
            <Dimmer active={!!activeRequest} inverted>
              <Loader />
            </Dimmer>
            <div className="overview">
              <Grid>
                {activeOrder && (
                  <Grid.Column width={15} textAlign="right">
                    <Button
                      positive
                      onClick={() => {
                        setActiveBooking(activeOrder.orderItems[0].accommodationBooking);
                      }}
                    >
                      <Translate
                        id="default.confirm-bookings"
                        data={{
                          itemsCount: activeOrder.orderItems.length,
                          amount: activeOrder.orderTotal,
                          currency: golfclub.currency
                        }}
                      />
                    </Button>
                    <Button
                      onClick={() => setConfirmCancelOrder(true)}
                      negative
                      loading={activeRequest === ActiveRequest.ORDER_CANCEL}
                    >
                      <Translate
                        id="default.cancel-bookings"
                        data={{
                          itemsCount: activeOrder.orderItems.length,
                          amount: activeOrder.orderTotal,
                          currency: golfclub.currency
                        }}
                      />
                    </Button>
                    <SimpleConfirm
                      open={confirmCancelOrder}
                      size="mini"
                      onCancel={() => {
                        setConfirmCancelOrder(false);
                      }}
                      onConfirm={() => {
                        setConfirmCancelOrder(false);
                        onCancelOrderClick();
                      }}
                      header={translate('default.cancel-bookings-header')}
                      content={translate('default.cancel-bookings-content')}
                      cancelButton={translate('default.no')}
                      confirmButton={translate('default.yes')}
                      confirmButtonColor="red"
                    />
                  </Grid.Column>
                )}
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Form>
                      <Form.Group widths="equal">
                        <Form.Field>
                          <h3 className="information" style={{ paddingTop: 5, marginRight: 40 }}>
                            {translate('club-admin.accommodations')}
                          </h3>
                        </Form.Field>
                        <Form.Field>
                          <Form.Select
                            name="accommodationTypeId"
                            placeholder={translate('accommodation.search-type-placeholder')}
                            clearable
                            options={accommodationTypes || []}
                            onChange={filterAndShowAccommodations}
                            style={{ marginRight: '10px' }}
                          />
                        </Form.Field>
                        {(filterData.accommodationTypeId === 1 || filterData.accommodationTypeId === 2) && (
                          <Form.Field>
                            {filterData.accommodationTypeId === 1 && (
                              <>
                                <Checkbox
                                  style={{ paddingTop: 8 }}
                                  name="hasElectricity"
                                  label={translate('accommodation.electricity')}
                                  checked={filterData.hasElectricity}
                                  onChange={filterAndShowAccommodations}
                                />

                                <Checkbox
                                  name="hasDrain"
                                  label={translate('accommodation.drainage')}
                                  checked={filterData.hasDrain}
                                  style={{ marginLeft: '15px', paddingTop: 8 }}
                                  onChange={filterAndShowAccommodations}
                                />
                              </>
                            )}
                            {filterData.accommodationTypeId === 2 && (
                              <Form.Select
                                clearable
                                name="numberOfBeds"
                                placeholder={translate('accommodation.number-of-beds')}
                                options={nrOfBeds}
                                value={filterData.numberOfBeds || null}
                                onChange={filterAndShowAccommodations}
                              />
                            )}
                          </Form.Field>
                        )}
                        <Form.Field>
                          <Form.Input
                            width={16}
                            name="searchOrder"
                            placeholder={translate('accommodation.search-placeholder')}
                            value={searchOrderData}
                            onChange={handleQueryChange}
                            onKeyDown={handleQueryKeyDown}
                          />
                        </Form.Field>
                      </Form.Group>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <div className="information mb-1 d-flex">
                <ErrorMessage error={error} />
              </div>
              <div className={`d-flex days-header ${isStickyHeader ? 'is-sticky' : ''}`}>
                <div className="d-flex accommodationNameCell" style={{ width: '130px', maxWidth: '130px' }}>
                  &nbsp;
                </div>
                {daysInMonth.map((d: any, index: number) => (
                  <div key={d.day}>
                    <CellHeader label={d.day} isWeekend={d.isWeekend} cellWidth={cellWidth} index={index} />
                  </div>
                ))}
              </div>
              <div style={{ maxHeight: hasRentalUnitTypes ? '55vh' : '65vh', overflow: 'auto' }}>
                {overViewData?.map((d: any) => (
                  <Row
                    key={d.id}
                    cellWidth={cellWidth}
                    accommodation={d}
                    daysInMonth={selectedYearMonth.daysInMonth()}
                    onDrop={onDrop}
                    onClickAvailable={onClickAvailable}
                    onClickOccupied={onClickOccupied}
                    overViewDate={selectedYearMonth}
                    translate={translate}
                  />
                ))}
              </div>
            </div>
          </Segment>
        </Container>
      </div>
      {activeBooking && (
        <BookingModalView
          activeBooking={activeBooking}
          activeOrder={activeOrder}
          error={error}
          golfclub={golfclub}
          publicHolidays={publicHolidays}
          isWidgetRequest={false}
          onClose={onCloseBookingView}
          pendingTransaction={undefined}
          setActiveOrder={setActiveOrder}
          setError={setError}
          switchBooking={switchBooking}
          translate={translate}
          updateBooking={updateBooking}
          isSuperAdmin={login.isAuthenticated && login.role === 'Super Admin'}
        />
      )}

      {confirmDropData && (
        <Modal open size="small">
          <Modal.Header>{translate('default.update-booking')}</Modal.Header>
          <Modal.Content>
            <p>
              {translate('default.booking')}: <b>{confirmDropData.bookingId}</b>
            </p>
            <p>
              {translate('default.customer')}: <b>{confirmDropData.user}</b>
            </p>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={8}>{translate('default.from')}</Table.HeaderCell>
                  <Table.HeaderCell>{translate('default.to')}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell style={{ color: '#999999' }}>{confirmDropData.checkinDate}</Table.Cell>
                  <Table.Cell>{confirmDropData.newDate}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell style={{ color: '#999999' }}>{confirmDropData.oldAccommodationName}</Table.Cell>
                  <Table.Cell>{confirmDropData.accommodationName}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>

            {moveBookingError && (
              <div className="information mb-1 d-flex">
                <ErrorMessage error={moveBookingError} />
              </div>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button loading={!!activeRequest} disabled={!!activeRequest} onClick={() => setConfirmDropData(null)}>
              {translate('default.cancel')}
            </Button>
            <Button loading={!!activeRequest} disabled={!!activeRequest} onClick={onConfirmMove} color="green">
              {translate('default.confirm')}
            </Button>
          </Modal.Actions>
        </Modal>
      )}

      {isSearchResultVisible && (
        <OrderSearchResults
          searchQuery={searchOrderData}
          golfClubId={golfclub.id}
          onClose={onCloseSearchResults}
          onClickBooking={onClickOccupied}
        />
      )}
    </React.Fragment>
  );
}

function mapStateToProps(state: any) {
  return {
    golfClubs: getGolfClubs(state),
    publicHolidays: () => getPublicHolidays(state),
    login: state.login
  };
}

export default withLocalize(connect(mapStateToProps, null)(AccommodationOverview));
