import React from 'react';
import { CSVLink } from 'react-csv';
import { Translate } from 'react-localize-redux';
import { Segment, Grid, Table, Icon } from 'semantic-ui-react';
import { getCustomerPeriodPayoutReport, getPayouts } from '../../../core/services';
import { isValidDate, startOfMonth, endOfMonth, todaysDate } from '../../../utils';
import { ReportDateComponent } from '../ReportDateComponent';

interface PayoutReportTabProps {
  active: boolean;
  golfClubs: any[];
  translate: any;
}

interface PayoutItem {
  payoutId: string;
  created: Date;
  arrives: Date;
  amount: string;
  currency: string;
  reportUrl: string;
}

export class PayoutReportTab extends React.Component<PayoutReportTabProps> {
  state = {
    loading: false,
    payouts: [] as PayoutItem[],
    startDate: startOfMonth(todaysDate()),
    startDateValid: true,
    endDate: endOfMonth(todaysDate()),
    endDateValid: true
  };

  payoutHeaders = [
    { label: this.props.translate('club-admin.report-tabs.payout-id'), key: 'payoutId' },
    { label: this.props.translate('club-admin.report-tabs.payout-created'), key: 'created' },
    { label: this.props.translate('club-admin.report-tabs.payout-amount'), key: 'amount' },
    { label: this.props.translate('club-admin.report-tabs.payout-arrives'), key: 'arrives' },
    { label: 'Url', key: 'reportUrl' }
  ];

  getGolfClubId = () => {
    return this.props.golfClubs && this.props.golfClubs.length > 0 ? this.props.golfClubs[0].id : 0;
  };

  validateDatetimeChange = (_e: any, { name, value }: any) => {
    let { startDate, endDate, startDateValid, endDateValid } = this.state;

    if (name === 'startDate') {
      startDate = value;
      startDateValid = isValidDate(startDate);
      if (startDateValid) {
        endDate = endOfMonth(startDate);
      }
    } else {
      endDate = value;
    }
    endDateValid = isValidDate(endDate) && endDate >= startDate;
    this.setState({ startDate, endDate, startDateValid, endDateValid });
  };

  fetchReport = async () => {
    const golfClubId = this.getGolfClubId();
    const { startDate, endDate, startDateValid, endDateValid } = this.state;
    if (startDateValid && endDateValid) {
      this.setState({ loading: true });
      try {
        const report = await getCustomerPeriodPayoutReport(golfClubId, startDate, endDate);

        if ('data' in report) {
          const blob = new Blob([report.data], { type: 'text/csv' });
          const downloadUrl = window.URL.createObjectURL(blob);

          const a = document.createElement('a');
          if (typeof a.download === 'undefined') {
            window.location.href = downloadUrl;
          } else {
            a.href = downloadUrl;
            a.download = 'payouts_summary.csv';
            document.body.appendChild(a);
            a.click();
          }
        }

        this.setState({ loading: false });
      } catch (error) {
        this.setState({ loading: false });
      }
    }
  };

  fetchPayouts = async () => {
    const golfClubId = this.getGolfClubId();

    const { startDate, endDate, startDateValid, endDateValid } = this.state;
    if (startDateValid && endDateValid) {
      this.setState({ loading: true });
      try {
        const payouts = await getPayouts(golfClubId, startDate, endDate);

        if ('data' in payouts) {
          const payoutsData = payouts.data;

          payoutsData.forEach((element: PayoutItem) => {
            // convert to number, calculate amount from stripe amount, then convert back to string and replace dot with comma
            element.amount = (Number(element.amount.replace(',', '.')) / 100).toFixed(2).toString().replace('.', ',');
          });

          this.setState({
            loading: false,
            payouts: payoutsData
          });
        }
      } catch (error) {
        this.setState({ loading: false });
      }
    }
  };

  render() {
    const { startDate, endDate, startDateValid, endDateValid, loading, payouts } = this.state;

    const payoutsCsvReport = {
      data: payouts,
      headers: this.payoutHeaders,
      filename: `${this.props.translate('club-admin.payouts')}.csv`,
      separator: ';'
    };

    if (this.props.active) {
      return (
        <Translate>
          {({ translate }) => (
            <Segment>
              <div className="p-1">
                <ReportDateComponent
                  startDate={startDate}
                  startDateValid={startDateValid}
                  endDate={endDate}
                  endDateValid={endDateValid}
                  loading={loading}
                  fetchBookings={this.fetchPayouts}
                  validateDatetimeChange={this.validateDatetimeChange}
                />
                <Grid>
                  <Grid.Column floated="left" width="5">
                    <Translate id="club-admin.payouts" />
                  </Grid.Column>
                  {payouts?.length > 0 && (
                    <Grid.Column floated="right" width="5" textAlign="right">
                      <CSVLink {...payoutsCsvReport}>{translate('club-admin.export-to-csv')}</CSVLink>
                      <a
                        href="/#"
                        style={{ marginLeft: '20px' }}
                        onClick={(e: any) => {
                          this.fetchReport();
                          e.preventDefault();
                        }}
                      >
                        <Icon name="file alternate" /> {translate('club-admin.report-tabs.payout-summary-download')}
                      </a>
                    </Grid.Column>
                  )}
                </Grid>

                <Segment loading={loading}>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          <Translate id="club-admin.report-tabs.payout-id" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="club-admin.report-tabs.payout-created" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="club-admin.report-tabs.payout-amount" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="club-admin.report-tabs.payout-arrives" />
                        </Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {payouts?.map((payout: PayoutItem, i: number) => {
                        return (
                          <Table.Row key={i}>
                            <Table.Cell>{payout.payoutId}</Table.Cell>
                            <Table.Cell>{payout.created}</Table.Cell>
                            <Table.Cell singleLine textAlign="right">
                              {payout.amount} {payout.currency.toUpperCase()}
                            </Table.Cell>
                            <Table.Cell>{payout.arrives}</Table.Cell>
                            <Table.Cell>
                              {payout.reportUrl && (
                                <a href={`${payout.reportUrl}`}>
                                  <Icon name="file alternate" /> {translate('club-admin.report-tabs.payout-download')}
                                </a>
                              )}
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                </Segment>
              </div>
            </Segment>
          )}
        </Translate>
      );
    } else {
      return <React.Fragment />;
    }
  }
}
