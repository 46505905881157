import React, { Component } from 'react';

import { Container } from 'semantic-ui-react';

import Helmet from '../components/Helmet';
import ReportsForm from '../containers/ReportsForm';

class ReportsPage extends Component {
  render() {
    return (
      <div className="app app-reports">
        <Helmet title="OnTee reports" description="OnTee X!" />
        <Container>
          <ReportsForm />
        </Container>
      </div>
    );
  }
}

export default ReportsPage;
