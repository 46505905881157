import React from 'react';
import { golfCourseEmpty } from '../GolfCourseListComponent';
import { Translate } from 'react-localize-redux';
import { Segment } from 'semantic-ui-react';
import { EditableListComponent } from '../EditableListComponent';
import { EmailSettingsEditItem, EmailSettingsViewItem } from './EmailSettings';

export default class EmailsConfigTab extends React.Component {
  emptyItem = () => {
    return {
      name: '',
      golfCourses: [golfCourseEmpty()],
      admins: [],
      players: []
    };
  };

  render() {
    const { active } = this.props;

    if (active) {
      const emptyItem = this.emptyItem();

      return (
        <Translate>
          {({ translate }) => (
            <Segment loading={this.props.loading}>
              <EditableListComponent
                singleList
                emptyItem={emptyItem}
                data={this.props.golfClubs}
                itemDesc="golfclubs"
                saveItemAction={this.props.saveItemAction}
                login={this.props.login}
                viewComponent={<EmailSettingsViewItem />}
                editComponent={<EmailSettingsEditItem error={this.props.error} />}
              />
            </Segment>
          )}
        </Translate>
      );
    }
    return null;
  }
}
