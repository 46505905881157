import React from 'react';
import { Translate } from 'react-localize-redux';

import { Form } from 'semantic-ui-react';

class GolfCourseSelector extends React.Component {
  state = {
    selectedGolfCourseIndex: 0
  };

  selectGolfCourse = ({ value }) => {
    this.setState({ selectedGolfCourseIndex: value });
  };

  filterData = (data, golfCourse) => {
    return data ? data.filter((d) => d.golfCourse.id === golfCourse.id) : [];
  };

  render() {
    if (this.props.active) {
      const golfCourse = this.props.golfCourses[this.state.selectedGolfCourseIndex];
      const { children } = this.props;
      const childrenWithProps = React.Children.map(children, (child) =>
        React.cloneElement(child, { golfCourse, data: this.filterData(this.props.data, golfCourse) })
      );

      return (
        <Translate>
          {({ translate }) => (
            <div>
              {this.props.golfCourses && this.props.golfCourses.length > 1 && (
                <Form.Select
                  fluid
                  label={translate('default.golfcourse')}
                  onChange={(_e, d) => this.selectGolfCourse(d)}
                  options={this.props.golfCourses.map((x, i) => ({
                    text: x.name + '  (' + x.numberOfHoles + ')',
                    value: i
                  }))}
                  placeholder={translate('default.golfcourse')}
                  value={this.state.selectedGolfCourseIndex}
                />
              )}
              {childrenWithProps}
            </div>
          )}
        </Translate>
      );
    }
    return null;
  }
}

export { GolfCourseSelector };
