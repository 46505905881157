import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Translate, withLocalize } from 'react-localize-redux';

import { Button, Grid, Form, Divider, Popup, Segment } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';

import Teetime from '../../components/Teetime';
import { BookingSidebar } from '../../components/BookingSidebar';
import { EditBooking } from '../../components/EditBooking';
import { ShowBookings } from '../../components/ShowBookings';
import { ErrorMessage } from '../../components/ErrorMessage';
import {
  actions,
  selectedDate,
  selectedTime,
  currentTeetimes,
  currentTeetime,
  hasActiveRequest,
  getGolfClubs
} from './ducks';
import { clubAdminConfigActions } from '../ClubAdminConfigureForm/ducks';
import { GolfCourseSelector } from '../../components/GolfCourseSelector';
import { isTodayOrLater, formatDate, formatTime } from '../../utils';
//import { select } from 'redux-saga/effects';

import moment from 'moment';
//import 'moment/locale/sv';
//moment.locale('sv');
moment.updateLocale('en', {
  week: {
    dow: 1 // Monday is the first day of the week.
  }
});

class GolfCourseTeesheet extends React.Component {
  componentDidMount = () => {
    this.props.fetchGolfClubsAction();
    this.props.fetchHolidaysAction();
  };

  render() {
    const golfCourses =
      this.props.golfClubs && this.props.golfClubs.length > 0 ? this.props.golfClubs[0].golfCourses : [];
    return (
      <GolfCourseSelector active golfCourses={golfCourses}>
        <Teesheet {...this.props} />
      </GolfCourseSelector>
    );
  }
}

class SimpleTimer extends React.Component {
  state = {
    id: null
  };
  componentDidMount = () => {
    const id = setInterval(this.props.callback, this.props.interval);
    this.setState({ id });
  };

  componentWillUnmount = () => {
    if (this.state.id) {
      clearInterval(this.state.id);
    }
  };

  render() {
    return null;
  }
}
class Teesheet extends React.Component {
  state = {
    dataIsValid: false,
    currentTime: new Date().getTime()
  };

  dataIsValid = valid => this.setState({ dataIsValid: valid });

  UNSAFE_componentWillMount = () => {};
  componentDidMount = () => {};
  UNSAFE_componentWillReceiveProps = nextProps => {};
  componentWillUnmount = () => {};

  componentDidUpdate = prevProps => {
    if (this.props.golfCourse !== prevProps.golfCourse) {
      const { selectedDate } = this.props;
      if (selectedDate) {
        // console.log('Golf course changed, fetching new data...');
        this.dateSelected(null, { value: selectedDate });
      }
    }
  };

  closeBookingView = () => {
    const prebooking =
      this.props.teetime &&
      this.props.teetime.bookings &&
      this.props.teetime.bookings.find(x => x.currentUserPrebooking);
    if (prebooking) {
      prebooking.status = 'PrebookCancelled';
    }
    this.props.teetimeDeselectedAction(prebooking);
  };

  dateSelected = (event, { name, value: date }) => this.props.fetchTeeTimesAction(date, this.props.golfCourse.id);

  teetimeClicked = time => {
    const { teetimes } = this.props;
    // console.log(time);
    const tee = teetimes.times.find(t => t.startTime === time);

    const golfCourse = { Id: tee.golfCourseId };
    const booking = {
      golfCourse: golfCourse,
      products: [],
      players: [],
      startDate: tee.date,
      startTime: tee.startTime,
      numberOfPlayers: tee.availableSlots,
      status: 'Prebooked'
    };
    this.props.teetimeSelectedAction(booking, tee);
  };

  isTodayAndTooLate = (currentTime, dateStr, timeStr) => {
    const leeway = 3;
    const now = new Date(currentTime - leeway * 60000);
    const date = formatDate(now);
    const time = formatTime(now);
    const dateTime = date + time;
    const booking = dateStr + timeStr;
    return booking <= dateTime;
  };

  teetimePopupInfo = teetime => {
    const bookings = teetime.bookings.filter(x => x.status === 'Confirmed');
    if (bookings.length === 0) {
      return null;
    }
    const result = bookings.map((x, i) => {
      return (
        <p key={i}>
          Booking {i + 1}:<br />
          {x.players.map((p, j) => this.teetimePlayerInfo(p, j))}
        </p>
      );
    });
    return <div>{result}</div>;
  };

  teetimePlayerInfo = (p, j) => {
    if (!(p.name || p.email)) {
      return null;
    }
    return (
      <span key={j}>
        {p.name} {p.email}
        <br />
      </span>
    );
  };

  render() {
    const { error, teetimeError, selectedDate, selectedTime, teetime, teetimes, golfClubs } = this.props;
    const players =
      golfClubs && golfClubs[0] && golfClubs[0].players && golfClubs[0].players.length > 0 ? golfClubs[0].players : [];
    const editBookingProps = {
      date: selectedDate,
      time: selectedTime,
      teetime: teetime,
      players
    };
    const prebooking = teetime && teetime.bookings && teetime.bookings.find(x => x.currentUserPrebooking);
    const key = 'EditBooking' + selectedTime + this.props.bookingCounter.toString();

    return (
      <Segment style={{ padding: 0 }} loading={this.props.activeRequest} disabled={!this.props.golfCourse}>
        <div className="bg-white p-3">
          <Grid columns="1" stackable>
            <Grid.Column>
              <div className="p-5">
                <h1 style={{ display: 'inline-block' }}>Teesheet</h1>
                <h3 style={{ marginLeft: 20, display: 'inline-block' }}>
                  {this.props.golfCourse && this.props.golfCourse.name}
                </h3>

                <Form>
                  <DateInput
                    name="date"
                    placeholder="Date"
                    dateFormat="YYYY-MM-DD"
                    inline
                    value={selectedDate || ''}
                    iconPosition="left"
                    onChange={this.dateSelected}
                    localization="sv"
                  />
                </Form>

                <div style={{ marginTop: '2rem', marginBottom: 10 }}>
                  {this.props.selectedDate && (
                    <h3>
                      <Translate id="teesheet.date-select" />: {this.props.selectedDate}
                    </h3>
                  )}
                </div>
                <div className="teetimes">
                  <SimpleTimer interval={60000} callback={() => this.setState({ currentTime: new Date().getTime() })} />
                  {teetimes &&
                    teetimes.times &&
                    teetimes.times.map(teetime => {
                      const grayed = this.isTodayAndTooLate(
                        this.state.currentTime,
                        this.props.selectedDate,
                        teetime.startTime
                      );
                      const clickable =
                        !this.isTodayAndTooLate(this.state.currentTime, this.props.selectedDate, teetime.startTime) ||
                        teetime.bookings.filter(x => !x.currentUserPrebooking).length > 0;
                      const onClick = () => this.teetimeClicked(teetime.startTime);
                      return (
                        <Popup
                          basic
                          flowing
                          key={teetime.startTime}
                          style={{
                            opacity: this.teetimePopupInfo(teetime) !== null ? '1' : '0'
                          }}
                          trigger={
                            <div
                              style={grayed ? { backgroundColor: 'rgba(0,0,0,0.1)' } : {}}
                              href={'#'}
                              onClick={clickable ? onClick : null}
                              onKeyPress={() => {}}
                              className={clickable ? 'teetime active' : 'teetime'}
                              role="button"
                              tabIndex={0}
                            >
                              <Teetime
                                key={teetime.startTime}
                                time={teetime.startTime}
                                available={teetime.availableSlots}
                              />
                            </div>
                          }
                        >
                          {this.teetimePopupInfo(teetime)}
                        </Popup>
                      );
                    })}
                  {(!teetimes || !teetimes.times || teetimes.times.length === 0) &&
                    !this.props.activeRequest &&
                    selectedDate &&
                    !error && (
                      <h1>
                        <Translate id="teesheet.teetimes-not-found" data={{ date: selectedDate }} />
                      </h1>
                    )}
                </div>
                {error && <ErrorMessage error={error} />}
                {teetimeError && <ErrorMessage error={teetimeError} />}
              </div>
            </Grid.Column>
          </Grid>
          <BookingSidebar
            visible={this.props.bookingView}
            closeClick={this.closeBookingView}
            teetime={this.props.teetime}
            date={this.props.selectedDate}
          >
            {isTodayOrLater(this.props.selectedDate) &&
              this.props.teetime &&
              !this.isTodayAndTooLate(this.state.currentTime, this.props.selectedDate, this.props.teetime.startTime) &&
              ((editBookingProps.teetime && editBookingProps.teetime.availableSlots > 0) ||
                this.props.createBookingError) && (
                <Segment basic padded={false} loading={this.props.createBookingActive} style={{ padding: 0 }}>
                  <h1>
                    <Translate id="teesheet.booking-create" />
                  </h1>
                  <EditBooking
                    key={key}
                    id={key}
                    editProps={editBookingProps}
                    bookingAction={this.props.createBookingAction}
                    error={this.props.createBookingError}
                    bookingCounter={this.props.bookingCounter}
                    data={prebooking}
                    activeRequest={this.props.createBookingActive}
                    dataIsValid={this.dataIsValid}
                  ></EditBooking>
                  <div style={{ paddingTop: '0.75rem' }}>
                    <Button type="submit" disabled={!this.state.dataIsValid} form={key} color="green">
                      <Translate id="teesheet.booking-create" />
                    </Button>
                  </div>
                </Segment>
              )}
            {!isTodayOrLater(this.props.selectedDate) && (
              <Segment basic padded={false} style={{ padding: 0 }}>
                <h1>
                  <Translate id="teesheet.booking-not-possible" />
                </h1>
              </Segment>
            )}
            {isTodayOrLater(this.props.selectedDate) &&
              this.props.teetime &&
              this.isTodayAndTooLate(this.state.currentTime, this.props.selectedDate, this.props.teetime.startTime) && (
                <Segment basic padded={false} style={{ padding: 0 }}>
                  <h1>
                    <Translate id="teesheet.booking-not-possible-time" />
                  </h1>
                </Segment>
              )}
            {((editBookingProps.teetime && editBookingProps.teetime.availableSlots > 0) ||
              this.props.createBookingError) && <Divider />}
            <ShowBookings
              cancelBookingAction={this.props.cancelBookingAction}
              teetime={this.props.teetime}
              activeRequest={this.props.showBookingsActive}
              editProps={editBookingProps}
              bookingAction={this.props.editBookingAction}
              error={this.props.showBookingsError}
            />
          </BookingSidebar>
        </div>
      </Segment>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedDate: selectedDate(state),
    teetimes: currentTeetimes(state),
    selectedTime: selectedTime(state),
    teetime: currentTeetime(state),
    activeRequest: hasActiveRequest(state),
    error: state.teesheet.error,
    teetimeError: state.teesheet.teetimeError,
    bookingView: state.teesheet.bookingView,
    createBookingError: state.teesheet.createBookingError,
    showBookingsError: state.teesheet.showBookingsError,
    bookingCounter: state.teesheet.bookingCounter,
    //    golfCourse: state.teesheet.golfCourse,
    golfClubs: getGolfClubs(state),
    showBookingsActive: state.teesheet.showBookingsActive,
    createBookingActive: state.teesheet.createBookingActive
  };
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(actions, dispatch),
  ...bindActionCreators(clubAdminConfigActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter(GolfCourseTeesheet)));
