import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Translate, withLocalize } from 'react-localize-redux';
import { Button, Form, Grid, Icon, Input, Message, Segment, Select, Table } from 'semantic-ui-react';
import AccommodationEdit from './AccommodationEdit';
import AccommodationView from './AccommodationView';
import { CaravanIcon } from '../../utils/icons';
import * as api from '../../utils/api';
import { getGolfClubs } from '../../containers/AccommodationSheet/ducks';
import { RentalUnitType } from '../../core/constants';

const lodgeNumberOfBeds = Array.from(Array(12), (_, i) => i + 1).map(i => ({ value: i, text: i }));
const roomsNumberOfBeds = Array.from(Array(12), (_, i) => i + 1).map(i => ({ value: i, text: i }));
let allAccommodations = [];
let categories = {};
let accommodationTypes = [];
let priceCategories = [];
let golfclub = null;

function Accomodations({ golfClubs, translate }) {
  const [accommodations, setAccommodations] = useState(null);
  const [accommodation, setAccommodation] = useState(null);
  const [apiLoading, setApiLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [filterData, setFilterData] = useState({ name: '', accommodationType: '' });

  useEffect(() => {
    document.body.classList.add('config-accommodations');
    window.scrollTo(0, 0);

    [golfclub] = golfClubs;

    initializeData();

    return function cleanup() {
      document.body.classList.remove('config-accommodations');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initializeData = async () => {
    const fetchCategories = async () => {
      const { code, data } = await api.fetchCategories();
      if (code === 200) {
        categories = data;
        accommodationTypes = data.accommodationTypes.map(a => ({
          value: a.id,
          text: translate(`accommodation.${a.name}`),
          icon: a.id === 1 ? <CaravanIcon /> : <Icon name="home" />
        }));
        priceCategories = data.priceCategories.map(p => ({
          value: p.id,
          text: translate(`accommodation.${p.name}`),
          accommodationtypeid: p.accommodationTypeId // lowercase letters otherwise dom throws exception in form select element
        }));
      }
    };

    const fetchAccommodations = async () => {
      const { data } = await api.fetchAccommodations(golfclub.id);
      return data;
    };

    setApiLoading(true);
    Promise.all([fetchCategories(), fetchAccommodations()]).then(response => {
      if (response[1]) {
        [, allAccommodations] = response;
        setAccommodations(response[1]);
      }
      setApiLoading(false);
    });
  };

  const filteredAccommodations = () => {
    let filteredAcc = allAccommodations;

    if (filterData.name) {
      filteredAcc = filteredAcc.filter(a => a.name.includes(filterData.name));
    }

    if (filterData.accommodationType) {
      filteredAcc = filteredAcc.filter(a => a.type.id === filterData.accommodationType);
    }

    return filteredAcc;
  };

  const filterByName = (_e, { name, value }) => {
    setAccommodations(allAccommodations.filter(a => a.name.includes(value)));
    setFilterData({ accommodationType: '', [name]: value });
  };

  const filterByType = (_e, { name, value }) => {
    if (value) {
      setAccommodations(allAccommodations.filter(a => a.type.id === value));
    } else {
      setAccommodations(allAccommodations);
    }
    setFilterData({ name: '', [name]: value });
  };

  const onClickAddAccommodation = () => {
    const acc = { type: {}, priceCategory: {}, info: '', name: '', golfClubId: golfclub.id, new: `R${Math.random()}` };
    setAccommodation(acc);
    setAccommodations([acc, ...accommodations]);
    setErrorMessage(null);
  };

  const onClickEditAccommodation = acc => {
    setErrorMessage(null);
    setAccommodation(acc);
  };

  const onClickDeleteAccommodation = async acc => {
    setApiLoading(true);
    setErrorMessage(null);
    const { code, data, error } = await api.deleteAccommodation(golfclub.id, acc);
    if (code === 200) {
      allAccommodations = data;
      const accs = filteredAccommodations();
      ReactDOM.unstable_batchedUpdates(() => {
        setAccommodation(null);
        setAccommodations(accs);
      });
    } else {
      const message =
        error.errorCode === 'activeBookings'
          ? 'club-admin.delete-error-active-booking'
          : 'default.something-went-wrong';
      setErrorMessage(message);
    }
    setApiLoading(false);
  };

  const onClickCancelEditAccommodation = () => {
    setAccommodations(allAccommodations);
    setAccommodation(null);
    setErrorMessage(null);
  };

  const onClickSaveAccommodation = async acc => {
    setApiLoading(true);
    setErrorMessage(null);
    const nAcc = {
      ...acc,
      priceCategory: categories.priceCategories.find(p => p.id === acc.priceCategoryId),
      type: categories.accommodationTypes.find(a => a.id === acc.accommodationTypeId),
      numberOfBeds:
        acc.accommodationTypeId === RentalUnitType.LODGE.id || acc.accommodationTypeId === RentalUnitType.ROOMS.id
          ? acc.numberOfBeds
          : null,
      hasDrain: acc.accommodationTypeId === RentalUnitType.CAMPING.id ? acc.hasDrain : null,
      hasElectricity: acc.accommodationTypeId === RentalUnitType.CAMPING.id ? acc.hasElectricity : null
    };
    const { code, data } = await api.saveAccommodation(golfclub.id, nAcc);
    if (code === 200) {
      allAccommodations = data;
      const accs = filteredAccommodations();
      ReactDOM.unstable_batchedUpdates(() => {
        setAccommodation(null);
        setAccommodations(accs);
      });
    } else {
      setErrorMessage('Tyvärr kunde enheten inte sparas');
    }
    setApiLoading(false);
  };

  return (
    <Segment loading={apiLoading}>
      <Grid colums={2}>
        <Grid.Row>
          <Grid.Column width={8}>
            <Form>
              <Form.Group inline>
                <Input
                  name="name"
                  icon="search"
                  placeholder={translate('accommodation.search-name-placeholder')}
                  value={filterData.name}
                  onChange={filterByName}
                  style={{ marginRight: '10px' }}
                />
                <Select
                  name="accommodationType"
                  placeholder={translate('accommodation.search-type-placeholder')}
                  options={accommodationTypes}
                  value={filterData.accommodationType}
                  onChange={filterByType}
                  clearable
                />
              </Form.Group>
            </Form>
          </Grid.Column>
          <Grid.Column width={8} textAlign="right">
            <Button color="green" onClick={onClickAddAccommodation}>
              <Icon name="add" />
              {translate('discount.add')}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {errorMessage && (
        <Message negative>
          <Translate id={errorMessage} />
        </Message>
      )}
      {accommodation?.new && (
        <Message positive>
          <Translate id="club-admin.accommodations-add" />
        </Message>
      )}
      <Table striped style={{ maxWidth: '100%' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <Translate id="accommodation.name" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Translate id="accommodation.type" />
            </Table.HeaderCell>
            <Table.HeaderCell>Fac</Table.HeaderCell>
            <Table.HeaderCell>
              <Translate id="accommodation.category" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Translate id="accommodation.beds" />
            </Table.HeaderCell>
            {/* <Table.HeaderCell>
              <Translate id="accommodation.max-guests" />
            </Table.HeaderCell> */}
            <Table.HeaderCell>
              <Translate id="default.info" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              {/* <Translate id="accommodation.secret" /> */}
              Information ({translate('default.email')})
            </Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {accommodations?.map(a =>
            a.id === accommodation?.id ? (
              <AccommodationEdit
                accommodation={accommodation}
                accommodations={accommodations}
                key={a.id || a.new}
                onClickSave={onClickSaveAccommodation}
                onClickCancel={onClickCancelEditAccommodation}
                loading={apiLoading}
                lodgeNumberOfBeds={lodgeNumberOfBeds}
                roomsNumberOfBeds={roomsNumberOfBeds}
                priceCategories={priceCategories}
                accommodationTypes={accommodationTypes}
                errorMessage={errorMessage}
              />
            ) : (
              <AccommodationView
                accommodation={a}
                key={a.id || a.new}
                onClickEdit={onClickEditAccommodation}
                onClickDelete={onClickDeleteAccommodation}
                btnsDisabled={!!accommodation?.id}
                loading={apiLoading}
              />
            )
          )}
        </Table.Body>
      </Table>
      {/* <Loader inline active={apiLoading} /> */}
      {!apiLoading && !accommodations && (
        <Message negative>
          <Message.Header>Data kunde inte hämtas</Message.Header>
          <p>Försök hämta på nytt genom att klicka på knappen</p>
          <Button icon color="red" onClick={initializeData}>
            <Icon name="redo alternate" />
          </Button>
        </Message>
      )}
    </Segment>
  );
}

function mapStateToProps(state) {
  return {
    golfClubs: getGolfClubs(state)
  };
}

export default withLocalize(connect(mapStateToProps, null)(Accomodations));
