import React, { Component } from 'react';

import { Container } from 'semantic-ui-react';

import Helmet from '../components/Helmet';

import ClubAdminRegisterForm from '../containers/ClubAdminRegisterForm';

class ClubAdminRegisterPage extends Component {
  render() {
    return (
      <div className="app app-clubadminregister">
        <Helmet title="Club admin register" description="OnTee on club admin register steroid!" />
        <Container className="mw-900">
          <ClubAdminRegisterForm />
        </Container>
      </div>
    );
  }
}

export default ClubAdminRegisterPage;
