import React, { Component } from 'react';
import { connect } from 'react-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import { withRouter } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { Translate, withLocalize } from 'react-localize-redux';
import qs from 'qs';
import { Container, Dimmer, Loader } from 'semantic-ui-react';

import moment from 'moment';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { SecretRoute } from './components/SecretRoute';

import 'semantic-ui-css/semantic.min.css';
import './App.css';

import Navbar from './containers/Navbar';
import { isClubAdmin, isInHouseAdmin, isSuperAdmin, parseJwt } from './utils';
import HomePage from './pages/Home';
import SignInPage from './pages/Login';
import SignOutPage from './pages/Logout';
import SignUpPage from './pages/SignUp';
// import TermsPage from './pages/Terms';
import ClubAdminConfigPage from './pages/ClubAdminConfig';
import ReportsPage from './pages/Reports';
import ClubAdminRegisterPage from './pages/ClubAdminRegister';
import ResetPasswordPage from './pages/ResetPassword';
import ChangePasswordPage from './pages/ChangePassword';
import InHouseConfigPage from './pages/InHouseConfig';
import AccommodationBooking from './pages/AccommodationBooking';
// import RentalUnitBooking from './pages/RentalUnitBooking';
import svJson from './assets/translations/sv.json';
import enJson from './assets/translations/en.json';
import { widgetLogoutLogin, widgetLogout } from './containers/LoginForm/ducks';
import AccommodationsCalendar from './pages/AccomodationsCalendar';
import RentalUnitsCalendar from './pages/RentalUnitsCalendar';
import { clubAdminConfigActions } from './containers/ClubAdminConfigureForm/ducks';
// TODO: see currently implemented patterns, convention where to put pages/components/containers
import PaymentRedirectPage from './components/Payment/PaymentRedirectPage';
import LanguageSwitcher from './components/LanguageSwitcher';
import BookingsOverview from './pages/BookingsOverview';
import Booking from './pages/Booking';
import SwitchClubPage from './pages/SwitchClub';
import Survey from './pages/Survey';

// if (process.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, {
//     trackAllPureComponents: true
//   });
// }

const MyRouter = ({ isAuthenticated, isClubAdmin, isInHouseAdmin, isSuperAdmin, isWidgetRequest }) => (
  <Switch>
    {isWidgetRequest ? (
      <SecretRoute
        exact
        path="/"
        isAuthenticated={isAuthenticated}
        isWidgetRequest={isWidgetRequest}
        component={AccommodationBooking}
      />
    ) : (
      <SecretRoute exact path="/" isAuthenticated={isAuthenticated} component={BookingsOverview} />
    )}
    <SecretRoute
      exact
      path="/accommodations"
      isAuthenticated={isAuthenticated}
      isWidgetRequest={isWidgetRequest}
      component={AccommodationsCalendar}
    />
    <SecretRoute
      path="/booking"
      isAuthenticated={isAuthenticated}
      isWidgetRequest={isWidgetRequest}
      component={Booking}
    />
    {/* <SecretRoute
      exact
      path="/accommodations-new"
      isAuthenticated={isAuthenticated}
      isWidgetRequest={isWidgetRequest}
      component={AccomodationsCalendar}
    /> */}
    <Route exact path="/gantt" component={BookingsOverview} />
    <SecretRoute exact path="/teesheet" isAuthenticated={isAuthenticated} component={HomePage} />
    <SecretRoute
      exact
      path="/rentalUnits"
      isAuthenticated={isAuthenticated}
      isWidgetRequest={isWidgetRequest}
      component={RentalUnitsCalendar}
    />
    {/* <SecretRoute
      exact
      path="/rentalUnits-new"
      isAuthenticated={isAuthenticated}
      isWidgetRequest={isWidgetRequest}
      component={RentalUnitsCalendar}
    /> */}
    <SecretRoute exact path="/reports" isAuthenticated={isAuthenticated} component={ReportsPage} />
    <SecretRoute exact path="/switch" isAuthenticated={isSuperAdmin} component={SwitchClubPage} />
    <Route exact path="/sign-in" component={SignInPage} />
    <Route exact path="/sign-out" component={SignOutPage} />
    <Route exact path="/sign-up" component={SignUpPage} />
    {/*   <Route exact path='/about' component={AboutPage}/> */}
    {/* <Route exact path="/terms" component={TermsPage} /> */}
    <Route exact path="/reset-password" component={ResetPasswordPage} />
    <Route exact path="/change-password" component={ChangePasswordPage} />
    <Route exact path="/survey" component={Survey} />
    <SecretRoute exact path="/config" isAuthenticated={isClubAdmin} component={ClubAdminConfigPage} />
    <SecretRoute exact path="/inhouse-config" isAuthenticated={isInHouseAdmin} component={InHouseConfigPage} />
    <Route exact path="/clubadmin-register" component={ClubAdminRegisterPage} />
    <Route exact path="/payment-redirect" component={PaymentRedirectPage} />
    <Route
      render={() => (
        <div style={{ paddingTop: '10%' }}>
          {' '}
          <Translate id="default.404" />
        </div>
      )}
    />
  </Switch>
);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-console
    console.log('REACT_APP_ENV', process.env.REACT_APP_ENV);
    // eslint-disable-next-line no-console
    console.log('REACT_APP_TEESHEET_BACKEND_URL', process.env.REACT_APP_TEESHEET_BACKEND_URL);

    // get browser language
    const browserLanguage = (navigator.language || navigator.userLanguage).split(/-|_/)[0];

    let defaultLanguageSet = 'sv';

    if (browserLanguage !== 'sv') {
      defaultLanguageSet = 'en';
    }

    moment.locale(defaultLanguageSet);

    this.props.initialize({
      languages: [
        { name: 'Svenska', code: 'sv' },
        { name: 'English', code: 'en' }
      ],
      options: { renderToStaticMarkup, defaultLanguage: defaultLanguageSet }
    });
    this.props.addTranslationForLanguage(enJson, 'en');
    this.props.addTranslationForLanguage(svJson, 'sv');

    this.validateIfWidgetRequest();
    this.setState({ loaded: true });
  }

  validateIfWidgetRequest = () => {
    const params = this.extractUrlParams();
    const isWidget = !!params.widget;

    // if (
    //   isWidget &&
    //   (params.hash !== this.props.login.token || params['rental-unit-type'] !== this.props.login.rentalUnitType)
    // ) {
    if (
      isWidget &&
      (!sessionStorage.authToken ||
        sessionStorage.authToken === 'undefined' ||
        params.hash !== this.props.login.token ||
        params['rental-unit-type'] !== this.props.login.rentalUnitType)
    ) {
      sessionStorage.authToken = `Bearer ${params.hash}`;

      this.props.widgetLogoutLogin(params.hash, params['rental-unit-type']);
      if (!params.del) {
        this.props.fetchGolfClubsAction();
        this.props.fetchHolidaysAction();
      }
    } else if (!isWidget) {
      delete sessionStorage.authToken;
      if (this.props.login.type === 'widget') {
        this.props.widgetLogout();
      }
    }
  };

  extractUrlParams = () => qs.parse(window.location.search, { ignoreQueryPrefix: true });

  isWidgetRequest = () => {
    // console.log('isWidgetRequest', window.location.search.includes('widget'), this.props.login.type);
    const params = this.extractUrlParams();
    const { hash } = params;
    let hashData;
    if (hash) {
      hashData = parseJwt(hash);
    }
    return (
      window.location.search.includes('widget') ||
      // eslint-disable-next-line camelcase
      hashData?.client_id === 'widgetClient' ||
      this.props.login.type === 'widget'
    );
  };

  isSurvey = () => {
    return window.location.href.includes('survey');
  };

  render() {
    const isWidgetRequest = this.isWidgetRequest();
    const isSurvey = this.isSurvey();

    return (
      <>
        {this.state.loaded && (
          <>
            <DndProvider backend={HTML5Backend}>
              {!isWidgetRequest && !isSurvey && <Navbar />}
              <Container className={isWidgetRequest ? 'widget' : ''}>
                <Dimmer active={isWidgetRequest && !this.props.login.isAuthenticated}>
                  <Loader />
                </Dimmer>
                <MyRouter
                  isAuthenticated={this.props.login.isAuthenticated}
                  isClubAdmin={isClubAdmin(this.props)}
                  isInHouseAdmin={isInHouseAdmin(this.props)}
                  isSuperAdmin={isSuperAdmin(this.props)}
                  isWidgetRequest={isWidgetRequest}
                />
              </Container>
              {false && <LanguageSwitcher />}
            </DndProvider>
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login
  };
}

function mapDispatchToProps(dispatch) {
  return {
    widgetLogoutLogin: (token, rentalUnitType) => dispatch(widgetLogoutLogin(token, rentalUnitType)),
    widgetLogout: () => dispatch(widgetLogout()),
    fetchGolfClubsAction: () => dispatch(clubAdminConfigActions.fetchGolfClubsAction()),
    fetchHolidaysAction: () => dispatch(clubAdminConfigActions.fetchHolidaysAction())
  };
}

export default withLocalize(withRouter(connect(mapStateToProps, mapDispatchToProps)(App)));
