export const TransactionType = {
  StripeCheckout: 'StripeCheckout',
  StripeEmailPayment: 'StripeEmailPayment',
  ManualPayment: 'ManualPayment',
  StripeRefund: 'StripeRefund',
  ManualRefund: 'ManualRefund',
  SwishPayment: 'SwishPayment',
  SwishRefund: 'SwishRefund'
};
Object.freeze(TransactionType);
