import React from 'react';
import { Translate } from 'react-localize-redux';
import { Grid } from 'semantic-ui-react';
import { IBookingProductAddon } from '../../core/services/product.service';

export interface ProductAddonModalProps {
  productAddons: IBookingProductAddon[];
  golfClubCurrency: string;
}

function ProductAddonsList(props: ProductAddonModalProps) {
  return (
    <Translate>
      {({ translate }) =>
        props.productAddons &&
        props.productAddons.length > 0 && (
          <>
            <h3>
              <Translate id="product-addons.addons" />
            </h3>

            <Grid style={{ marginBottom: 5 }}>
              <Grid.Row className="pb-0">
                <Grid.Column computer={8} mobile={8}>
                  <div className="field">
                    <label>
                      <Translate id="product-addons.headers.name" />
                    </label>
                  </div>
                </Grid.Column>
                <Grid.Column computer={7} mobile={7}>
                  <div className="field" style={{ textAlign: 'center' }}>
                    <label>
                      <Translate id="product-addons.headers.price" />
                    </label>
                  </div>
                </Grid.Column>
              </Grid.Row>
              {props.productAddons?.map(addon => (
                <Grid.Row className="pt-0 pb-0" key={Math.random().toString()}>
                  <Grid.Column computer={7} mobile={8}>
                    {translate(`product-addons.type-names.${addon.product?.name}`)}
                    {addon.itemsCount > 0 && <span style={{ paddingLeft: '5px' }}>({addon.itemsCount}x )</span>}
                  </Grid.Column>
                  <Grid.Column computer={7} mobile={8} style={{ whiteSpace: 'nowrap' }} textAlign="right">
                    {addon.chargeAmount.toFixed(2).toString().replace('.', ',')} {props.golfClubCurrency}
                  </Grid.Column>
                </Grid.Row>
              ))}
            </Grid>
          </>
        )
      }
    </Translate>
  );
}

export default ProductAddonsList;
