export const headerColors = [
  'rgba(207, 230, 207, 0.5)',
  'rgba(207, 207, 230, 0.5)',
  'rgba(230, 230, 207, 0.5)',
  'rgba(230, 207, 230, 0.5)',
  'rgba(207, 230, 230, 0.5)',
  'rgba(230, 207, 207, 0.5)',
  'rgba(205, 205, 205, 0.5)'
];
export const colors = [
  'rgba(230, 255, 230, 0.5)',
  'rgba(230, 230, 255, 0.5)',
  'rgba(255, 255, 230, 0.5)',
  'rgba(255, 230, 255, 0.5)',
  'rgba(230, 255, 255, 0.5)',
  'rgba(255, 230, 230, 0.5)',
  'rgba(230, 230, 230, 0.5)'
];
