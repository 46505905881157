import React from 'react';

import { Translate } from 'react-localize-redux';
import { Button, Segment, Table, Popup, Grid } from 'semantic-ui-react';
import { CSVLink } from 'react-csv';
import { isValidDate, startOfMonth, endOfMonth, todaysDate } from '../../../utils';
import { rentalUnitBookingService } from '../../../core/services';
import { ReportDateComponent } from '../ReportDateComponent';

export class RentalUnitTransactionsPeriodReportTab extends React.Component {
  state = {
    startDate: startOfMonth(todaysDate()),
    startDateValid: true,
    endDate: endOfMonth(todaysDate()),
    endDateValid: true,
    loading: false,
    rentalUnitBookingsPeriod: []
  };

  rentalUnitBookingSummaryHeaders = [
    { label: this.props.translate('club-admin.checkin-date'), key: 'checkInDate' },
    { label: this.props.translate('club-admin.category'), key: 'priceCategoryName' },
    { label: this.props.translate('club-admin.customer-name'), key: 'customerName' },
    { label: this.props.translate('club-admin.total-income'), key: 'totalIncome' },
    { label: this.props.translate('club-admin.total-commission'), key: 'totalCommission' }
  ];

  validateDatetimeChange = (_e, { name, value }) => {
    let { startDate, endDate, startDateValid, endDateValid } = this.state;

    if (name === 'startDate') {
      startDate = value;
      startDateValid = isValidDate(startDate);
      if (startDateValid) {
        endDate = endOfMonth(startDate);
      }
    } else {
      endDate = value;
    }
    endDateValid = isValidDate(endDate) && endDate >= startDate;
    this.setState({ startDate, endDate, startDateValid, endDateValid });
  };

  getGolfClubId = () => {
    return this.props.golfClubs && this.props.golfClubs.length > 0 ? this.props.golfClubs[0].id : 0;
  };

  getGolfClubCurrency = () => {
    return this.props.golfClubs && this.props.golfClubs.length > 0 ? this.props.golfClubs[0].currency : 'N/A';
  };

  groupBy = (list, keyGetter) => {
    const map = new Map();
    list.forEach(item => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  };

  groupBookings = bookings => {
    const map = this.groupBy(
      bookings,
      b => `${b.nominalStart}-${b.bookingUser.email}-${b.rentalUnit.priceCategory.name}`
    );
    return Array.from(map, ([key, value]) => value);
  };

  fetchBookings = async () => {
    const { startDate, endDate, startDateValid, endDateValid } = this.state;
    if (startDateValid && endDateValid) {
      const golfClubId = this.getGolfClubId();

      this.setState({ loading: true });
      try {
        const book = await rentalUnitBookingService.getAll(golfClubId, startDate, endDate);
        const bookings = this.groupBookings(book.data);

        const rentalUnitBookingsPeriod = this.getRentalUnitBookingsPeriod(bookings);
        this.setState({ loading: false, rentalUnitBookingsPeriod });
      } catch (error) {
        this.setState({ loading: false });
      }
    }
  };

  getRentalUnitBookingsPeriod = rentalUnitBookings => {
    const rentalUnitBookingsPeriod = [];

    for (const rentalUnitBooking of rentalUnitBookings) {
      const { totalIncome, totalCommission } = rentalUnitBooking.reduce(
        (acc, item) => {
          acc.totalIncome += item.priceInclVat;
          acc.totalCommission += item.commission;
          return acc;
        },
        { totalIncome: 0, totalCommission: 0 }
      );

      const [booking] = rentalUnitBooking;

      rentalUnitBookingsPeriod.push({
        checkInDate: booking.nominalStart,
        priceCategoryName: this.props.translate(`accommodation.${booking.rentalUnit.priceCategory.name}`),
        customerName: booking.bookingUser.name,
        comment: booking.comment,
        totalIncome,
        totalCommission
      });
    }

    // sort by checkin date desc
    rentalUnitBookingsPeriod.sort(function (a, b) {
      return new Date(b.checkInDate).getTime() - new Date(a.checkInDate).getTime();
    });

    return rentalUnitBookingsPeriod;
  };

  render() {
    const { startDate, endDate, startDateValid, endDateValid, loading, rentalUnitBookingsPeriod } = this.state;
    const rentalUnitBookingSummaryCsvReport = {
      data: rentalUnitBookingsPeriod,
      headers: this.rentalUnitBookingSummaryHeaders,
      filename: `${startDate} to ${endDate} ${this.props.translate('club-admin.rental-unit-booking-period')}.csv`,
      separator: ';'
    };

    const golfClubCurrency = this.getGolfClubCurrency();

    if (this.props.active) {
      return (
        <Translate>
          {({ translate }) => (
            <Segment>
              <div className="p-1">
                <ReportDateComponent
                  startDate={startDate}
                  startDateValid={startDateValid}
                  endDate={endDate}
                  endDateValid={endDateValid}
                  loading={loading}
                  fetchBookings={this.fetchBookings}
                  validateDatetimeChange={this.validateDatetimeChange}
                />
                <Grid>
                  <Grid.Column floated="left" width="5">
                    <Translate id="club-admin.rental-units" />
                  </Grid.Column>
                  {rentalUnitBookingsPeriod?.length > 0 && (
                    <Grid.Column floated="right" width="5" textAlign="right">
                      <CSVLink {...rentalUnitBookingSummaryCsvReport}>{translate('club-admin.export-to-csv')}</CSVLink>
                    </Grid.Column>
                  )}
                </Grid>
                <Segment loading={loading}>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          <Translate id="club-admin.checkin-date" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="club-admin.category" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="club-admin.customer-name" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="club-admin.total-income" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="club-admin.total-commission" />
                        </Table.HeaderCell>
                        {/* <Table.HeaderCell>
                            <Translate id="club-admin.of-which-vat" />
                          </Table.HeaderCell> */}
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {rentalUnitBookingsPeriod?.map((booking, i) => {
                        return (
                          <Table.Row key={i}>
                            <Table.Cell>{booking.checkInDate}</Table.Cell>
                            <Table.Cell>{booking.priceCategoryName}</Table.Cell>
                            <Table.Cell>
                              {booking.customerName}{' '}
                              {booking.comment && (
                                <Popup content={booking.comment} trigger={<Button size="mini" icon="comment" />} />
                              )}
                            </Table.Cell>
                            <Table.Cell>{`${booking.totalIncome} ${golfClubCurrency}`}</Table.Cell>
                            <Table.Cell>{`${booking.totalCommission} ${golfClubCurrency}`}</Table.Cell>
                            {/* <Table.Cell>{Math.round(amount * (b.priceInclVat - b.priceExclVat))} {golfClubCurrency}</Table.Cell> */}
                          </Table.Row>
                        );
                      })}
                      {rentalUnitBookingsPeriod?.length > 0 && (
                        <Table.Row style={{ fontWeight: 700 }}>
                          <Table.Cell>{translate('club-admin.sum')}</Table.Cell>
                          <Table.Cell />
                          <Table.Cell />
                          <Table.Cell />
                          <Table.Cell>
                            {rentalUnitBookingsPeriod.reduce((acc, curr) => acc + curr.totalIncome, 0)}{' '}
                            {golfClubCurrency}
                          </Table.Cell>
                          {/* <Table.Cell>
                              {this.state.bookings
                                .flat()
                                .reduce((acc, curr) => acc + Math.round(curr.priceInclVat - curr.priceExclVat), 0)}{' '}
                              {golfClubCurrency}
                            </Table.Cell> */}
                        </Table.Row>
                      )}
                    </Table.Body>
                  </Table>
                </Segment>
              </div>
            </Segment>
          )}
        </Translate>
      );
    }
    return null;
  }
}
