import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const SecretRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      rest.isAuthenticated === true ? (
        <Component {...props} isWidgetRequest={rest.isWidgetRequest} />
      ) : (
        <Redirect
          to={{
            pathname: '/sign-in',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);
