import React from 'react';
import { Translate } from 'react-localize-redux';
import { Dropdown, Form, Input, List, Table } from 'semantic-ui-react';
import { pick } from 'lodash-es';
import { ErrorMessage } from '../ErrorMessage';
import { isSuperAdmin } from '../../utils';

const CompanyViewItem = ({ data }) => {
  const { companies } = data;

  if (companies === null || companies.length === 0) {
    return (
      <h3>
        <Translate id="golfclub.company-no-data" />
      </h3>
    );
  }

  return (
    <>
      <Table striped style={{ maxWidth: '100%' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <Translate id="golfclub.company-name" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Translate id="default.address" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Translate id="golfclub.company-regnr" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Translate id="golfclub.company-bankaccount" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{companies[0].companyName}</Table.Cell>
            <Table.Cell>{`${companies[0].street}, ${companies[0].city}, ${companies[0].postalCode}`}</Table.Cell>
            <Table.Cell>{companies[0].registrationNumber}</Table.Cell>
            <Table.Cell>{companies[0].bankAccount}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
};

class CompanyEditItem extends React.Component {
  state = {
    name: '',
    isValidName: true,
    isValidConnectedAccount: true,
    accommodationInfo: '',
    admins: [],
    companies: [],
    golfCourses: [],
    golfCoursesValid: false,
    login: {},
    showUpload: false
  };

  componentDidMount = () => {
    if (!this.props.data) {
      return;
    }
    const { data, login } = this.props;

    this.setState({ ...data, login });

    if (data.companies === null || data.companies.length === 0) {
      this.setState({ companies: [{}] });
    }

    this.props.dataIsValid(!!data.name);
  };

  onCompanyChange = (_e, { name, value }) => {
    const company = { ...this.state.companies[0], [name]: value };

    this.setState({ companies: [company] });
  };

  saveItem = () => {
    const data = pick(this.state, [
      'name',
      'accommodationInfo',
      'golfCourses',
      'admins',
      'companies',
      'golfCartWeekdayHours',
      'golfCartWeekendHours',
      'logoUrl',
      'imageUrl',
      'email',
      'webSite',
      'useOnlinePayment',
      'connectedAccountId',
      'campingCommission',
      'lodgeCommission',
      'golfCartCommission',
      'golfBikeCommission',
      'campingVat',
      'lodgeVat',
      'golfCartVat',
      'golfBikeVat',
      'transactionFee',
      'currency',
      'widgetDisabled',
      'receiveBookingsReport',
      'receiveConfirmationEmails',
      'sendCheckinReminderEmail',
      'checkinReminderEmailDays',
      'checkinReminderEmailText'
    ]);

    const hasOwnProperty = Object.prototype.hasOwnProperty.call(this.props.data, 'id');

    if (hasOwnProperty) {
      data.id = this.props.data.id;
    }
    this.props.saveAction(data, this.props.onSubmit);
  };

  getOptions = translate => {
    const invoicePeriodOptions = [
      { key: 'week', text: translate('golfclub.company-invoice-freq-type-week'), value: 'Weeks' },
      { key: 'days', text: translate('golfclub.company-invoice-freq-type-day'), value: 'Days' }
    ];

    return invoicePeriodOptions;
  };

  getCompanyOptions = translate => {
    return [
      { key: 'company', text: translate('golfclub.company-type-company'), value: 'Company' },
      { key: 'association', text: translate('golfclub.company-type-association'), value: 'Association' }
    ];
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <>
            <Form id={this.props.id} onSubmit={this.saveItem}>
              <List>
                <List.Item>
                  <h3>{translate('golfclub.company-header')}</h3>

                  <Form.Group>
                    <Form.Field width={8}>
                      <label>{translate('golfclub.company-type')}</label>
                      <Dropdown
                        defaultValue="company"
                        options={this.getCompanyOptions(translate)}
                        value={this.state.companies[0]?.golfClubCompanyType || 'company'}
                        name="golfClubCompanyType"
                        onChange={this.onCompanyChange}
                        selection
                      />
                    </Form.Field>
                  </Form.Group>

                  <Form.Group widths="equal">
                    <Form.Field>
                      <Form.Input
                        label={`${translate('golfclub.company-name')}`}
                        placeholder={`${translate('golfclub.company-name')}`}
                        name="companyName"
                        onChange={this.onCompanyChange}
                        value={this.state.companies[0]?.companyName || ''}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Form.Input
                        label={`${translate('golfclub.company-regnr')}`}
                        placeholder={`${translate('golfclub.company-regnr')}`}
                        name="registrationNumber"
                        onChange={this.onCompanyChange}
                        value={this.state.companies[0]?.registrationNumber || ''}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Form.Input
                        label={`${translate('golfclub.company-vatnr')}`}
                        placeholder={`${translate('golfclub.company-vatnr')}`}
                        name="vatNumber"
                        onChange={this.onCompanyChange}
                        value={this.state.companies[0]?.vatNumber || ''}
                      />
                    </Form.Field>
                  </Form.Group>

                  <Form.Group widths="equal">
                    <Form.Field>
                      <Form.Input
                        label={`${translate('golfclub.company-street')}`}
                        placeholder={`${translate('golfclub.company-street')}`}
                        name="street"
                        onChange={this.onCompanyChange}
                        value={this.state.companies[0]?.street || ''}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Form.Input
                        label={`${translate('golfclub.company-city')}`}
                        placeholder={`${translate('golfclub.company-city')}`}
                        name="city"
                        onChange={this.onCompanyChange}
                        value={this.state.companies[0]?.city || ''}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Form.Input
                        label={`${translate('golfclub.company-zip')}`}
                        placeholder={`${translate('golfclub.company-zip')}`}
                        name="postalCode"
                        onChange={this.onCompanyChange}
                        value={this.state.companies[0]?.postalCode || ''}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Form.Input
                        label={`${translate('golfclub.company-country')}`}
                        placeholder={`${translate('golfclub.company-country')}`}
                        name="country"
                        onChange={this.onCompanyChange}
                        value={this.state.companies[0]?.country || ''}
                      />
                    </Form.Field>
                  </Form.Group>

                  <Form.Group widths="equal">
                    <Form.Field>
                      <Form.Input
                        label={`${translate('golfclub.company-iban')}`}
                        placeholder={`${translate('golfclub.company-iban')}`}
                        name="iBAN"
                        onChange={this.onCompanyChange}
                        value={this.state.companies[0]?.iBAN || ''}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Form.Input
                        label={`${translate('golfclub.company-bankaccount')}`}
                        placeholder={`${translate('golfclub.company-bankaccount')}`}
                        name="bankAccount"
                        onChange={this.onCompanyChange}
                        value={this.state.companies[0]?.bankAccount || ''}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Form.Input
                        label={`${translate('golfclub.company-reference')}`}
                        placeholder={`${translate('golfclub.company-reference')}`}
                        name="reference"
                        onChange={this.onCompanyChange}
                        value={this.state.companies[0]?.reference || ''}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Form.Input
                        label={`${translate('golfclub.company-email')}`}
                        placeholder={`${translate('golfclub.company-email')}`}
                        name="emailAddress"
                        onChange={this.onCompanyChange}
                        value={this.state.companies[0]?.emailAddress || ''}
                      />
                    </Form.Field>
                  </Form.Group>

                  {isSuperAdmin(this.props) && (
                    <>
                      <h3>{translate('golfclub.company-invoice-header')}</h3>
                      <Form.Group widths="equal">
                        <Form.Field>
                          <label>{translate('golfclub.company-invoice-title')}</label>
                          <Input
                            label={
                              <Dropdown
                                defaultValue="weeks"
                                options={this.getOptions(translate)}
                                value={this.state.companies[0]?.invoiceIssueFrequencyType || 'weeks'}
                                name="invoiceIssueFrequencyType"
                                onChange={this.onCompanyChange}
                              />
                            }
                            labelPosition="right"
                            placeholder={translate('golfclub.company-invoice-freq')}
                            name="invoiceIssueFrequency"
                            onChange={this.onCompanyChange}
                            type="number"
                            value={this.state.companies[0]?.invoiceIssueFrequency || '2'}
                          />
                        </Form.Field>
                        <Form.Field>
                          <Form.Input
                            label={`${translate('golfclub.company-invoice-due-days')}`}
                            placeholder={`${translate('golfclub.company-invoice-due-days')}`}
                            name="invoiceDueDays"
                            type="number"
                            onChange={this.onCompanyChange}
                            value={this.state.companies[0]?.invoiceDueDays || '14'}
                          />
                        </Form.Field>
                      </Form.Group>
                    </>
                  )}
                </List.Item>
              </List>
              <ErrorMessage error={this.props.error} />
            </Form>
          </>
        )}
      </Translate>
    );
  }
}

export { CompanyViewItem, CompanyEditItem };
