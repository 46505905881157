import React from 'react';
import { Translate } from 'react-localize-redux';
import { Accordion, Icon, Button, Message, Segment } from 'semantic-ui-react';
import ViewEditDeleteComponent from './ViewEditDeleteComponent';
import { todaysDate } from '../utils';

/**
 * props emptyItem, itemDesc, viewComponent, editComponent, data (list), saveItemAction, deleteItemAction
 */
class EditableListComponent extends React.Component {
  state = {
    createNewClicked: false
  };

  cancelCallback = () => {
    this.setState({ createNewClicked: false });
  };

  render() {
    return (
      <>
        {this.props.emptyDescription && <Message color="green" content={this.props.emptyDescription} />}

        {!this.state.createNewClicked &&
          !this.props.additionalComponentActive &&
          !(this.props.singleList && this.props.data && this.props.data.length > 0) && (
            <Button color="teal" size="tiny" onClick={() => this.setState({ createNewClicked: true })}>
              <Translate id="default.create-new" />
            </Button>
          )}
        {this.state.createNewClicked && !this.props.additionalComponentActive && (
          <ViewEditDeleteComponent
            deleteButtonText
            // buttonsLocation={this.props.buttonsLocation}
            id={this.props.itemDesc}
            data={this.props.emptyItem}
            deleteParams={this.props.deleteParams}
            isEditing
            saveAction={this.props.saveItemAction}
            cancelCallback={this.cancelCallback}
            viewComponent={this.props.viewComponent}
            editComponent={this.props.editComponent}
            buttonsLocation="bottom"
            login={this.props.login}
          />
        )}
        {/* {!this.state.createNewClicked && !this.props.additionalComponentActive && this.props.additionalComponent}
        {this.props.additionalComponentActive} */}
        {!this.state.createNewClicked &&
          !this.props.additionalComponentActive &&
          (!this.props.data || this.props.data.length === 0) &&
          !this.props.emptyDescription && <p>{`No ${this.props.itemDesc} found`}</p>}
        {!this.state.createNewClicked &&
          !this.props.additionalComponentActive &&
          this.props.data &&
          this.props.data.length > 0 && (
            <Segment.Group>
              {this.props.data.map((x, i) => (
                <ViewEditDeleteComponent
                  deleteButtonText
                  buttonsLocation={this.props.buttonsLocation || 'bottom'}
                  key={x.id}
                  id={x.id}
                  data={x}
                  deleteParams={this.props.deleteParams}
                  hideDeleteWhenNotEditing={this.props.hideDeleteWhenNotEditing}
                  deleteAction={this.props.deleteItemAction}
                  saveAction={this.props.saveItemAction}
                  viewComponent={this.props.viewComponent}
                  editComponent={this.props.editComponent}
                  login={this.props.login}
                />
              ))}
            </Segment.Group>
          )}
      </>
    );
  }
}

class EditableAccordionListComponent extends React.Component {
  state = {
    createNewClicked: false,
    activeIndex: 0
  };

  handleTitleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  cancelCallback = () => {
    this.setState({ createNewClicked: false });
  };

  getCurrentPeriod = data => {
    const today = todaysDate();

    return data.findIndex(x => {
      const { teeSlotPeriods: periods } = x;
      const start = periods ? periods.map(x => x.startDate).sort()[0] : '';
      const end = periods ? periods.map(x => x.endDate).sort()[periods.length - 1] : '';
      return today >= start && today <= end;
    });
  };

  render() {
    const panels = this.props.data.map((x, i) => {
      const viewTitleComponent = React.cloneElement(this.props.viewTitleComponent, { data: x });
      const viewEditDeleteComponent = (
        <ViewEditDeleteComponent
          deleteButtonText
          buttonsLocation={this.props.buttonsLocation}
          key={x.id}
          id={x.id}
          data={x}
          deleteParams={this.props.deleteParams}
          hideDeleteWhenNotEditing={this.props.hideDeleteWhenNotEditing}
          deleteAction={this.props.deleteItemAction}
          saveAction={this.props.saveItemAction}
          viewComponent={this.props.viewContentComponent}
          editComponent={this.props.editComponent}
          login={this.props.login}
        />
      );

      const title = (
        <Accordion.Title>
          <Icon name="dropdown" />
          {viewTitleComponent}
        </Accordion.Title>
      );
      const content = <Accordion.Content>{viewEditDeleteComponent}</Accordion.Content>;

      return {
        key: `panel-${i}`,
        title: title,
        content: content
      };
    });

    return (
      <>
        {this.props.emptyDescription && <Message color="green" content={this.props.emptyDescription} />}

        {!this.state.createNewClicked && !(this.props.singleList && this.props.data && this.props.data.length > 0) && (
          <Button onClick={() => this.setState({ createNewClicked: true })}>
            <Translate id="default.create-new" />
          </Button>
        )}
        {this.state.createNewClicked && (
          <ViewEditDeleteComponent
            deleteButtonText
            buttonsLocation={this.props.buttonsLocation}
            id={this.props.itemDesc}
            data={this.props.emptyItem}
            deleteParams={this.props.deleteParams}
            isEditing={true}
            saveAction={this.props.saveItemAction}
            cancelCallback={this.cancelCallback}
            viewComponent={this.props.viewComponent}
            editComponent={this.props.editComponent}
            login={this.props.login}
          />
        )}
        {!this.state.createNewClicked &&
          (!this.props.data || this.props.data.length === 0) &&
          !this.props.emptyDescription && <p>{`No ${this.props.itemDesc} found`}</p>}
        {!this.state.createNewClicked && this.props.data && this.props.data.length > 0 && (
          <Accordion
            defaultActiveIndex={this.getCurrentPeriod(this.props.data)}
            style={{ marginTop: 15 }}
            styled
            panels={panels}
            exclusive={true}
            fluid
          />
        )}
      </>
    );
  }
}

export { EditableListComponent, EditableAccordionListComponent };
