import React, { useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { Button, Icon, Popup, Table } from 'semantic-ui-react';
import { CaravanIcon } from '../../utils/icons';

const AccommodationView = ({ accommodation, onClickEdit, onClickDelete, btnsDisabled, loading }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    onClickDelete(accommodation);
    setIsOpen(false);
  };

  return (
    <Table.Row>
      <Table.Cell>
        {accommodation.adminBookingOnly ? (
          <em style={{ color: '#FF3333' }}>{accommodation.name}</em>
        ) : (
          accommodation.name
        )}
      </Table.Cell>
      <Table.Cell>
        {accommodation.type.id === 1 && <CaravanIcon />}
        {accommodation.type.id === 2 && <Icon name="home" />}
        <Translate id={`accommodation.${accommodation.type.name}`} />
      </Table.Cell>
      <Table.Cell>
        {accommodation.hasElectricity && <Icon name="plug" style={{ margin: 0 }} />}
        {accommodation.hasDrain && <Icon name="tint" />}
      </Table.Cell>
      <Table.Cell>
        <Translate id={`accommodation.${accommodation.priceCategory.name}`} />
      </Table.Cell>
      <Table.Cell>{accommodation.numberOfBeds}</Table.Cell>
      {/* <Table.Cell>{accommodation.maxGuests}</Table.Cell> */}
      <Table.Cell>
        {accommodation.info && (
          <Popup trigger={<p>{accommodation.info.substring(0, 10)}...</p>}>{accommodation.info}</Popup>
        )}
      </Table.Cell>
      <Table.Cell>
        {accommodation.secret && (
          <Popup trigger={<p>{accommodation.secret.substring(0, 10)}...</p>}>{accommodation.secret}</Popup>
        )}
      </Table.Cell>
      <Table.Cell textAlign="right">
        <Button color="green" size="mini" icon onClick={() => onClickEdit(accommodation)} disabled={btnsDisabled}>
          <Icon name="edit outline" />
        </Button>
        <Popup
          trigger={
            <Button color="red" size="mini" icon disabled={btnsDisabled}>
              <Icon name="trash alternate" />
            </Button>
          }
          content={<Button color="red" content="Bekräfta borttagning?" onClick={handleClick} loading={loading} />}
          on="click"
          position="top right"
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default withLocalize(AccommodationView);
