export const SIGN_IN_REQUEST = 'sign-in/SIGN_IN_REQUEST';
export const SIGN_IN_REQUEST_SUCCESS = 'sign-in/SIGN_IN_REQUEST_SUCCESS';
export const SIGN_IN_REQUEST_FAILED = 'sign-in/SIGN_IN_REQUEST_FAILED';
export const WIDGET_SIGN_OUT_IN_REQUEST = 'sign-in/WIDGET_SIGN_OUT_IN_REQUEST';

export const SIGN_OUT_REQUEST = 'sign-out/SIGN_OUT_REQUEST';
export const UNAUTHORIZED = 'sign-in/UNAUTHORIZED';

export const requestLogin = user => ({ type: SIGN_IN_REQUEST, user });
export const widgetLogoutLogin = (token, rentalUnitType) => ({
  type: WIDGET_SIGN_OUT_IN_REQUEST,
  token,
  rentalUnitType
});
export const widgetLogout = () => ({ type: SIGN_OUT_REQUEST });

const initialState = {
  user: {},
  activeRequest: false,
  token: null,
  isAuthenticated: false,
  role: null,
  type: null,
  error: null,
  rentalUnitType: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UNAUTHORIZED:
      return {
        ...state,
        user: {},
        activeRequest: false,
        isAuthenticated: false,
        token: null,
        error: null
      };
    case SIGN_IN_REQUEST:
      return {
        ...state,
        user: action.user,
        activeRequest: true,
        isAuthenticated: false,
        token: null,
        error: null
      };
    case SIGN_IN_REQUEST_SUCCESS:
      return {
        ...state,
        activeRequest: false,
        isAuthenticated: true,
        role: action.role,
        token: action.token,
        user: {
          ...state.user,
          password: ''
        }
      };
    case SIGN_IN_REQUEST_FAILED:
      return {
        ...state,
        activeRequest: false,
        error: action.error
      };
    case WIDGET_SIGN_OUT_IN_REQUEST:
      return {
        ...initialState,
        isAuthenticated: true,
        type: 'widget',
        token: action.token,
        rentalUnitType: action.rentalUnitType
      };
    case SIGN_OUT_REQUEST:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
