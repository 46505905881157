import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Translate, withLocalize } from 'react-localize-redux';

import { Button, Divider, Grid, Form, Popup, Message, Segment } from 'semantic-ui-react';
import { DatesRangeInput } from 'semantic-ui-calendar-react';

import { fetchCategories } from '../../utils/api';
import { ErrorMessage } from '../../components/ErrorMessage';
import {
  actions,
  selectedDateFrom,
  selectedDateTo,
  hasActiveRequest,
  getGolfClubs,
  getAccommodationSheet
} from './ducks';
import { clubAdminConfigActions } from '../ClubAdminConfigureForm/ducks';
import { AccommodationBookingView } from '../../components/Accommodation';
import { AccommodationBookingSidebar } from '../../components/BookingSidebar';
import { EditAccommodationBooking } from '../../components/EditBooking';
import { ShowAccommodationBookings } from '../../components/ShowBookings';
import { PriceType } from '../../core/enums';
import { isTodayOrLater, calculatePrice } from '../../utils';

import moment from 'moment';
import 'moment/locale/sv';
//moment.locale('sv');
moment.updateLocale('en', {
  week: {
    dow: 1 // Monday is the first day of the week.
  }
});

class AccommodationSheet extends React.Component {
  state = {
    dataIsValid: false,
    isWidgetRequest: this.props.isWidgetRequest,
    priceWithDiscount: null,
    bookingRentalUnitTypes: [],
    priceInclVat: 0
  };

  // used to filter accommodation that can be booked based on rental unit type ids in widget URL
  bookingRentalUnitTypeIds = null;

  dataIsValid = valid => this.setState({ dataIsValid: valid });

  componentDidMount = async () => {
    this.props.fetchGolfClubsAction();
    this.props.fetchHolidaysAction();
    this.bookingRentalUnitTypeIds =
      this.props.isWidgetRequest && this.props.bookingRentalUnitTypeIds
        ? this.props.bookingRentalUnitTypeIds.split(',').map(x => +x)
        : null;

    const result = await fetchCategories();
    if (result.data) {
      const { accommodationTypes } = result.data;
      let bookingRentalUnitTypes = this.bookingRentalUnitTypeIds
        ? accommodationTypes.filter(x => this.bookingRentalUnitTypeIds.includes(x.id))
        : accommodationTypes;
      bookingRentalUnitTypes = bookingRentalUnitTypes
        .map(x => this.props.translate(`accommodation.${x.name}`))
        .join('/');

      this.setState({ bookingRentalUnitTypes });
    }
    this.setAccommodationPrice(this.props.selectedAcc.accommodation);
  };

  componentDidUpdate = prevProps => {
    if (this.props.golfCourse !== prevProps.golfCourse) {
      const { selectedDate } = this.props;
      if (selectedDate) {
        this.dateSelected(null, { value: selectedDate });
      }
    }
  };

  closeBookingView = acc => {
    const prebooking = acc.bookings.find(x => x.currentUserPrebooking);
    if (prebooking) {
      prebooking.status = 'PrebookCancelled';
    }
    const golfClubId = this.getGolfClubId();
    this.props.accommodationDeselectedAction(golfClubId, prebooking);
  };

  dateSelected = (event, data) => {
    const dates = data.value.split(' - ');
    if (dates.length === 1) {
      this.props.selectedDateAction('', '');
    } else {
      const dateFrom = dates[0];
      const dateTo = dates[1];
      this.props.selectedDateAction(dateFrom, dateTo);
      if (dateFrom !== '' && dateTo !== '') {
        const golfClubId = this.getGolfClubId();
        this.props.fetchAccommodationAvailabilityAction(golfClubId, dateFrom, dateTo, this.bookingRentalUnitTypeIds);
      }
    }
  };

  accommodationPopupInfo = acc => {
    return (
      <div>
        {acc.accommodation.name}
        <br />
        {acc.bookings &&
          acc.bookings.length > 0 &&
          acc.bookings.map((x, i) => {
            return (
              <div key={i}>
                Bokning {i + 1}: <br />
                {x.checkInDate} - {x.checkOutDate} {x.bookingUser.name}
              </div>
            );
          })}
      </div>
    );
  };

  accommodationClicked = acc => {
    const golfClubId = this.getGolfClubId();
    let booking;
    if (acc.available) {
      booking = {
        golfClubId: this.getGolfClubId(),
        accommodation: acc.accommodation,
        status: 'Prebooked',
        bookingUser: { name: '', email: '' },
        additionalGuests: [],
        checkInDate: this.props.selectedDateFrom,
        checkOutDate: this.props.selectedDateTo,
        priceType: PriceType.SELL
      };
    }
    this.props.accommodationSelectedAction(golfClubId, booking, acc);
    window.scrollTo(0, 0);
    this.setPriceWithDiscount(null);
    this.setAccommodationPrice(acc);
  };

  setAccommodationPrice = selectedAccommodation => {
    const {
      selectedDateFrom: _selectedDateFrom,
      selectedDateTo: _selectedDateTo,
      accommodationsAvailabilities
    } = this.props;
    const priceInclVat = calculatePrice(
      selectedAccommodation.accommodation,
      _selectedDateFrom,
      _selectedDateTo,
      accommodationsAvailabilities.pricings
    );
    this.setState({ priceInclVat });
  };

  getGolfClubId = () => {
    return this.props.golfClubs && this.props.golfClubs.length > 0 ? this.props.golfClubs[0].id : 0;
  };

  getGolfClubCurrency = () => {
    return this.props.golfClubs && this.props.golfClubs.length > 0 ? this.props.golfClubs[0].currency : 'N/A';
  };

  getGolfClubName = () => {
    return this.props.golfClubs && this.props.golfClubs.length > 0 ? this.props.golfClubs[0].name : '';
  };

  setPriceWithDiscount = value => {
    this.setState({ priceWithDiscount: value });
  };

  setPriceInclVat = priceInclVat => {
    this.setState({ priceInclVat });
  };

  render() {
    const {
      fetchAvailabilityError,
      accommodationError,
      selectedDate,
      selectedDateFrom,
      selectedDateTo,
      accommodationsAvailabilities,
      translate
    } = this.props;
    const { selectedAcc, golfClubs } = this.props;
    const players =
      golfClubs && golfClubs[0] && golfClubs[0].players && golfClubs[0].players.length > 0 ? golfClubs[0].players : [];
    const editBookingProps = {
      date: selectedDate,
      checkinDate: selectedDateFrom,
      checkoutDate: selectedDateTo,
      accommodation: selectedAcc ? selectedAcc.accommodation : null,
      acc: selectedAcc,
      players,
      accommodations: accommodationsAvailabilities.accommodations,
      pricings: accommodationsAvailabilities.pricings
    };
    const golfClubId = this.getGolfClubId();
    const golfClubName = this.getGolfClubName();
    const golfClubCurrency = this.getGolfClubCurrency();
    // eslint-disable-next-line no-constant-condition
    const key = `EditAccommodationBooking${selectedDate}${selectedAcc}` ? selectedAcc.accommodation.name : 'unknown';
    const prebooking = selectedAcc && selectedAcc.bookings && selectedAcc.bookings.find(x => x.currentUserPrebooking);

    return (
      <Segment style={{ padding: 0 }} loading={this.props.activeRequest}>
        <div className={`bg-white ${this.state.isWidgetRequest ? 'p-1 p-sm-3' : 'p-3'}`}>
          <Grid columns="1" stackable>
            <Grid.Column>
              <div className="p-5">
                <Message color="green">
                  <Translate id="accommodation-sheet.see-availability" />
                </Message>

                <h1 style={{ display: 'inline-block' }}>
                  <Translate id="accommodation-sheet.booking" /> {this.state.bookingRentalUnitTypes} - {golfClubName}
                </h1>
                {!this.state.isWidgetRequest && (
                  <div>
                    <Translate id="accommodation-sheet.booking-description" />
                  </div>
                )}
                <br />

                <Form>
                  <DatesRangeInput
                    name="date"
                    placeholder={translate('default.date')}
                    dateFormat="YYYY-MM-DD"
                    inline
                    value={selectedDate || ''}
                    iconPosition="left"
                    onChange={this.dateSelected}
                    localization="sv"
                    minDate={this.state.isWidgetRequest ? moment() : null}
                  />
                </Form>

                <div style={{ marginTop: '2rem', marginBottom: 10 }} />

                <div className="accommodationItems" style={{ overflowY: 'scroll' }}>
                  {accommodationsAvailabilities?.accommodations?.map(acc => {
                    // only checks if url contains rental-unit-type id filters, bookingRentalUnitTypeIds is truthy
                    if (
                      this.bookingRentalUnitTypeIds &&
                      !this.bookingRentalUnitTypeIds.includes(acc.accommodation.type.id)
                    ) {
                      return null;
                    }

                    const onClick = () =>
                      acc.available || !this.state.isWidgetRequest ? this.accommodationClicked(acc) : null;
                    return (
                      <Popup
                        basic
                        flowing
                        key={acc.accommodation.id}
                        style={{
                          opacity: this.accommodationPopupInfo(acc) !== null ? '1' : '0'
                        }}
                        trigger={
                          <div
                            className={'active accommodationItem'}
                            href={'#'}
                            onClick={onClick}
                            onKeyPress={() => {}}
                            role="button"
                            tabIndex={0}
                          >
                            <AccommodationBookingView
                              data={acc}
                              pricing={editBookingProps.pricings}
                              golfClubCurrency={golfClubCurrency}
                            />
                          </div>
                        }
                      >
                        {!this.state.isWidgetRequest && this.accommodationPopupInfo(acc)}
                      </Popup>
                    );
                  })}
                  {(!accommodationsAvailabilities ||
                    !accommodationsAvailabilities.accommodations ||
                    accommodationsAvailabilities.accommodations.length === 0) &&
                    !this.props.activeRequest &&
                    selectedDateFrom &&
                    selectedDateTo &&
                    !fetchAvailabilityError && (
                      <h1>
                        <Translate id="accommodation-sheet.no-availability" data={{ date: selectedDate }} />
                      </h1>
                    )}
                </div>

                {fetchAvailabilityError && <ErrorMessage error={fetchAvailabilityError} />}
                {accommodationError && <ErrorMessage error={accommodationError} />}
              </div>
            </Grid.Column>
          </Grid>
          <AccommodationBookingSidebar
            visible={this.props.bookingView}
            closeClick={this.closeBookingView}
            editProps={editBookingProps}
            priceInclVat={this.state.priceInclVat}
            priceWithDiscount={this.state.priceWithDiscount}
            golfClubCurrency={golfClubCurrency}
          >
            {isTodayOrLater(this.props.selectedDateFrom) &&
              ((editBookingProps.accommodation && editBookingProps.acc.available) || this.props.createBookingError) && (
                <Segment
                  basic
                  padded={false}
                  loading={this.props.createBookingActive || this.props.accommodationLoading}
                  style={{ padding: 0 }}
                >
                  <h1>
                    <Translate id="accommodation-sheet.create" />
                  </h1>
                  <EditAccommodationBooking
                    key={key}
                    id={key}
                    golfClubId={golfClubId}
                    editProps={editBookingProps}
                    priceInclVat={this.state.priceInclVat}
                    setPriceInclVat={this.setPriceInclVat}
                    bookingAction={this.props.createBookingAction}
                    error={this.props.createBookingError}
                    bookingCounter={this.props.bookingCounter}
                    data={prebooking}
                    activeRequest={this.props.createBookingActive || this.props.accommodationLoading}
                    dataIsValid={this.dataIsValid}
                    accommodationLoading={this.props.accommodationLoading}
                    isWidgetRequest={this.props.isWidgetRequest}
                    setPriceWithDiscount={this.setPriceWithDiscount}
                  />
                  <div style={{ paddingTop: '1.75rem', display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                    <Button type="submit" disabled={!this.state.dataIsValid} form={key} color="green">
                      <Translate id="accommodation-sheet.create" />
                    </Button>
                  </div>
                </Segment>
              )}
            {!isTodayOrLater(this.props.selectedDateFrom) && (
              <Segment basic padded={false} style={{ padding: 0 }}>
                <h1>
                  <Translate id="accommodation-sheet.availability-changed" />
                </h1>
              </Segment>
            )}
            {((editBookingProps.selectedAcc && editBookingProps.selectedAcc.available) ||
              this.props.showBookingsError) && <Divider />}
            <ShowAccommodationBookings
              golfClubId={golfClubId}
              golfClubCurrency={golfClubCurrency}
              cancelBookingAction={this.props.cancelBookingAction}
              accommodation={this.props.selectedAcc}
              activeRequest={this.props.showBookingsActive}
              editProps={editBookingProps}
              bookingAction={this.props.editBookingAction}
              error={this.props.showBookingsError}
              setPriceWithDiscount={this.setPriceWithDiscount}
            />
          </AccommodationBookingSidebar>
        </div>
      </Segment>
    );
  }
}

function createSelectedDate(dateFrom, dateTo) {
  if (dateFrom === '' && dateTo === '') {
    return '';
  } else {
    return `${dateFrom} - ${dateTo}`;
  }
}

function mapStateToProps(state) {
  return {
    selectedDate: createSelectedDate(selectedDateFrom(state), selectedDateTo(state)),
    selectedDateFrom: selectedDateFrom(state),
    selectedDateTo: selectedDateTo(state),
    selectedAcc: getAccommodationSheet(state).selectedAcc,
    activeRequest: hasActiveRequest(state),
    accommodationsAvailabilities: getAccommodationSheet(state).accommodationsAvailabilities,
    fetchAvailabilityError: getAccommodationSheet(state).fetchAvailabilityError,
    accommodationError: getAccommodationSheet(state).accommodationError,
    bookingView: getAccommodationSheet(state).bookingView,
    createBookingError: getAccommodationSheet(state).createBookingError,
    showBookingsError: getAccommodationSheet(state).showBookingsError,
    bookingCounter: getAccommodationSheet(state).bookingCounter,
    golfClubs: getGolfClubs(state),
    showBookingsActive: getAccommodationSheet(state).showBookingsActive,
    createBookingActive: getAccommodationSheet(state).createBookingActive,
    accommodationLoading: getAccommodationSheet(state).accommodationLoading,
    bookingRentalUnitTypeIds: state.login.rentalUnitType
  };
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(actions, dispatch),
  ...bindActionCreators(clubAdminConfigActions, dispatch)
});

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(withRouter(AccommodationSheet)));
