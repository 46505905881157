import * as Api from '../../utils/api';

export const types = {
  TEETIME_SELECTED: 'teesheet/TEETIME_SELECTED',
  TEETIME_SELECTED_SUCCESS: 'teesheet/TEETIME_SELECTED_SUCCESS',
  TEETIME_SELECTED_FAILED: 'teesheet/TEETIME_SELECTED_FAILED',
  TEETIME_DESELECTED: 'teesheet/TEETIME_DESELECTED',
  TEETIME_DESELECTED_SUCCESS: 'teesheet/TEETIME_DESELECTED_SUCCESS',
  TEETIME_DESELECTED_FAILED: 'teesheet/TEETIME_DESELECTED_FAILED',
  FETCH_TEETIMES: 'teesheet/FETCH_TEETIMES',
  FETCH_TEETIMES_SUCCESS: 'teesheet/FETCH_TEETIMES_SUCCESS',
  FETCH_TEETIMES_FAILED: 'teesheet/FETCH_TEETIMES_FAILED',
  CREATE_BOOKING: 'teesheet/CREATE_BOOKING',
  CREATE_BOOKING_SUCCESS: 'teesheet/CREATE_BOOKING_SUCCESS',
  CREATE_BOOKING_FAILED: 'teesheet/CREATE_BOOKING_FAILED',
  CANCEL_BOOKING: 'teesheet/CANCEL_BOOKING',
  CANCEL_BOOKING_SUCCESS: 'teesheet/CANCEL_BOOKING_SUCCESS',
  CANCEL_BOOKING_FAILED: 'teesheet/CANCEL_BOOKING_FAILED',
  EDIT_BOOKING: 'teesheet/EDIT_BOOKING',
  EDIT_BOOKING_SUCCESS: 'teesheet/EDIT_BOOKING_SUCCESS',
  EDIT_BOOKING_FAILED: 'teesheet/EDIT_BOOKING_FAILED'
};

export const actions = {
  teetimeSelectedAction: (booking, teetime) => ({ type: types.TEETIME_SELECTED, booking: [booking], teetime }),
  teetimeDeselectedAction: booking => ({ type: types.TEETIME_DESELECTED, booking: [booking] }),
  fetchTeeTimesAction: (selectedDate, golfCourseId) => ({
    type: types.FETCH_TEETIMES,
    data: [selectedDate, golfCourseId],
    backendFunction: Api.fetchTeeTimes,
    callSuccessType: types.FETCH_TEETIMES_SUCCESS,
    callFailedType: types.FETCH_TEETIMES_FAILED
  }),
  createBookingAction: (booking, onSubmit) => ({
    type: types.CREATE_BOOKING,
    booking: [booking],
    onSubmit,
    backendFunction: Api.createBooking,
    callSuccessType: types.CREATE_BOOKING_SUCCESS,
    callFailedType: types.CREATE_BOOKING_FAILED
  }),
  cancelBookingAction: (booking, onSubmit) => ({ type: types.CANCEL_BOOKING, booking: [booking], onSubmit }),
  editBookingAction: (booking, onSubmit) => ({ type: types.EDIT_BOOKING, booking: [booking], onSubmit })
};

const initialState = {
  golfCourse: { name: '', golfClub: { name: '' } },
  selectedDate: '',
  selectedTime: '',
  teetimes: {},
  teetime: null,
  activeRequest: null,
  createBookingActive: null,
  showBookingsActive: null,
  bookingCounter: 0,
  error: null,
  createBookingError: null,
  showBookingsError: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_TEETIMES:
      return {
        ...state,
        selectedDate: action.data[0],
        activeRequest: true,
        error: null
      };
    case types.FETCH_TEETIMES_SUCCESS: {
      const newTeeTimes = { ...state.teetimes };
      newTeeTimes[action.data.date] = { timestamp: Date.now(), times: action.data.teetimes };
      const golfCourse = action.data.golfCourse;
      return {
        ...state,
        golfCourse: golfCourse,
        teetimes: newTeeTimes,
        selectedTime: null,
        teetime: null,
        activeRequest: false,
        error: null
      };
    }
    case types.FETCH_TEETIMES_FAILED:
      return {
        ...state,
        activeRequest: false,
        error: action.error
      };
    case types.CREATE_BOOKING: {
      return {
        ...state,
        createBookingActive: true
      };
    }
    case types.CREATE_BOOKING_SUCCESS: {
      const newTeetime = action.teetime;
      const index = state.teetimes[newTeetime.date].times.findIndex(t => t.startTime === newTeetime.startTime);
      const newTeeTimes = { ...state.teetimes };
      newTeeTimes[newTeetime.date].times[index] = newTeetime;
      return {
        ...state,
        teetimes: newTeeTimes,
        teetime: newTeetime,
        createBookingActive: false,
        bookingCounter: state.bookingCounter + 1,
        createBookingError: null
      };
    }
    case types.CREATE_BOOKING_FAILED: {
      let newTeetime = state.teetime;
      const newTeeTimes = { ...state.teetimes };
      if (action.teetime) {
        newTeetime = action.teetime;
        const index = state.teetimes[newTeetime.date].times.findIndex(t => t.startTime === newTeetime.startTime);
        newTeeTimes[newTeetime.date].times[index] = newTeetime;
      }
      return {
        ...state,
        teetimes: newTeeTimes,
        teetime: newTeetime,
        createBookingActive: false,
        createBookingError: action.error
      };
    }
    case types.EDIT_BOOKING:
    case types.CANCEL_BOOKING: {
      return {
        ...state,
        showBookingsActive: true
      };
    }
    case types.EDIT_BOOKING_SUCCESS:
    case types.CANCEL_BOOKING_SUCCESS: {
      const newTeetime = action.teetime;
      const index = state.teetimes[newTeetime.date].times.findIndex(t => t.startTime === newTeetime.startTime);
      const newTeeTimes = { ...state.teetimes };
      newTeeTimes[newTeetime.date].times[index] = newTeetime;
      return {
        ...state,
        teetimes: newTeeTimes,
        teetime: newTeetime,
        showBookingsActive: false,
        bookingCounter: state.bookingCounter + 1,
        showBookingsError: null
      };
    }
    case types.EDIT_BOOKING_FAILED:
    case types.CANCEL_BOOKING_FAILED: {
      let newTeetime = state.teetime;
      const newTeeTimes = { ...state.teetimes };
      if (action.teetime) {
        newTeetime = action.teetime;
        const index = state.teetimes[newTeetime.date].times.findIndex(t => t.startTime === newTeetime.startTime);
        newTeeTimes[newTeetime.date].times[index] = newTeetime;
      }
      return {
        ...state,
        teetimes: newTeeTimes,
        teetime: newTeetime,
        showBookingsActive: false,
        showBookingsError: action.error
      };
    }
    case types.TEETIME_SELECTED:
      return {
        ...state,
        teetime: action.teetime,
        selectedTime: action.teetime.startTime,
        bookingView: true,
        teetimeError: null
      };
    case types.TEETIME_SELECTED_SUCCESS: {
      const newTeetime = action.teetime;
      const selectedTime = newTeetime.startTime;
      const index = state.teetimes[newTeetime.date].times.findIndex(t => t.startTime === newTeetime.startTime);
      const newTeeTimes = { ...state.teetimes };
      newTeeTimes[newTeetime.date].times[index] = newTeetime;
      return {
        ...state,
        teetimes: newTeeTimes,
        teetime: newTeetime,
        selectedTime: selectedTime,
        teetimeError: null
      };
    }
    case types.TEETIME_SELECTED_FAILED: {
      let newTeetime = state.teetime;
      const newTeeTimes = { ...state.teetimes };
      if (action.teetime) {
        newTeetime = action.teetime;
        const index = state.teetimes[newTeetime.date].times.findIndex(t => t.startTime === newTeetime.startTime);
        newTeeTimes[newTeetime.date].times[index] = newTeetime;
      }
      return {
        ...state,
        teetimes: newTeeTimes,
        teetime: newTeetime,
        teetimeError: action.error
      };
    }
    case types.TEETIME_DESELECTED:
      return {
        ...state,
        showBookingsError: null,
        teetimeError: null,
        createBookingError: null,
        bookingView: false
      };
    // Don't do anything about these for now, nothing visible to the user anyway
    case types.TEETIME_DESELECTED_SUCCESS:
    case types.TEETIME_DESELECTED_FAILED:
      return state;

    default:
      return state;
  }
};

export default reducer;

// selectors
const getTeesheet = state => state.teesheet;
const getTeetimes = state => getTeesheet(state).teetimes;

export const selectedDate = state => getTeesheet(state).selectedDate;
export const selectedTime = state => getTeesheet(state).selectedTime;
export const currentTeetimes = state => getTeetimes(state)[selectedDate(state)];
export const currentTeetime = state => getTeesheet(state).teetime;
export const hasActiveRequest = state => getTeesheet(state).activeRequest;
export const getGolfClubs = state => state.clubAdminConfig.golfClubs;
