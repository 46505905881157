import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

export function SimpleConfirm({
  header,
  content,
  open,
  size,
  onCancel,
  onConfirm,
  cancelButton,
  confirmButton,
  confirmButtonColor
}: {
  header: any;
  content: string;
  open: any;
  size: any;
  onCancel: any;
  onConfirm: any;
  cancelButton: any;
  confirmButton: any;
  confirmButtonColor: any;
}) {
  return (
    <Modal open={open} size={size}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel}>{cancelButton}</Button>
        <Button onClick={onConfirm} color={confirmButtonColor || 'red'}>
          {confirmButton}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
