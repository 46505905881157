import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Translate, withLocalize } from 'react-localize-redux';

import { Link } from 'react-router-dom';

import { Button, Message, Grid, Form, Popup } from 'semantic-ui-react';

import queryString from 'query-string';
import { ErrorMessage } from '../../components/ErrorMessage';
import { actions } from './ducks';

class ClubAdminRegisterForm extends React.Component {
  constructor() {
    super();

    this.state = {
      user: {
        firstname: '',
        lastname: '',
        password: '',
        confirmPassword: '',
        phoneNumber: '',
        golfid: '',
        handicap: '',
        email: '',
        code: ''
      },
      valid: {
        all: true,
        firstname: true,
        lastname: true,
        password: true,
        confirmPassword: true,
        phoneNumber: true,
        golfid: true,
        handicap: true
      }
    };
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    const { user } = this.state;
    user.email = query.email;
    user.code = query.code;
    this.setState({ user });
  }

  handleSubmit = event => {
    if (this.state.valid.all) {
      event.preventDefault();
      this.props.clubAdminRegistration(this.state.user);
      this.setState({ validationError: false });
    } else {
      this.setState({ validationError: true });
    }
  };

  validateField = (e, validator) => {
    const val = e.target.value;
    const field = e.target.name;
    const { user, valid } = this.state;

    user[field] = val;
    valid[field] = validator(val, e);
    valid.all =
      valid.firstname === true &&
      valid.lastname === true &&
      valid.password === true &&
      valid.confirmPassword === true &&
      valid.golfid === true &&
      valid.phoneNumber === true &&
      valid.handicap === true;

    this.setState({
      user,
      valid
    });
    // console.log(user);
    // console.log(valid);
  };

  validateName = e => this.validateField(e, val => val.length > 0);
  validateHandicap = e =>
    this.validateField(e, val => val === '' || (!isNaN(parseFloat(val)) && isFinite(val) && val < 100));
  validatePhonenumber = e => this.validateField(e, val => val === '' || /^\+?\d+-?\d+$/.test(val));
  validateGolfId = e => this.validateField(e, val => val === '' || /^\d{6}-\d{3}$/.test(val));
  validatePassword = e => {
    this.validateField(e, this.passwordValidator);
    this.validateConfirmPassword({
      target: {
        value: this.state.user.confirmPassword,
        name: 'confirmPassword'
      }
    });
  };
  validateConfirmPassword = e => this.validateField(e, this.confirmPasswordValidator);

  passwordValidator = (val, e) => {
    const field = e.target.name;
    const { user } = this.state;
    user[field] = val;

    const valid = val.length > 4 && /\d/.test(val) && /[a-zA-Z]/.test(val);
    return valid;
  };

  confirmPasswordValidator = (val, e) => {
    const field = e.target.name;
    const { user } = this.state;
    user[field] = val;

    const valid = user.password === user.confirmPassword;
    return valid;
  };

  render() {
    const { login, translate } = this.props;
    const { error } = this.props.register;

    if (login.isAuthenticated) {
      return (
        <div className="p-3 bg-white">
          <h1>
            <Translate id="sign-up.user-reg" />
          </h1>
          <Message success content={translate('sign-up.account-created')} />
          <div className="p-3 text-center text-white">
            <Link to="/config">
              <Button color="blue" size="massive" className="mt-3 px-5">
                <Translate id="default.continue" />
              </Button>
            </Link>
          </div>
        </div>
      );
    }
    return (
      <div className="p-3 bg-white">
        <h1>
          <Translate id="club-admin.registration" />
        </h1>
        <Form
          onSubmit={this.handleSubmit}
          loading={this.props.register.activeRequest}
          error={!!this.props.register.error}
        >
          <Grid stackable>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Form.Input
                  required
                  label={translate('default.firstname')}
                  type="text"
                  name={'firstname'}
                  placeholder={translate('default.firstname')}
                  onChange={this.validateName}
                  error={!this.state.valid.firstname}
                />
              </Grid.Column>
              <Grid.Column>
                <Form.Input
                  required
                  label={translate('default.lastname')}
                  type="text"
                  name={'lastname'}
                  placeholder={translate('default.firstname')}
                  onChange={this.validateName}
                  error={!this.state.valid.lastname}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Popup
                  trigger={
                    <Form.Input
                      required
                      label={translate('default.password')}
                      type="password"
                      name={'password'}
                      placeholder={translate('default.password')}
                      onChange={this.validatePassword}
                      error={!this.state.valid.password}
                    />
                  }
                  content={translate('password.rule-length')}
                  wide="very"
                  position="top right"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Input
                  required
                  label={translate('password.new-confirm')}
                  type="password"
                  name={'confirmPassword'}
                  placeholder={translate('password.rule-length')}
                  onChange={this.validateConfirmPassword}
                  error={!this.state.valid.confirmPassword}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Input
                  label={translate('default.phone')}
                  type="text"
                  name={'phoneNumber'}
                  placeholder={translate('default.phone')}
                  onChange={this.validatePhonenumber}
                  error={!this.state.valid.phoneNumber}
                />
              </Grid.Column>
            </Grid.Row>
            {/* <Grid.Row>
              <Grid.Column>
                <Form.Input
                  label={translate('default.golf-id')}
                  type="text"
                  name={'golfid'}
                  placeholder={translate('default.golf-id-format')}
                  onChange={this.validateGolfId}
                  error={!this.state.valid.golfid}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Input
                  label={translate('default.handicap')}
                  step="any"
                  type="number"
                  name={'handicap'}
                  placeholder={translate('default.handicap')}
                  onChange={this.validateHandicap}
                  error={!this.state.valid.handicap}
                />{' '}
              </Grid.Column>
            </Grid.Row> */}
            <Grid.Row columns={1}>
              <Grid.Column>
                <ErrorMessage error={error} />
                <ErrorMessage error={this.state.validationError && translate('club-admin.validation-error')} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Button fluid color="teal" disabled={false}>
                  <Translate id="default.register" />
                </Form.Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    register: state.clubAdminRegistration
  };
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter(ClubAdminRegisterForm)));
