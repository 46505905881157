import React, { Fragment } from 'react';
import { Translate } from 'react-localize-redux';

import { Accordion, Button, Form, Icon, Item, List, Label, Message, Modal, Segment, Table } from 'semantic-ui-react';
import { DateInput, TimeInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import {
  weekdays,
  isValidDate,
  isValidTime,
  isPositiveInteger,
  todaysDate,
  endOfThisYearDate,
  headerColors,
  colors,
  isInHouseAdmin,
  formatTime
} from '../utils';

import { EditableSubListComponent } from './EditableSubListComponent';
// import 'moment/locale/sv';
// moment.locale('sv');
moment.updateLocale('en', {
  week: {
    dow: 1 // Monday is the first day of the week.
  }
});

let newItemIdCounter = -1;

const teeSlotDayTimespanEmpty = () => ({
  id: newItemIdCounter--,
  startTime: '06:00',
  endTime: '20:00',
  minutesBetweenSlots: 15
});

const teeSlotWeekSetupEmpty = dataList => {
  const checkedDays = dataList ? dataList.flatMap(x => x.daysOfWeek) : [];
  const daysLeft = weekdays.filter(x => !checkedDays.some(y => y.day === x));
  return {
    id: newItemIdCounter--,
    daysOfWeek: daysLeft.map(x => ({ day: x })),
    teeSlotDayTimespans: [teeSlotDayTimespanEmpty()]
  };
};

export const teeSlotPeriodEmpty = () => ({
  id: newItemIdCounter--,
  startDate: todaysDate(),
  endDate: endOfThisYearDate(),
  teeSlotWeekSetups: [teeSlotWeekSetupEmpty()],
  allocations: []
});

const priceDayTimespanEmpty = () => ({
  id: newItemIdCounter--,
  startTime: '06:00',
  endTime: '20:00',
  productPrices: []
});

const priceWeekSetupEmpty = () => ({
  id: newItemIdCounter--,
  daysOfWeek: weekdays.map(x => ({ day: x })),
  priceDayTimespans: [priceDayTimespanEmpty()]
});

export const pricePeriodEmpty = () => ({
  id: newItemIdCounter--,
  startDate: todaysDate(),
  endDate: endOfThisYearDate(),
  priceWeekSetups: [priceWeekSetupEmpty()]
});

const getColorIndex = (dataList, i) => {
  // The last color in colors is grey, which is used
  // if we have multiple items that hash to the same color
  const indexes = dataList.map(x => mod(x.id, colors.length - 1));
  const uniqueAmongSaved = dataList.filter((x, j) => x.id > 0 && indexes[j] === indexes[i]).length === 1;
  const isSaved = dataList[i].id > 0;
  const unique = indexes.filter(x => x === indexes[i]).length === 1;
  if (isSaved && uniqueAmongSaved) {
    return indexes[i];
  }
  return unique ? indexes[i] : colors.length - 1;
};

const getAllTimes = weekSetups => {
  const times = [];
  weekSetups.forEach(ws => times.push(...getTimes(ws)));
  const uniqueItems = [...new Set(times)].sort();
  return uniqueItems;
};

const getTimesPerDay = (weekdays, weekSetups) => {
  return weekdays.map(day => {
    const weekSetup = getWeekSetup(day, weekSetups);
    return weekSetup ? getTimes(weekSetup) : [];
  });
};

const getTimes = weekSetup => {
  const times = [];
  weekSetup.teeSlotDayTimespans.forEach(ts => {
    let time = ts.startTime;
    do {
      times.push(time);
      const newTime = new Date(new Date('1970/01/01 ' + time).getTime() + ts.minutesBetweenSlots * 60000);
      time = formatTime(newTime);
    } while (time <= ts.endTime);
  });
  const uniqueItems = [...new Set(times)].sort();
  return uniqueItems;
};

const getWeekSetup = (day, teeSlotWeekSetups) => {
  const setups = teeSlotWeekSetups.filter(x => x.daysOfWeek.findIndex(y => y.day === day) >= 0);
  if (setups.length === 0 || setups.length > 1) {
    return null;
  } else {
    return setups[0];
  }
};

function mod(n, m) {
  return ((n % m) + m) % m;
}

class TeetimeScheduleViewTitleItem extends React.Component {
  render() {
    const { data } = this.props;
    const { teeSlotPeriods: periods } = this.props.data;
    const start = periods ? periods.map(x => x.startDate).sort()[0] : '';
    const end = periods ? periods.map(x => x.endDate).sort()[periods.length - 1] : '';
    return (
      <List as="ol" style={{ margin: 0, display: 'inline-block' }}>
        <List.Item>
          <h3 style={{ marginTop: 15, marginBottom: 0, display: 'inline-block' }}>
            Period: {start} - {end}{' '}
          </h3>
          <Label style={{ marginLeft: 15 }} color={data.isActivated ? 'green' : 'orange'} size="medium">
            {data.isActivated ? 'Active' : 'Not active'}
          </Label>
          <Label color={data.isValid ? 'green' : 'orange'} size="medium">
            {data.isValid && <Icon name="check" />}
            {data.isValid ? 'Valid' : 'Not valid'}
          </Label>
        </List.Item>
      </List>
    );
  }
}

class TeetimeScheduleViewContentItem extends React.Component {
  state = { activeIndex: null };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  getWeekSetupIndex = (day, teeSlotWeekSetups) => {
    const setups = teeSlotWeekSetups.filter(x => x.daysOfWeek.findIndex(y => y.day === day) >= 0);
    if (setups.length === 0 || setups.length > 1) {
      return 6;
    } else {
      return getColorIndex(teeSlotWeekSetups, teeSlotWeekSetups.indexOf(setups[0]));
    }
  };

  getWeekdayInformation = (day, teeSlotWeekSetups) => {
    const setups = teeSlotWeekSetups.filter(x => x.daysOfWeek.findIndex(y => y.day === day) >= 0);
    let result = null;
    if (setups.length === 0) {
      result = {
        value: 'Empty',
        element: (
          <p>
            <Translate id="schedule.no-teetimes" />
          </p>
        )
      };
    } else if (setups.length > 1) {
      result = {
        value: 'Conflict',
        element: (
          <p>
            <Translate id="schedule.no-conflict" />
          </p>
        )
      };
    } else {
      result = {
        value: setups[0],
        element: (
          <div style={{ display: 'table', margin: 'auto' }}>
            {setups[0].teeSlotDayTimespans.map((x, i) => (
              <List key={i}>
                <List.Item>
                  <Translate id="default.start" />: {x.startTime}
                </List.Item>
                <List.Item>
                  <Translate id="default.end" />: {x.endTime}
                </List.Item>
                <List.Item>
                  <Translate id="default.interval" data={{ min: x.minutesBetweenSlots }} />
                </List.Item>
              </List>
            ))}
          </div>
        )
      };
    }
    return result;
  };

  getPriceInformation = (day, priceWeekSetups) => {
    const setups = priceWeekSetups.filter(x => x.daysOfWeek.findIndex(y => y.day === day) >= 0);
    let result = null;
    if (setups.length === 0) {
      result = {
        value: 'Empty',
        element: (
          <p>
            <Translate id="schedule.no-prices" />
          </p>
        )
      };
    } else if (setups.length > 1) {
      result = {
        value: 'Conflict',
        element: (
          <p>
            <Translate id="schedule.conflict-prices" />
          </p>
        )
      };
    } else {
      result = {
        value: setups[0],
        element: (
          <div style={{ display: 'table', margin: 'auto' }}>
            {setups[0].priceDayTimespans.map((x, i) => (
              <List key={i}>
                <List.Item>
                  <Translate id="default.start" />: {x.startTime}
                </List.Item>
                <List.Item>
                  <Translate id="default.end" />: {x.endTime}
                </List.Item>
                <List.Item>
                  <Translate id="default.products" />:{this.productPriceInfo(x.productPrices)}
                </List.Item>
              </List>
            ))}
          </div>
        )
      };
    }
    return result;
  };

  productPriceInfo = productPrices => (
    <Translate>
      {({ translate }) => (
        <List>
          {productPrices.map((y, j) => (
            <List.Item key={j}>
              <List.Header>{y.product.name}</List.Header>
              {y.product.isZeroPrice
                ? translate('schedule.zero-price')
                : `${translate('default.rack-price')}: ${y.rackPrice} ${translate('default.sale-price')} ${
                    y.sellPrice
                  }`}
            </List.Item>
          ))}
        </List>
      )}
    </Translate>
  );

  createColumns = (weekdays, period, infoFunction, weekSetupProp) => {
    const weekSetups = period[weekSetupProp];
    let colSpan = 1;
    const dayInfo = weekdays.map(day => infoFunction(day, weekSetups));
    const cols = [];
    let i = 0;
    let finished = false;
    const lastIndex = weekdays.length - 1;
    do {
      let same = false;
      for (let j = i + 1; j < weekdays.length; j++) {
        if (dayInfo[i].value === dayInfo[j].value) {
          colSpan += 1;
          same = true;
        } else {
          cols.push(this.getTableCell(i, dayInfo, weekSetups, colSpan));
          i = j;
          colSpan = 1;
          same = false;
          break;
        }
        if (j === lastIndex && same) {
          cols.push(this.getTableCell(i, dayInfo, weekSetups, colSpan));
          finished = true;
        }
      }
      if (i === lastIndex) {
        cols.push(this.getTableCell(i, dayInfo, weekSetups, colSpan));
        finished = true;
      }
    } while (i < weekdays.length && !finished);
    return cols;
  };

  getTableCell = (i, dayInfo, weekSetups, colSpan) => {
    const info = dayInfo[i].element;
    return (
      <Table.Cell
        key={i}
        colSpan={colSpan}
        style={{ backgroundColor: colors[this.getWeekSetupIndex(weekdays[i], weekSetups)] }}
      >
        <span>{info}</span>
      </Table.Cell>
    );
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <List as="ol" style={{ margin: 0 }}>
            <List.Item>
              <h3 style={{ marginTop: 15, marginBottom: 0 }}>
                <Translate id="schedule.teetime-periods" />:
              </h3>
              <List size="small" bulleted>
                {this.props.data.teeSlotPeriods.map((x, i) => {
                  const hasAllocations = x.allocations && x.allocations.length > 0;
                  const allTimes = hasAllocations ? getAllTimes(x.teeSlotWeekSetups) : [];
                  const timesPerDay = hasAllocations ? getTimesPerDay(weekdays, x.teeSlotWeekSetups) : [];
                  return (
                    <Fragment key={i}>
                      <List.Item>
                        {x.startDate} - {x.endDate}
                        <Table celled fixed columns={7}>
                          <Table.Header>
                            <Table.Row>
                              {weekdays.map((day, i) => (
                                <Table.HeaderCell
                                  key={i}
                                  style={{
                                    backgroundColor: headerColors[this.getWeekSetupIndex(day, x.teeSlotWeekSetups)]
                                  }}
                                >
                                  {day}
                                </Table.HeaderCell>
                              ))}
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            <Table.Row>
                              {this.createColumns(weekdays, x, this.getWeekdayInformation, 'teeSlotWeekSetups')}
                            </Table.Row>
                            {isInHouseAdmin(this.props) && (
                              <Table.Row>
                                <Table.Cell colSpan={7} style={{ backgroundColor: colors[6] }}>
                                  <Accordion styled fluid>
                                    <Accordion.Title
                                      active={this.state.activeIndex === 0}
                                      index={0}
                                      onClick={this.handleClick}
                                    >
                                      <Icon name="dropdown" />
                                      <Translate id="schedule.has-allocations" />:
                                      {hasAllocations ? translate('default.yes') : translate('default.no')}
                                    </Accordion.Title>
                                    <Accordion.Content active={this.state.activeIndex === 0}>
                                      {this.state.activeIndex === 0 && (
                                        <Table celled fixed columns={7}>
                                          <Table.Header>
                                            <Table.Row>
                                              {weekdays.map((day, i) => (
                                                <Table.HeaderCell key={i}>{day}</Table.HeaderCell>
                                              ))}
                                            </Table.Row>
                                          </Table.Header>
                                          <Table.Body>
                                            {allTimes.map((time, i) => (
                                              <Table.Row key={i}>
                                                {weekdays.map((day, j) => {
                                                  const times = timesPerDay[j];
                                                  const hasTime = times && times.indexOf(time) >= 0;
                                                  const allocation = x.allocations.find(
                                                    x => x.startTime === time && x.day === day
                                                  );
                                                  const type = allocation ? allocation.type : '';
                                                  return (
                                                    <Table.Cell
                                                      key={j}
                                                      textAlign="center"
                                                      className="tableCellNoPadding"
                                                    >
                                                      <AllocationItem
                                                        viewOnly={true}
                                                        time={time}
                                                        hasTime={hasTime}
                                                        day={day}
                                                        type={type}
                                                      />
                                                    </Table.Cell>
                                                  );
                                                })}
                                              </Table.Row>
                                            ))}
                                          </Table.Body>
                                        </Table>
                                      )}
                                    </Accordion.Content>
                                  </Accordion>
                                </Table.Cell>
                              </Table.Row>
                            )}
                          </Table.Body>
                        </Table>
                      </List.Item>
                    </Fragment>
                  );
                })}
              </List>
            </List.Item>
            <List.Item>
              <h3 style={{ marginTop: 15, marginBottom: 0 }}>
                <Translate id="schedule.price-periods" />:
              </h3>
              <List size="small" bulleted>
                {this.props.data.pricePeriods.map((x, i) => {
                  return (
                    <Fragment key={i}>
                      <List.Item>
                        {x.startDate} - {x.endDate}
                        <Table celled fixed columns={7}>
                          <Table.Header>
                            <Table.Row>
                              {weekdays.map((day, i) => (
                                <Table.HeaderCell
                                  key={i}
                                  style={{
                                    backgroundColor: headerColors[this.getWeekSetupIndex(day, x.priceWeekSetups)]
                                  }}
                                >
                                  {day}
                                </Table.HeaderCell>
                              ))}
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            <Table.Row>
                              {this.createColumns(weekdays, x, this.getPriceInformation, 'priceWeekSetups')}
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </List.Item>
                    </Fragment>
                  );
                })}
              </List>
            </List.Item>
          </List>
        )}
      </Translate>
    );
  }
}

class TeetimeScheduleViewItem extends React.Component {
  getWeekSetupIndex = (day, teeSlotWeekSetups) => {
    const setups = teeSlotWeekSetups.filter(x => x.daysOfWeek.findIndex(y => y.day === day) >= 0);
    if (setups.length === 0 || setups.length > 1) {
      return 6;
    } else {
      return getColorIndex(teeSlotWeekSetups, teeSlotWeekSetups.indexOf(setups[0]));
    }
  };
  getWeekdayInformation = (day, teeSlotWeekSetups) => {
    const setups = teeSlotWeekSetups.filter(x => x.daysOfWeek.findIndex(y => y.day === day) >= 0);
    let result = null;
    if (setups.length === 0) {
      result = {
        value: 'Empty',
        element: (
          <p>
            <Translate id="schedule.no-teetimes" />
          </p>
        )
      };
    } else if (setups.length > 1) {
      result = {
        value: 'Conflict',
        element: (
          <p>
            <Translate id="schedule.conflict" />
          </p>
        )
      };
    } else {
      result = {
        value: setups[0],
        element: (
          <div>
            {setups[0].teeSlotDayTimespans.map((x, i) => (
              <List key={i}>
                <List.Item>
                  <Translate id="default.start" />: {x.startTime}
                </List.Item>
                <List.Item>
                  <Translate id="default.end" />: {x.endTime}
                </List.Item>
                <List.Item>
                  <Translate id="default.interval" data={{ min: x.minutesBetweenSlots }} />
                </List.Item>
              </List>
            ))}
          </div>
        )
      };
    }
    return result;
  };

  getPriceInformation = (day, priceWeekSetups) => {
    const setups = priceWeekSetups.filter(x => x.daysOfWeek.findIndex(y => y.day === day) >= 0);
    let result = null;
    if (setups.length === 0) {
      result = {
        value: 'Empty',
        element: (
          <p>
            <Translate id="schedule.no-prices" />
          </p>
        )
      };
    } else if (setups.length > 1) {
      result = {
        value: 'Conflict',
        element: (
          <p>
            <Translate id="schedule.conflict" />
          </p>
        )
      };
    } else {
      result = {
        value: setups[0],
        element: (
          <div>
            {setups[0].priceDayTimespans.map((x, i) => (
              <Translate>
                {({ translate }) => (
                  <List key={i}>
                    <List.Item>
                      <Translate id="default.start-time" />: {x.startTime}
                    </List.Item>
                    <List.Item>
                      <Translate id="default.end-time" />: {x.endTime}
                    </List.Item>
                    <List.Item>
                      <Translate id="default.products" />:
                      <Item.Group>
                        {x.productPrices.map((y, j) => (
                          <Item key={j}>
                            <Item.Content>
                              <Item.Meta>{y.product.name}</Item.Meta>
                              <Item.Description>
                                {y.product.isZeroPrice
                                  ? translate('schedule.zero-price')
                                  : `${translate('default.price')}: ${y.sellPrice}`}
                              </Item.Description>
                            </Item.Content>
                          </Item>
                        ))}
                      </Item.Group>
                    </List.Item>
                  </List>
                )}
              </Translate>
            ))}
          </div>
        )
      };
    }
    return result;
  };

  createColumns = (weekdays, period, infoFunction, weekSetupProp) => {
    const weekSetups = period[weekSetupProp];
    let colSpan = 1;
    const dayInfo = weekdays.map(day => infoFunction(day, weekSetups));
    const cols = [];
    let i = 0;
    let finished = false;
    const lastIndex = weekdays.length - 1;
    do {
      let same = false;
      for (let j = i + 1; j < weekdays.length; j++) {
        if (dayInfo[i].value === dayInfo[j].value) {
          colSpan += 1;
          same = true;
        } else {
          cols.push(this.getTableCell(i, dayInfo, weekSetups, colSpan));
          i = j;
          colSpan = 1;
          same = false;
          break;
        }
        if (j === lastIndex && same) {
          cols.push(this.getTableCell(i, dayInfo, weekSetups, colSpan));
          finished = true;
        }
      }
      if (i === lastIndex) {
        cols.push(this.getTableCell(i, dayInfo, weekSetups, colSpan));
        finished = true;
      }
    } while (i < weekdays.length && !finished);
    return cols;
  };

  getTableCell = (i, dayInfo, weekSetups, colSpan) => {
    const info = dayInfo[i].element;
    return (
      <Table.Cell
        key={i}
        textAlign="center"
        colSpan={colSpan}
        style={{ backgroundColor: colors[this.getWeekSetupIndex(weekdays[i], weekSetups)] }}
      >
        {info}
      </Table.Cell>
    );
  };

  render() {
    const { data } = this.props;
    const { teeSlotPeriods: periods } = this.props.data;
    const start = periods ? periods.map(x => x.startDate).sort()[0] : '';
    const end = periods ? periods.map(x => x.endDate).sort()[periods.length - 1] : '';
    return (
      <Translate>
        {({ translate }) => (
          <List as="ol">
            <List.Item>
              <h3 style={{ marginTop: 15, marginBottom: 0 }}>
                Period: {start} - {end}
              </h3>
            </List.Item>
            <List.Item>
              <Label color={data.isActivated ? 'green' : 'orange'} size="medium">
                {data.isActivated ? translate('default.active') : translate('default.not-active')}
              </Label>
              <Label color={data.isValid ? 'green' : 'orange'} size="medium">
                {data.isValid && <Icon name="check" />}
                {data.isValid ? translate('default.valid') : translate('default.not-valid')}
              </Label>
            </List.Item>
            <List.Item>
              <h3 style={{ marginTop: 15, marginBottom: 0 }}>
                <Translate id="schedule.teetime-periods" />:
              </h3>
              <List size="small" bulleted>
                {this.props.data.teeSlotPeriods.map((x, i) => {
                  return (
                    <Fragment key={i}>
                      <List.Item>
                        {x.startDate} - {x.endDate}
                        <Table celled fixed columns={7}>
                          <Table.Header>
                            <Table.Row>
                              {weekdays.map((day, i) => (
                                <Table.HeaderCell
                                  key={i}
                                  style={{
                                    backgroundColor: headerColors[this.getWeekSetupIndex(day, x.teeSlotWeekSetups)]
                                  }}
                                >
                                  {day}
                                </Table.HeaderCell>
                              ))}
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            <Table.Row>
                              {this.createColumns(weekdays, x, this.getWeekdayInformation, 'teeSlotWeekSetups')}
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </List.Item>
                    </Fragment>
                  );
                })}
              </List>
            </List.Item>
            <List.Item>
              <h3 style={{ marginTop: 15, marginBottom: 0 }}>
                <Translate id="schedule.price-periods" />:
              </h3>
              <List size="small" bulleted>
                {this.props.data.pricePeriods.map((x, i) => {
                  return (
                    <Fragment key={i}>
                      <List.Item>
                        {x.startDate} - {x.endDate}
                        <Table celled fixed columns={7}>
                          <Table.Header>
                            <Table.Row>
                              {weekdays.map((day, i) => (
                                <Table.HeaderCell
                                  key={i}
                                  style={{
                                    backgroundColor: headerColors[this.getWeekSetupIndex(day, x.priceWeekSetups)]
                                  }}
                                >
                                  {day}
                                </Table.HeaderCell>
                              ))}
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            <Table.Row>
                              {this.createColumns(weekdays, x, this.getPriceInformation, 'priceWeekSetups')}
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </List.Item>
                    </Fragment>
                  );
                })}
              </List>
            </List.Item>
          </List>
        )}
      </Translate>
    );
  }
}

class TeetimeScheduleEditItem extends React.Component {
  state = {
    isActivated: true,
    teeSlotPeriods: [],
    teeSlotPeriodsValid: false,
    pricePeriods: [],
    pricePeriodsValid: false,
    accordionActiveIndex: [-1]
  };

  componentDidMount = () => {
    this.setState({ isActivated: this.props.data.isActivated });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { teeSlotPeriodsValid, pricePeriodsValid } = this.state;
    if (
      prevState.teeSlotPeriodsValid !== this.state.teeSlotPeriodsValid ||
      prevState.pricePeriodsValid !== this.state.pricePeriodsValid
    ) {
      this.props.dataIsValid(teeSlotPeriodsValid && pricePeriodsValid);
    }
  };

  validateDescription = (event, { value }) => {
    let { description, descriptionValid } = this.state;
    description = value;
    descriptionValid = description && description.length > 0;
    this.setState({ description, descriptionValid });
  };

  pricePeriodsUpdated = (dataList, dataListValid) => {
    const updatedState = { pricePeriods: dataList, pricePeriodsValid: dataListValid };
    // TODO remove when validation is complete
    updatedState.pricePeriodsValid = true;
    // TODO end
    this.setState(updatedState);
  };

  teeSlotPeriodsUpdated = (dataList, dataListValid) => {
    const updatedState = { teeSlotPeriods: dataList, teeSlotPeriodsValid: dataListValid };
    // TODO remove when validation is complete
    updatedState.teeSlotPeriodsValid = true;
    // TODO end
    this.setState(updatedState);
  };

  saveItem = () => {
    const { isActivated, teeSlotPeriods, pricePeriods } = this.state;
    const data = { isActivated, teeSlotPeriods, pricePeriods };
    data.golfCourse = this.props.data.golfCourse;
    if (Object.prototype.hasOwnProperty.call(this.props.data, 'id')) {
      data.id = this.props.data.id;
    }
    this.props.saveAction(data, this.props.onSubmit);
  };

  handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const { accordionActiveIndex } = this.state;
    const expandedIndex = accordionActiveIndex.indexOf(index);
    let removed = false;
    if (expandedIndex >= 0) {
      accordionActiveIndex.splice(expandedIndex, 1);
      removed = true;
    } else {
      accordionActiveIndex.push(index);
    }
    if (!e.ctrlKey && !removed) {
      const otherIndex = 1 - index;
      const expandedOtherIndex = accordionActiveIndex.indexOf(otherIndex);
      if (expandedOtherIndex >= 0) {
        accordionActiveIndex.splice(expandedOtherIndex, 1);
      }
    }
    this.setState({ accordionActiveIndex });
  };

  handleButtonAccordionClick = (indexClicked, otherIndex) => {
    const { accordionActiveIndex } = this.state;
    const expandedIndex = accordionActiveIndex.indexOf(indexClicked);
    if (expandedIndex >= 0) {
      accordionActiveIndex.splice(expandedIndex, 1);
    } else {
      accordionActiveIndex.push(indexClicked);
    }
    const expandedOtherIndex = accordionActiveIndex.indexOf(otherIndex);
    if (expandedOtherIndex >= 0) {
      accordionActiveIndex.splice(expandedOtherIndex, 1);
    }
    this.setState({ accordionActiveIndex });
  };

  render() {
    const teetimeTitle = (
      <Accordion.Title>
        <Icon name="dropdown" />
        <h3 style={{ margin: 0, display: 'inline-block' }}>
          <Translate id="schedule.teetime-periods" />
        </h3>
      </Accordion.Title>
    );
    const teetimeContent = (
      <Accordion.Content>
        <TeeSlotPeriodListComponent
          login={this.props.login}
          data={this.props.data.teeSlotPeriods}
          stateUpdated={this.teeSlotPeriodsUpdated}
        />
      </Accordion.Content>
    );
    const priceTitle = (
      <Accordion.Title>
        <Icon name="dropdown" />
        <h3 style={{ margin: 0, display: 'inline-block' }}>
          <Translate id="schedule.price-periods" />
        </h3>
      </Accordion.Title>
    );
    const priceContent = (
      <Accordion.Content>
        <PricePeriodListComponent
          products={this.props.products}
          data={this.props.data.pricePeriods}
          stateUpdated={this.pricePeriodsUpdated}
        />
      </Accordion.Content>
    );
    const panels = [
      {
        key: 'teetime',
        title: teetimeTitle,
        content: teetimeContent
      },
      {
        key: 'price',
        title: priceTitle,
        content: priceContent
      }
    ];

    return (
      <Translate>
        {({ translate }) => (
          <Form id={this.props.id} onSubmit={this.saveItem}>
            <List>
              <List.Item>
                <Form.Field>
                  <Form.Checkbox
                    inline
                    label={translate('default.active')}
                    onChange={(e, { checked }) => this.setState({ isActivated: checked })}
                    checked={this.state.isActivated}
                  />
                </Form.Field>
              </List.Item>

              <Message color="green" content={translate('schedule.atleast-one')} />

              <Button
                color="green"
                onClick={() => this.handleButtonAccordionClick(0, 1)}
                disabled={this.state.accordionActiveIndex.indexOf(0) >= 0}
              >
                <Translate id="schedule.step1" />
              </Button>
              <Button
                color="green"
                onClick={() => this.handleButtonAccordionClick(1, 0)}
                disabled={this.state.accordionActiveIndex.indexOf(1) >= 0}
              >
                <Translate id="schedule.step2" />
              </Button>
              <Accordion
                style={{ marginTop: 15 }}
                activeIndex={this.state.accordionActiveIndex}
                onTitleClick={this.handleAccordionClick}
                styled
                panels={panels}
                exclusive={false}
                fluid
              />
            </List>
          </Form>
        )}
      </Translate>
    );
  }
}

class TeeSlotPeriodListComponent extends React.Component {
  validate = (dataList, dataListValid) => {
    dataList.forEach((x, i) => {
      this.validateEndDatetime(dataList, dataListValid, i);
    });
  };

  allValid = (dataList, dataListValid) => {
    return dataList.length > 0 && dataListValid.every(x => this.validItem(x));
  };

  validItem = item => {
    return item.startDate && item.endDate && item.teeSlotWeekSetup;
  };

  emptyValidationItem = () => {
    return { startDate: true, endDate: true };
  };

  validateEndDatetime = (dataList, dataListValid, i) => {
    dataListValid[i].endDate = dataList[i].endDate >= dataList[i].startDate;
    dataListValid[i].startDate = isValidDate(dataList[i].startDate);
    dataListValid[i].endDate = dataListValid[i].endDate && isValidDate(dataList[i].endDate);
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <EditableSubListComponent
            data={this.props.data}
            itemName="teetime period"
            addHelpText={translate('schedule.teetime-info')}
            stateUpdated={this.props.stateUpdated}
            error={this.props.error}
            validate={this.validate}
            emptyItem={teeSlotPeriodEmpty}
            emptyValidationItem={this.emptyValidationItem}
            allValid={this.allValid}
            subComponentListName="teeSlotWeekSetups"
            subComponent={
              <TeeSlotPeriodComponent
                login={this.props.login}
                products={this.props.products}
                validateEndDatetime={this.validateEndDatetime}
              />
            }
          />
        )}
      </Translate>
    );
  }
}

class TeeSlotPeriodComponent extends React.Component {
  state = {
    allocationsShowing: false
  };
  validateDatetimeChange = (_e, { name, value }) => {
    const { dataList, dataListValid } = this.props;
    const propNameLength = name.startsWith('start') ? 9 : 7;
    const i = name.substring(propNameLength);
    const prop = name.substring(0, propNameLength);
    dataList[i][prop] = value;
    this.props.validateEndDatetime(dataList, dataListValid, i);
    this.props.updateState(dataList, dataListValid);
  };

  allocationClicked = e => {
    e.preventDefault();
    this.setState({ allocationClicked: true });
  };

  allocationClosed = () => {
    this.setState({ allocationClicked: true });
  };

  updateAllocation = (time, day, type) => {
    const { dataList, dataListValid, index } = this.props;
    const allocations = dataList[index].allocations;
    const itemIndex = allocations.findIndex(x => x.startTime === time && x.day === day);
    if (itemIndex >= 0 && type === '') {
      allocations.splice(itemIndex, 1);
    } else if (itemIndex >= 0) {
      allocations[itemIndex].type = type === 'Regular' ? type : 'HotDeal';
    } else {
      allocations.push({ startTime: time, day, type: type === 'Regular' ? type : 'HotDeal' });
    }

    this.props.updateState(dataList, dataListValid);
  };

  render() {
    const i = this.props.index;
    const item = this.props.dataList[i];
    const itemValid = this.props.dataListValid[i];
    const allTimes = this.state.allocationClicked ? getAllTimes(item.teeSlotWeekSetups) : [];
    const timesPerDay = this.state.allocationClicked ? getTimesPerDay(weekdays, item.teeSlotWeekSetups) : [];
    return (
      <Translate>
        {({ translate }) => (
          <Segment color={item.id < 0 ? 'green' : null}>
            {this.props.dataList.length !== 1 && (
              <Icon
                style={{ float: 'right' }}
                link
                color="red"
                name="delete"
                size="large"
                onClick={e => {
                  e.preventDefault();
                  this.props.removeItem(i);
                }}
              />
            )}
            <Form.Group style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Field>
                <label>
                  <Translate id="default.start" />
                </label>
                <DateInput
                  closable
                  dateFormat="YYYY-MM-DD"
                  name={'startDate' + i}
                  placeholder={translate('default.start-date')}
                  value={item.startDate}
                  error={!itemValid.startDate}
                  onChange={this.validateDatetimeChange}
                />
              </Form.Field>
              <Form.Field>
                <label>End</label>
                <DateInput
                  closable
                  dateFormat="YYYY-MM-DD"
                  minDate={item.startDate}
                  name={'endDate' + i}
                  placeholder={translate('default.end-date')}
                  value={item.endDate}
                  error={!itemValid.endDate}
                  onChange={this.validateDatetimeChange}
                />
              </Form.Field>
            </Form.Group>
            <TeeSlotWeekSetupListComponent
              login={this.props.login}
              products={this.props.products}
              data={item.teeSlotWeekSetups}
              stateUpdated={(dataList, dataListValid) => this.props.stateUpdated(dataList, dataListValid, i)}
            />
            {isInHouseAdmin(this.props) && (
              <>
                <h3>Allocations</h3>
                <Modal
                  onClose={this.allocationClosed}
                  trigger={
                    <Button onClick={e => this.allocationClicked(e)}>
                      <Translate id="default.select" />
                    </Button>
                  }
                >
                  <Modal.Header>
                    <Translate id="schedule.allocations" />
                  </Modal.Header>
                  <Modal.Content>
                    <Modal.Description>
                      {this.state.allocationClicked && (
                        <Table celled fixed columns={7}>
                          <Table.Header>
                            <Table.Row>
                              {weekdays.map((day, i) => (
                                <Table.HeaderCell key={i}>{day}</Table.HeaderCell>
                              ))}
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {allTimes.map((time, i) => (
                              <Table.Row key={i}>
                                {weekdays.map((day, j) => {
                                  const times = timesPerDay[j];
                                  const hasTime = times && times.indexOf(time) >= 0;
                                  const allocation = item.allocations.find(x => x.startTime === time && x.day === day);
                                  const type = allocation ? allocation.type : '';
                                  return (
                                    <Table.Cell key={j} className="tableCellNoPadding">
                                      <AllocationItem
                                        updateAllocation={this.updateAllocation}
                                        time={time}
                                        hasTime={hasTime}
                                        day={day}
                                        type={type}
                                      />
                                    </Table.Cell>
                                  );
                                })}
                              </Table.Row>
                            ))}
                          </Table.Body>
                        </Table>
                      )}
                    </Modal.Description>
                  </Modal.Content>
                </Modal>
              </>
            )}
          </Segment>
        )}
      </Translate>
    );
  }
}

class AllocationItem extends React.Component {
  clicked = e => {
    e.preventDefault();
    const { time, day, type } = this.props;
    const newType = this.getNextType(type);
    this.props.updateAllocation(time, day, newType);
  };

  getNextType = type => {
    if (type === '') {
      return 'Regular';
    }
    if (type === 'Regular') {
      return 'HotDeal';
    }
    return '';
  };

  getTypeText = type => {
    if (type === 'HotDeal') {
      return 'Hot deal';
    }
    return type;
  };

  getColor = type => {
    if (type === 'Regular') {
      return 'green';
    }
    if (type === 'HotDeal') {
      return 'red';
    }
    return null;
  };

  getViewColor = type => {
    if (type === 'Regular') {
      return '#21ba45';
    }
    if (type === 'HotDeal') {
      return '#db2828';
    }
    return null;
  };

  render() {
    const { type, time, hasTime } = this.props;
    if (!hasTime) {
      return null;
    }
    if (this.props.viewOnly) {
      const color = this.getViewColor(type);
      return (
        <div style={{ color: color, border: color ? '1px solid' : null, borderRadius: '3px', padding: '5px' }}>
          {time}
          <br />
          {this.getTypeText(type)}
        </div>
      );
    }
    const color = this.getColor(type);
    return (
      <Button onClick={this.clicked} color={color} basic fluid compact>
        {time}
        <br />
        {this.getTypeText(type)}
      </Button>
    );
  }
}

class TeeSlotWeekSetupListComponent extends React.Component {
  validate = (dataList, dataListValid) => {
    dataList.forEach((x, i) => {
      dataListValid[i].daysOfWeek = dataList[i].daysOfWeek.length > 0;
    });
  };

  allValid = (dataList, dataListValid) => {
    return dataList.length > 0 && dataListValid.every(x => this.validItem(x));
  };

  validItem = item => {
    return item.daysOfWeek && item.teeSlotDayTimespans;
  };

  emptyValidationItem = () => {
    return {};
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <EditableSubListComponent
            data={this.props.data}
            listLabel="Week setup"
            addHelpText={translate('schedule.week-info')}
            itemName={translate('club-admin.week-setup')}
            stateUpdated={this.props.stateUpdated}
            error={this.props.error}
            validate={this.validate}
            emptyItem={teeSlotWeekSetupEmpty}
            emptyValidationItem={this.emptyValidationItem}
            allValid={this.allValid}
            subComponentListName="teeSlotDayTimespans"
            subComponent={
              <WeekSetupComponent
                login={this.props.login}
                type="TeeSlotDayTimespanListComponent"
                validate={this.validate}
              />
            }
          />
        )}
      </Translate>
    );
  }
}

/**
 * dataList, dataListValid, index, updateState, removeItem, stateUpdated (injected by EditableSubListComponent)
 * products
 */
class WeekSetupComponent extends React.Component {
  validateDayChange = ({ checked }, day, i) => {
    const { dataList, dataListValid } = this.props;
    if (checked) {
      dataList[i].daysOfWeek.push({ day });
    } else {
      const index = dataList[i].daysOfWeek.findIndex(y => y.day === day);
      dataList[i].daysOfWeek.splice(index, 1);
    }
    this.props.validate(dataList, dataListValid);
    this.props.updateState(dataList, dataListValid);
  };

  render() {
    const i = this.props.index;
    const item = this.props.dataList[i];
    const color = colors[getColorIndex(this.props.dataList, i)];
    return (
      <Segment style={{ backgroundColor: color }} color={item.id < 0 ? 'green' : null}>
        {this.props.dataList.length !== 1 && (
          <Icon
            style={{ float: 'right' }}
            link
            color="red"
            name="delete"
            size="large"
            onClick={e => {
              e.preventDefault();
              this.props.removeItem(i);
            }}
          />
        )}
        <Form.Group style={{ display: 'flex', alignItems: 'center' }}>
          {weekdays.map((day, j) => (
            <Form.Checkbox
              key={j}
              label={day}
              checked={item.daysOfWeek.findIndex(y => y.day === day) >= 0}
              onChange={(_e, data) => this.validateDayChange(data, day, i)}
            />
          ))}
        </Form.Group>
        {this.props.type === 'PriceDayTimespanListComponent' && (
          <PriceDayTimespanListComponent
            products={this.props.products}
            data={item.priceDayTimespans}
            stateUpdated={(dataList, dataListValid) => this.props.stateUpdated(dataList, dataListValid, i)}
          />
        )}
        {this.props.type === 'TeeSlotDayTimespanListComponent' && (
          <TeeSlotDayTimespanListComponent
            data={item.teeSlotDayTimespans}
            stateUpdated={(dataList, dataListValid) => this.props.stateUpdated(dataList, dataListValid, i)}
          />
        )}
      </Segment>
    );
  }
}
class TeeSlotDayTimespanListComponent extends React.Component {
  validate = (dataList, dataListValid) => {
    dataList.forEach((x, i) => {
      this.validateEndDatetime(dataList, dataListValid, i);
      this.validateIntervalInternal(dataList, dataListValid, i);
    });
  };

  allValid = (dataList, dataListValid) => {
    return dataList.length > 0 && dataListValid.every(x => this.validItem(x));
  };

  validItem = item => {
    return item.startTime && item.endTime && item.minutesBetweenSlots;
  };

  emptyValidationItem = () => {
    return { endTime: true, startTime: true, minutesBetweenSlots: true };
  };

  validateEndDatetime = (dataList, dataListValid, i) => {
    dataListValid[i].endTime = dataList[i].endTime >= dataList[i].startTime;
    dataListValid[i].endTime = dataListValid[i].endTime && isValidTime(dataList[i].endTime);
    dataListValid[i].startTime = isValidTime(dataList[i].startTime);
  };

  validateIntervalInternal(dataList, dataListValid, i) {
    dataListValid[i].minutesBetweenSlots = isPositiveInteger(dataList[i].minutesBetweenSlots);
  }

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <EditableSubListComponent
            data={this.props.data}
            listLabel="Teetimes"
            itemName="teetime timespan"
            addHelpText={translate('schedule.teetimes-info')}
            stateUpdated={this.props.stateUpdated}
            error={this.props.error}
            validate={this.validate}
            emptyItem={teeSlotDayTimespanEmpty}
            emptyValidationItem={this.emptyValidationItem}
            allValid={this.allValid}
            subComponent={
              <TeeSlotDayTimespanComponent
                products={this.props.products}
                validateEndDatetime={this.validateEndDatetime}
                validateIntervalInternal={this.validateIntervalInternal}
              />
            }
          />
        )}
      </Translate>
    );
  }
}

class TeeSlotDayTimespanComponent extends React.Component {
  validateDatetimeChange = (_e, { name, value }) => {
    const { dataList, dataListValid } = this.props;
    const propNameLength = name.startsWith('start') ? 9 : 7;
    const i = name.substring(propNameLength);
    const prop = name.substring(0, propNameLength);
    dataList[i][prop] = value;
    this.props.validateEndDatetime(dataList, dataListValid, i);
    this.props.updateState(dataList, dataListValid);
  };

  validateInterval = ({ value }, i) => {
    const { dataList, dataListValid } = this.props;
    dataList[i].minutesBetweenSlots = value;
    this.props.validateIntervalInternal(dataList, dataListValid, i);
    this.props.updateState(dataList, dataListValid);
  };

  render() {
    const i = this.props.index;
    const item = this.props.dataList[i];
    const itemValid = this.props.dataListValid[i];
    return (
      <Translate>
        {({ translate }) => (
          <Segment style={{ backgroundColor: 'rgba(255, 255, 255, 0)' }} color={item.id < 0 ? 'green' : null}>
            {this.props.dataList.length !== 1 && (
              <Icon
                style={{ float: 'right' }}
                link
                color="red"
                name="delete"
                size="large"
                onClick={e => {
                  e.preventDefault();
                  this.props.removeItem(i);
                }}
              />
            )}
            <Form.Group style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Field>
                <label>
                  <Translate id="default.start" />
                </label>

                <TimeInput
                  closable
                  name={'startTime' + i}
                  placeholder={'default.start-time'}
                  value={item.startTime}
                  error={!itemValid.startTime}
                  onChange={this.validateDatetimeChange}
                />
              </Form.Field>
              <Form.Field>
                <label>End</label>

                <TimeInput
                  closable
                  name={'endTime' + i}
                  placeholder={'default.end-time'}
                  value={item.endTime}
                  error={!itemValid.endTime}
                  onChange={this.validateDatetimeChange}
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  label={'schedule.time-interval'}
                  type="text"
                  name="description"
                  placeholder=""
                  onChange={(_e, d) => this.validateInterval(d, i)}
                  value={item.minutesBetweenSlots}
                  error={!itemValid.minutesBetweenSlots}
                />
              </Form.Field>
            </Form.Group>
          </Segment>
        )}
      </Translate>
    );
  }
}

class PricePeriodListComponent extends React.Component {
  validate = (dataList, dataListValid) => {
    dataList.forEach((x, i) => {
      this.validateEndDatetime(dataList, dataListValid, i);
    });
  };

  allValid = (dataList, dataListValid) => {
    return dataList.length > 0 && dataListValid.every(x => this.validItem(x));
  };

  validItem = item => {
    return item.startDate && item.endDate && item.priceWeekSetup;
  };

  emptyValidationItem = () => {
    return { startDate: true, endDate: true };
  };

  validateEndDatetime = (dataList, dataListValid, i) => {
    dataListValid[i].endDate = dataList[i].endDate >= dataList[i].startDate;
    dataListValid[i].startDate = isValidDate(dataList[i].startDate);
    dataListValid[i].endDate = dataListValid[i].endDate && isValidDate(dataList[i].endDate);
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <EditableSubListComponent
            data={this.props.data}
            itemName={translate('club-admin.price-period')}
            addHelpText={translate('schedule.price-period-info')}
            stateUpdated={this.props.stateUpdated}
            error={this.props.error}
            validate={this.validate}
            emptyItem={pricePeriodEmpty}
            emptyValidationItem={this.emptyValidationItem}
            allValid={this.allValid}
            subComponentListName="priceWeekSetups"
            subComponent={
              <PricePeriodComponent products={this.props.products} validateEndDatetime={this.validateEndDatetime} />
            }
          />
        )}
      </Translate>
    );
  }
}

class PricePeriodComponent extends React.Component {
  validateDatetimeChange = (_e, { name, value }) => {
    const { dataList, dataListValid } = this.props;
    const propNameLength = name.startsWith('start') ? 9 : 7;
    const i = name.substring(propNameLength);
    const prop = name.substring(0, propNameLength);
    dataList[i][prop] = value;
    this.props.validateEndDatetime(dataList, dataListValid, i);
    this.props.updateState(dataList, dataListValid);
  };

  render() {
    const i = this.props.index;
    const item = this.props.dataList[i];
    const itemValid = this.props.dataListValid[i];
    return (
      <Translate>
        {({ translate }) => (
          <Segment color={item.id < 0 ? 'green' : null}>
            {this.props.dataList.length !== 1 && (
              <Icon
                style={{ float: 'right' }}
                link
                color="red"
                name="delete"
                size="large"
                onClick={e => {
                  e.preventDefault();
                  this.props.removeItem(i);
                }}
              />
            )}
            <Form.Group style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Field>
                <label>
                  <Translate id="default.start" />
                </label>
                <DateInput
                  closable
                  dateFormat="YYYY-MM-DD"
                  name={'startDate' + i}
                  placeholder={translate('default.start-date')}
                  value={item.startDate}
                  error={!itemValid.startDate}
                  onChange={this.validateDatetimeChange}
                />
              </Form.Field>
              <Form.Field>
                <label>End</label>
                <DateInput
                  closable
                  dateFormat="YYYY-MM-DD"
                  minDate={item.startDate}
                  name={'endDate' + i}
                  placeholder={translate('default.end-date')}
                  value={item.endDate}
                  error={!itemValid.endDate}
                  onChange={this.validateDatetimeChange}
                />
              </Form.Field>
            </Form.Group>
            <PriceWeekSetupListComponent
              products={this.props.products}
              data={item.priceWeekSetups}
              stateUpdated={(dataList, dataListValid) => this.props.stateUpdated(dataList, dataListValid, i)}
            />
          </Segment>
        )}
      </Translate>
    );
  }
}

class PriceWeekSetupListComponent extends React.Component {
  validate = (dataList, dataListValid) => {
    dataList.forEach((x, i) => {
      dataListValid[i].daysOfWeek = dataList[i].daysOfWeek.length > 0;
    });
  };

  allValid = (dataList, dataListValid) => {
    return dataList.length > 0 && dataListValid.every(x => this.validItem(x));
  };

  validItem = item => {
    return item.daysOfWeek && item.priceDayTimespans;
  };

  emptyValidationItem = () => {
    return {};
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <EditableSubListComponent
            data={this.props.data}
            listLabel="Week setup"
            itemName={translate('club-admin.week-setup')}
            addHelpText="If pricing is the same for every day of the week then you only need one week setup. But, as an example, if you have the same pricing on Monday-Friday and different pricing on the weekend, then you need two week setups: one for Monday-Friday and another for Saturday-Sunday."
            stateUpdated={this.props.stateUpdated}
            error={this.props.error}
            validate={this.validate}
            emptyItem={priceWeekSetupEmpty}
            emptyValidationItem={this.emptyValidationItem}
            allValid={this.allValid}
            subComponentListName="priceDayTimespans"
            subComponent={
              <WeekSetupComponent
                type="PriceDayTimespanListComponent"
                validate={this.validate}
                products={this.props.products}
              />
            }
          />
        )}
      </Translate>
    );
  }
}

class PriceDayTimespanListComponent extends React.Component {
  validate = (dataList, dataListValid) => {
    dataList.forEach((x, i) => {
      this.validateEndDatetime(dataList, dataListValid, i);
      x.productPrices.forEach(y => {
        this.validateProductPriceInternal(y.rackPrice, y.sellPrice, i, y.product, dataList, dataListValid);
      });
    });
  };

  allValid = (dataList, dataListValid) => {
    return dataList.length > 0 && dataListValid.every(x => this.validItem(x));
  };

  validItem = item => {
    return (
      item.startTime &&
      item.endTime &&
      item.products.length > 0 &&
      item.products.every(x => x.sellPrice === true && x.rackPrice === true)
    );
  };

  validateEndDatetime = (data, dataValid, i) => {
    dataValid[i].endTime = data[i].endTime >= data[i].startTime;
    dataValid[i].endTime = dataValid[i].endTime && isValidTime(data[i].endTime);
    dataValid[i].startTime = isValidTime(data[i].startTime);
  };

  validateProductPriceInternal = (rackPrice, sellPrice, i, product, dataList, dataListValid) => {
    const { productPrices } = dataList[i];
    const productPrice = productPrices.find(x => x.product.id === product.id);
    if (productPrice) {
      if (rackPrice !== null && rackPrice !== undefined) {
        productPrice.rackPrice = rackPrice;
        dataListValid[i].products[product.id].rackPrice = isPositiveInteger(rackPrice) || rackPrice === '';
      }
      if (sellPrice !== null && sellPrice !== undefined) {
        productPrice.sellPrice = sellPrice;
        dataListValid[i].products[product.id].sellPrice =
          isPositiveInteger(sellPrice) || (product.isZeroPrice && sellPrice === '');
      }
    }
  };

  emptyValidationItem = () => {
    const products = {};
    this.props.products.forEach(x => (products[x.id] = { rackPrice: true, sellPrice: true }));
    return { endTime: true, startTime: true, products };
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <EditableSubListComponent
            data={this.props.data}
            listLabel={translate('default.pricing')}
            itemName="price timespan"
            addHelpText={translate('schedule.price-info')}
            stateUpdated={this.props.stateUpdated}
            error={this.props.error}
            validate={this.validate}
            emptyItem={priceDayTimespanEmpty}
            emptyValidationItem={this.emptyValidationItem}
            allValid={this.allValid}
            subComponent={
              <PriceDayTimespanComponent
                products={this.props.products}
                validateEndDatetime={this.validateEndDatetime}
                validateProductPriceInternal={this.validateProductPriceInternal}
              />
            }
          />
        )}
      </Translate>
    );
  }
}

/**
 * dataList, dataListValid, index, updateState, removeItem (injected by EditableSubListComponent)
 * products, validateEndDateTime, validateProductPriceInternal
 */
class PriceDayTimespanComponent extends React.Component {
  validateDatetimeChange = data => {
    const { name, value } = data;
    const { dataList, dataListValid } = this.props;
    const propNameLength = name.startsWith('start') ? 9 : 7;
    const i = name.substring(propNameLength);
    const prop = name.substring(0, propNameLength);
    dataList[i][prop] = value;
    this.props.validateEndDatetime(dataList, dataListValid, i);
    this.props.updateState(dataList, dataListValid);
  };

  getProductRackPrice = (i, product) => {
    const { productPrices } = this.props.dataList[i];
    const productPrice = productPrices.find(x => x.product.id === product.id);
    return productPrice && productPrice.rackPrice ? productPrice.rackPrice : '';
  };

  getProductSellPrice = (i, product) => {
    const { productPrices } = this.props.dataList[i];
    const productPrice = productPrices.find(x => x.product.id === product.id);
    return productPrice && productPrice.sellPrice ? productPrice.sellPrice : '';
  };

  hasProductPrice = (i, product) => {
    const { productPrices } = this.props.dataList[i];
    const productPrice = productPrices.find(x => x.product.id === product.id);
    return productPrice ? true : false;
  };

  addRemoveProductPrice = ({ checked }, i, product) => {
    const { dataList, dataListValid } = this.props;
    const { productPrices } = dataList[i];
    if (checked) {
      const newPrice = { product, sellPrice: 0 };
      productPrices.push(newPrice);
    } else {
      const index = productPrices.findIndex(x => x.product.id === product.id);
      productPrices.splice(index, 1);
    }
    this.props.updateState(dataList, dataListValid);
  };

  validateProductPrice = ({ rackPrice, sellPrice }, i, product) => {
    const { dataList, dataListValid } = this.props;
    this.props.validateProductPriceInternal(rackPrice, sellPrice, i, product, dataList, dataListValid);
    this.props.updateState(dataList, dataListValid);
  };

  render() {
    const i = this.props.index;
    const item = this.props.dataList[i];
    const itemValid = this.props.dataListValid[i];
    return (
      <Translate>
        {({ translate }) => (
          <Segment style={{ backgroundColor: 'rgba(255, 255, 255, 0)' }} color={item.id < 0 ? 'green' : null}>
            {this.props.dataList.length !== 1 && (
              <Icon
                style={{ float: 'right' }}
                link
                color="red"
                name="delete"
                size="large"
                onClick={e => {
                  e.preventDefault();
                  this.props.removeItem(i);
                }}
              />
            )}
            <Form.Group style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Field>
                <label>
                  <Translate id="default.start" />
                </label>

                <TimeInput
                  closable
                  name={'startTime' + i}
                  placeholder={translate('default.start-time')}
                  value={item.startTime}
                  error={!itemValid.startTime}
                  onChange={(_e, d) => this.validateDatetimeChange(d)}
                />
              </Form.Field>
              <Form.Field>
                <label>
                  <Translate id="default.end" />
                </label>

                <TimeInput
                  closable
                  name={'endTime' + i}
                  placeholder={translate('default.end-time')}
                  value={item.endTime}
                  error={!itemValid.endTime}
                  onChange={(_e, d) => this.validateDatetimeChange(d)}
                />
              </Form.Field>
            </Form.Group>
            {this.props.products.map((product, j) => (
              <Form.Group inline key={j}>
                <Form.Checkbox
                  inline
                  label={product.name}
                  onChange={(_e, d) => this.addRemoveProductPrice(d, i, product)}
                  checked={this.hasProductPrice(i, product)}
                />
                {!product.isZeroPrice && (
                  <Form.Input
                    label={translate('default.rack-price')}
                    type="text"
                    name="rackPrice"
                    disabled={!this.hasProductPrice(i, product)}
                    placeholder={translate('default.rack-price')}
                    onChange={(_e, d) => this.validateProductPrice({ rackPrice: d.value }, i, product)}
                    value={this.getProductRackPrice(i, product)}
                    error={!itemValid.products[product.id].rackPrice}
                  />
                )}
                {!product.isZeroPrice && (
                  <Form.Input
                    label={translate('default.sale-time')}
                    type="text"
                    name="sellPrice"
                    disabled={!this.hasProductPrice(i, product)}
                    placeholder={translate('default.sale-time')}
                    onChange={(_e, d) => this.validateProductPrice({ sellPrice: d.value }, i, product)}
                    value={this.getProductSellPrice(i, product)}
                    error={!itemValid.products[product.id].sellPrice}
                  />
                )}
              </Form.Group>
            ))}
          </Segment>
        )}
      </Translate>
    );
  }
}

export {
  TeetimeScheduleViewItem,
  TeetimeScheduleViewTitleItem,
  TeetimeScheduleViewContentItem,
  TeetimeScheduleEditItem
};
