import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Translate, withLocalize } from 'react-localize-redux';

import { Container, Menu } from 'semantic-ui-react';
import { isClubAdmin, isSuperAdmin } from '../../utils';

class Navbar extends React.Component {
  render() {
    const { location, translate } = this.props;
    const { pathname } = location;

    return (
      <Menu pointing secondary stackable size="small" fixed="top">
        <Container>
          <Menu.Menu position="left">
            <Menu.Item header>
              <Translate id="AppName" />
            </Menu.Item>
          </Menu.Menu>
          <Menu.Menu position="right">
            {this.props.login.isAuthenticated && (
              <Menu.Item
                content={translate('menu.accommodations')}
                name="home"
                as={Link}
                to="/"
                active={['/', '/home'].includes(pathname)}
              />
            )}
            {this.props.login.isAuthenticated && isSuperAdmin(this.props) && (
              <Menu.Item
                content={translate('menu.accommodations')}
                name="accommodations"
                as={Link}
                to="/accommodations"
                active={pathname === '/accommodations'}
              />
            )}
            {this.props.login.isAuthenticated && isClubAdmin(this.props) && (
              <Menu.Item
                content={translate('menu.rental-units')}
                name="rentalUnits"
                as={Link}
                to="/rentalUnits"
                active={pathname === '/rentalUnits'}
              />
            )}
            {this.props.login.isAuthenticated && isClubAdmin(this.props) && (
              <Menu.Item
                content={translate('menu.settings')}
                name="config"
                as={Link}
                to="/config"
                active={pathname === '/config'}
              />
            )}
            {this.props.login.isAuthenticated && isSuperAdmin(this.props) && (
              <Menu.Item
                content={translate('menu.settings-inhouse')}
                name="In-house config"
                as={Link}
                to="/inhouse-config"
                active={pathname === '/inhouse-config'}
              />
            )}
            {this.props.login.isAuthenticated && isClubAdmin(this.props) && (
              <Menu.Item
                content={translate('club-admin.reports')}
                name="reports"
                as={Link}
                to="/reports"
                active={pathname === '/reports'}
              />
            )}

            {/*            <Menu.Item
              name="about"
              as={Link}
              to="/about"
              active={pathname === '/about'}
            />*/}

            {/* <Menu.Item
              content={translate("menu.terms")}
              name="terms"
              as={Link}
              to="/terms"
              active={pathname === "/terms"}
            /> */}

            {this.props.login.isAuthenticated ? (
              <Menu.Item
                content={translate('menu.sign-out')}
                name="sign-out"
                as={Link}
                to="/sign-out"
                active={pathname === '/sign-out'}
              />
            ) : (
              <Menu.Item
                content={translate('menu.sign-in')}
                name="sign-in"
                as={Link}
                to="/sign-in"
                active={pathname === '/sign-in'}
              />
            )}
          </Menu.Menu>
        </Container>
      </Menu>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login
  };
}

export default withLocalize(withRouter(connect(mapStateToProps, null)(Navbar)));
