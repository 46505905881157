import React, { useEffect, useRef } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Translate, withLocalize } from 'react-localize-redux';
import { Button, Form, Modal } from 'semantic-ui-react';
import { ErrorMessage } from '../ErrorMessage';

const RentalUnitEdit = ({
  rentalUnit,
  priceCategories,
  rentalUnitTypes,
  onClickSave,
  onClickCancel,
  // onChange,
  errorMessage,
  loading,
  translate
}) => {
  const { register, handleSubmit, errors, control, setValue } = useForm({
    submitFocusError: true,
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const currentRentalUnitTypeId = useWatch({
    control,
    name: 'rentalUnitTypeId',
    defaultValue: rentalUnit.type.id
  });

  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      setValue('priceCategoryId', null);
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRentalUnitTypeId]);

  const onSubmit = data => {
    onClickSave({
      ...rentalUnit,
      ...data
    });
  };

  return (
    <>
      <Modal open>
        <Modal.Header>{rentalUnit.id ? `Uppdatera enhet ${rentalUnit.name}` : 'Lägg till ny enhet'}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Field error={!!errors.name}>
                <label>
                  <Translate id="accommodation.name" />
                </label>
                <input
                  type="text"
                  name="name"
                  defaultValue={rentalUnit.name}
                  placeholder={translate('accommodation.name')}
                  ref={register({ required: true, minLength: 4 })}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field error={!!errors.accommodationTypeId}>
                <label>
                  <Translate id="accommodation.type" />
                </label>
                <Controller
                  name="rentalUnitTypeId"
                  control={control}
                  defaultValue={rentalUnit.type.id || null}
                  rules={{ required: true }}
                  render={({ onChange, value: nValue }) => (
                    <Form.Select
                      fluid
                      placeholder={translate('accommodation.type')}
                      options={rentalUnitTypes}
                      value={nValue}
                      onChange={(_e, data) => onChange(data.value)}
                    />
                  )}
                />
              </Form.Field>

              <Form.Field error={!!errors.priceCategoryId}>
                <label>
                  <Translate id="accommodation.category" />
                </label>
                <Controller
                  name="priceCategoryId"
                  control={control}
                  defaultValue={rentalUnit.priceCategory?.id || null}
                  rules={{ required: true }}
                  render={({ onChange, value: nValue }) => (
                    <Form.Select
                      fluid
                      placeholder={translate('accommodation.category')}
                      options={priceCategories.filter(p => p.accommodationtypeid === currentRentalUnitTypeId)}
                      value={nValue}
                      onChange={(_e, data) => onChange(data.value)}
                    />
                  )}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field error={!!errors.allowedBookingsPerDay}>
                <label>
                  <Translate id="rental-units.max-day-bookings" />
                </label>
                <Controller
                  name="allowedBookingsPerDay"
                  control={control}
                  defaultValue={rentalUnit.attributes?.find(a => a.key === 'AllowedBookingsPerDay')?.value || null}
                  rules={{ required: true }}
                  render={({ onChange, value: nValue }) => (
                    <Form.Select
                      fluid
                      placeholder={translate('rental-units.max-day-bookings')}
                      options={[
                        { value: '1', text: 1 },
                        { value: '2', text: 2 }
                      ]}
                      value={nValue}
                      onChange={(_e, data) => onChange(data.value)}
                    />
                  )}
                />
              </Form.Field>
            </Form.Group>

            {/* <Form.Group widths="equal">
              <Form.Field error={!!errors.info}>
                <label>
                  <Translate id="accommodation.info" />
                </label>
                <textarea
                  type="text"
                  name="info"
                  defaultValue={rentalUnit.info}
                  placeholder={translate('accommodation.info')}
                  ref={register()}
                />
              </Form.Field>
            </Form.Group> */}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <ErrorMessage error={errorMessage} />
          <Button color="green" size="small" onClick={handleSubmit(onSubmit)} loading={loading}>
            <Translate id="default.save" />
          </Button>
          <Button size="small" onClick={onClickCancel} disabled={loading}>
            <Translate id="default.cancel" />
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default withLocalize(RentalUnitEdit);
