import React from 'react';
import { Translate } from 'react-localize-redux';

import { Segment, Table, Grid, Divider } from 'semantic-ui-react';
import { CSVLink } from 'react-csv';
import { daysBetween, isValidDate, startOfMonth, endOfMonth, todaysDate } from '../../../utils';

import { accommodationBookingService, rentalUnitBookingService } from '../../../core/services';
import { ReportDateComponent } from '../ReportDateComponent';

export class SummationPeriodReportTab extends React.Component {
  state = {
    startDate: startOfMonth(todaysDate()),
    startDateValid: true,
    endDate: endOfMonth(todaysDate()),
    endDateValid: true,
    loading: false,
    accommodationBookingSummary: [],
    rentalUnitBookingSummary: []
  };

  accommodationSummaryHeaders = [
    { label: this.props.translate('club-admin.category'), key: 'priceCategoryName' },
    { label: this.props.translate('club-admin.no-of-bookings'), key: 'bookingsCount' },
    { label: this.props.translate('club-admin.no-of-nights'), key: 'numberOfNights' },
    { label: this.props.translate('club-admin.average-nights'), key: 'averageNights' },
    { label: this.props.translate('club-admin.total-income'), key: 'totalIncome' },
    { label: this.props.translate('club-admin.total-commission'), key: 'totalCommission' }
  ];

  rentalUnitSummaryHeaders = [
    { label: this.props.translate('club-admin.category'), key: 'priceCategoryName' },
    { label: this.props.translate('club-admin.total-income'), key: 'totalIncome' },
    { label: this.props.translate('club-admin.total-commission'), key: 'totalCommission' }
  ];

  validateDatetimeChange = (_e, { name, value }) => {
    let { startDate, endDate, startDateValid, endDateValid } = this.state;

    if (name === 'startDate') {
      startDate = value;
      startDateValid = isValidDate(startDate);
      if (startDateValid) {
        endDate = endOfMonth(startDate);
      }
    } else {
      endDate = value;
    }
    endDateValid = isValidDate(endDate) && endDate >= startDate;
    this.setState({ startDate, endDate, startDateValid, endDateValid });
  };

  getGolfClubId = () => {
    return this.props.golfClubs && this.props.golfClubs.length > 0 ? this.props.golfClubs[0].id : 0;
  };

  getGolfClubCurrency = () => {
    return this.props.golfClubs && this.props.golfClubs.length > 0 ? this.props.golfClubs[0].currency : 'N/A';
  };

  groupBy = (list, keyGetter) => {
    const map = new Map();
    list.forEach(item => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  };

  groupAccommodationBookings = bookings => {
    const map = this.groupBy(bookings, b => `${b.accommodation.priceCategory.name}`);
    return Array.from(map, ([key, value]) => value);
  };

  groupRentalUnitBookings = bookings => {
    const map = this.groupBy(bookings, b => `${b.rentalUnit.priceCategory.name}`);
    return Array.from(map, ([key, value]) => value);
  };

  fetchBookings = async () => {
    const { startDate, endDate, startDateValid, endDateValid } = this.state;
    if (startDateValid && endDateValid) {
      const golfClubId = this.getGolfClubId();

      this.setState({ loading: true });
      try {
        const accommodationBookingResult = await accommodationBookingService.getAll(golfClubId, startDate, endDate);
        const rentalUnitBookingResult = await rentalUnitBookingService.getAll(golfClubId, startDate, endDate);

        const accommodationBookings = this.groupAccommodationBookings(accommodationBookingResult.data);
        const rentalUnitBookings = this.groupRentalUnitBookings(rentalUnitBookingResult.data);

        const accommodationBookingSummary = this.getAccommodationBookingsSummary(accommodationBookings);
        const rentalUnitBookingSummary = this.getRentalUnitBookingsSummary(rentalUnitBookings);

        this.setState({
          loading: false,
          accommodationBookingSummary,
          rentalUnitBookingSummary
        });
      } catch (error) {
        this.setState({ loading: false });
      }
    }
  };

  getAccommodationBookingsSummary = accommodationBookings => {
    const accommodationBookingSummary = [];

    for (const accommodationBooking of accommodationBookings) {
      const { bookingsCount, numberOfNights, totalIncome, totalCommission } = accommodationBooking.reduce(
        (acc, item) => {
          acc.bookingsCount++;
          acc.numberOfNights += daysBetween(item.checkInDate, item.checkOutDate);
          acc.totalIncome += item.priceInclVat;
          acc.totalCommission += item.commission;
          return acc;
        },
        { bookingsCount: 0, numberOfNights: 0, totalIncome: 0, totalCommission: 0 }
      );

      const numberOfBookings = accommodationBooking.length;

      // review calculation
      const averageNights = Math.round((numberOfNights * 10) / numberOfBookings) / 10;

      accommodationBookingSummary.push({
        priceCategoryName: this.props.translate(
          `accommodation.${accommodationBooking[0].accommodation.priceCategory.name}`
        ),
        bookingsCount,
        numberOfNights,
        averageNights,
        totalIncome,
        totalCommission
      });
    }
    return accommodationBookingSummary;
  };

  getRentalUnitBookingsSummary = rentalUnitBookings => {
    const rentalUnitBookingSummary = [];

    for (const rentalUnitBooking of rentalUnitBookings) {
      const { totalIncome, totalCommission } = rentalUnitBooking.reduce(
        (acc, item) => {
          acc.totalIncome += item.priceInclVat;
          acc.totalCommission += item.commission;
          return acc;
        },
        { totalIncome: 0, totalCommission: 0 }
      );

      rentalUnitBookingSummary.push({
        priceCategoryName: this.props.translate(`accommodation.${rentalUnitBooking[0].rentalUnit.priceCategory.name}`),
        totalIncome,
        totalCommission
      });
    }
    return rentalUnitBookingSummary;
  };

  render() {
    const {
      startDate,
      endDate,
      startDateValid,
      endDateValid,
      loading,
      accommodationBookingSummary,
      rentalUnitBookingSummary
    } = this.state;

    const accommodationSummaryCsvReport = {
      data: accommodationBookingSummary,
      headers: this.accommodationSummaryHeaders,
      filename: `${startDate} to ${endDate} ${this.props.translate('club-admin.accommodation-summation')}.csv`,
      separator: ';'
    };
    const rentalUnitSummaryCsvReport = {
      data: rentalUnitBookingSummary,
      headers: this.rentalUnitSummaryHeaders,
      filename: `${startDate} to ${endDate} ${this.props.translate('club-admin.rental-unit-summation')}.csv`,
      separator: ';'
    };

    const golfClubCurrency = this.getGolfClubCurrency();

    if (this.props.active) {
      return (
        <Translate>
          {({ translate }) => (
            <Segment>
              <div className="p-1">
                <ReportDateComponent
                  startDate={startDate}
                  startDateValid={startDateValid}
                  endDate={endDate}
                  endDateValid={endDateValid}
                  loading={loading}
                  fetchBookings={this.fetchBookings}
                  validateDatetimeChange={this.validateDatetimeChange}
                />

                <Grid>
                  <Grid.Column floated="left" width="5">
                    <Translate id="club-admin.accommodations" />
                  </Grid.Column>
                  {accommodationBookingSummary?.length > 0 && (
                    <Grid.Column floated="right" width="5" textAlign="right">
                      <CSVLink {...accommodationSummaryCsvReport}>{translate('club-admin.export-to-csv')}</CSVLink>
                    </Grid.Column>
                  )}
                </Grid>
                <Segment loading={loading}>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          <Translate id="club-admin.category" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="club-admin.no-of-bookings" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="club-admin.no-of-nights" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="club-admin.average-nights" />
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          <Translate id="club-admin.total-income" />
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          <Translate id="club-admin.total-commission" />
                        </Table.HeaderCell>
                        {/* <Table.HeaderCell>
                            <Translate id="club-admin.of-which-vat" />
                          </Table.HeaderCell> */}
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {accommodationBookingSummary?.map((booking, i) => {
                        return (
                          <Table.Row key={i}>
                            <Table.Cell>{booking.priceCategoryName}</Table.Cell>
                            <Table.Cell>{booking.bookingsCount}</Table.Cell>
                            <Table.Cell>{booking.numberOfNights}</Table.Cell>
                            <Table.Cell>{booking.averageNights}</Table.Cell>
                            <Table.Cell textAlign="right">{`${booking.totalIncome
                              .toFixed(2)
                              .replace('.', ',')} ${golfClubCurrency}`}</Table.Cell>
                            <Table.Cell textAlign="right">{`${booking.totalCommission
                              .toFixed(2)
                              .replace('.', ',')} ${golfClubCurrency}`}</Table.Cell>
                            {/* <Table.Cell>{`${totalVat} ${golfClubCurrency}`}</Table.Cell> */}
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                </Segment>

                <Divider style={{ margin: '30px' }} />

                <Grid>
                  <Grid.Column floated="left" width="5">
                    <Translate id="club-admin.rental-units" />
                  </Grid.Column>
                  {rentalUnitBookingSummary?.length > 0 && (
                    <Grid.Column floated="right" width="5" textAlign="right">
                      <CSVLink {...rentalUnitSummaryCsvReport}>{translate('club-admin.export-to-csv')}</CSVLink>
                    </Grid.Column>
                  )}
                </Grid>
                <Segment loading={loading}>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          <Translate id="club-admin.category" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="club-admin.total-income" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="club-admin.total-commission" />
                        </Table.HeaderCell>
                        {/* <Table.HeaderCell>
                            <Translate id="club-admin.of-which-vat" />
                          </Table.HeaderCell> */}
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {rentalUnitBookingSummary?.map((booking, i) => {
                        return (
                          <Table.Row key={i}>
                            <Table.Cell>{booking.priceCategoryName}</Table.Cell>
                            <Table.Cell>{`${booking.totalIncome} ${golfClubCurrency}`}</Table.Cell>
                            <Table.Cell>{`${booking.totalCommission} ${golfClubCurrency}`}</Table.Cell>
                            {/* <Table.Cell>{`${totalVat} ${golfClubCurrency}`}</Table.Cell> */}
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                </Segment>
              </div>
            </Segment>
          )}
        </Translate>
      );
    }
    return null;
  }
}
