import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { Button, Grid, Icon, Segment, Table } from 'semantic-ui-react';
import { deleteProductAddon, getProductAddons, IProductAddon } from '../../core/services/product.service';
import { getGolfClubs } from '../../containers/AccommodationSheet/ducks';
import { IGolfClubDetailItem } from '../../core/services/golfclub.service';
import ProductAddonListItem from './ProductAddonListItem';
import ProductAddonEdit from './ProductAddonEdit';
import { ProductAddonChargeType } from '../../core/enums/product-addon-charge-type.enum';
import { endOfYear, startOfYear, todaysDate } from '../../utils';

export interface ProductAddonModalProps extends LocalizeContextProps {
  golfClubs: any[];
}

let golfclub: IGolfClubDetailItem | null = null;

function ProductAddonsSettingsList(props: ProductAddonModalProps) {
  const [addon, setAddon] = useState<IProductAddon | null>(null);
  const [apiLoading, setApiLoading] = useState(false);
  const [productAddons, setProductAddons] = useState<IProductAddon[]>([]);

  useEffect(() => {
    [golfclub] = props.golfClubs;

    initializeData();
  }, [props.golfClubs]);

  const initializeData = async () => {
    setApiLoading(true);

    const data = await getProductAddons();
    setProductAddons(data);

    setApiLoading(false);
  };

  const onEditClick = (editAddon: IProductAddon) => {
    setAddon(editAddon);
  };

  const onDeleteClick = async (deleteAddon: IProductAddon) => {
    setApiLoading(true);

    try {
      await deleteProductAddon(deleteAddon.id);

      const data = await getProductAddons();
      setProductAddons(data);
    } finally {
      setApiLoading(false);
    }
  };

  const onAddClick = () => {
    if (golfclub) {
      setAddon({
        id: -1,
        golfClubId: golfclub.id,
        name: '',
        description: '',
        chargeAmount: 0,
        chargeType: ProductAddonChargeType.PerBooking,
        rentalUnitTypeIds: [],
        vatRate: 25,
        validFrom: startOfYear(todaysDate()),
        validTo: endOfYear(todaysDate())
      });
    }
  };

  const onAddonSaved = (addons: IProductAddon[]) => {
    setProductAddons(addons);
    setAddon(null);
  };

  return (
    <Segment loading={apiLoading}>
      <Grid colums={2}>
        <Grid.Row>
          <Grid.Column width={8} textAlign="right">
            &nbsp;
          </Grid.Column>
          <Grid.Column width={8} textAlign="right">
            <Button color="green" onClick={onAddClick}>
              <Icon name="add" />
              <Translate id="discount.add" />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <Translate id="default.type" />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              <Translate id="product-addons.headers.price" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Translate id="product-addons.headers.charged" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Translate id="product-addons.valid-from" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Translate id="product-addons.valid-to" />
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {productAddons?.map((addonItem: IProductAddon) => (
            <ProductAddonListItem
              addon={addonItem}
              currency={golfclub?.currency}
              key={addonItem.id}
              onDeleteClick={onDeleteClick}
              onEditClick={onEditClick}
            />
          ))}
        </Table.Body>
      </Table>

      {addon && (
        <ProductAddonEdit
          addon={addon}
          currency={golfclub?.currency || 'SEK'}
          onCancelClick={() => setAddon(null)}
          onSaved={onAddonSaved}
        />
      )}
    </Segment>
  );
}

function mapStateToProps(state: any) {
  return {
    golfClubs: getGolfClubs(state)
  };
}

export default withLocalize(connect(mapStateToProps, null)(ProductAddonsSettingsList));
