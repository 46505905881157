import React, { useEffect, useState } from 'react';

import { Translate } from 'react-localize-redux';
import { Form, Button, Modal, Input, Message } from 'semantic-ui-react';
import { DiscountTypes } from '../../core/enums';
import { todaysDate } from '../../utils';
import { fetchDiscountByCode, fetchDiscounts } from '../../utils/api';

export interface IDiscount {
  active: boolean;
  amount: number;
  applyEnd: Date;
  applyStart: Date;
  canBeCombined: boolean;
  code: string;
  discountType: number;
  golfClubId: number;
  id: number;
  info: string;
  isSecret: boolean;
  name: string;
  publishEnd: Date;
  publishStart: Date;
  rentalUnitTypeIds: number[];
  validity: number;
}

function DiscountModal({
  checkinDate,
  createdDate,
  golfClubCurrency,
  golfClubId,
  isAdminAccess,
  rentalUnitTypeId,
  show,
  translate,
  onCloseDiscountModal,
  onSelect
}: {
  checkinDate: Date;
  createdDate: Date;
  golfClubCurrency: string;
  golfClubId: number;
  isAdminAccess: boolean;
  rentalUnitTypeId: number;
  show: boolean;
  translate: any;
  onCloseDiscountModal: () => void;
  onSelect: (discount: IDiscount) => void;
}) {
  const [activeRequest, setActiveRequest] = useState<boolean>(false);
  const [discountCode, setDiscountCode] = useState<string>('');
  const [discounts, setDiscounts] = useState<IDiscount[]>([]);
  const [discountError, setDiscountError] = useState<string>('');
  const [discountId, setDiscountId] = useState<any>(null);

  useEffect(() => {
    /**
     * Checks if the specified discount is valid for specified check in date and rental unit type id.
     * @param {Date} checkin The date of check in.
     * @param {number} rentalUnitType The rental unit type id that the discount should be valid for.
     * @param {*} discount The discount to check.
     * @returns True if the discount is valid for specified check in date.
     */
    const isValidActiveDiscount = (
      checkin: Date,
      createdAt: Date | undefined,
      rentalUnitType: number,
      discount: IDiscount
    ) => {
      const created = createdAt ? new Date(createdAt) : new Date();
      const check = new Date(checkin);

      return (
        discount.active &&
        new Date(discount.applyStart) <= check &&
        new Date(discount.applyEnd) >= check && // check against check in date
        new Date(discount.publishStart) <= created &&
        new Date(discount.publishEnd) >= created && // check against the date of creation
        (discount.rentalUnitTypeIds.includes(rentalUnitType) || discount.rentalUnitTypeIds.length === 0)
      );
    };

    const getDiscounts = async () => {
      setActiveRequest(true);

      const result = await fetchDiscounts();
      if ('data' in result) {
        const activeAndValidDiscounts = result.data.filter((x: IDiscount) =>
          isValidActiveDiscount(checkinDate, createdDate, rentalUnitTypeId, x)
        );

        setDiscounts(activeAndValidDiscounts);
      }

      setActiveRequest(false);
    };

    if (isAdminAccess && show) {
      getDiscounts();
    }
  }, [checkinDate, createdDate, isAdminAccess, rentalUnitTypeId, show]);

  const setDiscount = async (e: any) => {
    e.preventDefault(); // Important since form in widget otherwise submits a booking

    if (isAdminAccess && discountId) {
      const discount = discounts.find(x => x.id === discountId);
      if (discount) {
        setDiscountError('');
        setDiscountCode('');

        onSelect(discount);
      }
    } else if (!isAdminAccess && discountCode.trim()) {
      setActiveRequest(true);
      setDiscountError('');

      const result = await fetchDiscountByCode(
        golfClubId,
        discountCode,
        rentalUnitTypeId,
        checkinDate,
        createdDate || todaysDate()
      );

      if ('error' in result) {
        const resError = result.error as any;
        setDiscountError(resError.error);
      } else if ('data' in result && 'code' in result) {
        if (result.code === 204) {
          setDiscountError(translate('discount.invalid-code'));
        } else {
          const discount = result.data;
          onSelect(discount);
        }
      }

      setActiveRequest(false);
    }
  };

  return (
    <Modal centered={true} className="modal-compact" open={show} size="tiny">
      <Modal.Header>
        <Translate id="discount.add-discount-code" />
      </Modal.Header>
      <Modal.Content>
        {isAdminAccess ? (
          <Form.Select
            name="discount"
            placeholder={translate('discount.select-a-discount')}
            onChange={(_e, data) => setDiscountId(data.value)}
            options={discounts?.map(x => ({
              text: `${x.name && `${x.name} - `} ${x.code} - ${x.amount}${
                x.discountType === DiscountTypes.PERCENT ? '%' : ` ${golfClubCurrency}`
              }`,
              value: x.id
            }))}
            fluid
            width={'16'}
          />
        ) : (
          <>
            <Form.Field
              control={Input}
              type="text"
              name="code"
              fluid
              placeholder={translate('discount.enter-discount-code')}
              onChange={(_e: React.ChangeEvent<HTMLInputElement>) => setDiscountCode(_e.target.value)}
              error={!!discountError}
              value={discountCode}
            />
            {!!discountError && (
              <Message visible error>
                {discountError}
              </Message>
            )}
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={setDiscount} loading={activeRequest}>
          <Translate id="discount.add" />
        </Button>
        <Button
          color="black"
          onClick={() => {
            setDiscountError('');
            setDiscountCode('');
            onCloseDiscountModal();
          }}
        >
          <Translate id="default.close" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default DiscountModal;
