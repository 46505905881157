import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { DateInput } from 'semantic-ui-calendar-react';
import { Button, Checkbox, DropdownItemProps, Form, Input, Label, List, ListItem, Modal } from 'semantic-ui-react';
import { ProductAddonChargeType } from '../../core/enums/product-addon-charge-type.enum';
import { ProductAddonTypes } from '../../core/enums/product-addon-types.enum';
import { createOrUpdateProductAddon, IProductAddon } from '../../core/services/product.service';
import { ErrorMessage } from '../ErrorMessage';
import { RentalUnitType } from '../../core/constants';

const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

export interface ProductAddonEditProps extends LocalizeContextProps {
  addon: IProductAddon;
  currency: string;
  onCancelClick: () => void;
  onSaved: (addonsList: IProductAddon[]) => void;
}

let chargeTypes: DropdownItemProps[] = [];
let productAddonTypes: DropdownItemProps[] = [];

function ProductAddonEdit(props: ProductAddonEditProps) {
  const { addon, currency, onCancelClick, onSaved, translate } = props;

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const accommodationTypes: DropdownItemProps[] = [
    { text: translate('accommodation.Camping'), value: RentalUnitType.CAMPING.id },
    { text: translate('accommodation.Lodge'), value: RentalUnitType.LODGE.id },
    { text: translate('accommodation.Rooms'), value: RentalUnitType.ROOMS.id }
  ];

  if (chargeTypes.length === 0) {
    chargeTypes = Object.entries(ProductAddonChargeType).map(([key, value]) => ({
      text: translate(`product-addons.charge-types.${key}`),
      value
    }));

    // need just the second half of the array where the texts are
    chargeTypes = chargeTypes.slice(chargeTypes.length / 2);
  }

  if (productAddonTypes.length === 0) {
    productAddonTypes = Object.entries(ProductAddonTypes).map(([key]) => ({
      text: translate(`product-addons.type-names.${key}`),
      value: key
    }));

    productAddonTypes = productAddonTypes.slice(productAddonTypes.length / 2);
  }

  const { register, handleSubmit, errors, control } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const onSubmit = async (data: any) => {
    setLoading(true);

    try {
      const dataToSave = {
        ...addon,
        ...data
      };

      const addons = await createOrUpdateProductAddon(dataToSave);
      onSaved(addons);
    } catch (e) {
      if (typeof e === 'string') {
        setErrorMessage(e);
      } else if (e instanceof Error) {
        setErrorMessage(e.message); // works, `e` narrowed to Error
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open className="position-unset">
      <Modal.Header>
        {addon.id !== -1 ? translate('product-addons.update-modal-title') : translate('product-addons.add-modal-title')}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <List>
            <ListItem>
              <Form.Group widths="equal">
                <Form.Field error={!!errors.name}>
                  <label>
                    <Translate id="default.type" />
                  </label>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue={addon.name}
                    rules={{ required: true }}
                    render={({ onChange, value: nValue }) => (
                      <Form.Select
                        fluid
                        placeholder={translate('default.type').toString()}
                        options={productAddonTypes}
                        value={nValue}
                        onChange={(_e, data) => onChange(data.value)}
                      />
                    )}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field error={!!errors.description}>
                  <label>
                    <Translate id="default.description" />
                  </label>
                  <input
                    type="text"
                    name="description"
                    defaultValue={addon.description}
                    placeholder={translate('default.description').toString()}
                    ref={register()}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field error={!!errors.chargeAmount}>
                  <label>
                    <Translate id="product-addons.headers.price" />
                  </label>
                  <Input labelPosition="right" type="text">
                    <input
                      type="number"
                      name="chargeAmount"
                      defaultValue={addon.chargeAmount}
                      placeholder={translate('product-addons.headers.price').toString()}
                      ref={register({ required: true, valueAsNumber: true })}
                    />
                    <Label>{currency}</Label>
                  </Input>
                </Form.Field>
                <Form.Field error={!!errors.chargeType}>
                  <label>
                    <Translate id="product-addons.headers.charged" />
                  </label>
                  <Controller
                    name="chargeType"
                    control={control}
                    defaultValue={addon.chargeType}
                    rules={{ required: true }}
                    render={({ onChange, value: nValue }) => (
                      <Form.Select
                        fluid
                        placeholder={translate('product-addons.headers.charged').toString()}
                        options={chargeTypes}
                        value={nValue}
                        onChange={(_e, data) => onChange(data.value)}
                      />
                    )}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field error={!!errors.rentalUnitTypeIds}>
                  <label>
                    <Translate id="product-addons.accommodation-types" />
                  </label>
                  <Controller
                    name="rentalUnitTypeIds"
                    control={control}
                    defaultValue={addon.rentalUnitTypeIds.map(item => item.rentalUnitTypeId)}
                    rules={{
                      validate: { oneSelected: data => data.length > 0 || 'At least one needs to be selected' }
                    }}
                    render={({ onChange, value: nValue }) => (
                      <Form.Select
                        clearable
                        multiple
                        placeholder={translate('product-addons.accommodation-types').toString()}
                        options={accommodationTypes}
                        value={nValue}
                        onChange={(_e, data) => onChange(data.value)}
                      />
                    )}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal" style={{ paddingLeft: 6, paddingTop: 10, paddingBottom: 10 }}>
                <Form.Field>
                  <Controller
                    name="isForPackagesOnly"
                    control={control}
                    defaultValue={addon.isForPackagesOnly}
                    render={({ onChange, value }) => (
                      <Checkbox
                        label={translate('product-addons.sold-packages-only')}
                        checked={value}
                        onChange={(_e, data) => onChange(data.checked)}
                      />
                    )}
                  />
                </Form.Field>
                <Form.Field error={!!errors.vatRate}>
                  <label>
                    <Translate id="default.vat-rate" />
                  </label>
                  <Input type="text" labelPosition="right">
                    <input
                      type="number"
                      name="vatRate"
                      defaultValue={addon.vatRate}
                      placeholder={translate('default.vat-rate').toString()}
                      ref={register({ required: true, valueAsNumber: true })}
                    />
                    <Label>%</Label>
                  </Input>
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field error={!!errors.validFrom}>
                  <label>
                    <Translate id="product-addons.valid-from" />
                  </label>
                  <Controller
                    name="validFrom"
                    control={control}
                    defaultValue={addon.validFrom.substring(0, 10)}
                    rules={{ required: true, pattern: dateRegex }}
                    render={({ onChange, value }) => (
                      <DateInput
                        closable
                        dateFormat="YYYY-MM-DD"
                        localization="sv"
                        placeholder={translate('product-addons.valid-from')}
                        value={value}
                        onChange={(_e, data) => onChange(data.value)}
                      />
                    )}
                  />
                </Form.Field>
                <Form.Field error={!!errors.validTo}>
                  <label>
                    <Translate id="product-addons.valid-to" />
                  </label>
                  <Controller
                    name="validTo"
                    control={control}
                    defaultValue={addon.validTo.substring(0, 10)}
                    rules={{ required: true, pattern: dateRegex }}
                    render={({ onChange, value }) => (
                      <DateInput
                        closable
                        dateFormat="YYYY-MM-DD"
                        localization="sv"
                        placeholder={translate('product-addons.valid-to')}
                        value={value}
                        onChange={(_e, data) => onChange(data.value)}
                      />
                    )}
                  />
                </Form.Field>
              </Form.Group>
            </ListItem>
          </List>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <ErrorMessage error={errorMessage} />
        <Button color="green" size="small" onClick={handleSubmit(onSubmit)} loading={loading} disabled={loading}>
          <Translate id="default.save" />
        </Button>
        <Button size="small" onClick={onCancelClick} disabled={loading}>
          <Translate id="default.cancel" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default withLocalize(ProductAddonEdit);
