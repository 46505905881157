import { escapeRegExp } from 'lodash-es';
import React from 'react';
import { Translate } from 'react-localize-redux';

import { Form, List, Label, Popup, Segment } from 'semantic-ui-react';
import { emailRegex } from '../utils';

const emptyIfNull = str => {
  return str || '';
};
class GolfClubPlayerViewItem extends React.Component {
  render() {
    const { player } = this.props.data;
    return (
      <>
        <Popup
          basic
          flowing
          trigger={
            <List>
              <List.Item>
                <Label size="medium">
                  <Translate id="default.name" />:
                  <Label.Detail>{`${player.firstName} ${player.lastName}`}</Label.Detail>
                </Label>
                <Label size="medium">
                  <Translate id="default.email" />:<Label.Detail>{player.email}</Label.Detail>
                </Label>
                <Label size="medium">
                  <Translate id="default.name" />
                  Club member:
                  <Label.Detail>{this.props.data.isClubMember ? 'Yes' : 'No'}</Label.Detail>
                </Label>
              </List.Item>
            </List>
          }
          content={
            <List>
              <List.Item>
                <Label size="medium">
                  <Translate id="default.name" />:
                  <Label.Detail>{`${player.firstName} ${player.lastName}`}</Label.Detail>
                </Label>
              </List.Item>
              <List.Item>
                <Label size="medium">
                  <Translate id="default.email" />:<Label.Detail>{player.email}</Label.Detail>
                </Label>
              </List.Item>
              <Label size="medium">
                <Translate id="default.club-member" />:
                <Label.Detail>{this.props.data.isClubMember ? 'Yes' : 'No'}</Label.Detail>
              </Label>
              <List.Item>
                <Label size="medium">
                  <Translate id="default.phone" />:<Label.Detail>{player.phoneNumber}</Label.Detail>
                </Label>
              </List.Item>
              <List.Item>
                <Label size="medium">
                  <Translate id="default.address" />
                  <Label.Detail>
                    <div>
                      {player.streetAddress1 && <p>{player.streetAddress1}</p>}
                      {player.streetAddress2 && <p>{player.streetAddress2}</p>}
                      {(player.postCode || player.city) && (
                        <p>{`${emptyIfNull(player.postCode)} ${emptyIfNull(player.city)}`}</p>
                      )}
                      {player.country && <p>{player.country}</p>}
                    </div>
                  </Label.Detail>
                </Label>
              </List.Item>
              <List.Item>
                <Label size="medium">
                  <Translate id="default.golf-id" />:<Label.Detail>{player.golfId}</Label.Detail>
                </Label>
              </List.Item>
              <List.Item>
                <Label size="medium">
                  <Translate id="default.handicap" />:<Label.Detail>{player.handicap}</Label.Detail>
                </Label>
              </List.Item>
            </List>
          }
        />
      </>
    );
  }
}
class GolfClubPlayerEditItem extends React.Component {
  state = {
    isClubMember: true,
    player: {
      id: null,
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      streetAddress1: '',
      streetAddress2: '',
      postCode: '',
      city: '',
      country: '',
      golfId: '',
      handicap: '',
      userSub: ''
    },
    valid: {
      all: true,
      firstName: true,
      lastName: true,
      email: true,
      phoneNumber: true,
      golfId: true,
      handicap: true,
      role: true
    }
  };

  componentDidMount = () => {
    const { data } = this.props;
    this.setState({ player: data.player, isClubMember: data.isClubMember });
    // if (!this.props.data) {
    //   return;
    // }
    // const { data } = this.props;
    // this.validate(data);
    // this.props.dataIsValid(this.state.valid.all);
  };

  componentDidUpdate = (_prevProps, prevState) => {
    const { valid } = this.state;
    if (prevState.valid.all !== valid.all) {
      this.props.dataIsValid(valid.all);
    }
  };

  saveItem = () => {
    const { player, isClubMember } = this.state;
    player.normalizedEmail = player.email.toUpperCase();
    const data = { player, isClubMember };
    data.golfClubId = this.props.golfClub.id;
    if (this.props.data && Object.prototype.hasOwnProperty.call(this.props.data.player, 'id')) {
      data.player.id = this.props.data.player.id;
    }
    if (this.props.data && Object.prototype.hasOwnProperty.call(this.props.data, 'id')) {
      data.id = this.props.data.id;
    }
    this.props.saveAction(data, this.props.onSubmit);
  };

  validate = data => {
    if (data && data.player) {
      this.validateName({ target: { name: 'firstName', value: data.player.firstName } });
      this.validateName({ target: { name: 'lastName', value: data.player.lastName } });
      this.validateEmail({ initial: true, target: { name: 'email', value: data.player.email } });
      // this.validatePhonenumber({target: {name: 'phoneNumber', value: data.player.phoneNumber}});
      // this.validateHandicap({target: {name: 'handicap', value: data.player.handicap}});
      // this.validateGolfId({target: {name: 'golfId', value: data.player.golfId}});
      this.setState({ player: data.player, isClubMember: data.isClubMember });
    }
  };

  validateField = (e, validator) => {
    const val = e.target.value;
    const field = e.target.name;
    const { player, valid } = this.state;

    player[field] = val;
    valid[field] = validator(val, e);
    valid.all =
      valid.firstName === true &&
      valid.lastName === true &&
      valid.email === true &&
      valid.golfId === true &&
      valid.phoneNumber === true &&
      valid.handicap === true;

    this.setState({
      player,
      valid
    });
    this.props.dataIsValid(valid.all);
  };

  validateName = e => this.validateField(e, val => val.length > 0);

  validateHandicap = e =>
    this.validateField(e, val => val === '' || (!isNaN(parseFloat(val)) && isFinite(val) && val < 100));

  validatePhonenumber = e => this.validateField(e, val => val === '' || /^\+?\d+-?\d+$/.test(val));

  validateGolfId = e => this.validateField(e, val => val === '' || /^\d{6}-\d{3}$/.test(val));

  validateEmail = e => {
    this.validateField(
      {
        target: {
          value:
            e.initial || !Object.prototype.hasOwnProperty.call(this.props.data.player, 'id')
              ? e.target.value
              : this.state.player.email,
          name: e.target.name
        }
      },
      val => emailRegex.test(val)
    );
  };

  validateGenericField = e => this.validateField(e, _val => true);

  validateClubMember = checked => {
    const { valid } = this.state;
    this.setState({ isClubMember: checked });
    this.props.dataIsValid(valid.all);
  };

  render() {
    const isNewItem = !Object.prototype.hasOwnProperty.call(this.props.data.player, 'id');
    return (
      <Translate>
        {({ translate }) => (
          <Form id={this.props.id} onSubmit={this.saveItem}>
            <List>
              <List.Item>
                <Form.Field>
                  <Form.Input
                    required
                    label={translate('default.firstname')}
                    type="text"
                    name="firstName"
                    placeholder={translate('default.firstname')}
                    onChange={this.validateName}
                    error={!this.state.valid.firstName}
                    value={this.state.player.firstName}
                  />
                  <Form.Input
                    required
                    label={translate('default.lastname')}
                    type="text"
                    name="lastName"
                    placeholder={translate('default.lastname')}
                    onChange={this.validateName}
                    error={!this.state.valid.lastName}
                    value={this.state.player.lastName}
                  />
                </Form.Field>
                <Popup
                  style={{ opacity: isNewItem ? '1' : '0' }}
                  trigger={
                    <Form.Input
                      required
                      label={translate('default.email')}
                      type="text"
                      name="email"
                      placeholder={translate('default.email')}
                      onChange={this.validateEmail}
                      error={!this.state.valid.email}
                      value={this.state.player.email}
                    />
                  }
                  content="Changing email address is not supported right now."
                />
                <Form.Field>
                  <Form.Checkbox
                    inline
                    label={translate('default.club-member')}
                    onChange={(e, { checked }) => {
                      this.validateClubMember(checked);
                    }}
                    checked={this.state.isClubMember}
                  />
                </Form.Field>
                <Form.Input
                  label={translate('default.phone')}
                  type="text"
                  name="phoneNumber"
                  placeholder={translate('default.phone')}
                  onChange={this.validatePhonenumber}
                  error={!this.state.valid.phoneNumber}
                  value={emptyIfNull(this.state.player.phoneNumber)}
                />
                <Form.Input
                  label={translate('default.address')}
                  type="text"
                  name="streetAddress1"
                  placeholder={translate('default.address')}
                  onChange={this.validateGenericField}
                  value={emptyIfNull(this.state.player.streetAddress1)}
                />
                <Form.Input
                  label={translate('default.address2')}
                  type="text"
                  name="streetAddress2"
                  placeholder={translate('default.address2')}
                  onChange={this.validateGenericField}
                  value={emptyIfNull(this.state.player.streetAddress2)}
                />
                <Form.Input
                  label={translate('default.post-code')}
                  type="text"
                  name="postCode"
                  placeholder={translate('default.post-code')}
                  onChange={this.validateGenericField}
                  value={emptyIfNull(this.state.player.postCode)}
                />
                <Form.Input
                  label={translate('default.city')}
                  type="text"
                  name="city"
                  placeholder={translate('default.city')}
                  onChange={this.validateGenericField}
                  value={emptyIfNull(this.state.player.city)}
                />
                <Form.Input
                  label={translate('default.country')}
                  type="text"
                  name="country"
                  placeholder={translate('default.country')}
                  onChange={this.validateGenericField}
                  value={emptyIfNull(this.state.player.country)}
                />
                <Form.Input
                  label={translate('default.golf-id')}
                  type="text"
                  name="golfId"
                  placeholder={translate('default.golf-id-format')}
                  onChange={this.validateGolfId}
                  error={!this.state.valid.golfId}
                  value={emptyIfNull(this.state.player.golfId)}
                />
                <Form.Input
                  label={translate('default.handicap')}
                  step="any"
                  type="number"
                  name="handicap"
                  placeholder={translate('default.handicap')}
                  onChange={this.validateHandicap}
                  error={!this.state.valid.handicap}
                  value={emptyIfNull(this.state.player.handicap)}
                />
              </List.Item>
            </List>
          </Form>
        )}
      </Translate>
    );
  }
}

class FilterPlayersComponent extends React.Component {
  state = {
    onlyShowMembers: false,
    name: '',
    email: ''
  };

  render() {
    const regexName = new RegExp(escapeRegExp(this.state.name), 'i');
    const isNameMatch = name => regexName.test(name);
    const regexEmail = new RegExp(escapeRegExp(this.state.email), 'i');
    const isEmailMatch = email => regexEmail.test(email);

    let filteredData = this.props.data;
    filteredData = filteredData.filter(x => !this.state.onlyShowMembers || x.isClubMember);
    filteredData = filteredData.filter(
      x => this.state.name === '' || isNameMatch(x.player.firstName + ' ' + x.player.lastName)
    );
    filteredData = filteredData.filter(x => this.state.email === '' || isEmailMatch(x.player.email));
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, { data: filteredData })
    );

    return (
      <Translate>
        {({ translate }) => (
          <>
            <Segment>
              <h3>
                <Translate id="golfclub-player.filter" />
              </h3>
              <Form.Field>
                <Form.Checkbox
                  label={translate('golfclub-player.filter-club-member')}
                  onChange={(_e, { checked }) => this.setState({ onlyShowMembers: checked })}
                  checked={this.state.onlyShowMembers}
                />
              </Form.Field>

              <Form.Input
                fluid
                label={translate('golfclub-player.filter-name')}
                type="text"
                placeholder={translate('default.name')}
                onChange={(_e, { value }) => this.setState({ name: value })}
                value={this.state.name}
              />
              <Form.Input
                fluid
                label={translate('golfclub-player.filter-email')}
                type="text"
                placeholder={translate('default.email')}
                onChange={(_e, { value }) => this.setState({ email: value })}
                value={this.state.email}
              />
            </Segment>
            {childrenWithProps}
          </>
        )}
      </Translate>
    );
  }
}

export { GolfClubPlayerViewItem, GolfClubPlayerEditItem, FilterPlayersComponent };
