import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { Button, Container, Dimmer, Form, Grid, Loader, Modal, Segment } from 'semantic-ui-react';
import * as api from '../utils/api';
import { ErrorMessage } from './ErrorMessage';

export default function ClubSwitch() {
  const [activeRequest, setActiveRequest] = useState(true);
  const [clubName, setClubName] = useState('');
  const [error, setError] = useState(null);
  const [golfClubs, setGolfClubs] = useState([]);
  const [filteredClubs, setFilteredClubs] = useState([]);
  const [pinCode, setPinCode] = useState('');
  const [selectedClub, setSelectedClub] = useState(null);

  useEffect(() => {
    const fetchGolfClubs = async () => {
      const { data } = await api.fetchGolfClubsSimple();
      if (data) {
        setGolfClubs(data);
      }

      setActiveRequest(false);
    };

    fetchGolfClubs();
  }, []);

  useEffect(() => {
    if (clubName) {
      const filterName = clubName.toLowerCase();

      const clubs = golfClubs.filter(gc => gc.name.toLowerCase().indexOf(filterName) !== -1);

      setFilteredClubs(clubs);
    } else {
      setFilteredClubs(golfClubs);
    }
  }, [clubName, golfClubs]);

  const onSwitchClub = async () => {
    if (selectedClub) {
      setError(null);

      const { error: switchError } = await api.switchGolfClub(selectedClub.id, pinCode);

      if (switchError) {
        setError(switchError);
      }
    }
  };

  return (
    <Container>
      <Segment style={{ height: '85vh', overflowY: 'scroll' }}>
        <Dimmer active={!!activeRequest} inverted>
          <Loader />
        </Dimmer>

        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <h3>Select a club to switch to</h3>
            </Grid.Column>
            <Grid.Column width={8}>
              <Form.Field>
                <Form.Input
                  size="large"
                  name="clubName"
                  placeholder="Filter clubs"
                  value={clubName}
                  onChange={e => setClubName(e.target.value)}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {filteredClubs.map(club => (
              <Grid.Column width={5} key={club.id}>
                <Button
                  style={{ width: '100%', height: 75, padding: 15, margin: 5, overflow: 'hidden' }}
                  onClick={() => setSelectedClub(club)}
                >
                  {`${club.name} (${club.id})`}
                </Button>
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </Segment>

      {selectedClub && (
        <Modal centered open className="modal-compact" size="tiny">
          <Modal.Header>Confirm club switching</Modal.Header>
          <Modal.Content>
            <p>
              Do you really want to switch your account to <b>{selectedClub.name}</b>?
            </p>
            <Form.Input
              label="PIN: "
              type="text"
              name="pinCode"
              placeholder="Enter PIN to switch"
              onChange={(_e, { value }) => setPinCode(value)}
              value={pinCode}
            />

            {error && <ErrorMessage error={error} />}
          </Modal.Content>
          <Modal.Actions>
            <Button color="green" onClick={onSwitchClub}>
              Switch
            </Button>
            <Button color="black" onClick={() => setSelectedClub(null)}>
              <Translate id="default.close" />
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Container>
  );
}
