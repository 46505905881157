import * as Api from '../../utils/api';
export const SIGNUP_REQUEST = 'signup/SIGNUP_REQUEST';
export const SIGNUP_REQUEST_SUCCESS = 'signup/SIGNUP_REQUEST_SUCCESS';
export const SIGNUP_REQUEST_FAILED = 'signup/SIGNUP_REQUEST_FAILED';

export const actions = {
  userRegistration: (user) => ({
    type: SIGNUP_REQUEST,
    data: [user],
    backendFunction: Api.userRegistration,
    callSuccessType: SIGNUP_REQUEST_SUCCESS,
    callFailedType: SIGNUP_REQUEST_FAILED
  })
};

const initialState = {
  user: {},
  activeRequest: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return {
        ...state,
        user: action.user,
        activeRequest: true,
        error: null
      };
    case SIGNUP_REQUEST_SUCCESS:
      return {
        ...state,
        activeRequest: false,
        error: null
      };
    case SIGNUP_REQUEST_FAILED:
      return {
        ...state,
        activeRequest: false,
        error: action.error
      };
    default:
      return state;
  }
};

export default reducer;
