import moment from 'moment';
import { deleteRequest, getRequest, postRequest } from '../../utils/fetch';
import { ProductAddonChargeType } from '../enums/product-addon-charge-type.enum';

export interface IProductAddonRentalUnit {
  rentalUnitTypeId: number;
  accommodationId: number | null;
  rentalUnitId: number | null;
}

export interface IPackagePricing {
  dbId?: number;
  minCount: number;
  maxCount: number;
  price: number;
  highSeasonPrice: number | null;
}

export interface IPackageProductAddon {
  dbId?: number;
  productAddon: IProductAddon;
  productAddonId: number;
  count: number;
}

export interface IPackageRentalUnitType {
  rentalUnitTypeId: number;
}

export interface IPackageSelectPricing {
  id: number;
  minCount: number;
  maxCount: number;
  price: number;
  highSeasonPrice?: number;
}

export interface IPackageSelect {
  id: number;
  count: number;
  name: string;
  chargeType: number;
  description: string;
  nights: number;
  price: number;
  highSeasonStart: string;
  highSeasonEnd: string;
  pricings: IPackageSelectPricing[];
  productAddons: IPackageSelectProductAddon[];
  rentalUnitTypes: number[];
}

export interface IPackageSelectProductAddon {
  id: number;
  productAddonId: number;
  name: string;
  count: number;
}

export interface IPackage {
  dbId: number;
  golfClubId: number;
  name: string;
  description: string;
  isActivated: boolean;
  nightsCount?: number;
  chargeType: number;
  validFrom: string;
  validTo: string;
  highSeasonStart: string;
  highSeasonEnd: string;
  productAddons: IPackageProductAddon[];
  pricings: IPackagePricing[];
  priceCategories: number[];
}

/** Holds information about product addon */
export interface IProductAddon {
  id: number;
  golfClubId: number;
  name: string;
  description: string;
  chargeAmount: number;
  vatRate: number;
  chargeType: ProductAddonChargeType;
  isForPackagesOnly?: boolean;
  validFrom: string;
  validTo: string;
  rentalUnitTypeIds: IProductAddonRentalUnit[];
}

export interface IBookingProductAddon {
  id?: number;
  innerId: number;
  productId: number;
  product: IProductAddon;
  accommodationBookingId?: number;
  chargeAmount: number;
  chargeType: ProductAddonChargeType;
  itemsCount: number;
}

export interface IAddonReportItem {
  accommodationBookingId: number;
  accommodationName: string;
  id: number;
  productName: string;
  checkinDate: Date;
  checkoutDate: Date;
  itemsCount: number;
  chargeAmount: number;
  chargeCurrency: string;
}

export interface IBookingHistory {
  id: number;
  dateUpdated: string;
  accommodationName: string;
  status: string;
  checkInDate: string;
  checkOutDate: string;
  price: number;
  checkedIn: boolean;
  paid: boolean;
  userName: string;
}

const getPrice = (p: IPackageSelect, pricing: IPackageSelectPricing, checkInDate: string): number => {
  if (!pricing) {
    return 0;
  }

  // do we have high season pricing and the checkin date is within high season?
  if (p.highSeasonStart && pricing.highSeasonPrice) {
    const checkIn = moment(checkInDate.substring(0, 10));
    const start = moment(p.highSeasonStart.substring(0, 10));
    const end = moment(p.highSeasonEnd.substring(0, 10));

    // we do, return high season price
    if (checkIn.isBetween(start, end, 'days', '[]')) {
      return pricing.highSeasonPrice;
    }
  }

  return pricing.price;
};

export const calculatePackagePrice = (p: IPackageSelect, checkinDate: string, isHoliday: boolean): number => {
  let price = 0;

  if (p.chargeType === 1) {
    const pricing = p.pricings.find(pr => pr.minCount === p.count);

    if (pricing) {
      price = getPrice(p, pricing, checkinDate) * p.count;
    }
  } else if (p.chargeType === 2) {
    let pricing;

    if (isHoliday) {
      // try to find holiday pricing
      pricing = p.pricings.find(pr => pr.minCount === 8);
    }

    if (!pricing) {
      let checkinDay = moment(checkinDate).day();
      if (checkinDay === 0) {
        checkinDay = 7;
      }

      // no holiday pricing, try to find common "by checkin day" pricing
      pricing = p.pricings.find(pr => pr.minCount <= checkinDay && pr.maxCount >= checkinDay);
    }

    if (pricing) {
      price = getPrice(p, pricing, checkinDate) * p.count;
    }
  }

  // TODO: Add more charge types calculation

  return price;
};

export const calculateAddonPrice = (addon: IBookingProductAddon): number => {
  // TODO: Implement price calculation according to charge type
  if (addon.chargeType === ProductAddonChargeType.PerPerson) {
    // per person
    return addon.product.chargeAmount * (addon.itemsCount || 0);
  } else if (addon.chargeType === ProductAddonChargeType.PerBooking) {
    // per item count
    return addon.product.chargeAmount * (addon.itemsCount || 0);
  } else {
    // per booking and others if not implemented right
    return addon.product.chargeAmount * (addon.itemsCount || 0);
  }
};

/** Gets all available product addons for a current golf club. */
export const getProductAddons = async (): Promise<IProductAddon[]> => {
  let result = [] as IProductAddon[];

  await getRequest('ProductAddons/All').then((res: any) => {
    if ('data' in res) {
      result = res.data as IProductAddon[];
    }
  });

  return result;
};

/**
 * Gets all available product addons for a current golf club and a specified
 * checkin date.
 * @param checkinDate Checkin date of a booking.
 * @returns All available product addons.
 */
export const getProductAddonsForCheckinDate = async (checkinDate: string): Promise<IProductAddon[]> => {
  let result = [] as IProductAddon[];

  await getRequest('ProductAddons/GetProductAddonsForCheckinDate', {
    checkinDate
  }).then((res: any) => {
    if ('data' in res) {
      result = res.data as IProductAddon[];
    }
  });

  return result;
};

/**
 * Creates or updates product addon data.
 * @param data Data for a product addon.
 * @returns Array of existing addons for a golf club.
 */
export const createOrUpdateProductAddon = async (data: IProductAddon): Promise<IProductAddon[]> => {
  let result = [] as IProductAddon[];

  await postRequest('ProductAddons/CreateOrUpdateAddon', data).then((res: any) => {
    if ('data' in res) {
      result = res.data as IProductAddon[];
    }
  });

  return result;
};

export interface ISuccessResponse {
  code: number;
  data: any;
}

export interface IErrorResponse {
  error: object;
}

export type IResponse = ISuccessResponse | IErrorResponse;

export const deleteProductAddon = async (id: number): Promise<any> => {
  return await deleteRequest(`ProductAddons/DeleteProductAddon/${id}`);
};

/** Gets a list of accommodation bookings addon services for
 * bookings checkin date between a specified period.
 * */
export const getAccommodationBookingAddons = (dateFrom: Date, dateTo: Date): Promise<any> => {
  return getRequest('/ProductAddons/GetAccommodationBookingAddons', {
    dateFrom,
    dateTo
  });
};

/** Gets a list of accommodation bookings packages for
 * bookings checkin date between a specified period.
 * */
export const getAccommodationPackages = (dateFrom: Date, dateTo: Date): Promise<any> => {
  return getRequest('/Packages/GetAccommodationBookingPackages', {
    dateFrom,
    dateTo
  });
};

/** Gets a cleaning report list containing date, count of cabins to clean
 * and a list of cabins to clean for the date.
 * */
export const getCleaningReport = (dateFrom: Date, dateTo: Date): Promise<any> => {
  return getRequest('/ProductAddons/GetCleaningReport', {
    dateFrom,
    dateTo
  });
};

/** Gets a bed linen report list containing date, count of cabins to add bed linen to
 * and a list of cabins to ad bed linen for the date.
 * */
export const getBedLinenReport = (dateFrom: Date, dateTo: Date): Promise<any> => {
  return getRequest('/ProductAddons/GetBedLinenReport', {
    dateFrom,
    dateTo
  });
};

/**
 * Gets all packages for a current golf club.
 * @returns A list of {@link IPackage}
 */
export const getPackages = async (): Promise<IPackage[]> => {
  let result = [] as IPackage[];

  await getRequest('Packages/All').then((res: any) => {
    if ('data' in res) {
      result = res.data as IPackage[];
    }
  });

  return result;
};

/**
 * Gets a list of valid packages for a specified rental unit type
 * and number of nights specified by checkin and checkout dates.
 * @param checkInDate Checkin date of a booking.
 * @param checkOutDate Checkout date of a booking.
 * @param priceCategoryId Price category id of a bookings accommodation.
 * @returns All available product packages.
 */
export const getAvailablePackages = async (
  checkInDate: string,
  checkOutDate: string,
  priceCategoryId: number
): Promise<IPackageSelect[]> => {
  let result = [] as IPackageSelect[];

  await getRequest('Packages/Available', {
    checkInDate,
    checkOutDate,
    priceCategoryId
  }).then((res: any) => {
    if ('data' in res) {
      result = res.data as IPackageSelect[];
    }
  });

  return result;
};

/**
 * Creates or updates product addon data.
 * @param data Data for a product addon.
 * @returns Array of existing addons for a golf club.
 */
export const createOrUpdatePackage = async (data: IPackage): Promise<IPackage[]> => {
  let result = [] as IPackage[];

  await postRequest('Packages/CreateOrUpdate', data).then((res: any) => {
    if ('data' in res) {
      result = res.data as IPackage[];
    }
  });

  return result;
};

/**
 * Activates a single package. There is a new copy of a package
 * created when changing an activate package.
 * @param idPackage Id of a package to activate.
 * @returns True/false depending of an activation succes or failure.
 */
export const activatePackage = async (packageId: number): Promise<boolean> => {
  let result = false;

  await postRequest(`Packages/Activate/${packageId}`).then((res: any) => {
    if ('data' in res) {
      result = res.data as boolean;
    }
  });

  return result;
};

/**
 * Deletes a single package.
 * @param idPackage Id of a package to delete.
 * @returns True/false depending of an delete succes or failure.
 */
export const deletePackage = async (packageId: number): Promise<boolean> => {
  let result = false;

  await deleteRequest(`Packages/Delete/${packageId}`).then((res: any) => {
    if ('data' in res) {
      result = res.data as boolean;
    }
  });

  return result;
};

/**
 * Gets all history records for an accommodation booking.
 * @returns A list of {@link IBookingHistory}
 */
export const getBookingHistory = async (id: number, golfClubId: number): Promise<IBookingHistory[]> => {
  let result = [] as IBookingHistory[];

  await getRequest(`AccommodationBookings/GetBookingHistory?id=${id}&golfClubId=${golfClubId}`).then((res: any) => {
    if ('data' in res) {
      result = res.data as IBookingHistory[];
    }
  });

  return result;
};
