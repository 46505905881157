import * as Api from '../../utils/api';

export const REGISTER_CLUBADMIN_REQUEST = 'clubadmin/REGISTER_CLUBADMIN_REQUEST';
export const REGISTER_CLUBADMIN_REQUEST_SUCCESS = 'clubadmin/REGISTER_CLUBADMIN_REQUEST_SUCCESS';
export const REGISTER_CLUBADMIN_REQUEST_FAILED = 'clubadmin/REGISTER_CLUBADMIN_REQUEST_FAILED';

export const actions = {
  clubAdminRegistration: (user) => ({
    type: REGISTER_CLUBADMIN_REQUEST,
    data: [user],
    backendFunction: Api.clubAdminRegistration,
    callSuccessType: REGISTER_CLUBADMIN_REQUEST_SUCCESS,
    callFailedType: REGISTER_CLUBADMIN_REQUEST_FAILED
  })
};

const initialState = {
  user: {},
  activeRequest: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_CLUBADMIN_REQUEST:
      return {
        ...state,
        user: action.data[0],
        activeRequest: true,
        error: null
      };
    case REGISTER_CLUBADMIN_REQUEST_SUCCESS:
      return {
        ...state,
        user: action.user,
        activeRequest: false,
        error: null
      };
    case REGISTER_CLUBADMIN_REQUEST_FAILED:
      return {
        ...state,
        activeRequest: false,
        error: action.error
      };
    default:
      return state;
  }
};

export default reducer;
