import React from 'react';
import { CSVLink } from 'react-csv';
import { Translate } from 'react-localize-redux';
import { Segment, Grid, Table } from 'semantic-ui-react';
import { getCleaningReport, getBedLinenReport } from '../../../core/services/product.service';
import { isValidDate, startOfMonth, endOfMonth, todaysDate } from '../../../utils';
import { ReportDateComponent } from '../ReportDateComponent';

interface ServicesReportTabProps {
  active: boolean;
  translate: any;
}

interface GroupReportItem {
  date: Date;
  count: number;
  items: string[];
}

export default class ServicesReportTab extends React.Component<ServicesReportTabProps> {
  state = {
    loading: false,
    cleanings: [],
    bedLinen: [],
    startDate: startOfMonth(todaysDate()),
    startDateValid: true,
    endDate: endOfMonth(todaysDate()),
    endDateValid: true
  };

  serviceHeaders = [
    { label: this.props.translate('default.date'), key: 'date' },
    { label: '#', key: 'count' },
    { label: '', key: 'items' }
  ];

  validateDatetimeChange = (_e: any, { name, value }: any) => {
    let { startDate, endDate, startDateValid, endDateValid } = this.state;

    if (name === 'startDate') {
      startDate = value;
      startDateValid = isValidDate(startDate);
      if (startDateValid) {
        endDate = endOfMonth(startDate);
      }
    } else {
      endDate = value;
    }
    endDateValid = isValidDate(endDate) && endDate >= startDate;
    this.setState({ startDate, endDate, startDateValid, endDateValid });
  };

  fetchData = async () => {
    const { startDate, endDate, startDateValid, endDateValid } = this.state;
    if (startDateValid && endDateValid) {
      this.setState({ loading: true });

      try {
        const cleanings = await getCleaningReport(startDate, endDate);
        const bedLinen = await getBedLinenReport(startDate, endDate);

        if ('data' in cleanings && 'data' in bedLinen) {
          this.setState({
            loading: false,
            cleanings: cleanings.data,
            bedLinen: bedLinen.data
          });
        } else {
          this.setState({
            loading: false
          });
        }
      } catch (error) {
        this.setState({ loading: false });
      }
    }
  };

  render() {
    const { startDate, endDate, startDateValid, endDateValid, loading, cleanings, bedLinen } = this.state;

    const dayCleaninsCsvReport = {
      data: cleanings,
      headers: this.serviceHeaders,
      filename: `${this.props.translate('product-addons.daily-cleanings')}.csv`,
      separator: ';'
    };

    const dayBedLinenCsvReport = {
      data: bedLinen,
      headers: this.serviceHeaders,
      filename: `${this.props.translate('product-addons.daily-linen')}.csv`,
      separator: ';'
    };

    if (this.props.active) {
      return (
        <Translate>
          {({ translate }) => (
            <Segment>
              <div className="p-1">
                <ReportDateComponent
                  startDate={startDate}
                  startDateValid={startDateValid}
                  endDate={endDate}
                  endDateValid={endDateValid}
                  loading={loading}
                  fetchBookings={this.fetchData}
                  validateDatetimeChange={this.validateDatetimeChange}
                />

                <Grid>
                  <Grid.Column floated="left" width="5">
                    <Translate id="product-addons.daily-cleanings" />
                  </Grid.Column>
                  {cleanings?.length > 0 && (
                    <Grid.Column floated="right" width="5" textAlign="right">
                      <CSVLink {...dayCleaninsCsvReport}>{translate('club-admin.export-to-csv')}</CSVLink>
                    </Grid.Column>
                  )}
                </Grid>

                <Segment loading={loading}>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={3}>
                          <Translate id="default.date" />
                        </Table.HeaderCell>
                        <Table.HeaderCell width={3}>#</Table.HeaderCell>
                        <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {cleanings?.map((addon: GroupReportItem, i) => (
                        <Table.Row key={i}>
                          <Table.Cell verticalAlign="top">{addon.date}</Table.Cell>
                          <Table.Cell verticalAlign="top">{addon.count}</Table.Cell>
                          <Table.Cell>
                            {addon.items.map(item => (
                              <small key={item}>
                                {item}
                                <br />
                              </small>
                            ))}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Segment>

                <Grid>
                  <Grid.Column floated="left" width="5">
                    <Translate id="product-addons.daily-linen" />
                  </Grid.Column>
                  {cleanings?.length > 0 && (
                    <Grid.Column floated="right" width="5" textAlign="right">
                      <CSVLink {...dayBedLinenCsvReport}>{translate('club-admin.export-to-csv')}</CSVLink>
                    </Grid.Column>
                  )}
                </Grid>

                <Segment loading={loading}>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={3}>
                          <Translate id="default.date" />
                        </Table.HeaderCell>
                        <Table.HeaderCell width={3}>#</Table.HeaderCell>
                        <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {bedLinen?.map((linen: GroupReportItem, i) => (
                        <Table.Row key={i}>
                          <Table.Cell verticalAlign="top">{linen.date}</Table.Cell>
                          <Table.Cell verticalAlign="top">{linen.count}</Table.Cell>
                          <Table.Cell>
                            {linen.items.map(item => (
                              <small key={item}>
                                {item}
                                <br />
                              </small>
                            ))}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Segment>
              </div>
            </Segment>
          )}
        </Translate>
      );
    }

    return <></>;
  }
}
