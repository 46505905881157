import { getRequest, postRequest } from '../../utils/fetch';

const getAll = (golfClubId, dateFrom, dateTo) =>
  getRequest('/RentalUnitBookings', {
    golfClubId,
    withinDateStart: dateFrom,
    withinDateEnd: dateTo
  });
const getById = id => getRequest(`/RentalUnitBookings/GetById/${id}`);
const getAvailableUnits = (golfClubId, dateFrom, dateTo, isWidgetRequest, currentRentalUnitBookingId) =>
  getRequest('/RentalUnitBookings/AvailableRentalUnits', {
    golfClubId,
    startDatetime: dateFrom,
    endDatetime: dateTo,
    isWidgetRequest,
    currentRentalUnitBookingId
  });
const create = data => postRequest('/RentalUnitBookings', data);
const checkAvailability = data => postRequest('/RentalUnitBookings/CheckBookingAvailability', data);

export const rentalUnitBookingService = {
  getAll,
  getById,
  create,
  getAvailableUnits,
  checkAvailability
};
