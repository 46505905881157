import React from 'react';
import { Translate } from 'react-localize-redux';

import { Confirm, Form, Icon, Segment } from 'semantic-ui-react';
import { EditableSubListComponent } from './EditableSubListComponent';

let newItemIdCounter = -1;

export const golfCourseEmpty = () => {
  return {
    id: newItemIdCounter--,
    name: '',
    numberOfHoles: 18,
    isMainCourse: true
  };
};

const validNumberOfHoles = [...Array(60).keys()].map((x) => (x + 1).toString());

class GolfCourseListComponent extends React.Component {
  validate = (dataList, dataListValid) => {
    dataList.forEach((x, i) => {
      this.validateGolfCourse(dataList, dataListValid, i);
    });
  };

  allValid = (dataList, dataListValid) => {
    return (
      dataList.length > 0 &&
      dataListValid.every((x) => this.validItem(x)) &&
      dataList.filter((x) => x.isMainCourse).length <= 1
    );
  };

  onlyOneMainCourse = (dataList, _dataListValid) => {
    return dataList.filter((x) => x.isMainCourse).length <= 1;
  };

  validItem = (item) => {
    return item.name && item.numberOfHoles;
  };

  emptyValidationItem = () => {
    return { name: true, numberOfHoles: true };
  };

  validateGolfCourse = (dataList, dataListValid, i) => {
    const item = dataList[i];
    dataListValid[i].name = item.name && item.name.length > 0;
    dataListValid[i].numberOfHoles = validNumberOfHoles.includes(item.numberOfHoles.toString());
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <EditableSubListComponent
            data={this.props.data}
            listLabel={translate('default.golfcourses')}
            itemName={translate("club-admin.golf-course")}
            stateUpdated={this.props.stateUpdated}
            error={this.props.error}
            validate={this.validate}
            listValidationStatus={[
              { validationFn: this.onlyOneMainCourse, text: translate('golfcourse.one-main-course') }
            ]}
            emptyItem={golfCourseEmpty}
            emptyValidationItem={this.emptyValidationItem}
            allValid={this.allValid}
            subComponent={<GolfCourseComponent validateGolfCourse={this.validateGolfCourse} />}
          />
        )}
      </Translate>
    );
  }
}

class GolfCourseComponent extends React.Component {
  state = {
    confirmDeleteShowing: false
  };

  validateGolfCourseName = ({ value }, i) => {
    const { dataList, dataListValid } = this.props;
    dataList[i].name = value;
    this.props.validateGolfCourse(dataList, dataListValid, i);
    this.props.updateState(dataList, dataListValid);
  };

  validateNumberOfHoles = ({ value }, i) => {
    const { dataList, dataListValid } = this.props;
    dataList[i].numberOfHoles = value;
    this.props.validateGolfCourse(dataList, dataListValid, i);
    this.props.updateState(dataList, dataListValid);
  };

  validateMainCourse = ({ checked }, i) => {
    const { dataList, dataListValid } = this.props;
    dataList[i].isMainCourse = checked;
    this.props.validateGolfCourse(dataList, dataListValid, i);
    this.props.updateState(dataList, dataListValid);
  };

  deleteGolfCourse = (e, i) => {
    e.preventDefault();
    if (this.props.dataList[i].id > 0) {
      this.setState({ confirmDeleteShowing: true });
    } else {
      this.props.removeItem(i);
    }
  };

  golfCourseDeleted = (e, i) => {
    e.preventDefault();
    this.setState({ confirmDeleteShowing: false });
    this.props.removeItem(i);
  };

  render() {
    const i = this.props.index;
    const item = this.props.dataList[i];
    const itemValid = this.props.dataListValid[i];
    return (
      <Translate>
        {({ translate }) => (
          <Segment>
            <Confirm
              header={translate('golfcourse.delete')}
              content={null}
              confirmButton="Delete"
              open={this.state.confirmDeleteShowing}
              onCancel={() => this.setState({ confirmDeleteShowing: false })}
              onConfirm={(e) => this.golfCourseDeleted(e, i)}
            />

            {this.props.dataList.length !== 1 && (
              <Icon
                style={{ float: 'right' }}
                link
                color="red"
                name="delete"
                size="large"
                onClick={(e) => this.deleteGolfCourse(e, i)}
              />
            )}
            <Form.Group style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Field>
                <Form.Input
                  required
                  label={translate('default.name')}
                  type="text"
                  name="golfCourse"
                  placeholder={translate('default.golfcourse')}
                  onChange={(_e, d) => this.validateGolfCourseName(d, i)}
                  error={!itemValid.name}
                  value={item.name}
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  required
                  label={translate('golfclub.nr-holes')}
                  type="number"
                  onChange={(_e, d) => this.validateNumberOfHoles(d, i)}
                  placeholder={'18'}
                  error={!itemValid.numberOfHoles}
                  value={item.numberOfHoles}
                />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <Form.Checkbox
                inline
                label={translate('golfcourse.main-course')}
                onChange={(_e, d) => this.validateMainCourse(d, i)}
                checked={item.isMainCourse}
              />
            </Form.Field>
          </Segment>
        )}
      </Translate>
    );
  }
}

export { GolfCourseListComponent };
