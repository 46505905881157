import moment from 'moment';
import { formatSwedishPhoneNumber } from '../../utils';
import { getRequest, postRequest } from '../../utils/fetch';

export const processOrder = async ({
  customer,
  golfClubId,
  accommodationBookingId = undefined,
  rentalUnitBookingId = undefined,
  order = undefined,
  returnUrl = undefined,
  isProcessCharge = true,
  isOrderConfirmed = false,
  bookingChangeInfo = undefined,
  transactionType = undefined,
  swishPhoneNumber = undefined,
  swishPaymentType = undefined
}) => {
  const orderInput = {
    order: {
      id: order?.id,
      customerName: customer?.name,
      customerEmail: customer?.email,
      customerPhone: customer?.phone,
      customerUserId: customer?.userId,
      isOpen: order?.isOpen,
      orderItems: [
        {
          accommodationBookingId,
          rentalUnitBookingId
        }
      ],
      golfClubId
    },
    accommodationBookingId,
    rentalUnitBookingId,
    isProcessCharge,
    isOrderConfirmed,
    returnUrl,
    bookingChangeInfo,
    transactionType,
    swishPhoneNumber: formatSwedishPhoneNumber(swishPhoneNumber),
    swishPaymentType
  };

  const { data } = await postRequest(`/Order/ProcessBooking?language=${moment.locale()}`, orderInput);

  return data;
};

// applicable only for all manual payment
export const cancelOrder = data => postRequest('Order/Cancel', data);

export const refundTransaction = data => postRequest('Order/RefundTransaction', data);

export const fetchAccommodationOrder = accommodationBookingId =>
  getRequest(`Order/Accommodation/${accommodationBookingId}`);

export const fetchAccommodationBookingOrder = (accommodationBookingId, golfClubId, email, orderDeleteId) =>
  getRequest(
    `Booking?id=${accommodationBookingId}&gcid=${golfClubId}&email=${email.replace('+', '`')}&odid=${orderDeleteId}`
  );

export const cancelCustomerOrder = data => postRequest('Booking', data);

export const fetchRentalUnitOrder = rentalUnitBookingId => getRequest(`Order/RentalUnit/${rentalUnitBookingId}`);

/**
 * Gets an open order for specified golf club if it exists.
 * @param {number} golfClubId Golf club id.
 * @returns Open order if exists, null otherwise.
 */
export const fetchOpenOrder = golfClubId => getRequest(`Order/GetOpenOrder/${golfClubId}`);

/**
 * Closes an active order for current user and specified golf club.
 * @param {number} golfClubId Golf club id.
 * @returns
 */
export const closeOpenOrder = golfClubId => postRequest(`Order/CloseOpenOrder/${golfClubId}`, {});
/** Gets orders by order id, booking user name, email or phone.  */
export const searchOrders = (golfClubId, searchBy) =>
  getRequest('/Order/Search', {
    golfClubId,
    searchBy
  });
