import * as Api from '../../utils/api';

export const CHANGE_PASSWORD_REQUEST = 'change-password/CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_REQUEST_SUCCESS = 'change-password/CHANGE_PASSWORD_REQUEST_SUCCESS';
export const CHANGE_PASSWORD_REQUEST_FAILED = 'change-password/CHANGE_PASSWORD_REQUEST_FAILED';
export const CHANGE_PASSWORD_CONTINUE = 'change-password/CHANGE_PASSWORD_CONTINUE';

export const changePasswordAction = (user) => ({
  type: CHANGE_PASSWORD_REQUEST,
  data: [user],
  backendFunction: Api.changePassword,
  callSuccessType: CHANGE_PASSWORD_REQUEST_SUCCESS,
  callFailedType: CHANGE_PASSWORD_REQUEST_FAILED
});
export const changePasswordContinueAction = () => ({ type: CHANGE_PASSWORD_CONTINUE });

const initialState = {
  user: {},
  activeRequest: false,
  passwordChangeDone: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        user: action.data[0],
        activeRequest: true,
        error: null
      };
    case CHANGE_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        activeRequest: false,
        passwordChangeDone: true,
        user: {
          ...state.user,
          oldPassword: '',
          password: '',
          confirmPassword: ''
        }
      };
    case CHANGE_PASSWORD_REQUEST_FAILED:
      return {
        ...state,
        activeRequest: false,
        error: action.error
      };
    case CHANGE_PASSWORD_CONTINUE:
      return {
        ...state,
        passwordChangeDone: false
      };
    default:
      return state;
  }
};

export default reducer;
