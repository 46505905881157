import * as Api from '../../utils/api';

export const RESET_PASSWORD_REQUEST = 'reset-password/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_REQUEST_SUCCESS = 'reset-password/RESET_PASSWORD_REQUEST_SUCCESS';
export const RESET_PASSWORD_REQUEST_FAILED = 'reset-password/RESET_PASSWORD_REQUEST_FAILED';

export const resetPasswordAction = (email) => ({
  type: RESET_PASSWORD_REQUEST,
  data: [email],
  backendFunction: Api.resetPassword,
  callSuccessType: RESET_PASSWORD_REQUEST_SUCCESS,
  callFailedType: RESET_PASSWORD_REQUEST_FAILED
});

const initialState = {
  email: {},
  activeRequest: false,
  error: null,
  passwordResetDone: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        email: action.data[0],
        activeRequest: true,
        isAuthenticated: false,
        token: null,
        error: null
      };
    case RESET_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        activeRequest: false,
        isAuthenticated: true,
        passwordResetDone: true
      };
    case RESET_PASSWORD_REQUEST_FAILED:
      return {
        ...state,
        activeRequest: false,
        error: action.error
      };
    default:
      return state;
  }
};

export default reducer;
