import React, { useState } from 'react';
import moment from 'moment';
import { Icon, Popup } from 'semantic-ui-react';
import Cell, { BlockedPeriodCell, BookingCell } from './Cell';
import { BookingStatus } from '../../core/enums';

function RentalUnitRow({
  rentalUnit,
  onClickAvailable,
  onClickOccupied,
  onClickMultipleBookings,
  cellWidth,
  overViewDate,
  translate
}) {
  const currentDate = moment();
  const yearMonthIsCurrent = overViewDate.isSame(currentDate, 'month');
  const yearMonthInFuture = overViewDate.isAfter(currentDate);

  const [days, setDays] = useState({});
  const [active, setActive] = useState(false);

  const onMouseDown = day => {
    setDays({
      [day]: true
    });
    setActive(true);
  };

  // TODO: Enable if we allow multiple day bookings
  // const onMouseOver = day => {
  //   if (active) {
  //     setDays({
  //       ...days,
  //       [day]: true
  //     });
  //   }
  // };

  const onMouseUp = () => {
    if (active) {
      const daysInSequence = Object.keys(days).sort((a, b) => a - b);
      const startDay = daysInSequence[0];
      let endDay = daysInSequence[daysInSequence.length - 1];

      if (startDay === endDay) {
        endDay = (parseInt(endDay, 10) + 1).toString();
      }

      setDays({});
      setActive(false);
      onClickAvailable(rentalUnit.id, { startDay, endDay });
    }
  };

  const onMouseLeaveRow = () => {
    if (active) {
      setDays({});
      setActive(false);
    }
  };

  const handleBookingClick = booking => {
    if ((!booking.isMultipleBookings && booking.status === BookingStatus.CONFIRMED) || booking.currentUserPrebooking) {
      onClickOccupied(booking);
    }
    if (booking.isMultipleBookings) {
      onClickMultipleBookings(booking.bookings);
    }
  };

  const rows = [];
  const currentDay = parseInt(currentDate.format('DD'), 10);
  rentalUnit.scheme.forEach((s, idx) => {
    const { nrOfDays, booking, startDatetimeInPrevMonth, day } = s;

    if (booking) {
      const width = cellWidth * nrOfDays + (startDatetimeInPrevMonth ? 15 : 0);

      if (booking.isBlockedPeriod) {
        rows.push(
          <BlockedPeriodCell key={`${rentalUnit.id}${day}${booking.id}`} cellWidth={width} translate={translate} />
        );
      } else {
        rows.push(
          <BookingCell
            key={`${rentalUnit.id}${day}${booking.id}`}
            cellWidth={width}
            booking={booking}
            onClick={() => handleBookingClick(booking)}
          />
        );
      }
    } else {
      for (let i = day; i < day + nrOfDays; i += 1) {
        rows.push(
          <Cell
            key={`${rentalUnit.id}-${i}`}
            cellWidth={cellWidth}
            day={i}
            activeDays={days}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            available={yearMonthInFuture || (yearMonthIsCurrent && i >= currentDay)}
          />
        );
      }
    }
  });

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'row', marginTop: '10px' }} onMouseLeave={onMouseLeaveRow}>
      <div
        className="accommodationNameCell"
        style={{
          display: 'flex',
          width: '130px',
          maxWidth: '130px',
          minWidth: '130px',
          alignItems: 'center',
          wordBreak: 'break-all',
          borderRight: '1pt solid #ccc'
        }}
      >
        <Popup
          trigger={
            // <Button color="red" size="mini" icon disabled={btnsDisabled}>
            <Icon name="info circle" />
            // </Button>
          }
          content={rentalUnit.info}
          // on="click"
          position="top right"
        />
        {rentalUnit.name}
      </div>
      {rows}
    </div>
  );
}

export default RentalUnitRow;
