import React from 'react';

import { Loader } from 'semantic-ui-react';

const Teetime = ({ time, available, loading }) => (
  <div>
    <Loader active={loading} inline="centered" />
    {time}
    <div className="circles">
      <Rounders available={available} />
    </div>
  </div>
);

export default Teetime;

const Rounders = ({ available }) =>
  [1, 2, 3, 4].map((spot) => <div key={spot} className={`circle ${spot <= available ? 'green' : 'red'}`}></div>);
