import React from 'react';
import { Form } from 'semantic-ui-react';

function PriceSelection({ label, options, textValue, value, onChange, loading, disabled, readonlyBooking }) {
  return readonlyBooking ? (
    <Form.Input label={label} type="text" value={textValue} readOnly />
  ) : (
    <Form.Select
      label={label}
      selection
      value={value}
      onChange={(_e, data) => onChange(data.value)}
      // options={options?.map(x => ({
      //   // text: `${translate(x.text)} - ${x.price} {golfClubCurrency}`,
      //   text: translate(x.text),
      //   value: x.value
      // }))}
      options={options}
      loading={loading}
      disabled={disabled}
    />
  );
}

export default PriceSelection;
