import React, { Component } from 'react';

import { Container } from 'semantic-ui-react';

import Helmet from '../components/Helmet';

import GolfCourseTeesheet from '../containers/Teesheet';

class HomePage extends Component {
  render() {
    return (
      <div className="app app-home">
        <Helmet title="OnTee home/teesheet" description="OnTee teesheet on steroid!" />

        <Container>
          <GolfCourseTeesheet />
        </Container>
      </div>
    );
  }
}

export default HomePage;
