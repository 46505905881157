import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Translate, withLocalize } from 'react-localize-redux';

import { Grid, Form, Menu, Message, Segment } from 'semantic-ui-react';

import { ErrorMessage } from '../../components/ErrorMessage';
import { ProductViewItem, ProductEditItem } from '../../components/Product';
import { EditableListComponent } from '../../components/EditableListComponent';

import { inviteClubAdminAction, inviteClubAdminResetAction } from './ducks';
import { clubAdminConfigActions } from '../ClubAdminConfigureForm/ducks';
import { emailRegex } from '../../utils';
import { GolfClubDetailsTab } from '../../components/Internal/Tabs/GolfClubDetailsTab';

class InhouseConfigureForm extends React.Component {
  invitation = 'Golf club invitation';

  products = 'Products';

  details = 'Golf club management';

  state = {
    activeItem: this.invitation
  };

  componentDidMount() {
    this.props.fetchProductsAction();
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const { activeItem } = this.state;
    return (
      <>
        <Menu className="p-05" pointing secondary>
          <Menu.Item name={this.invitation} active={activeItem === this.invitation} onClick={this.handleItemClick} />
          <Menu.Item name={this.products} active={activeItem === this.products} onClick={this.handleItemClick} />
          <Menu.Item name={this.details} active={activeItem === this.details} onClick={this.handleItemClick} />
        </Menu>
        <GolfClubInvitationTab
          error={this.props.invitationError}
          invitationDone={this.props.invitationDone}
          loading={this.props.invitationActiveRequest}
          inviteClubAdminResetAction={this.props.inviteClubAdminResetAction}
          inviteClubAdminAction={this.props.inviteClubAdminAction}
          active={activeItem === this.invitation}
        />
        <ProductsTab
          data={this.props.products}
          error={this.props.productsError}
          loading={this.props.golfClubsLoading}
          saveItemAction={this.props.saveProductAction}
          deleteItemAction={this.props.deleteProductAction}
          active={activeItem === this.products}
        />
        <GolfClubDetailsTab active={activeItem === this.details} />
      </>
    );
  }
}
class GolfClubInvitationTab extends React.Component {
  constructor() {
    super();

    this.state = {
      user: { email: '' },
      valid: {
        email: true
      }
    };
  }

  componentDidUpdate(_prevProps, _prevState) {
    if (this.props.invitationDone && !_prevProps.invitationDone && this.state.user.email !== '') {
      const { user } = this.state;
      user.email = '';
      this.setState({ user });
    }
  }

  componentWillUnmount() {
    this.props.inviteClubAdminResetAction();
  }

  validateEmail = e => {
    const email = e.target.value;
    const { user, valid } = this.state;

    user.email = email;
    valid.email = emailRegex.test(email);

    this.setState({
      user,
      valid
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.inviteClubAdminAction(this.state.user);
  };

  render() {
    const { valid } = this.state;
    // const { translate } = this.props;
    if (this.props.active) {
      return (
        <Segment>
          <Grid columns="1" stackable>
            <Grid.Column className="bg-white">
              <div className="p-3">
                <h1>
                  <Translate id="inhouse-config.invitation" />
                </h1>
                {this.props.invitationDone && (
                  <Translate>
                    {({ translate }) => (
                      <Message
                        success
                        content={translate('inhouse-config.email-sent', {
                          mail: this.props.invitationDone
                        })}
                      />
                    )}
                  </Translate>
                )}
                <div>
                  <p className="text-muted">
                    <Translate id="inhouse-config.email-person" />
                  </p>
                  <Form onSubmit={this.handleSubmit} loading={this.props.loading} error={!!this.props.error}>
                    <Form.Field className="mb-3">
                      <Form.Input
                        required
                        label="Email"
                        type="email"
                        name="email"
                        placeholder="Email"
                        onChange={this.validateEmail}
                        error={!valid.email}
                        value={this.state.user.email}
                      />
                    </Form.Field>
                    <Message error content={this.props.error} />
                    <Form.Button fluid color="teal" disabled={!valid.email || !this.state.user.email}>
                      <Translate id="default.submit" />
                    </Form.Button>
                  </Form>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </Segment>
      );
    }
    return null;
  }
}

class ProductsTab extends React.Component {
  emptyItem = () => ({
    description: '',
    numberOfPlayers: 1,
    sortOrder: 99,
    isZeroPrice: false
  });

  render() {
    if (this.props.active) {
      // const { translate } = this.props;
      const emptyItem = this.emptyItem();
      return (
        <Translate>
          {({ translate }) => (
            <Segment loading={this.props.loading}>
              <>
                <ErrorMessage error={this.props.error} />
                <EditableListComponent
                  emptyItem={emptyItem}
                  data={this.props.data}
                  itemDesc="products"
                  emptyDescription={translate('inhouse-config.products')}
                  saveItemAction={this.props.saveItemAction}
                  deleteItemAction={this.props.deleteItemAction}
                  viewComponent={<ProductViewItem />}
                  editComponent={<ProductEditItem />}
                />
              </>
            </Segment>
          )}
        </Translate>
      );
    }
    return null;
  }
}

function mapStateToProps(state) {
  return {
    invitationError: state.inHouseConfig.error,
    invitationActiveRequest: state.inHouseConfig.activeRequest,
    invitationDone: state.inHouseConfig.invitationDone,
    products: state.clubAdminConfig.products,
    productsLoading: state.clubAdminConfig.productsLoading,
    productsError: state.clubAdminConfig.productsError
  };
}

function mapDispatchToProps(dispatch) {
  return {
    inviteClubAdminAction: user => dispatch(inviteClubAdminAction(user)),
    inviteClubAdminResetAction: () => dispatch(inviteClubAdminResetAction()),
    fetchProductsAction: () => dispatch(clubAdminConfigActions.fetchProductsAction()),
    saveProductAction: (product, onSubmit) => dispatch(clubAdminConfigActions.saveProductAction(product, onSubmit)),
    deleteProductAction: product => dispatch(clubAdminConfigActions.deleteProductAction(product))
  };
}

export default withLocalize(withRouter(connect(mapStateToProps, mapDispatchToProps)(InhouseConfigureForm)));
