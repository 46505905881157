import React, { Component } from 'react';

import { Container } from 'semantic-ui-react';

import Helmet from '../components/Helmet';
import InHouseConfigureForm from '../containers/InHouseConfigureForm';

class InHouseConfigPage extends Component {
  render() {
    return (
      <div className="app app-in-house-config">
        <Helmet title="OnTee in-house config" description="OnTee on in-house config steroid!" />
        <Container>
          <InHouseConfigureForm />
        </Container>
      </div>
    );
  }
}

export default InHouseConfigPage;
