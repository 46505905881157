import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Translate } from 'react-localize-redux';

function AccommodationBookingInformation({ booking, golfClubCurrency }) {
  const [bookingDate, setBookingDate] = useState(null);
  useEffect(() => {
    setBookingDate({
      checkInDate: moment(booking.checkInDate).format('YYYY-MM-DD'),
      checkOutDate: moment(booking.checkOutDate).format('YYYY-MM-DD')
    });
  }, [booking]);

  return (
    <table>
      <tbody>
        <tr>
          <td>
            <b>
              <Translate id="default.booked-by" />:
            </b>
          </td>
          <td>{booking.bookingUser.name}</td>
        </tr>
        <tr>
          <td>
            <b>
              <Translate id="edit-booking.booking-number" />:
            </b>
          </td>
          <td>{booking.id}</td>
        </tr>
        <tr>
          <td>
            <b>
              <Translate id="default.product" />:
            </b>
          </td>
          <td>{booking.accommodation.name}</td>
        </tr>
        <tr>
          <td>
            <b>
              <Translate id="default.checkin" />:
            </b>
          </td>
          <td>{bookingDate?.checkInDate}</td>
        </tr>
        <tr>
          <td>
            <b>
              <Translate id="default.checkout" />:
            </b>
          </td>
          <td>{bookingDate?.checkOutDate}</td>
        </tr>
        <tr>
          <td>
            <b>
              <Translate id="edit-booking.price" />:
            </b>
          </td>
          <td>
            {booking.priceInclVat} {golfClubCurrency}
          </td>
        </tr>
        <tr>
          <td colSpan="2" className="pt-1">
            <Translate id="default.confirmation-sent-to" /> <b>{booking.bookingUser.email}</b>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default AccommodationBookingInformation;
