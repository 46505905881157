import React from 'react';
import { Translate } from 'react-localize-redux';

import { Button, Segment, Table, Popup, Grid } from 'semantic-ui-react';
import { CSVLink } from 'react-csv';
import { daysBetween, isValidDate, startOfMonth, endOfMonth, todaysDate } from '../../../utils';
import { accommodationBookingService } from '../../../core/services';
import { ReportDateComponent } from '../ReportDateComponent';

export default class AccomodationTransactionsPeriodReportTab extends React.Component {
  state = {
    startDate: startOfMonth(todaysDate()),
    startDateValid: true,
    endDate: endOfMonth(todaysDate()),
    endDateValid: true,
    loading: false,
    accommodationBookingsPeriod: []
  };

  accommodationBookingPeriodHeaders = [
    { label: this.props.translate('club-admin.checkin-date'), key: 'checkInDate' },
    { label: this.props.translate('club-admin.category'), key: 'priceCategoryName' },
    { label: this.props.translate('club-admin.no-of-slots'), key: 'slots' },
    { label: this.props.translate('club-admin.no-of-nights'), key: 'numberOfNights' },
    { label: this.props.translate('club-admin.report-tabs.guestlist'), key: 'numberOfGuests' },
    { label: this.props.translate('club-admin.customer-name'), key: 'customerName' },
    { label: this.props.translate('club-admin.total-income'), key: 'totalIncome' },
    { label: this.props.translate('club-admin.total-commission'), key: 'totalCommission' }
  ];

  validateDatetimeChange = (_e, { name, value }) => {
    let { startDate, endDate, startDateValid, endDateValid } = this.state;

    if (name === 'startDate') {
      startDate = value;
      startDateValid = isValidDate(startDate);
      if (startDateValid) {
        endDate = endOfMonth(startDate);
      }
    } else {
      endDate = value;
    }
    endDateValid = isValidDate(endDate) && endDate >= startDate;
    this.setState({ startDate, endDate, startDateValid, endDateValid });
  };

  getGolfClubId = () => {
    return this.props.golfClubs && this.props.golfClubs.length > 0 ? this.props.golfClubs[0].id : 0;
  };

  getGolfClubCurrency = () => {
    return this.props.golfClubs && this.props.golfClubs.length > 0 ? this.props.golfClubs[0].currency : 'N/A';
  };

  groupBy = (list, keyGetter) => {
    const map = new Map();
    list.forEach(item => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  };

  groupBookings = bookings => {
    const map = this.groupBy(
      bookings,
      b => `${b.checkInDate}-${b.checkOutDate}-${b.bookingUser.name}-${b.accommodation.priceCategory.name}`
    );
    return Array.from(map, ([key, value]) => value);
  };

  fetchBookings = async () => {
    const { startDate, endDate, startDateValid, endDateValid } = this.state;
    if (startDateValid && endDateValid) {
      const golfClubId = this.getGolfClubId();

      this.setState({ loading: true });
      try {
        const book = await accommodationBookingService.getAll(golfClubId, startDate, endDate);
        const bookings = this.groupBookings(book.data);

        const accommodationBookingsPeriod = this.getAccommodationBookingsPeriod(bookings);

        this.setState({ loading: false, accommodationBookingsPeriod });
      } catch (error) {
        this.setState({ loading: false });
      }
    }
  };

  getAccommodationBookingsPeriod = accommodationBookings => {
    const accommodationBookingsPeriod = [];

    for (const accommodationBooking of accommodationBookings) {
      const { numberOfNights, totalIncome, totalCommission } = accommodationBooking.reduce(
        (acc, item) => {
          acc.numberOfNights += daysBetween(item.checkInDate, item.checkOutDate);
          acc.totalIncome += item.priceInclVat;
          acc.totalCommission += item.commission;
          return acc;
        },
        { numberOfNights: 0, totalIncome: 0, totalCommission: 0 }
      );

      const [booking] = accommodationBooking;

      accommodationBookingsPeriod.push({
        checkInDate: booking.checkInDate,
        checkOutDate: booking.checkOutDate,
        priceCategoryName: this.props.translate(`accommodation.${booking.accommodation.priceCategory.name}`),
        slots: accommodationBooking.length,
        customerName: booking.bookingUser.name,
        comment: booking.comment,
        numberOfGuests: booking.numberOfGuests,
        numberOfNights,
        totalIncome,
        totalCommission
      });
    }

    // sort by checkin date desc
    accommodationBookingsPeriod.sort(function (a, b) {
      return new Date(b.checkInDate).getTime() - new Date(a.checkInDate).getTime();
    });

    return accommodationBookingsPeriod;
  };

  sumData = d => {
    const summed = {};
    for (const { checkInDate, checkOutDate, totalIncome, totalCommission, slots, numberOfGuests } of d) {
      // By using a unique key for each year/month combi you can easily access the
      // intermedeiate result and add another value.
      const key = checkInDate;

      // If it does not yet exist, create an empty record for this year/month
      if (!(key in summed)) {
        summed[key] = {
          checkInDate,
          totalIncome: 0,
          totalCommission: 0,
          slots: 0,
          numberOfGuests: 0,
          numberOfNights: 0
        };
      }

      // Grab the intermediate values and add amount to sum and to values
      summed[key].totalIncome += totalIncome;
      summed[key].totalCommission += totalCommission;
      summed[key].slots += slots;
      summed[key].numberOfGuests += numberOfGuests || 0;
      summed[key].numberOfNights += daysBetween(checkInDate, checkOutDate);
    }
    return Object.values(summed);
  };

  render() {
    const { startDate, endDate, startDateValid, endDateValid, loading, accommodationBookingsPeriod } = this.state;

    const accommodationBookingPeriodCsvData = {
      data: accommodationBookingsPeriod,
      headers: this.accommodationBookingPeriodHeaders,
      filename: `${startDate} to ${endDate} ${this.props.translate('club-admin.accommodation-booking-period')}.csv`,
      separator: ';'
    };

    const golfClubCurrency = this.getGolfClubCurrency();

    const summedData = this.sumData(accommodationBookingsPeriod);

    let checkInDate = null;

    if (this.props.active) {
      return (
        <Translate>
          {({ translate }) => (
            <Segment>
              <div className="p-1">
                <ReportDateComponent
                  startDate={startDate}
                  startDateValid={startDateValid}
                  endDate={endDate}
                  endDateValid={endDateValid}
                  loading={loading}
                  fetchBookings={this.fetchBookings}
                  validateDatetimeChange={this.validateDatetimeChange}
                />
                <Grid>
                  <Grid.Column floated="left" width="5">
                    <Translate id="club-admin.accommodations" />
                  </Grid.Column>
                  {accommodationBookingsPeriod?.length > 0 && (
                    <Grid.Column floated="right" width="5" textAlign="right">
                      <CSVLink {...accommodationBookingPeriodCsvData}>{translate('club-admin.export-to-csv')}</CSVLink>
                    </Grid.Column>
                  )}
                </Grid>
                <Segment loading={loading}>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          <Translate id="club-admin.checkin-date" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="club-admin.checkout-date" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="club-admin.category" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="club-admin.no-of-slots" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="club-admin.no-of-nights" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="club-admin.report-tabs.guestlist" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="club-admin.customer-name" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="club-admin.total-income" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="club-admin.total-commission" />
                        </Table.HeaderCell>
                        {/* <Table.HeaderCell>
                          <Translate id="club-admin.of-which-vat" />
                        </Table.HeaderCell> */}
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {accommodationBookingsPeriod?.map((booking, i) => {
                        const lastCheckInDate = checkInDate;
                        let summaryRow = null;
                        let lastSummaryRow = null;

                        if (checkInDate && checkInDate !== booking.checkInDate) {
                          summaryRow = summedData.find(data => data.checkInDate === lastCheckInDate);
                        } else if (i === accommodationBookingsPeriod.length - 1) {
                          lastSummaryRow = summedData.find(data => data.checkInDate === booking.checkInDate);
                        }

                        checkInDate = booking.checkInDate;

                        return (
                          <React.Fragment key={i}>
                            {summaryRow && (
                              <AccommodationReportSumRow golfClubCurrency={golfClubCurrency} summaryRow={summaryRow} />
                            )}
                            <Table.Row>
                              <Table.Cell>{booking.checkInDate}</Table.Cell>
                              <Table.Cell>{booking.checkOutDate}</Table.Cell>
                              <Table.Cell>{booking.priceCategoryName}</Table.Cell>
                              <Table.Cell>{booking.slots}</Table.Cell>
                              <Table.Cell>{daysBetween(booking.checkInDate, booking.checkOutDate)}</Table.Cell>
                              <Table.Cell>{booking.numberOfGuests}</Table.Cell>
                              <Table.Cell>
                                {booking.customerName}{' '}
                                {booking.comment && (
                                  <Popup content={booking.comment} trigger={<Button size="mini" icon="comment" />} />
                                )}
                              </Table.Cell>
                              <Table.Cell textAlign="right">{`${booking.totalIncome
                                .toFixed(2)
                                .replace('.', ',')} ${golfClubCurrency}`}</Table.Cell>
                              <Table.Cell textAlign="right">{`${booking.totalCommission
                                .toFixed(2)
                                .replace('.', ',')} ${golfClubCurrency}`}</Table.Cell>
                              {/* <Table.Cell>{Math.round(amount * (b.priceInclVat - b.priceExclVat))} {golfClubCurrency}</Table.Cell> */}
                            </Table.Row>
                            {lastSummaryRow && (
                              <AccommodationReportSumRow
                                golfClubCurrency={golfClubCurrency}
                                summaryRow={lastSummaryRow}
                              />
                            )}
                          </React.Fragment>
                        );
                      })}
                    </Table.Body>
                    <Table.Footer>
                      {accommodationBookingsPeriod?.length > 0 && (
                        <Table.Row key="footer">
                          <Table.HeaderCell style={{ fontWeight: 'bold' }}>
                            {translate('club-admin.sum')}
                          </Table.HeaderCell>
                          <Table.HeaderCell />
                          <Table.HeaderCell />
                          <Table.HeaderCell />
                          <Table.HeaderCell />
                          <Table.HeaderCell />
                          <Table.HeaderCell />
                          <Table.HeaderCell style={{ fontWeight: 'bold' }} textAlign="right">
                            {accommodationBookingsPeriod
                              .reduce((acc, curr) => acc + curr.totalIncome, 0)
                              .toFixed(2)
                              .replace('.', ',')}{' '}
                            {golfClubCurrency}
                          </Table.HeaderCell>
                          <Table.HeaderCell style={{ fontWeight: 'bold' }} textAlign="right">
                            {accommodationBookingsPeriod
                              .reduce((acc, curr) => acc + curr.totalCommission, 0)
                              .toFixed(2)
                              .replace('.', ',')}{' '}
                            {golfClubCurrency}
                          </Table.HeaderCell>
                          {/* <Table.Cell>
                            {this.state.bookings
                              .flat()
                              .reduce((acc, curr) => acc + Math.round(curr.priceInclVat - curr.priceExclVat), 0)}{' '}
                            {golfClubCurrency}
                          </Table.Cell> */}
                        </Table.Row>
                      )}
                    </Table.Footer>
                  </Table>
                </Segment>
              </div>
            </Segment>
          )}
        </Translate>
      );
    }
    return null;
  }
}

function AccommodationReportSumRow({ summaryRow, golfClubCurrency }) {
  if (summaryRow) {
    return (
      <Table.Row>
        <Table.Cell>&nbsp;</Table.Cell>
        <Table.Cell>&nbsp;</Table.Cell>
        <Table.Cell>&nbsp;</Table.Cell>
        <Table.Cell style={{ fontWeight: 'bold' }}>{summaryRow.slots}</Table.Cell>
        <Table.Cell style={{ fontWeight: 'bold' }}>{summaryRow.numberOfNights}</Table.Cell>
        <Table.Cell style={{ fontWeight: 'bold' }}>{summaryRow.numberOfGuests}</Table.Cell>
        <Table.Cell>&nbsp;</Table.Cell>
        <Table.Cell style={{ fontWeight: 'bold' }} textAlign="right">{`${summaryRow.totalIncome
          .toFixed(2)
          .replace('.', ',')} ${golfClubCurrency}`}</Table.Cell>
        <Table.Cell style={{ fontWeight: 'bold' }} textAlign="right">{`${summaryRow.totalCommission
          .toFixed(2)
          .replace('.', ',')} ${golfClubCurrency}`}</Table.Cell>
      </Table.Row>
    );
  }

  return null;
}
