import React, { useEffect, useState } from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { Button, DropdownItemProps, Form, Icon, List, ListItem, Message, Modal, Segment } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import { createOrUpdatePackage, getProductAddons, IPackage } from '../../core/services/product.service';
import { dateRegex } from '../../utils';
import { ErrorMessage } from '../ErrorMessage';
import { PriceCategory } from './PackagesList';

export interface PackageEditProps extends LocalizeContextProps {
  editPackage: IPackage;
  currency: string;
  priceCategories: PriceCategory[];
  onCancelClick: () => void;
  onSaved: (packagesList: IPackage[]) => void;
}

export interface IFormPackageAddon {
  count: number;
  productAddonId: number | null;
}

function PackageEdit(props: PackageEditProps) {
  const { editPackage, onCancelClick, onSaved, translate } = props;

  const chargeTypes: DropdownItemProps[] = [
    { text: translate('packages.charge-types.per-person-day'), value: 2 },
    { text: translate('packages.charge-types.per-person-fixed-nights'), value: 1 }
    // ,
    // { text: translate('packages.charge-types.per-booking'), value: 0 }
  ];

  const weekdays: DropdownItemProps[] = moment.weekdays(true).map((day, index) => ({
    text: day,
    value: index + 1
  }));

  weekdays.push({ text: translate('default.public-holidays'), value: 8 });

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [productAddonsSelect, setProductAddonsSelect] = useState<DropdownItemProps[]>([]);
  const [chargeType, setChargeType] = useState<number>(props.editPackage.chargeType);

  const { register, handleSubmit, errors, control, reset } = useForm<IPackage>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: editPackage
  });

  const {
    fields: pricingFields,
    append: appendField,
    remove: removeField
  } = useFieldArray({
    control,
    name: 'pricings'
  });

  const {
    fields: addonFields,
    append: addAddon,
    remove: removeAddon
  } = useFieldArray({
    control,
    name: 'productAddons'
  });

  useEffect(() => {
    setLoading(true);
    getProductAddons()
      .then(results => {
        setProductAddonsSelect(
          results.map(addon => ({ text: translate(`product-addons.type-names.${addon.name}`), value: addon.id }))
        );

        reset();
      })
      .finally(() => setLoading(false));
  }, [reset, translate]);

  const onSubmit = async (data: any) => {
    setLoading(true);

    try {
      let dataToSave = {
        ...editPackage,
        ...data
      };

      if (dataToSave.priceCategories) {
        const newPriceCategories = dataToSave.priceCategories.map((pc: any) => ({
          rentalUnitTypeId: props.priceCategories.find(p => p.value === pc)?.accommodationTypeId,
          priceCategoryId: pc
        }));

        dataToSave = {
          ...dataToSave,
          priceCategories: newPriceCategories
        };
      }

      const packages = await createOrUpdatePackage(dataToSave);
      onSaved(packages);
    } catch (e) {
      if (typeof e === 'string') {
        setErrorMessage(e);
      } else if (e instanceof Error) {
        setErrorMessage(e.message); // works, `e` narrowed to Error
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open className="position-unset">
      <Modal.Header>
        {editPackage.dbId !== -1 ? translate('packages.update-modal-title') : translate('packages.add-modal-title')}
      </Modal.Header>
      <Modal.Content>
        <Segment loading={loading} basic>
          <Form>
            <List>
              {editPackage.isActivated && (
                <ListItem style={{ marginBottom: 15 }}>
                  <Message color="yellow" content={translate('packages.activated-package-info')} />
                </ListItem>
              )}
              {editPackage.dbId === -1 && (
                <ListItem style={{ marginBottom: 15 }}>
                  <Message color="blue" content={translate('packages.new-package-info')} />
                </ListItem>
              )}

              <ListItem>
                <Form.Group widths="equal">
                  <Form.Field error={!!errors.name}>
                    <label>
                      <Translate id="packages.headers.name" />
                    </label>
                    <input
                      type="text"
                      name="name"
                      defaultValue={editPackage.name}
                      placeholder={translate('packages.headers.name').toString()}
                      ref={register({ required: true })}
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field error={!!errors.description}>
                    <label>
                      <Translate id="default.description" />
                    </label>
                    <input
                      type="text"
                      name="description"
                      defaultValue={editPackage.description}
                      placeholder={translate('default.description').toString()}
                      ref={register()}
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field error={!!errors.priceCategories}>
                    <label>
                      <Translate id="rental-units.category" />
                    </label>
                    <Controller
                      name="priceCategories"
                      control={control}
                      defaultValue={editPackage.priceCategories}
                      rules={{
                        validate: { oneSelected: data => data.length > 0 || 'At least one needs to be selected' }
                      }}
                      render={({ onChange, value: nValue }) => (
                        <Form.Select
                          clearable
                          multiple
                          placeholder={translate('rental-units.category').toString()}
                          options={props.priceCategories.map(pc => ({
                            value: pc.value,
                            text: pc.text
                          }))}
                          value={nValue}
                          onChange={(_e, data) => onChange(data.value)}
                        />
                      )}
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field error={!!errors.chargeType} width={8}>
                    <label>
                      <Translate id="packages.charge-type" />
                    </label>
                    <Controller
                      name="chargeType"
                      control={control}
                      defaultValue={editPackage.chargeType}
                      rules={{ required: true }}
                      render={({ onChange, value: nValue }) => (
                        <Form.Select
                          placeholder={translate('packages.charge-type').toString()}
                          options={chargeTypes}
                          value={nValue}
                          onChange={(_e, data) => {
                            onChange(data.value);
                            setChargeType(Number(data.value));
                          }}
                        />
                      )}
                    />
                  </Form.Field>

                  {chargeType !== 0 && (
                    <Form.Field width={8}>
                      <label>
                        <Translate id="packages.headers.nights" />
                      </label>
                      <Controller
                        defaultValue={editPackage.nightsCount}
                        render={p => <input {...p} type="number" />}
                        name="nightsCount"
                        control={control}
                        rules={{ required: true }}
                      />
                    </Form.Field>
                  )}
                </Form.Group>

                <Form.Group>
                  <Form.Field width={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <label>{translate('packages.headers.addon')}</label>
                  </Form.Field>
                  <Form.Field width={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <label>{translate('product-addons.headers.count')}</label>
                  </Form.Field>
                  <Form.Field width={1}>
                    <Button color="green" icon onClick={() => addAddon({ productAddonId: null, count: 1, dbId: -1 })}>
                      <Icon name="plus" />
                    </Button>
                  </Form.Field>
                </Form.Group>

                {addonFields.length === 0 && (
                  <Form.Group>
                    <Form.Field width={12} style={{ textAlign: 'center' }}>
                      {translate('packages.add-one-addon')}
                    </Form.Field>
                  </Form.Group>
                )}

                {addonFields.map((item, index) => (
                  <Form.Group key={item.id}>
                    <Form.Field width={6}>
                      <Controller
                        defaultValue={item.productAddonId}
                        name={`productAddons.${index}.productAddonId`}
                        control={control}
                        rules={{ required: true }}
                        render={({ onChange, value: nValue }) => (
                          <Form.Select
                            placeholder={translate('packages.headers.addon').toString()}
                            options={productAddonsSelect}
                            value={nValue}
                            onChange={(_e, data) => onChange(data.value)}
                          />
                        )}
                      />
                    </Form.Field>
                    <Form.Field width={6} error={!!(errors.productAddons && errors.productAddons[index]?.count)}>
                      <input
                        type="number"
                        name={`productAddons.${index}.count`}
                        defaultValue={item.count}
                        ref={register({ required: true, min: 1 })}
                      />
                      <div style={{ display: 'none' }}>
                        <Controller
                          defaultValue={item.dbId}
                          render={p => <input {...p} type="number" />}
                          name={`productAddons.${index}.dbId`}
                          control={control}
                        />
                      </div>
                    </Form.Field>
                    <Form.Field width={1}>
                      <Button color="red" icon onClick={() => removeAddon(index)} size="medium">
                        <Icon name="trash" />
                      </Button>
                    </Form.Field>
                  </Form.Group>
                ))}

                <Form.Group>
                  <Form.Field width={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <label>
                      {chargeType === 0
                        ? translate('packages.headers.min-nights')
                        : chargeType === 2
                        ? translate('packages.headers.valid-from-day')
                        : translate('packages.headers.persons')}
                    </label>
                  </Form.Field>
                  {chargeType === 0 && (
                    <Form.Field width={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
                      <label>{translate('packages.headers.max-nights')}</label>
                    </Form.Field>
                  )}
                  {chargeType === 2 && (
                    <Form.Field width={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
                      <label>{translate('packages.headers.valid-to-day')}</label>
                    </Form.Field>
                  )}
                  <Form.Field width={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <label>{translate('packages.headers.price')}</label>
                  </Form.Field>
                  <Form.Field width={3} style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <label>{translate('packages.headers.high-season-price')}</label>
                  </Form.Field>
                  <Form.Field width={1}>
                    <Button
                      color="green"
                      icon
                      onClick={() => {
                        if (chargeType === 2) {
                          appendField({ minCount: 1, maxCount: 7, price: 0, dbId: -1 });
                        } else {
                          const max =
                            (pricingFields.length > 0 ? Math.max(...pricingFields.map(item => item.minCount)) : 0) + 1;

                          appendField({ minCount: max, maxCount: max, price: 0, dbId: -1 });
                        }
                      }}
                    >
                      <Icon name="plus" />
                    </Button>
                  </Form.Field>
                </Form.Group>

                {pricingFields.length === 0 && (
                  <Form.Group>
                    <Form.Field width={12} style={{ textAlign: 'center' }}>
                      {translate('packages.add-one-price')}
                    </Form.Field>
                  </Form.Group>
                )}

                {pricingFields.map((item, index) => (
                  <Form.Group key={item.id}>
                    <Form.Field width={4}>
                      {chargeType === 2 ? (
                        <Controller
                          defaultValue={item.minCount}
                          name={`pricings.${index}.minCount`}
                          control={control}
                          rules={{ required: true }}
                          render={({ onChange, value: nValue }) => (
                            <Form.Select
                              options={weekdays}
                              value={nValue}
                              onChange={(_e, data) => onChange(data.value)}
                            />
                          )}
                        />
                      ) : (
                        <Controller
                          defaultValue={item.minCount}
                          render={p => <input {...p} type="number" />}
                          name={`pricings.${index}.minCount`}
                          control={control}
                          rules={{ required: true }}
                        />
                      )}
                    </Form.Field>
                    {chargeType !== 1 && (
                      <Form.Field width={4}>
                        {chargeType === 2 ? (
                          <Controller
                            defaultValue={item.maxCount}
                            name={`pricings.${index}.maxCount`}
                            control={control}
                            rules={{ required: true }}
                            render={({ onChange, value: nValue }) => (
                              <Form.Select
                                options={weekdays}
                                value={nValue}
                                onChange={(_e, data) => onChange(data.value)}
                              />
                            )}
                          />
                        ) : (
                          <Controller
                            defaultValue={item.maxCount}
                            render={p => <input {...p} type="number" />}
                            name={`pricings.${index}.maxCount`}
                            control={control}
                            rules={{ required: true }}
                          />
                        )}
                      </Form.Field>
                    )}
                    <Form.Field width={4}>
                      <Controller
                        defaultValue={item.price}
                        render={p => <input {...p} type="number" />}
                        name={`pricings.${index}.price`}
                        control={control}
                        rules={{ required: true }}
                      />
                      <div style={{ display: 'none' }}>
                        <Controller
                          defaultValue={item.dbId}
                          render={p => <input {...p} type="number" />}
                          name={`pricings.${index}.dbId`}
                          control={control}
                        />
                      </div>
                    </Form.Field>
                    <Form.Field width={3}>
                      <Controller
                        defaultValue={item.highSeasonPrice}
                        render={p => <input {...p} type="number" />}
                        name={`pricings.${index}.highSeasonPrice`}
                        control={control}
                      />
                    </Form.Field>
                    <Form.Field width={1}>
                      <Button color="red" icon onClick={() => removeField(index)} size="medium">
                        <Icon name="trash" />
                      </Button>
                    </Form.Field>
                  </Form.Group>
                ))}

                <Form.Group widths="equal">
                  <Form.Field error={!!errors.highSeasonStart}>
                    <label>
                      <Translate id="packages.headers.high-season-starts" />
                    </label>
                    <Controller
                      name="highSeasonStart"
                      control={control}
                      defaultValue={editPackage.highSeasonStart?.substring(0, 10)}
                      rules={{ required: false, pattern: dateRegex }}
                      render={({ onChange, value }) => (
                        <DateInput
                          closable
                          dateFormat="YYYY-MM-DD"
                          localization="sv"
                          placeholder={translate('packages.headers.high-season-starts')}
                          value={value}
                          onChange={(_e, data) => onChange(data.value)}
                        />
                      )}
                    />
                  </Form.Field>
                  <Form.Field error={!!errors.highSeasonEnd}>
                    <label>
                      <Translate id="packages.headers.high-season-ends" />
                    </label>
                    <Controller
                      name="highSeasonEnd"
                      control={control}
                      defaultValue={editPackage.highSeasonEnd?.substring(0, 10)}
                      rules={{ required: false, pattern: dateRegex }}
                      render={({ onChange, value }) => (
                        <DateInput
                          closable
                          dateFormat="YYYY-MM-DD"
                          localization="sv"
                          placeholder={translate('packages.headers.high-season-ends')}
                          value={value}
                          onChange={(_e, data) => onChange(data.value)}
                        />
                      )}
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field error={!!errors.validFrom}>
                    <label>
                      <Translate id="product-addons.valid-from" />
                    </label>
                    <Controller
                      name="validFrom"
                      control={control}
                      defaultValue={editPackage.validFrom.substring(0, 10)}
                      rules={{ required: true, pattern: dateRegex }}
                      render={({ onChange, value }) => (
                        <DateInput
                          closable
                          dateFormat="YYYY-MM-DD"
                          localization="sv"
                          placeholder={translate('product-addons.valid-from')}
                          value={value}
                          onChange={(_e, data) => onChange(data.value)}
                        />
                      )}
                    />
                  </Form.Field>
                  <Form.Field error={!!errors.validTo}>
                    <label>
                      <Translate id="product-addons.valid-to" />
                    </label>
                    <Controller
                      name="validTo"
                      control={control}
                      defaultValue={editPackage.validTo.substring(0, 10)}
                      rules={{ required: true, pattern: dateRegex }}
                      render={({ onChange, value }) => (
                        <DateInput
                          closable
                          dateFormat="YYYY-MM-DD"
                          localization="sv"
                          placeholder={translate('product-addons.valid-to')}
                          value={value}
                          onChange={(_e, data) => onChange(data.value)}
                        />
                      )}
                    />
                  </Form.Field>
                </Form.Group>
              </ListItem>
            </List>
          </Form>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <ErrorMessage error={errorMessage} />
        <Button color="green" size="small" onClick={handleSubmit(onSubmit)} loading={loading} disabled={loading}>
          <Translate id="default.save" />
        </Button>
        <Button size="small" onClick={onCancelClick} disabled={loading}>
          <Translate id="default.cancel" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default withLocalize(PackageEdit);
