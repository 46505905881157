import { all, takeLatest } from 'redux-saga/effects';

import { types } from '../containers/Teesheet/ducks';
import { SIGN_IN_REQUEST } from '../containers/LoginForm/ducks';
import { SIGNUP_REQUEST } from '../containers/SignupForm/ducks';
import { RESET_PASSWORD_REQUEST } from '../containers/ResetPasswordForm/ducks';
import { CHANGE_PASSWORD_REQUEST } from '../containers/ChangePasswordForm/ducks';
import { REGISTER_CLUBADMIN_REQUEST } from '../containers/ClubAdminRegisterForm/ducks';
import { clubAdminConfigTypes } from '../containers/ClubAdminConfigureForm/ducks';
import { INVITE_CLUB_ADMIN_REQUEST } from '../containers/InHouseConfigureForm/ducks';
import { accommodationTypes } from '../containers/AccommodationSheet/ducks';
import { rentalUnitTypes } from '../containers/RentalUnitSheet/ducks';

import { requestLogin, backendServiceCallWithLogin } from './login';
import {
  createBooking,
  cancelPrebooking,
  createAccommodationBooking,
  cancelAccommodationPrebooking,
  createRentalUnitBooking,
  cancelRentalUnitPrebooking
} from './booking';
import { backendServiceCall } from './backend';

export default function* rootSaga() {
  yield all([
    takeLatest(SIGN_IN_REQUEST, requestLogin),
    takeLatest(SIGNUP_REQUEST, backendServiceCallWithLogin),
    takeLatest(REGISTER_CLUBADMIN_REQUEST, backendServiceCallWithLogin),
    takeLatest(CHANGE_PASSWORD_REQUEST, backendServiceCallWithLogin),
    takeLatest(RESET_PASSWORD_REQUEST, backendServiceCall),
    takeLatest(INVITE_CLUB_ADMIN_REQUEST, backendServiceCall),
    takeLatest(types.FETCH_TEETIMES, backendServiceCall),
    takeLatest(types.CREATE_BOOKING, createBooking),
    takeLatest(types.CANCEL_BOOKING, createBooking),
    takeLatest(types.EDIT_BOOKING, createBooking),
    takeLatest(types.TEETIME_SELECTED, createBooking),
    takeLatest(types.TEETIME_DESELECTED, cancelPrebooking),
    takeLatest(clubAdminConfigTypes.FETCH_HOLIDAYS, backendServiceCall),
    takeLatest(clubAdminConfigTypes.FETCH_GOLFCLUBS, backendServiceCall),
    takeLatest(clubAdminConfigTypes.SAVE_GOLFCLUB, backendServiceCall),
    takeLatest(clubAdminConfigTypes.DELETE_GOLFCLUB, backendServiceCall),
    takeLatest(clubAdminConfigTypes.FETCH_TEETIME_SCHEDULES, backendServiceCall),
    takeLatest(clubAdminConfigTypes.SAVE_TEETIME_SCHEDULE, backendServiceCall),
    takeLatest(clubAdminConfigTypes.DELETE_TEETIME_SCHEDULE, backendServiceCall),
    takeLatest(clubAdminConfigTypes.FETCH_UNAVAILABLE_TEETIMES, backendServiceCall),
    takeLatest(clubAdminConfigTypes.SAVE_UNAVAILABLE_TEETIME, backendServiceCall),
    takeLatest(clubAdminConfigTypes.DELETE_UNAVAILABLE_TEETIME, backendServiceCall),
    takeLatest(clubAdminConfigTypes.FETCH_PRODUCTS, backendServiceCall),
    takeLatest(clubAdminConfigTypes.SAVE_PRODUCT, backendServiceCall),
    takeLatest(clubAdminConfigTypes.DELETE_PRODUCT, backendServiceCall),
    takeLatest(clubAdminConfigTypes.FETCH_CATEGORIES, backendServiceCall),
    takeLatest(clubAdminConfigTypes.SAVE_GOLFCLUB_PLAYER, backendServiceCall),
    takeLatest(clubAdminConfigTypes.DELETE_GOLFCLUB_PLAYER, backendServiceCall),
    takeLatest(clubAdminConfigTypes.FETCH_ACCOMMODATIONS, backendServiceCall),
    takeLatest(clubAdminConfigTypes.SAVE_ACCOMMODATION, backendServiceCall),
    takeLatest(clubAdminConfigTypes.SAVE_ACCOMMODATION_BULK, backendServiceCall),
    takeLatest(clubAdminConfigTypes.DELETE_ACCOMMODATION, backendServiceCall),
    takeLatest(clubAdminConfigTypes.FETCH_RENTAL_UNITS, backendServiceCall),
    takeLatest(clubAdminConfigTypes.SAVE_RENTAL_UNIT, backendServiceCall),
    takeLatest(clubAdminConfigTypes.SAVE_RENTAL_UNIT_BULK, backendServiceCall),
    takeLatest(clubAdminConfigTypes.DELETE_RENTAL_UNIT, backendServiceCall),
    takeLatest(clubAdminConfigTypes.FETCH_ACCOMMODATION_SCHEDULES, backendServiceCall),
    takeLatest(clubAdminConfigTypes.SAVE_ACCOMMODATION_SCHEDULE, backendServiceCall),
    takeLatest(clubAdminConfigTypes.DELETE_ACCOMMODATION_SCHEDULE, backendServiceCall),
    takeLatest(clubAdminConfigTypes.FETCH_UNAVAILABLE_ACCOMMODATIONS, backendServiceCall),
    takeLatest(clubAdminConfigTypes.SAVE_UNAVAILABLE_ACCOMMODATION, backendServiceCall),
    takeLatest(clubAdminConfigTypes.DELETE_UNAVAILABLE_ACCOMMODATION, backendServiceCall),
    takeLatest(accommodationTypes.FETCH_ACCOMMODATION_AVAILABILITY, backendServiceCall),
    takeLatest(accommodationTypes.ACCOMMODATION_SELECTED, createAccommodationBooking),
    takeLatest(accommodationTypes.ACCOMMODATION_DESELECTED, cancelAccommodationPrebooking),
    takeLatest(accommodationTypes.CREATE_BOOKING, createAccommodationBooking),
    takeLatest(accommodationTypes.CANCEL_BOOKING, createAccommodationBooking),
    takeLatest(accommodationTypes.EDIT_BOOKING, createAccommodationBooking),
    takeLatest(rentalUnitTypes.FETCH_RENTAL_UNIT_AVAILABILITY, backendServiceCall),
    //    takeLatest(rentalUnitTypes.RENTAL_UNIT_SELECTED, createRentalUnitBooking),
    takeLatest(rentalUnitTypes.RENTAL_UNIT_DESELECTED, cancelRentalUnitPrebooking),
    takeLatest(rentalUnitTypes.CREATE_BOOKING, createRentalUnitBooking),
    takeLatest(rentalUnitTypes.CANCEL_BOOKING, createRentalUnitBooking),
    takeLatest(rentalUnitTypes.EDIT_BOOKING, createRentalUnitBooking)
  ]);
}
