import React from 'react';
import { Button, Icon, Modal, Segment, Table } from 'semantic-ui-react';
import { Translate } from 'react-localize-redux';
import { searchOrders } from '../core/services';
import { CaravanIcon } from '../utils/icons';

// interface SearchResult {
//   orderId: number;
//   createdAt: Date;
//   units: string;
//   bookingUserName: string;
//   priceInclVat: number;
// }

interface SearchBookingResult {
  id: number;
  checkInDate: Date;
  checkOutDate: Date;
  customerName: string;
  customerEmail: string;
  price: number;
  accommodationName: string;
  accommodationTypeId: number;
}

interface OrderSearchResultProps {
  searchQuery: string;
  onClose: () => void;
  golfClubId: number;
  onClickBooking: (booking: any) => void;
}

export class OrderSearchResults extends React.Component<OrderSearchResultProps> {
  state = {
    loading: false,
    items: [] as SearchBookingResult[]
  };

  componentDidMount() {
    this.initializeData();
  }

  initializeData = async () => {
    this.setState({ loading: true });

    try {
      const orders = await searchOrders(this.props.golfClubId, this.props.searchQuery);

      if ('data' in orders) {
        this.setState({
          loading: false,
          items: orders.data
        });
      }
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading, items } = this.state;
    const { onClose } = this.props;

    return (
      <Modal open size="large" className="position-unset">
        <Modal.Header>
          <Translate id="search-orders.title" data={{ searchBy: this.props.searchQuery, orderCount: items.length }} />
        </Modal.Header>
        <Modal.Content scrolling>
          <Segment loading={loading}>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <Translate id="search-orders.id" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Translate id="default.checkin" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Translate id="default.checkout" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Translate id="search-orders.customer" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                  <Table.HeaderCell>
                    <Translate id="default.object" />
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    <Translate id="search-orders.price" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {items?.map((item: SearchBookingResult, i: number) => {
                  return (
                    <Table.Row key={i}>
                      <Table.Cell>{item.id}</Table.Cell>
                      <Table.Cell>{item.checkInDate}</Table.Cell>
                      <Table.Cell>{item.checkOutDate}</Table.Cell>
                      <Table.Cell>{item.customerName}</Table.Cell>
                      <Table.Cell>
                        {item.accommodationTypeId === 1 && <CaravanIcon />}
                        {item.accommodationTypeId === 2 && <Icon name="home" />}
                      </Table.Cell>
                      <Table.Cell>{item.accommodationName}</Table.Cell>
                      <Table.Cell singleLine textAlign="right">
                        {item.price.toFixed(2).toString().replace('.', ',')}
                      </Table.Cell>
                      <Table.Cell>
                        <Button
                          icon="folder open outline"
                          size="tiny"
                          positive
                          onClick={() => this.props.onClickBooking(item)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>
            <Translate id="default.close" />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
