import { getRequest, postRequest, deleteRequest, putRequest } from './fetch';

// TODO: move this to respective files. group by entity

export const userLogin = user => postRequest('/Accounts/Login', user);

export const userAdd = user => postRequest('200', {});
export const userUpdate = user => putRequest('200', {});
export const userDelete = user => deleteRequest('200');
export const userGet = user => getRequest('200');

export const fetchTeeTimes = (date, golfCourseId) => getRequest('/Bookings/AvailableTeeSlots', { date, golfCourseId });
export const createBooking = data => postRequest('/Bookings', data);
export const clubAdminRegistration = user => postRequest('/Accounts/RegisterClubAdmin', user);
export const userRegistration = user => postRequest('/Accounts/Register', user);
export const resetPassword = user => postRequest('/Accounts/ResetPassword', user);
export const changePassword = user => postRequest('/Accounts/ChangePassword', user);
export const fetchHolidays = () => getRequest('/Packages/PublicHolidays');
export const fetchGolfClubs = isWidgetRequest => getRequest(`/GolfClubs?isWidgetRequest=${isWidgetRequest}`);
export const fetchUnavailableTeetimes = () => getRequest('/BlockedTeeSlots');
export const fetchTeetimeSchedules = () => getRequest('/TeeSlotSchedules');
export const fetchProducts = () => getRequest('/Products');
export const fetchCategories = () => getRequest('/Categories');
export const saveUnavailableTeetime = data => postRequest('/BlockedTeeSlots', data);
export const deleteUnavailableTeetime = data => deleteRequest('/BlockedTeeSlots', data);
export const saveTeetimeSchedule = data => postRequest('/TeeSlotSchedules', data);
export const fetchGolfClubsSimple = () => getRequest('/GolfClubs/All/Simple');
export const switchGolfClub = (golfClubId, pinCode) => postRequest('/GolfClubs/Switch', { golfClubId, pinCode });
export const deleteTeetimeSchedule = data => deleteRequest('/TeeSlotSchedules', data);
export const saveGolfClub = data => postRequest('/GolfClubs', data);
export const deleteGolfClub = data => deleteRequest('/GolfClubs', data);
export const inviteClubAdmin = data => postRequest('/Accounts/InviteClubAdmin', data);
export const saveGolfClubPlayer = data => postRequest('/GolfClubs/GolfClubPlayers', data);
export const deleteGolfClubPlayer = data => deleteRequest('/GolfClubs/GolfClubPlayers', data);
export const saveProduct = data => postRequest('/Products', data);
export const deleteProduct = data => deleteRequest('/Products', data);
export const fetchAccommodations = golfClubId => getRequest(`/GolfClubs/${golfClubId}/Accommodations`);
export const saveAccommodation = (golfClubId, data) => postRequest(`/GolfClubs/${golfClubId}/Accommodations`, data);
export const saveAccommodationBulk = (golfClubId, data) =>
  postRequest(`/GolfClubs/${golfClubId}/Accommodations/Bulk`, data);
export const deleteAccommodation = (golfClubId, data) => deleteRequest(`/GolfClubs/${golfClubId}/Accommodations`, data);
export const fetchRentalUnits = golfClubId => getRequest(`/GolfClubs/${golfClubId}/RentalUnits`);
export const saveRentalUnit = (golfClubId, data) => postRequest(`/GolfClubs/${golfClubId}/RentalUnits`, data);
export const saveRentalUnitBulk = (golfClubId, data) => postRequest(`/GolfClubs/${golfClubId}/RentalUnits/Bulk`, data);
export const deleteRentalUnit = (golfClubId, data) => deleteRequest(`/GolfClubs/${golfClubId}/RentalUnits`, data);
export const fetchAccommodationSchedules = golfClubId => getRequest(`/GolfClubs/${golfClubId}/AccommodationSchedules`);
export const saveAccommodationSchedule = (golfClubId, data) =>
  postRequest(`/GolfClubs/${golfClubId}/AccommodationSchedules`, data);
export const deleteAccommodationSchedule = (golfClubId, data) =>
  deleteRequest(`/GolfClubs/${golfClubId}/AccommodationSchedules`, data);
export const fetchUnavailableAccommodations = golfClubId =>
  getRequest(`/GolfClubs/${golfClubId}/BlockedRentalUnitPeriods`);
export const saveUnavailableAccommodation = (golfClubId, data) =>
  postRequest(`/GolfClubs/${golfClubId}/BlockedRentalUnitPeriods`, data);
export const deleteUnavailableAccommodation = (golfClubId, data) =>
  deleteRequest(`/GolfClubs/${golfClubId}/BlockedRentalUnitPeriods`, data);

export const fetchDiscounts = () => getRequest('/Discounts/All');
export const fetchDiscountByCode = (golfClubId, code, rentalUnitTypeId, checkInDate, bookingCreatedAt) =>
  getRequest(`/Discounts/Code/${golfClubId}/${code}/${rentalUnitTypeId}/${checkInDate}/${bookingCreatedAt}`);
export const saveDiscount = data => postRequest('/Discounts', data);
export const deleteDiscount = data => deleteRequest('/Discounts', data);
