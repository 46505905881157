import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Translate, withLocalize } from 'react-localize-redux';
import {
  Container,
  Segment,
  Loader,
  Dimmer,
  Label,
  Icon,
  Grid,
  Message,
  Form,
  Popup,
  Modal,
  Button
} from 'semantic-ui-react';
import moment from 'moment';
import { connect } from 'react-redux';
import qs from 'qs';
import {
  PriceType,
  Source,
  BookingStatus,
  TransactionStatus,
  OnteexStatus,
  ActiveRequest,
  TransactionType
} from '../core/enums';
import Helmet from '../components/Helmet';
import { getGolfClubs, getGolfClubsLoading } from '../containers/AccommodationSheet/ducks';
// import * as api from '../utils/api';
import BookingModalView from '../components/BookingOverView/BookingModalView';
import AccommodationBookingInformation from '../components/BookingOverView/AccommodationBookingInformation';
import {
  CaravanIcon,
  getAvailableAccommodation,
  createCallbackUrl,
  extractParentParams,
  stripePromise,
  isSuccessfulBooking
} from '../utils';
import DateRangePicker from '../components/DateTimePickers/DateRangePicker';
import { BOOKING_NOT_FOUND, BOOKING_PROBLEM, PAYMENT_ERROR, RentalUnitType, UNIT_UNAVAILABLE } from '../core/constants';
import {
  accommodationBookingService,
  fetchTransactionByReferenceId,
  getSwishPaymentStatus,
  processOrder
} from '../core/services';
import { retryRequest } from '../utils/fetch';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { getBookingPrice } from '../utils/booking.util';

// moment.locale('sv');

const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
const fullSize = width >= 992;

/**
 * Returns extracted data for easy population of the overview calendar
 *
 * @param {object} availability Availability object fetched from backend.
 */

const extractavailabilityData = (availability, translate) =>
  availability?.accommodations.map(t => ({
    id: t.accommodation.id,
    name: t.accommodation.name,
    advanceDays: t.accommodation.advanceDays,
    available: t.available,
    hasElectricity: t.accommodation.hasElectricity,
    hasDrain: t.accommodation.hasDrain,
    numberOfBeds: t.accommodation.numberOfBeds,
    accommodationTypeId: t.accommodation.typeId,
    accommodationTypeName: translate(`accommodation.${t.accommodation.typeName}`),
    price: getBookingPrice({
      availability,
      priceCategoryId: t.accommodation.priceCategoryId,
      priceType: PriceType.SELL
    }),
    info: (
      <>
        <span className="info">
          {t.accommodation.numberOfBeds && (
            <Popup
              content={`${translate('accommodation.number-of-beds')} ${t.accommodation.numberOfBeds}`}
              trigger={
                <span>
                  <Icon name="bed" />
                  {t.accommodation.numberOfBeds}
                </span>
              }
            />
          )}
          {t.accommodation.hasElectricity && (
            <Popup content={translate('accommodation.electricity')} trigger={<Icon name="plug" />} />
          )}
          {t.accommodation.hasDrain && (
            <Popup content={translate('accommodation.drainage')} trigger={<Icon name="tint" />} />
          )}
        </span>
        {t.accommodation.info ? (
          <>
            <Popup
              content={t.accommodation.info}
              trigger={<span className="info-text">{t.accommodation.info.substr(0, 69)}</span>}
            />
          </>
        ) : (
          <span className="info-text" />
        )}
      </>
    )
  }));

/**
 * Returns available objects
 *
 * @param {object} availability Availability object fetched from backend.
 * @param {object} booking Current booking - Necessary so object linked to the booking is considered available.
 */
const getAvailableObjects = (availability, booking = null) =>
  availability.accommodations.map(a => ({
    key: a.accommodation.id,
    text: a.accommodation.name,
    value: a.accommodation.id,
    disabled: !(
      a.available ||
      // If only one booking exists and it's the active booking then count object as available
      (a.accommodation.id === booking?.accommodation.id && a.bookings.length === 1 && a.bookings[0]?.id === booking?.id)
    ),
    golfersonly: availability.pricings.find(p =>
      p.pricings.find(pr => pr.priceCategory.id === a.accommodation.priceCategoryId && pr.isGolfersOnly)
    )
      ? 1
      : 0
  }));

/**
/**
 * Normalize a booking to ensure we compare correct values
 *
 * @param {object} booking A booking object.
 */
const normalizeBooking = (booking, isConfirmed) => ({
  ...booking,
  days: moment(booking.checkOutDate).diff(booking.checkInDate, 'day'),
  paid: !!booking.paid,
  checkedIn: !!booking.checkedIn,
  isConfirmed,
  bookingUser: {
    userId: booking.bookingUser?.userId || '',
    name: booking.bookingUser?.name || '',
    email: booking.bookingUser?.email || '',
    golfId: booking.bookingUser?.golfId || '',
    phone: booking.bookingUser?.phone || '',
    plateNumber: booking.bookingUser?.plateNumber || ''
  }
});

/**
 * Filters all objects in data based on filters in filter object
 *
 * @param {object} filter.
 * @param {array} data.
 */
const filterAccommodations = (filter, data) => {
  let filteredAccommodations = data;
  if (Object.keys(filter).length) {
    if (filter.onlyAvailableObjects) {
      filteredAccommodations = filteredAccommodations.filter(a => a.available);
    }
    if (filter.hasDrain) {
      filteredAccommodations = filteredAccommodations.filter(a => a.hasDrain === filter.hasDrain);
    }
    if (filter.hasElectricity) {
      filteredAccommodations = filteredAccommodations.filter(a => a.hasElectricity === filter.hasElectricity);
    }
    if (filter.accommodationTypeCabin) {
      filteredAccommodations = filteredAccommodations.filter(a => a.accommodationTypeId === 2);
    }
  }

  return filteredAccommodations;
};

let golfclub = null;
let bookingHasChanged = false;
let formattedAvailabilityData = null;
let originalAvailabilityData = null;
let availableAccommodations = null;
let calendarStartDate = null;
let calendarEndDate = null;

function AccommodationsCalendar({ golfClubsLoading, golfClubs, login, translate, isWidgetRequest }) {
  [golfclub] = golfClubs;

  const [availabilityData, setAvailabilityData] = useState(null);
  const [activeBooking, setActiveBooking] = useState(null);
  const [activeRequest, setActiveRequest] = useState(null);
  const [error, setError] = useState('');
  // const [rentalUnitTypesTitle, setRentalUnitTypesTitle] = useState('');
  const [filterData, setFilterData] = useState({ onlyAvailableObjects: isWidgetRequest });
  const [isDrainFilterVisible, setIsDrainFilterVisible] = useState(false);
  const [isElFilterVisible, setIsElFilterVisible] = useState(false);
  const [rentalUnitTypeIds, setRentalUnitTypeIds] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [confirmationBooking, setConfirmationBooking] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [pendingTransaction, setPendingTransaction] = useState(null);
  const [bookingConflictError, setBookingConflictError] = useState('');
  const [checkoutUrl, setCheckoutUrl] = useState(null);
  const [showBookingUnavailable, setShowBookingUnavailable] = useState(false);
  const [svgData, setSvgData] = useState(null);
  const [token, setToken] = useState(null);
  const [swishPaymentLocation, setSwishPaymentLocation] = useState(null);

  const accommodationRentalUnitTypes = [RentalUnitType.CAMPING.id, RentalUnitType.LODGE.id];

  const enableSwishPayment = true; // window.location.search.includes('swishEnabled');

  // TODO: We might need to create a unique link that the customer can come back to an edit or cancel the booking
  // useEffect(() => {
  // (async () => {
  //   const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  //   const accommodationId = parseInt(params.accommodationId, 10);
  //   const bookingId = parseInt(params.bookingId, 10);
  //   const availability = await fetchAccommodationAvailability(params.checkin, params.checkout);
  //   const foundBooking = availability.accommodations
  //     .find(acc => acc.accommodation.id === accommodationId)
  //     .bookings.find(b => b.id === bookingId);
  //   setActiveBooking(foundBooking);
  // })();
  // }, [golfclub]);

  const swishPaymentCheckInterval = useRef();

  useEffect(() => {
    if (activeRequest === ActiveRequest.WAITING_SWISH_PAYMENT && swishPaymentLocation) {
      // ask for payment status after 15 seconds first
      setTimeout(() => {
        // set interval for payment status check every 5 seconds
        swishPaymentCheckInterval.current = setInterval(async () => {
          const status = await getSwishPaymentStatus(swishPaymentLocation);
          const paymentStatus = status?.data?.data?.paymentStatus;

          if (paymentStatus === 'PAID') {
            clearInterval(swishPaymentCheckInterval.current);
            setSwishPaymentLocation(null);
            setActiveRequest(null);

            // display message to user
            setError('payments.successfull');

            // paid, redirect user to a callback url page
            const callbackUrl = createCallbackUrl(window.location.href);

            // wait a bit and redirect after
            setTimeout(() => {
              // maybe we can create a "payment successfull page"
              window.location.href = callbackUrl;
            }, 1500);
          } else if (paymentStatus === 'DECLINED') {
            clearInterval(swishPaymentCheckInterval.current);
            setSwishPaymentLocation(null);
            setActiveRequest(null);
            setError(translate('error.swish-declined'));

            // declined, try again?
          } else if (paymentStatus === 'ERROR') {
            clearInterval(swishPaymentCheckInterval.current);
            setSwishPaymentLocation(null);
            setActiveRequest(null);
            setError(translate('error.swish-error'));

            // error, try again?
          }
        }, 4000);
      }, 10000);
    }

    if (swishPaymentCheckInterval.current && activeRequest !== ActiveRequest.WAITING_SWISH_PAYMENT) {
      clearInterval(swishPaymentCheckInterval.current);
    }

    return () => {
      if (swishPaymentCheckInterval && swishPaymentCheckInterval.current) {
        clearInterval(swishPaymentCheckInterval.current);
      }
    };
  }, [activeRequest, swishPaymentLocation, translate]);

  useEffect(() => {
    // const fetchCategories = async () => {
    //   const { data } = await api.fetchCategories();
    //   if (data) {
    //     const { accommodationTypes } = data;
    //     const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    //     const rentalUnitTypesId = params['rental-unit-type']
    //       ? params['rental-unit-type'].split(',').map(t => parseInt(t, 10))
    //       : null;

    //     setRentalUnitTypeIds(rentalUnitTypesId);

    //     const bookingRentalUnitTypes = (
    //       rentalUnitTypesId?.length
    //         ? accommodationTypes.filter(x => rentalUnitTypesId.includes(x.id))
    //         : accommodationTypes
    //     )
    //       .map(x => translate(`accommodation.${x.name}`))
    //       .join('/');

    //     setRentalUnitTypesTitle(bookingRentalUnitTypes);
    //   }
    // };

    const setRentalUnitTypes = () => {
      const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

      const rentalUnitTypesId = params['rental-unit-type']
        ? params['rental-unit-type'].split(',').map(t => parseInt(t, 10))
        : null;

      setRentalUnitTypeIds(rentalUnitTypesId);
    };

    if (isWidgetRequest) {
      // fetchCategories();
      setRentalUnitTypes();
    }
  }, [isWidgetRequest]);

  useEffect(() => {
    const getTransactionStatus = async () => {
      const searchParams = extractParentParams(window.location.search);
      if (searchParams?.onteexClientReferenceId) {
        setActiveRequest(ActiveRequest.FETCHING_ORDER);
        setOpenModal(true);

        const { data: transactionData } = await fetchTransactionByReferenceId(searchParams.onteexClientReferenceId);
        let { data: bookingData } = await accommodationBookingService.getById(searchParams.onteexBookingId);

        if (bookingData) {
          if (searchParams.onteexStatus === OnteexStatus.SUCCESS) {
            let isBookingSuccess = isSuccessfulBooking(bookingData);

            const result = await retryRequest(() => accommodationBookingService.getById(searchParams.onteexBookingId), {
              isSuccess: isBookingSuccess,
              condition: isSuccessfulBooking,
              retryAttempt: 3,
              interval: 2000
            });
            isBookingSuccess = result.isSuccess;

            bookingData = result?.data ?? bookingData;
            setPaymentStatus(isBookingSuccess ? TransactionStatus.COMPLETED : TransactionStatus.NOT_COMPLETED);
            setConfirmationBooking(bookingData);
            setBookingConflictError(isBookingSuccess ? null : translate(BOOKING_PROBLEM));
          } else {
            setOpenModal(false);
          }

          if (
            searchParams.onteexStatus === OnteexStatus.CANCEL &&
            bookingData.status === BookingStatus.PREBOOKED &&
            transactionData.status === TransactionStatus.NOT_COMPLETED
          ) {
            setPaymentStatus(TransactionStatus.NOT_COMPLETED);
            setPendingTransaction(transactionData);
            setActiveBooking(normalizeBooking(bookingData, false));
          }
        } else {
          setBookingConflictError(translate(BOOKING_NOT_FOUND));
        }
        setActiveRequest(null);
      }
    };

    getTransactionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAccommodationAvailability = async (checkInDate = null, checkOutDate = null) => {
    if (!calendarStartDate || !golfclub) {
      return null;
    }

    originalAvailabilityData = null;
    formattedAvailabilityData = null;
    setActiveRequest('fetch');
    setError(null);
    setAvailabilityData(null);

    let ciDate;
    let coDate;
    if (checkInDate) {
      ciDate = checkInDate;
      coDate = checkOutDate;
    } else {
      ciDate = calendarStartDate.format('YYYY-MM-DD');
      coDate = calendarEndDate.format('YYYY-MM-DD');
    }

    const { error: fetchError, data: availability } = await accommodationBookingService.getAvailableAccommodations(
      golfclub.id,
      ciDate,
      coDate,
      true
    );

    if (fetchError) {
      setError(fetchError.error || 'Tillgänliga objekt kunde inte hämtas, vänligen försök på nytt.');
      setActiveRequest(null);
    } else {
      if (rentalUnitTypeIds && rentalUnitTypeIds.length > 0) {
        availability.accommodations = availability.accommodations.filter(x =>
          rentalUnitTypeIds?.includes(x.accommodation.typeId)
        );
      }

      originalAvailabilityData = availability;
      formattedAvailabilityData = extractavailabilityData(availability, translate);
      availableAccommodations = getAvailableObjects(availability);

      // let isLodgeAvailable = availability.accommodations.some(acc => acc.accommodation.type.id === RentalUnitType.LODGE.id);

      // camping lot with electricity exists
      const isWithElectricityAvailable = availability.accommodations.some(
        acc => acc.accommodation.hasElectricity === true && acc.accommodation.typeId === RentalUnitType.CAMPING.id
      );

      // anything without electricity exists
      const isWithoutElectricityAvailable = availability.accommodations.some(
        acc => acc.accommodation.hasElectricity !== true
      );

      setIsElFilterVisible(isWithElectricityAvailable && isWithoutElectricityAvailable);

      // camping lot with drain exists
      const isWithDrainAvailable = availability.accommodations.some(
        acc => acc.accommodation.hasDrain === true && acc.accommodation.typeId === RentalUnitType.CAMPING.id
      );
      // anything without drain exists
      const isWithoutDrainAvailable = availability.accommodations.some(acc => acc.accommodation.hasDrain !== true);

      setIsDrainFilterVisible(isWithDrainAvailable && isWithoutDrainAvailable);

      filterAndShowAccommodations();
    }

    setActiveRequest(null);

    return availability;
  };

  const getAvailableAccommodations = async (checkInDate, checkOutDate, booking) => {
    const availability = await fetchAccommodationAvailability(checkInDate, checkOutDate, false);
    const { price, pricing } = getBookingPrice({
      availability,
      priceCategoryId: booking.accommodation.priceCategory.id,
      priceType: booking.priceType
    });
    const avilableObjects = getAvailableObjects(availability, booking);
    setActiveRequest(null);
    return { price, accommodations: avilableObjects, pricing, availability };
  };

  const updateBooking = async ({
    status,
    booking,
    isOrderConfirmed,
    customer = null,
    transactionType = null,
    swishPhoneNumber = null,
    swishPaymentType = null
  }) => {
    setSvgData(null);
    setSwishPaymentLocation(null);

    if (
      isWidgetRequest &&
      status === BookingStatus.CONFIRMED &&
      pendingTransaction?.sessionId &&
      pendingTransaction?.amount === booking.priceInclVat
    ) {
      setActiveRequest(ActiveRequest.PROCESSING_ORDER);
      setIsRedirecting(true);
      const stripe = await stripePromise(golfclub.connectedAccountId);
      stripe.redirectToCheckout({ sessionId: pendingTransaction.sessionId });
      return;
    }

    setActiveRequest(status);
    setError('');

    const bStatus = status === BookingStatus.UPDATED ? BookingStatus.CONFIRMED : status;

    const confirmBooking = {
      ...booking,
      accommodationBookingDiscounts: [],
      status: bStatus,
      source: isWidgetRequest ? Source.WIDGET : Source.ADMIN
    };

    // fill in user name from the order when empty
    confirmBooking.bookingUser.name = confirmBooking.bookingUser?.name || customer?.name;

    const { error: bookingError, data: availabilityDataNew } = await accommodationBookingService.create(confirmBooking);

    if (bookingError) {
      if (bookingError.errorCode === 'conflict') {
        setError('Tiden är upptagen av en annan bokning, hämta data på nytt för att se tillgängliga objekt');
      } else if (bookingError.error) {
        setError(bookingError.error);
      } else {
        setError(translate(`booking-status-error.${status?.toLowerCase()}`));
      }
    } else {
      bookingHasChanged = true;
      const confirmedBooking = availabilityDataNew.booking;

      if (bStatus === BookingStatus.CANCELLED) {
        onCloseBookingView();
        return;
      }

      if (bStatus === BookingStatus.CONFIRMED || isOrderConfirmed) {
        await processBookingOrder(confirmBooking, customer, transactionType, swishPhoneNumber, swishPaymentType);
      }

      // no need to set active booking when canceling prebooe
      if (confirmedBooking && bStatus !== BookingStatus.PREBOOK_CANCELLED) {
        setActiveBooking(normalizeBooking(confirmedBooking, bStatus === BookingStatus.CONFIRMED));
      }
    }

    if (!swishPaymentType) {
      setActiveRequest(null);
    }
  };

  const processBookingOrder = async (booking, customer, transactionType, swishPhoneNumber, swishPaymentType) => {
    setActiveRequest(ActiveRequest.PROCESSING_ORDER);
    setError(null);

    const callbackUrl = createCallbackUrl(window.location.href);

    const orderData = await processOrder({
      accommodationBookingId: booking.id,
      customer,
      golfClubId: golfclub.id,
      order: { isOpen: false },
      isProcessCharge: isWidgetRequest,
      isOrderConfirmed: false,
      returnUrl: callbackUrl,
      transactionType,
      swishPhoneNumber,
      swishPaymentType
    });

    if (orderData?.sessionId) {
      setIsRedirecting(true);

      setSwishPaymentLocation(null);
      setSvgData(null);

      if (orderData?.checkoutUrl && golfclub.isUsingGoogleSites) {
        // for clubs using Google Sites we need to show a modal with Proceed to payment button
        setCheckoutUrl(orderData?.checkoutUrl);

        setIsRedirecting(false);
      } else {
        // try to initialize Stripe and redirect to checkout
        try {
          const stripe = await stripePromise(golfclub.connectedAccountId);
          if (!stripe && orderData?.checkoutUrl) {
            // need a manual redirect
            setIsRedirecting(false);

            // will display a window with <a> tag to be able to redirect to checkout page
            setCheckoutUrl(orderData?.checkoutUrl);
          }

          stripe.redirectToCheckout({ sessionId: orderData.sessionId }).then(result => {
            if (result.error && orderData?.checkoutUrl) {
              // need a manual redirect
              setIsRedirecting(false);

              // will display a window with <a> tag to be able to redirect to checkout page
              setCheckoutUrl(orderData?.checkoutUrl);
            }
          });
        } catch {
          // need a manual redirect
          setIsRedirecting(false);

          // will display a window with <a> tag to be able to redirect to checkout page
          setCheckoutUrl(orderData?.checkoutUrl);
        }
      }
    } else if (orderData && transactionType === TransactionType.SwishPayment) {
      // swish payment in the widget
      if (orderData.svgData) {
        // display qr code
        setSvgData(orderData.svgData);
        setToken(orderData.token);
      }

      if (orderData.swishError) {
        try {
          const jsonError = JSON.parse(orderData.swishError);
          setError(jsonError[0].errorMessage);
        } catch {
          setError(orderData.swishError.errorMessage || orderData.swishError);
        }
      } else {
        // set location for payment check
        setSwishPaymentLocation(orderData.location);
        setActiveRequest(ActiveRequest.WAITING_SWISH_PAYMENT);
      }
    } else {
      setIsRedirecting(false);
    }

    if (!orderData) {
      setError(translate(PAYMENT_ERROR));
    }

    if (transactionType !== TransactionType.SwishPayment) {
      setActiveRequest(null);
    }
  };

  const onClickAccommodation = async (accommodation, canBook, minCheckinDate) => {
    if (!canBook) {
      setShowBookingUnavailable(moment(minCheckinDate).format('YYYY-MM-DD'));
      return;
    }

    setActiveRequest(true);
    setError('');

    const { availableAccommodation, pricing } = getAvailableAccommodation({
      accommodationId: accommodation.id,
      defaultAvailability: originalAvailabilityData
    });

    if (!pricing.pricing) {
      setError(translate(UNIT_UNAVAILABLE));
      setActiveRequest(false);
      return;
    }

    const booking = {
      accommodation: availableAccommodation.accommodation,
      golfClubId: golfclub.id,
      checkInDate: calendarStartDate.format('YYYY-MM-DD'),
      checkOutDate: calendarEndDate.format('YYYY-MM-DD'),
      priceType: PriceType.SELL,
      productAddons: [],
      isChanged: false,
      closeAllBookings: false,
      checkedIn: false,
      comment: '',
      bookingUser: {
        email: '',
        golfId: '',
        name: '',
        plateNumber: '',
        phone: '',
        noGolfId: false
      }
    };

    updateBooking({ status: BookingStatus.PREBOOKED, booking });
  };

  const onCloseBookingView = async () => {
    ReactDOM.unstable_batchedUpdates(() => {
      setActiveRequest('fetch');
      setActiveBooking(null);
      setOpenModal(false);
      setError('');
    });

    if (activeBooking.status === BookingStatus.PREBOOKED) {
      await updateBooking({ status: BookingStatus.PREBOOK_CANCELLED, booking: activeBooking });
    }

    if (bookingHasChanged) {
      await fetchAccommodationAvailability();
      bookingHasChanged = false;
    }

    setActiveRequest(null);
  };

  const onCalendarClose = ({ startDate, endDate }) => {
    if (startDate && endDate) {
      calendarStartDate = startDate;
      calendarEndDate = endDate;
      fetchAccommodationAvailability(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
    }
  };

  const filterAndShowAccommodations = (_e, data) => {
    if (_e === undefined) {
      setAvailabilityData(filterAccommodations(filterData, formattedAvailabilityData));
    } else {
      let newFilterData = {};
      const { name, checked } = data;

      newFilterData = { ...filterData, [name]: checked };
      setFilterData(newFilterData);
      setAvailabilityData(filterAccommodations(newFilterData, formattedAvailabilityData));
    }
  };

  const onWidgetPaymentOptionsClose = () => {
    if (swishPaymentCheckInterval && swishPaymentCheckInterval.current) {
      clearInterval(swishPaymentCheckInterval.current);
    }

    // clear swish data as we need to get new transaction
    setSvgData(null);
    setSwishPaymentLocation(null);

    // clear active request too
    setActiveRequest(null);

    // and clear errors
    setError(null);
  };

  return (
    <>
      <div className="app overview-calendar">
        <Helmet title="" description="" />
        <Container>
          {paymentStatus && (
            <Message
              success={paymentStatus === TransactionStatus.COMPLETED}
              error={paymentStatus === TransactionStatus.NOT_COMPLETED}
            >
              {paymentStatus === TransactionStatus.COMPLETED ? (
                <Translate id="order.payment-completed" />
              ) : (
                <Translate id="order.payment-not-completed" />
              )}
            </Message>
          )}

          <Segment className="p-computer-3">
            <Dimmer active={!!activeRequest} inverted>
              <Loader />
            </Dimmer>
            <Grid>
              <Grid.Column mobile={16}>
                {!golfClubsLoading && (
                  <h2>
                    {golfclub?.name} - <Translate id="accommodation-sheet.booking" /> {/*rentalUnitTypesTitle*/}
                  </h2>
                )}
              </Grid.Column>

              <Grid.Column mobile={16} computer={11}>
                <DateRangePicker
                  fullSize={fullSize}
                  onClose={onCalendarClose}
                  calendarInfo={translate('accommodation-sheet.see-availability')}
                  startDatePlaceholderText={translate('default.checkin')}
                  endDatePlaceholderText={translate('default.checkout')}
                />
              </Grid.Column>

              <Grid.Column mobile={16} computer={5}>
                <LanguageSwitcher />
              </Grid.Column>

              {formattedAvailabilityData?.length > 0 && (
                <>
                  <Grid.Column mobile={16}>
                    <div className="filter">
                      <h4>FILTER</h4>
                      <Grid>
                        <Grid.Column mobile={16} desktop={8} computer={4}>
                          <Form.Checkbox
                            name="onlyAvailableObjects"
                            label={translate('rental-unit.available-only')}
                            checked={filterData.onlyAvailableObjects}
                            onClick={filterAndShowAccommodations}
                          />
                        </Grid.Column>
                        {(!rentalUnitTypeIds ||
                          accommodationRentalUnitTypes.every(x => rentalUnitTypeIds?.includes(x))) && (
                          <Grid.Column mobile={16} desktop={8} computer={4}>
                            <Form.Checkbox
                              name="accommodationTypeCabin"
                              label={translate('golfclub.lodge-commission')}
                              checked={filterData.accommodationTypeCabin}
                              onClick={filterAndShowAccommodations}
                            />
                          </Grid.Column>
                        )}
                        {(!rentalUnitTypeIds || rentalUnitTypeIds.includes(RentalUnitType.CAMPING.id)) && (
                          <>
                            {isElFilterVisible && (
                              <Grid.Column mobile={16} desktop={8} computer={4}>
                                <Form.Checkbox
                                  name="hasElectricity"
                                  label="Ställplatser med el"
                                  checked={filterData.hasElectricity}
                                  onClick={filterAndShowAccommodations}
                                />
                              </Grid.Column>
                            )}
                            {isDrainFilterVisible && false && (
                              <Grid.Column mobile={16} desktop={8} computer={4}>
                                <Form.Checkbox
                                  name="hasDrain"
                                  label="Ställplatser med avlopp"
                                  checked={filterData.hasDrain}
                                  onClick={filterAndShowAccommodations}
                                />
                              </Grid.Column>
                            )}
                          </>
                        )}
                      </Grid>
                    </div>
                  </Grid.Column>
                </>
              )}

              {error && (
                <Grid.Column mobile={16} className="information mb-1 d-flex">
                  <Message color="red">{error}</Message>
                </Grid.Column>
              )}

              {availabilityData?.length === 0 && (
                <Grid.Column mobile={16} className="information mb-1 d-flex">
                  <Message color="yellow">
                    <Translate id="default.no-units-available" />
                  </Message>
                </Grid.Column>
              )}
            </Grid>

            {availabilityData?.length > 0 && (
              <Grid className="mt-1">
                <Grid.Column>
                  <div className="accommodations-list">
                    <Grid>
                      {availabilityData.map(d => (
                        <Accommodation
                          key={d.id}
                          data={d}
                          checkInDate={calendarStartDate}
                          golfClubCurrency={golfclub.currency}
                          onClick={onClickAccommodation}
                          translate={translate}
                        />
                      ))}
                    </Grid>
                  </div>
                </Grid.Column>
              </Grid>
            )}
          </Segment>
        </Container>
      </div>
      {activeBooking && (
        <BookingModalView
          accommodations={availableAccommodations}
          activeRequest={activeRequest}
          activeBooking={activeBooking}
          enableSwishPayment={enableSwishPayment}
          error={error}
          getAvailableAccommodations={getAvailableAccommodations}
          golfclub={golfclub}
          isWidgetRequest
          onWidgetPaymentOptionsClose={onWidgetPaymentOptionsClose}
          onClose={onCloseBookingView}
          pendingTransaction={pendingTransaction}
          setError={setError}
          svgData={svgData}
          token={token}
          translate={translate}
          updateBooking={updateBooking}
          isSuperAdmin={false} // widget does not care about super admins
        />
      )}

      {checkoutUrl && (
        <Modal open={checkoutUrl} size="small">
          <Modal.Header>
            <Translate id="order.proceed-to-payment" />
          </Modal.Header>
          <Modal.Content>
            <Translate id="order.proceed-to-payment-text" />
          </Modal.Content>
          <Modal.Actions>
            <a
              href={checkoutUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="ui green button"
              onClick={() => {
                // go back to the accommodation view
                ReactDOM.unstable_batchedUpdates(async () => {
                  setCheckoutUrl(null);
                  setActiveRequest(null);
                  setActiveBooking(null);
                  setOpenModal(false);
                  setError('');

                  // reload accommodation availability
                  await fetchAccommodationAvailability();
                  bookingHasChanged = false;
                });
              }}
            >
              <Translate id="order.proceed-to-payment" />
            </a>
          </Modal.Actions>
        </Modal>
      )}

      <Modal open={isRedirecting} size="tiny">
        <Modal.Content>
          <Dimmer active inverted style={{ padding: '30px' }}>
            <Loader inverted>
              <Translate id="default.payment-page-redirect" />
            </Loader>
          </Dimmer>
        </Modal.Content>
      </Modal>

      <Modal open={showBookingUnavailable} size="tiny">
        <Modal.Header>{translate('rental-unit.booking-prepare-header')}</Modal.Header>
        <Modal.Content>
          {translate('rental-unit.booking-prepare', { advanceDays: showBookingUnavailable })}
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={() => setShowBookingUnavailable(false)}>
            <Translate id="default.ok" />
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal open={openModal} size="tiny" onClose={() => setOpenModal(false)}>
        {!activeRequest ? (
          <>
            <Modal.Header>
              {!bookingConflictError && confirmationBooking ? (
                <>
                  <Icon name="check circle outline" color="green" size="large" />
                  <span className="green">{translate('default.booking-confirmed')}</span>
                </>
              ) : (
                <Grid columns={2}>
                  <Grid.Row>
                    <Grid.Column width={2}>
                      <Icon name="exclamation circle" color="red" size="large" />
                    </Grid.Column>
                    <Grid.Column width={13}>
                      <span className="red">{bookingConflictError}</span>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
            </Modal.Header>
            {!bookingConflictError && confirmationBooking && (
              <Modal.Content>
                <AccommodationBookingInformation
                  booking={confirmationBooking}
                  golfClubCurrency={golfclub?.currency || 'SEK'}
                />
              </Modal.Content>
            )}
            <Modal.Actions>
              <Button onClick={() => setOpenModal(false)}>{translate('default.close')}</Button>
            </Modal.Actions>
          </>
        ) : (
          <Loader active>{translate('default.fetching-payment')} </Loader>
        )}
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return {
    golfClubs: getGolfClubs(state),
    golfClubsLoading: getGolfClubsLoading(state),
    login: state.login
  };
}

export default withLocalize(connect(mapStateToProps, null)(AccommodationsCalendar));

function Accommodation({ data, golfClubCurrency, onClick, translate, checkInDate }) {
  const advanceDays = data.advanceDays || 0;
  const minBookingStart = moment().add(advanceDays, 'day');
  const canBook = checkInDate.isSameOrAfter(minBookingStart, 'day');

  return (
    <Grid.Column mobile={16} tablet={8} computer={8}>
      <div
        className={`unit ${data.available ? 'available' : 'occuppied'}`}
        onClick={() => data.available && onClick(data, canBook, minBookingStart)}
        onKeyPress={() => {}}
        role="button"
        tabIndex={0}
      >
        <Grid>
          <Grid.Column mobile={10} className="pb-0">
            <h5 className="mb-0">{data.name}</h5>
            {data.accommodationTypeId === 1 && <CaravanIcon />}
            {data.accommodationTypeId === 2 && <Icon name="home" />}
            <h6 className="mt-0 mb-1">{`${data.accommodationTypeName}`}</h6>
          </Grid.Column>
          <Grid.Column mobile={6} textAlign="right" className="pb-1">
            <Label color="grey" className="mb-0">
              {data.price.price} {golfClubCurrency}
            </Label>
          </Grid.Column>
          <Grid.Column mobile={16} className="pt-0">
            <p>{data.info}</p>
          </Grid.Column>
        </Grid>

        <div className="label">
          <Label className="label" color={canBook ? (data.available ? 'green' : 'red') : 'olive'}>
            {data.available ? translate('rental-unit.book') : translate('booking-availability.booked')}
          </Label>
        </div>
      </div>
    </Grid.Column>
  );
}
