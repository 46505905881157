/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import { Popup } from 'semantic-ui-react';
import { useDrag, useDrop } from 'react-dnd';
import { StatusColor } from '../../core/constants';

export const DragItemTypes = {
  BOOKING: 'booking'
};

export function BookingCell({ accommodationName, canBeDragged, cellWidth, booking, onClick }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: DragItemTypes.BOOKING,
    item: {
      type: DragItemTypes.BOOKING,
      bookingId: booking.id,
      accommodationName,
      checkinDate: booking.checkInDate,
      user: booking.name
    },
    collect: monitor => ({
      item: monitor.getItem(),
      isDragging: !!monitor.isDragging()
    }),
    canDrag: () => canBeDragged
  }));

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  return (
    <Popup
      on="hover"
      open={isPopupOpen && !isDragging}
      onClose={() => setIsPopupOpen(false)}
      onOpen={() => setIsPopupOpen(true)}
      trigger={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <div
          ref={drag}
          className={`d-flex ${booking.hasCleaning ? 'has-cleaning' : ''} ${
            booking.accommodationPackagePricingId ? 'has-package' : ''
          } ${booking.comment && booking.statusColor !== StatusColor.PASSED_INCOMPLETE_BOOKING ? 'has-comment' : ''}`}
          style={{ opacity: isDragging ? 0.5 : 1 }}
        >
          {/* div neccessary for popup to work */}
          <DivCell
            onClick={onClick}
            cellWidth={cellWidth}
            bgColor={booking.statusColor}
            name={booking.name}
            booking={booking}
            available
          />
        </div>
      }
      popperModifiers={[{ name: 'preventOverflow', options: { boundariesElement: 'offsetParent' } }]}
      // popperModifiers={{
      //   preventOverflow: {
      //     boundariesElement: 'offsetParent'
      //   }
      // }}
    >
      {booking.name && (
        <Popup.Header>
          {booking.name} {booking.id}
        </Popup.Header>
      )}
      <Popup.Content>{booking.info}</Popup.Content>
    </Popup>
  );
}

export function BlockedPeriodCell({ cellWidth, text, translate }) {
  return (
    <Popup
      trigger={
        <div className="d-flex">
          <DivCell
            name={text || translate('default.locked')}
            cellWidth={cellWidth}
            bgColor={StatusColor.BLOCKED_BOOKING}
          />
        </div>
      }
    >
      <Popup.Header>{text || translate('default.locked')}</Popup.Header>
    </Popup>
  );
}

function Cell({
  accommodationId,
  accommodationName,
  cellWidth,
  canBeDroppedTo,
  day,
  dayString,
  available,
  activeDays,
  onDrop,
  onMouseDown,
  onMouseOver,
  onMouseUp
}) {
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: DragItemTypes.BOOKING,
      canDrop: () => canBeDroppedTo,
      drop: monitor => {
        if (onDrop) {
          onDrop({
            newDate: dayString,
            accommodationId,
            accommodationName,
            bookingId: monitor.bookingId,
            checkinDate: monitor.checkinDate,
            oldAccommodationName: monitor.accommodationName,
            user: monitor.user
          });
        }
      },
      collect: monitor => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop()
      })
    }),
    [dayString]
  );

  const activeDay = activeDays && activeDays[day];

  return (
    <div
      onMouseDown={() => available && onMouseDown && onMouseDown(day)}
      onMouseUp={() => onMouseUp && onMouseUp(day)}
      onMouseOver={() => onMouseOver && onMouseOver(day)}
      role="button"
      tabIndex={0}
      onKeyPress={() => {}}
      ref={drop}
      className="cell"
      style={{
        backgroundColor: isOver && canDrop ? 'yellow' : activeDay && '#38B261',
        minWidth: cellWidth,
        maxWidth: cellWidth,
        width: cellWidth,
        cursor: available ? 'pointer' : 'default'
      }}
    >
      {day && <span className="no-select info">{day}</span>}
    </div>

    // <DivCellDrop
    //   onMouseDown={onMouseDown}
    //   onMouseUp={onMouseUp}
    //   onMouseOver={onMouseOver}
    //   activeDays={activeDays}
    //   day={day}
    //   ref={drop}
    //   isOver={isOver}
    //   cellWidth={cellWidth}
    //   name={day}
    //   available={available}
    // />
  );
}

export default Cell;

function DivCell({
  onMouseDown,
  onMouseUp,
  onMouseOver,
  onClick,
  booking,
  day,
  activeDays,
  cellWidth,
  name,
  bgColor,
  available
}) {
  const activeDay = activeDays && activeDays[day];

  return (
    <div
      onMouseDown={() => available && onMouseDown && onMouseDown(day)}
      onMouseUp={() => onMouseUp && onMouseUp(day)}
      onMouseOver={() => onMouseOver && onMouseOver(day)}
      onClick={() => onClick && onClick(booking)}
      role="button"
      tabIndex={0}
      onKeyPress={() => {}}
      className="cell"
      style={{
        backgroundColor: bgColor || (activeDay && '#38B261'),
        minWidth: cellWidth,
        maxWidth: cellWidth,
        width: cellWidth,
        cursor: available ? 'pointer' : 'default'
      }}
    >
      {name && <span className="no-select info">{name}</span>}
    </div>
  );
}

export function CellHeader({ label, cellWidth, isWeekend, index }) {
  return (
    <div
      className="cell-header"
      style={{
        minWidth: cellWidth,
        maxWidth: cellWidth,
        width: cellWidth,
        backgroundColor: isWeekend ? '#F0F0F0' : '#FFFFFF',
        borderLeft: index === 0 ? '1pt solid #ccc' : ''
      }}
    >
      <span>{label}</span>
    </div>
  );
}
