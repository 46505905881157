import React, { Component } from 'react';

import { Container } from 'semantic-ui-react';

import Helmet from '../components/Helmet';
import LoginForm from '../containers/LoginForm';

class LoginPage extends Component {
  render() {
    return (
      <div className="app app-login">
        <Helmet title="OnTee sign in!!" description="OnTee on sign in steroid!" />
        <Container className="mw-900">
          <LoginForm />
        </Container>
      </div>
    );
  }
}

export default LoginPage;
