import React from 'react';
import { Translate } from 'react-localize-redux';
import { Form, Header, Icon, List, Popup, Segment } from 'semantic-ui-react';
import { pick } from 'lodash-es';
import { ErrorMessage } from '../ErrorMessage';
import { Editor } from '@tinymce/tinymce-react';

const EmailSettingsViewItem = ({ data }) => {
  return (
    <Translate>
      {({ translate }) => (
        <>
          <Header as="h5" attached="top" block>
            {translate('golfclub.info')}
            <Popup
              trigger={<Icon name="info circle" />}
              content={
                <p>
                  <Translate id="golfclub.info-info" />
                </p>
              }
            />
          </Header>
          <Segment attached>
            <div dangerouslySetInnerHTML={{ __html: data.accommodationInfo }} />
          </Segment>
          <Segment attached="bottom">
            <div>
              {data.receiveConfirmationEmails ? (
                <Icon name="check" color="green" />
              ) : (
                <Icon name="remove" color="red" />
              )}
              {translate('golfclub.receive-confirmation-emails')}{' '}
              <Popup
                trigger={<Icon name="info circle" />}
                content={
                  <p>
                    <Translate id="golfclub.receive-confirmation-emails-info" />
                  </p>
                }
              />
            </div>
            <div>
              {data.sendCheckinReminderEmail ? <Icon name="check" color="green" /> : <Icon name="remove" color="red" />}
              {data.sendCheckinReminderEmail
                ? translate('golfclub.send-checkin-reminder-email-complete', {
                    checkinReminderEmailDays: data.checkinReminderEmailDays
                  })
                : translate('golfclub.send-checkin-reminder-email-none')}
            </div>
          </Segment>

          {data.accommodationInfoRoomsLodges && (
            <>
              <Header as="h5" attached="top" block>
                {translate('golfclub.info-rooms-lodges')}
              </Header>
              <Segment attached>
                <div dangerouslySetInnerHTML={{ __html: data.accommodationInfoRoomsLodges }} />
              </Segment>
            </>
          )}

          <Header as="h5" attached="top" block>
            {translate('golfclub.send-checkin-reminder-email-text')}
            <Popup
              trigger={<Icon name="info circle" size="small" />}
              content={
                <p>
                  <Translate id="golfclub.send-checkin-reminder-email-text-info" />
                </p>
              }
            />
          </Header>
          <Segment attached>
            <div dangerouslySetInnerHTML={{ __html: data.checkinReminderEmailText }} />
          </Segment>
        </>
      )}
    </Translate>
  );
};

class EmailSettingsEditItem extends React.Component {
  state = {
    name: '',
    isValidName: true,
    isValidConnectedAccount: true,
    accommodationInfo: '',
    admins: [],
    companies: [],
    golfCourses: [],
    golfCoursesValid: false,
    login: {},
    showUpload: false
  };

  componentDidMount = () => {
    if (!this.props.data) {
      return;
    }
    const { data, login } = this.props;

    this.setState({ ...data, login });

    this.props.dataIsValid(!!data.name);
  };

  onChange = (_e, { name, value }) => {
    this.setState({ [name]: value });
  };

  onChangeReceiveConfirmationEmails = (evt, data) => {
    this.setState({ receiveConfirmationEmails: data.checked });
  };

  onChangeSendCheckinReminderEmail = (evt, data) => {
    this.setState({ sendCheckinReminderEmail: data.checked });

    if (!data.checked) {
      this.setState({ checkinReminderEmailDays: '' });
    }
  };

  subcomponentStateUpdated = (golfCourses, golfCoursesValid) => {
    this.setState({ golfCourses, golfCoursesValid });
  };

  saveItem = () => {
    const data = pick(this.state, [
      'name',
      'accommodationInfo',
      'accommodationInfoRoomsLodges',
      'golfCourses',
      'admins',
      'companies',
      'golfCartWeekdayHours',
      'golfCartWeekendHours',
      'logoUrl',
      'imageUrl',
      'email',
      'webSite',
      'useOnlinePayment',
      'connectedAccountId',
      'campingCommission',
      'lodgeCommission',
      'golfCartCommission',
      'golfBikeCommission',
      'campingVat',
      'lodgeVat',
      'golfCartVat',
      'golfBikeVat',
      'transactionFee',
      'currency',
      'widgetDisabled',
      'receiveBookingsReport',
      'receiveConfirmationEmails',
      'sendCheckinReminderEmail',
      'checkinReminderEmailDays',
      'checkinReminderEmailText'
    ]);

    data.golfCourse = this.props.data.golfCourse;
    const hasOwnProperty = Object.prototype.hasOwnProperty.call(this.props.data, 'id');

    if (hasOwnProperty) {
      data.id = this.props.data.id;
    }
    this.props.saveAction(data, this.props.onSubmit);
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <>
            <Form id={this.props.id} onSubmit={this.saveItem}>
              <List>
                <List.Item>
                  <h3>
                    <Translate id="golfclub.receive-confirmation-emails-header" />{' '}
                  </h3>
                  <Form.Field>
                    <div className="d-flex" style={{ paddingBottom: 5 }}>
                      <label style={{ fontWeight: 'bold' }}>{translate('golfclub.info')}</label>
                      <Popup
                        trigger={<Icon name="info circle" />}
                        content={
                          <p>
                            <Translate id="golfclub.info-info" />
                          </p>
                        }
                      />
                    </div>
                    <Editor
                      apiKey="3fzqpdja773xyef71c2817u2cwi1sfe28imehb8q2uzpbqoc"
                      value={this.state.accommodationInfo}
                      init={{
                        height: 300,
                        // menubar: false,
                        plugins: [
                          'advlist autolink lists preview',
                          'visualblocks code fullscreen',
                          'paste code help wordcount'
                        ],
                        toolbar:
                          'undo redo | formatselect | link | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                        formats: {
                          p: { inline: 'span', classes: 'information' }
                        }
                      }}
                      onEditorChange={content => this.setState({ accommodationInfo: content })}
                    />
                  </Form.Field>

                  <Form.Field>
                    <div className="d-flex" style={{ paddingBottom: 5 }}>
                      <label style={{ fontWeight: 'bold' }}>{translate('golfclub.info-rooms-lodges')}</label>
                      <Popup
                        trigger={<Icon name="info circle" />}
                        content={
                          <p>
                            <Translate id="golfclub.info-info-rooms-lodges" />
                          </p>
                        }
                      />
                    </div>
                    <Editor
                      apiKey="3fzqpdja773xyef71c2817u2cwi1sfe28imehb8q2uzpbqoc"
                      value={this.state.accommodationInfoRoomsLodges}
                      init={{
                        height: 300,
                        // menubar: false,
                        plugins: [
                          'advlist autolink lists preview',
                          'visualblocks code fullscreen',
                          'paste code help wordcount'
                        ],
                        toolbar:
                          'undo redo | formatselect | link | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                        formats: {
                          p: { inline: 'span', classes: 'information' }
                        }
                      }}
                      onEditorChange={content => this.setState({ accommodationInfoRoomsLodges: content })}
                    />
                  </Form.Field>

                  <Form.Group widths="equal">
                    <Form.Field className="d-flex" style={{ marginTop: 10 }}>
                      <Form.Checkbox
                        label={translate('golfclub.receive-confirmation-emails')}
                        name="receiveConfirmationEmails"
                        onChange={this.onChangeReceiveConfirmationEmails}
                        checked={this.state.receiveConfirmationEmails}
                      />
                      <Popup
                        trigger={<Icon name="info circle" />}
                        content={
                          <p>
                            <Translate id="golfclub.receive-confirmation-emails-info" />
                          </p>
                        }
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field style={{ verticalAlign: 'middle' }}>
                      <div className="d-flex">
                        <Form.Checkbox
                          label={translate('golfclub.send-checkin-reminder-email')}
                          name="sendCheckinReminderEmail"
                          onChange={this.onChangeSendCheckinReminderEmail}
                          checked={this.state.sendCheckinReminderEmail}
                          style={{ paddingTop: 10 }}
                        />
                        <span style={{ paddingLeft: 10, paddingRight: 10 }}>
                          <Form.Input
                            style={{ width: 100 }}
                            type="number"
                            name="checkinReminderEmailDays"
                            onChange={this.onChange}
                            value={
                              this.state.checkinReminderEmailDays === 0
                                ? '0'
                                : this.state.checkinReminderEmailDays || ''
                            }
                          />
                        </span>
                        <span style={{ paddingTop: 10 }}>
                          <Translate id="golfclub.send-checkin-reminder-email-days" />
                        </span>
                      </div>
                    </Form.Field>
                  </Form.Group>

                  <Form.Field>
                    <div className="d-flex" style={{ paddingBottom: 5 }}>
                      <label style={{ fontWeight: 'bold' }}>
                        {translate('golfclub.send-checkin-reminder-email-text')}
                      </label>
                      <Popup
                        trigger={<Icon name="info circle" />}
                        content={
                          <p>
                            <Translate id="golfclub.send-checkin-reminder-email-text-info" />
                          </p>
                        }
                      />
                    </div>
                    <Editor
                      apiKey="3fzqpdja773xyef71c2817u2cwi1sfe28imehb8q2uzpbqoc"
                      value={this.state.checkinReminderEmailText}
                      init={{
                        height: 300,
                        // menubar: false,
                        plugins: [
                          'advlist autolink lists preview',
                          'visualblocks code fullscreen',
                          'paste code help wordcount'
                        ],
                        toolbar:
                          'undo redo | formatselect | link | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                        formats: {
                          p: { inline: 'span', classes: 'information' }
                        }
                      }}
                      onEditorChange={content => this.setState({ checkinReminderEmailText: content })}
                    />
                  </Form.Field>
                </List.Item>
              </List>
              <ErrorMessage error={this.props.error} />
            </Form>
          </>
        )}
      </Translate>
    );
  }
}

export { EmailSettingsViewItem, EmailSettingsEditItem };
