import * as Api from '../../utils/api';

export const clubAdminConfigTypes = {
  FETCH_HOLIDAYS: 'clubadmin/FETCH_HOLIDAYS',
  FETCH_GOLFCLUBS: 'clubadmin/FETCH_GOLFCLUBS',
  FETCH_GOLFCLUBS_SUCCESS: 'clubadmin/FETCH_GOLFCLUBS_SUCCESS',
  FETCH_GOLFCLUBS_FAILED: 'clubadmin/FETCH_GOLFCLUBS_FAILED',
  FETCH_HOLIDAYS_SUCCESS: 'clubadmin/FETCH_HOLIDAYS_SUCCESS',
  FETCH_HOLIDAYS_FAILED: 'clubadmin/FETCH_HOLIDAYS_FAILED',
  SAVE_GOLFCLUB: 'clubadmin/SAVE_GOLFCLUB',
  SAVE_GOLFCLUB_SUCCESS: 'clubadmin/SAVE_GOLFCLUB_SUCCESS',
  SAVE_GOLFCLUB_FAILED: 'clubadmin/SAVE_GOLFCLUB_FAILED',
  DELETE_GOLFCLUB: 'clubadmin/DELETE_GOLFCLUB',
  DELETE_GOLFCLUB_SUCCESS: 'clubadmin/DELETE_GOLFCLUB_SUCCESS',
  DELETE_GOLFCLUB_FAILED: 'clubadmin/DELETE_GOLFCLUB_FAILED',
  FETCH_TEETIME_SCHEDULES: 'clubadmin/FETCH_TEETIME_SCHEDULES',
  FETCH_TEETIME_SCHEDULES_SUCCESS: 'clubadmin/FETCH_TEETIME_SCHEDULES_SUCCESS',
  FETCH_TEETIME_SCHEDULES_FAILED: 'clubadmin/FETCH_TEETIME_SCHEDULES_FAILED',
  FETCH_UNAVAILABLE_TEETIMES: 'clubadmin/FETCH_UNAVAILABLE_TEETIMES',
  FETCH_UNAVAILABLE_TEETIMES_SUCCESS: 'clubadmin/FETCH_UNAVAILABLE_TEETIMES_SUCCESS',
  FETCH_UNAVAILABLE_TEETIMES_FAILED: 'clubadmin/FETCH_UNAVAILABLE_TEETIMES_FAILED',
  FETCH_PRODUCTS: 'clubadmin/FETCH_PRODUCTS',
  FETCH_PRODUCTS_SUCCESS: 'clubadmin/FETCH_PRODUCTS_SUCCESS',
  FETCH_PRODUCTS_FAILED: 'clubadmin/FETCH_PRODUCTS_FAILED',
  SAVE_PRODUCT: 'clubadmin/SAVE_PRODUCT',
  SAVE_PRODUCT_SUCCESS: 'clubadmin/SAVE_PRODUCT_SUCCESS',
  SAVE_PRODUCT_FAILED: 'clubadmin/SAVE_PRODUCT_FAILED',
  DELETE_PRODUCT: 'clubadmin/DELETE_PRODUCT',
  DELETE_PRODUCT_SUCCESS: 'clubadmin/DELETE_PRODUCT_SUCCESS',
  DELETE_PRODUCT_FAILED: 'clubadmin/DELETE_PRODUCT_FAILED',
  FETCH_CATEGORIES: 'clubadmin/FETCH_CATEGORIES',
  FETCH_CATEGORIES_SUCCESS: 'clubadmin/FETCH_CATEGORIES_SUCCESS',
  FETCH_CATEGORIES_FAILED: 'clubadmin/FETCH_CATEGORIES_FAILED',
  SAVE_UNAVAILABLE_TEETIME: 'clubadmin/SAVE_UNAVAILABLE_TEETIME',
  SAVE_UNAVAILABLE_TEETIME_SUCCESS: 'clubadmin/SAVE_UNAVAILABLE_TEETIME_SUCCESS',
  SAVE_UNAVAILABLE_TEETIME_FAILED: 'clubadmin/SAVE_UNAVAILABLE_TEETIME_FAILED',
  DELETE_UNAVAILABLE_TEETIME: 'clubadmin/DELETE_UNAVAILABLE_TEETIME',
  DELETE_UNAVAILABLE_TEETIME_SUCCESS: 'clubadmin/DELETE_UNAVAILABLE_TEETIME_SUCCESS',
  DELETE_UNAVAILABLE_TEETIME_FAILED: 'clubadmin/DELETE_UNAVAILABLE_TEETIME_FAILED',
  SAVE_TEETIME_SCHEDULE: 'clubadmin/SAVE_TEETIME_SCHEDULE',
  SAVE_TEETIME_SCHEDULE_SUCCESS: 'clubadmin/SAVE_TEETIME_SCHEDULE_SUCCESS',
  SAVE_TEETIME_SCHEDULE_FAILED: 'clubadmin/SAVE_TEETIME_SCHEDULE_FAILED',
  DELETE_TEETIME_SCHEDULE: 'clubadmin/DELETE_TEETIME_SCHEDULE',
  DELETE_TEETIME_SCHEDULE_SUCCESS: 'clubadmin/DELETE_TEETIME_SCHEDULE_SUCCESS',
  DELETE_TEETIME_SCHEDULE_FAILED: 'clubadmin/DELETE_TEETIME_SCHEDULE_FAILED',
  SAVE_GOLFCLUB_PLAYER: 'clubadmin/SAVE_GOLFCLUB_PLAYER',
  SAVE_GOLFCLUB_PLAYER_SUCCESS: 'clubadmin/SAVE_GOLFCLUB_PLAYER_SUCCESS',
  SAVE_GOLFCLUB_PLAYER_FAILED: 'clubadmin/SAVE_GOLFCLUB_PLAYER_FAILED',
  DELETE_GOLFCLUB_PLAYER: 'clubadmin/DELETE_GOLFCLUB_PLAYER',
  DELETE_GOLFCLUB_PLAYER_SUCCESS: 'clubadmin/DELETE_GOLFCLUB_PLAYER_SUCCESS',
  DELETE_GOLFCLUB_PLAYER_FAILED: 'clubadmin/DELETE_GOLFCLUB_PLAYER_FAILED',
  FETCH_ACCOMMODATIONS: 'clubadmin/FETCH_ACCOMMODATIONS',
  FETCH_ACCOMMODATIONS_SUCCESS: 'clubadmin/FETCH_ACCOMMODATIONS_SUCCESS',
  FETCH_ACCOMMODATIONS_FAILED: 'clubadmin/FETCH_ACCOMMODATIONS_FAILED',
  SAVE_ACCOMMODATION: 'clubadmin/SAVE_ACCOMMODATION',
  SAVE_ACCOMMODATION_SUCCESS: 'clubadmin/SAVE_ACCOMMODATION_SUCCESS',
  SAVE_ACCOMMODATION_FAILED: 'clubadmin/SAVE_ACCOMMODATION_FAILED',
  SAVE_ACCOMMODATION_BULK: 'clubadmin/SAVE_ACCOMMODATION_BULK',
  SAVE_ACCOMMODATION_BULK_SUCCESS: 'clubadmin/SAVE_ACCOMMODATION_BULK_SUCCESS',
  SAVE_ACCOMMODATION_BULK_FAILED: 'clubadmin/SAVE_ACCOMMODATION_BULK_FAILED',
  DELETE_ACCOMMODATION: 'clubadmin/DELETE_ACCOMMODATION',
  DELETE_ACCOMMODATION_SUCCESS: 'clubadmin/DELETE_ACCOMMODATION_SUCCESS',
  DELETE_ACCOMMODATION_FAILED: 'clubadmin/DELETE_ACCOMMODATION_FAILED',
  FETCH_RENTAL_UNITS: 'clubadmin/FETCH_RENTAL_UNITS',
  FETCH_RENTAL_UNITS_SUCCESS: 'clubadmin/FETCH_RENTAL_UNITS_SUCCESS',
  FETCH_RENTAL_UNITS_FAILED: 'clubadmin/FETCH_RENTAL_UNITS_FAILED',
  SAVE_RENTAL_UNIT: 'clubadmin/SAVE_RENTAL_UNIT',
  SAVE_RENTAL_UNIT_SUCCESS: 'clubadmin/SAVE_RENTAL_UNIT_SUCCESS',
  SAVE_RENTAL_UNIT_FAILED: 'clubadmin/SAVE_RENTAL_UNIT_FAILED',
  SAVE_RENTAL_UNIT_BULK: 'clubadmin/SAVE_RENTAL_UNIT_BULK',
  SAVE_RENTAL_UNIT_BULK_SUCCESS: 'clubadmin/SAVE_RENTAL_UNIT_BULK_SUCCESS',
  SAVE_RENTAL_UNIT_BULK_FAILED: 'clubadmin/SAVE_RENTAL_UNIT_BULK_FAILED',
  DELETE_RENTAL_UNIT: 'clubadmin/DELETE_RENTAL_UNIT',
  DELETE_RENTAL_UNIT_SUCCESS: 'clubadmin/DELETE_RENTAL_UNIT_SUCCESS',
  DELETE_RENTAL_UNIT_FAILED: 'clubadmin/DELETE_RENTAL_UNIT_FAILED',
  FETCH_ACCOMMODATION_SCHEDULES: 'clubadmin/FETCH_ACCOMMODATION_SCHEDULES',
  FETCH_ACCOMMODATION_SCHEDULES_SUCCESS: 'clubadmin/FETCH_ACCOMMODATION_SCHEDULES_SUCCESS',
  FETCH_ACCOMMODATION_SCHEDULES_FAILED: 'clubadmin/FETCH_ACCOMMODATION_SCHEDULES_FAILED',
  SAVE_ACCOMMODATION_SCHEDULE: 'clubadmin/SAVE_ACCOMMODATION_SCHEDULE',
  SAVE_ACCOMMODATION_SCHEDULE_SUCCESS: 'clubadmin/SAVE_ACCOMMODATION_SCHEDULE_SUCCESS',
  SAVE_ACCOMMODATION_SCHEDULE_FAILED: 'clubadmin/SAVE_ACCOMMODATION_SCHEDULE_FAILED',
  DELETE_ACCOMMODATION_SCHEDULE: 'clubadmin/DELETE_ACCOMMODATION_SCHEDULE',
  DELETE_ACCOMMODATION_SCHEDULE_SUCCESS: 'clubadmin/DELETE_ACCOMMODATION_SCHEDULE_SUCCESS',
  DELETE_ACCOMMODATION_SCHEDULE_FAILED: 'clubadmin/DELETE_ACCOMMODATION_SCHEDULE_FAILED',
  FETCH_UNAVAILABLE_ACCOMMODATIONS: 'clubadmin/FETCH_UNAVAILABLE_ACCOMMODATIONS',
  FETCH_UNAVAILABLE_ACCOMMODATIONS_SUCCESS: 'clubadmin/FETCH_UNAVAILABLE_ACCOMMODATIONS_SUCCESS',
  FETCH_UNAVAILABLE_ACCOMMODATIONS_FAILED: 'clubadmin/FETCH_UNAVAILABLE_ACCOMMODATIONS_FAILED',
  SAVE_UNAVAILABLE_ACCOMMODATION: 'clubadmin/SAVE_UNAVAILABLE_ACCOMMODATION',
  SAVE_UNAVAILABLE_ACCOMMODATION_SUCCESS: 'clubadmin/SAVE_UNAVAILABLE_ACCOMMODATION_SUCCESS',
  SAVE_UNAVAILABLE_ACCOMMODATION_FAILED: 'clubadmin/SAVE_UNAVAILABLE_ACCOMMODATION_FAILED',
  DELETE_UNAVAILABLE_ACCOMMODATION: 'clubadmin/DELETE_UNAVAILABLE_ACCOMMODATION',
  DELETE_UNAVAILABLE_ACCOMMODATION_SUCCESS: 'clubadmin/DELETE_UNAVAILABLE_ACCOMMODATION_SUCCESS',
  DELETE_UNAVAILABLE_ACCOMMODATION_FAILED: 'clubadmin/DELETE_UNAVAILABLE_ACCOMMODATION_FAILED'
};

export const clubAdminConfigActions = {
  fetchHolidaysAction: () => ({
    type: clubAdminConfigTypes.FETCH_HOLIDAYS,
    data: [!!sessionStorage.authToken],
    backendFunction: Api.fetchHolidays,
    callSuccessType: clubAdminConfigTypes.FETCH_HOLIDAYS_SUCCESS,
    callFailedType: clubAdminConfigTypes.FETCH_HOLIDAYS_FAILED
  }),
  fetchGolfClubsAction: () => ({
    type: clubAdminConfigTypes.FETCH_GOLFCLUBS,
    data: [!!sessionStorage.authToken],
    backendFunction: Api.fetchGolfClubs,
    callSuccessType: clubAdminConfigTypes.FETCH_GOLFCLUBS_SUCCESS,
    callFailedType: clubAdminConfigTypes.FETCH_GOLFCLUBS_FAILED
  }),
  saveGolfClubAction: (data, onSubmit) => ({
    type: clubAdminConfigTypes.SAVE_GOLFCLUB,
    data: [data],
    onSubmit,
    backendFunction: Api.saveGolfClub,
    callSuccessType: clubAdminConfigTypes.SAVE_GOLFCLUB_SUCCESS,
    callFailedType: clubAdminConfigTypes.SAVE_GOLFCLUB_FAILED
  }),
  deleteGolfClubAction: (data, onSubmit) => ({
    type: clubAdminConfigTypes.DELETE_GOLFCLUB,
    data: [data],
    onSubmit,
    backendFunction: Api.deleteGolfClub,
    callSuccessType: clubAdminConfigTypes.DELETE_GOLFCLUB_SUCCESS,
    callFailedType: clubAdminConfigTypes.DELETE_GOLFCLUB_FAILED
  }),
  fetchTeetimeSchedulesAction: () => ({
    type: clubAdminConfigTypes.FETCH_TEETIME_SCHEDULES,
    data: [],
    backendFunction: Api.fetchTeetimeSchedules,
    callSuccessType: clubAdminConfigTypes.FETCH_TEETIME_SCHEDULES_SUCCESS,
    callFailedType: clubAdminConfigTypes.FETCH_TEETIME_SCHEDULES_FAILED
  }),
  saveTeetimeScheduleAction: (data, onSubmit) => ({
    type: clubAdminConfigTypes.SAVE_TEETIME_SCHEDULE,
    data: [data],
    onSubmit,
    backendFunction: Api.saveTeetimeSchedule,
    callSuccessType: clubAdminConfigTypes.SAVE_TEETIME_SCHEDULE_SUCCESS,
    callFailedType: clubAdminConfigTypes.SAVE_TEETIME_SCHEDULE_FAILED
  }),
  deleteTeetimeScheduleAction: (data, onSubmit) => ({
    type: clubAdminConfigTypes.DELETE_TEETIME_SCHEDULE,
    data: [data],
    onSubmit,
    backendFunction: Api.deleteTeetimeSchedule,
    callSuccessType: clubAdminConfigTypes.DELETE_TEETIME_SCHEDULE_SUCCESS,
    callFailedType: clubAdminConfigTypes.DELETE_TEETIME_SCHEDULE_FAILED
  }),
  fetchUnavailableTeetimesAction: () => ({
    type: clubAdminConfigTypes.FETCH_UNAVAILABLE_TEETIMES,
    data: [],
    backendFunction: Api.fetchUnavailableTeetimes,
    callSuccessType: clubAdminConfigTypes.FETCH_UNAVAILABLE_TEETIMES_SUCCESS,
    callFailedType: clubAdminConfigTypes.FETCH_UNAVAILABLE_TEETIMES_FAILED
  }),
  saveUnavailableTeetimeAction: (data, onSubmit) => ({
    type: clubAdminConfigTypes.SAVE_UNAVAILABLE_TEETIME,
    data: [data],
    onSubmit,
    backendFunction: Api.saveUnavailableTeetime,
    callSuccessType: clubAdminConfigTypes.SAVE_UNAVAILABLE_TEETIME_SUCCESS,
    callFailedType: clubAdminConfigTypes.SAVE_UNAVAILABLE_TEETIME_FAILED
  }),
  deleteUnavailableTeetimeAction: (data, onSubmit) => ({
    type: clubAdminConfigTypes.DELETE_UNAVAILABLE_TEETIME,
    data: [data],
    onSubmit,
    backendFunction: Api.deleteUnavailableTeetime,
    callSuccessType: clubAdminConfigTypes.DELETE_UNAVAILABLE_TEETIME_SUCCESS,
    callFailedType: clubAdminConfigTypes.DELETE_UNAVAILABLE_TEETIME_FAILED
  }),
  fetchProductsAction: () => ({
    type: clubAdminConfigTypes.FETCH_PRODUCTS,
    data: [],
    backendFunction: Api.fetchProducts,
    callSuccessType: clubAdminConfigTypes.FETCH_PRODUCTS_SUCCESS,
    callFailedType: clubAdminConfigTypes.FETCH_PRODUCTS_FAILED
  }),
  fetchCategoriesAction: () => ({
    type: clubAdminConfigTypes.FETCH_CATEGORIES,
    data: [],
    backendFunction: Api.fetchCategories,
    callSuccessType: clubAdminConfigTypes.FETCH_CATEGORIES_SUCCESS,
    callFailedType: clubAdminConfigTypes.FETCH_CATEGORIES_FAILED
  }),
  saveProductAction: (data, onSubmit) => ({
    type: clubAdminConfigTypes.SAVE_PRODUCT,
    data: [data],
    onSubmit,
    backendFunction: Api.saveProduct,
    callSuccessType: clubAdminConfigTypes.SAVE_PRODUCT_SUCCESS,
    callFailedType: clubAdminConfigTypes.SAVE_PRODUCT_FAILED
  }),
  deleteProductAction: (data, onSubmit) => ({
    type: clubAdminConfigTypes.DELETE_PRODUCT,
    data: [data],
    onSubmit,
    backendFunction: Api.deleteProduct,
    callSuccessType: clubAdminConfigTypes.DELETE_PRODUCT_SUCCESS,
    callFailedType: clubAdminConfigTypes.DELETE_PRODUCT_FAILED
  }),
  saveGolfClubPlayerAction: (data, onSubmit) => ({
    type: clubAdminConfigTypes.SAVE_GOLFCLUB_PLAYER,
    data: [data],
    onSubmit,
    backendFunction: Api.saveGolfClubPlayer,
    callSuccessType: clubAdminConfigTypes.SAVE_GOLFCLUB_PLAYER_SUCCESS,
    callFailedType: clubAdminConfigTypes.SAVE_GOLFCLUB_PLAYER_FAILED
  }),
  deleteGolfClubPlayerAction: (data, onSubmit) => ({
    type: clubAdminConfigTypes.DELETE_GOLFCLUB_PLAYER,
    data: [data],
    onSubmit,
    backendFunction: Api.deleteGolfClubPlayer,
    callSuccessType: clubAdminConfigTypes.DELETE_GOLFCLUB_PLAYER_SUCCESS,
    callFailedType: clubAdminConfigTypes.SAVE_GOLFCLUB_PLAYER_FAILED
  }),
  fetchAccommodationsAction: golfClubId => ({
    type: clubAdminConfigTypes.FETCH_ACCOMMODATIONS,
    data: [golfClubId],
    backendFunction: Api.fetchAccommodations,
    callSuccessType: clubAdminConfigTypes.FETCH_ACCOMMODATIONS_SUCCESS,
    callFailedType: clubAdminConfigTypes.FETCH_ACCOMMODATIONS_FAILED
  }),
  saveAccommodationAction: (golfClubId, data, onSubmit) => ({
    type: clubAdminConfigTypes.SAVE_ACCOMMODATION,
    data: [golfClubId, data],
    onSubmit,
    backendFunction: Api.saveAccommodation,
    callSuccessType: clubAdminConfigTypes.SAVE_ACCOMMODATION_SUCCESS,
    callFailedType: clubAdminConfigTypes.SAVE_ACCOMMODATION_FAILED
  }),
  saveAccommodationBulkAction: (golfClubId, data, onSubmit) => ({
    type: clubAdminConfigTypes.SAVE_ACCOMMODATION_BULK,
    data: [golfClubId, data],
    onSubmit,
    backendFunction: Api.saveAccommodationBulk,
    callSuccessType: clubAdminConfigTypes.SAVE_ACCOMMODATION_BULK_SUCCESS,
    callFailedType: clubAdminConfigTypes.SAVE_ACCOMMODATION_BULK_FAILED
  }),
  deleteAccommodationAction: (golfClubId, data, onSubmit) => ({
    type: clubAdminConfigTypes.DELETE_ACCOMMODATION,
    data: [golfClubId, data],
    onSubmit,
    backendFunction: Api.deleteAccommodation,
    callSuccessType: clubAdminConfigTypes.DELETE_ACCOMMODATION_SUCCESS,
    callFailedType: clubAdminConfigTypes.DELETE_ACCOMMODATION_FAILED
  }),
  fetchRentalUnitsAction: golfClubId => ({
    type: clubAdminConfigTypes.FETCH_RENTAL_UNITS,
    data: [golfClubId],
    backendFunction: Api.fetchRentalUnits,
    callSuccessType: clubAdminConfigTypes.FETCH_RENTAL_UNITS_SUCCESS,
    callFailedType: clubAdminConfigTypes.FETCH_RENTAL_UNITS_FAILED
  }),
  saveRentalUnitAction: (golfClubId, data, onSubmit) => ({
    type: clubAdminConfigTypes.SAVE_RENTAL_UNIT,
    data: [golfClubId, data],
    onSubmit,
    backendFunction: Api.saveRentalUnit,
    callSuccessType: clubAdminConfigTypes.SAVE_RENTAL_UNIT_SUCCESS,
    callFailedType: clubAdminConfigTypes.SAVE_RENTAL_UNIT_FAILED
  }),
  saveRentalUnitBulkAction: (golfClubId, data, onSubmit) => ({
    type: clubAdminConfigTypes.SAVE_RENTAL_UNIT_BULK,
    data: [golfClubId, data],
    onSubmit,
    backendFunction: Api.saveRentalUnitBulk,
    callSuccessType: clubAdminConfigTypes.SAVE_RENTAL_UNIT_BULK_SUCCESS,
    callFailedType: clubAdminConfigTypes.SAVE_RENTAL_UNIT_BULK_FAILED
  }),
  deleteRentalUnitAction: (golfClubId, data, onSubmit) => ({
    type: clubAdminConfigTypes.DELETE_RENTAL_UNIT,
    data: [golfClubId, data],
    onSubmit,
    backendFunction: Api.deleteRentalUnit,
    callSuccessType: clubAdminConfigTypes.DELETE_RENTAL_UNIT_SUCCESS,
    callFailedType: clubAdminConfigTypes.DELETE_RENTAL_UNIT_FAILED
  }),
  fetchAccommodationSchedulesAction: golfClubId => ({
    type: clubAdminConfigTypes.FETCH_ACCOMMODATION_SCHEDULES,
    data: [golfClubId],
    backendFunction: Api.fetchAccommodationSchedules,
    callSuccessType: clubAdminConfigTypes.FETCH_ACCOMMODATION_SCHEDULES_SUCCESS,
    callFailedType: clubAdminConfigTypes.FETCH_ACCOMMODATION_SCHEDULES_FAILED
  }),
  saveAccommodationScheduleAction: (golfClubId, data, onSubmit) => ({
    type: clubAdminConfigTypes.SAVE_ACCOMMODATION_SCHEDULE,
    data: [golfClubId, data],
    onSubmit,
    backendFunction: Api.saveAccommodationSchedule,
    callSuccessType: clubAdminConfigTypes.SAVE_ACCOMMODATION_SCHEDULE_SUCCESS,
    callFailedType: clubAdminConfigTypes.SAVE_ACCOMMODATION_SCHEDULE_FAILED
  }),
  deleteAccommodationScheduleAction: (golfClubId, data, onSubmit) => ({
    type: clubAdminConfigTypes.DELETE_ACCOMMODATION_SCHEDULE,
    data: [golfClubId, data],
    onSubmit,
    backendFunction: Api.deleteAccommodationSchedule,
    callSuccessType: clubAdminConfigTypes.DELETE_ACCOMMODATION_SCHEDULE_SUCCESS,
    callFailedType: clubAdminConfigTypes.DELETE_ACCOMMODATION_SCHEDULE_FAILED
  }),
  fetchUnavailableAccommodationsAction: golfClubId => ({
    type: clubAdminConfigTypes.FETCH_UNAVAILABLE_ACCOMMODATIONS,
    data: [golfClubId],
    backendFunction: Api.fetchUnavailableAccommodations,
    callSuccessType: clubAdminConfigTypes.FETCH_UNAVAILABLE_ACCOMMODATIONS_SUCCESS,
    callFailedType: clubAdminConfigTypes.FETCH_UNAVAILABLE_ACCOMMODATIONS_FAILED
  }),
  saveUnavailableAccommodationAction: (golfClubId, data, onSubmit) => ({
    type: clubAdminConfigTypes.SAVE_UNAVAILABLE_ACCOMMODATION,
    data: [golfClubId, data],
    onSubmit,
    backendFunction: Api.saveUnavailableAccommodation,
    callSuccessType: clubAdminConfigTypes.SAVE_UNAVAILABLE_ACCOMMODATION_SUCCESS,
    callFailedType: clubAdminConfigTypes.SAVE_UNAVAILABLE_ACCOMMODATION_FAILED
  }),
  deleteUnavailableAccommodationAction: (golfClubId, data, onSubmit) => ({
    type: clubAdminConfigTypes.DELETE_UNAVAILABLE_ACCOMMODATION,
    data: [golfClubId, data],
    onSubmit,
    backendFunction: Api.deleteUnavailableAccommodation,
    callSuccessType: clubAdminConfigTypes.DELETE_UNAVAILABLE_ACCOMMODATION_SUCCESS,
    callFailedType: clubAdminConfigTypes.DELETE_UNAVAILABLE_ACCOMMODATION_FAILED
  })
};

const initialState = {
  publicHolidays: [],
  golfClubs: [],
  golfClubsLoading: false,
  golfClubsError: null,
  teetimeSchedules: [],
  teetimeSchedulesLoading: false,
  teetimeSchedulesError: null,
  unavailableTeetimes: [],
  unavailableTeetimesLoading: false,
  unavailableTeetimesError: null,
  products: [],
  productsLoading: false,
  productsError: null,
  priceCategories: [],
  rentalUnitPriceCategories: [],
  priceCategoriesLoading: false,
  priceCategoriesError: null,
  accommodationTypes: [],
  accommodationTypesLoading: false,
  accommodationTypesError: null,
  rentalUnitTypes: [],
  accommodations: [],
  accommodationsLoading: false,
  accommodationsLoaded: false,
  accommodationsError: null,
  rentalUnits: [],
  rentalUnitsLoading: false,
  rentalUnitsLoaded: false,
  rentalUnitsError: null,
  accommodationSchedules: [],
  accommodationSchedulesLoading: false,
  accommodationSchedulesLoaded: false,
  accommodationSchedulesError: null,
  unavailableAccommodations: [],
  unavailableAccommodationsLoading: false,
  unavailableAccommodationsLoaded: false,
  unavailableAccommodationsError: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case clubAdminConfigTypes.FETCH_HOLIDAYS:
      return {
        ...state
      };
    case clubAdminConfigTypes.FETCH_GOLFCLUBS:
    case clubAdminConfigTypes.SAVE_GOLFCLUB:
    case clubAdminConfigTypes.DELETE_GOLFCLUB:
    case clubAdminConfigTypes.SAVE_GOLFCLUB_PLAYER:
    case clubAdminConfigTypes.DELETE_GOLFCLUB_PLAYER:
      return {
        ...state,
        golfClubsLoading: true,
        golfClubsError: null
      };
    case clubAdminConfigTypes.FETCH_HOLIDAYS_SUCCESS:
      return {
        ...state,
        publicHolidays: action.data
      };
    case clubAdminConfigTypes.FETCH_GOLFCLUBS_SUCCESS:
    case clubAdminConfigTypes.SAVE_GOLFCLUB_SUCCESS:
    case clubAdminConfigTypes.DELETE_GOLFCLUB_SUCCESS:
    case clubAdminConfigTypes.SAVE_GOLFCLUB_PLAYER_SUCCESS:
    case clubAdminConfigTypes.DELETE_GOLFCLUB_PLAYER_SUCCESS:
      return {
        ...state,
        golfClubs: action.data,
        golfClubsLoading: false,
        golfClubsError: null
      };
    case clubAdminConfigTypes.FETCH_GOLFCLUBS_FAILED:
    case clubAdminConfigTypes.SAVE_GOLFCLUB_FAILED:
    case clubAdminConfigTypes.DELETE_GOLFCLUB_FAILED:
    case clubAdminConfigTypes.SAVE_GOLFCLUB_PLAYER_FAILED:
    case clubAdminConfigTypes.DELETE_GOLFCLUB_PLAYER_FAILED:
      return {
        ...state,
        golfClubsLoading: false,
        golfClubsError: action.error
      };
    case clubAdminConfigTypes.FETCH_TEETIME_SCHEDULES:
    case clubAdminConfigTypes.SAVE_TEETIME_SCHEDULE:
    case clubAdminConfigTypes.DELETE_TEETIME_SCHEDULE:
      return {
        ...state,
        teetimeSchedulesLoading: true,
        teetimeSchedulesError: null,
        teetimeSchedulesValidationError: null
      };
    case clubAdminConfigTypes.FETCH_TEETIME_SCHEDULES_SUCCESS:
    case clubAdminConfigTypes.SAVE_TEETIME_SCHEDULE_SUCCESS:
    case clubAdminConfigTypes.DELETE_TEETIME_SCHEDULE_SUCCESS:
      return {
        ...state,
        teetimeSchedules: action.data.schedules,
        teetimeSchedulesLoading: false,
        teetimeSchedulesError: null,
        teetimeSchedulesValidationError: action.warning
      };
    case clubAdminConfigTypes.FETCH_TEETIME_SCHEDULES_FAILED:
    case clubAdminConfigTypes.SAVE_TEETIME_SCHEDULE_FAILED:
    case clubAdminConfigTypes.DELETE_TEETIME_SCHEDULE_FAILED:
      return {
        ...state,
        teetimeSchedulesLoading: false,
        teetimeSchedulesError: action.error,
        teetimeSchedulesValidationError: null
      };
    case clubAdminConfigTypes.FETCH_UNAVAILABLE_TEETIMES:
    case clubAdminConfigTypes.SAVE_UNAVAILABLE_TEETIME:
    case clubAdminConfigTypes.DELETE_UNAVAILABLE_TEETIME:
      return {
        ...state,
        unavailableTeetimesLoading: true,
        unavailableTeetimesError: null
      };
    case clubAdminConfigTypes.FETCH_UNAVAILABLE_TEETIMES_SUCCESS:
    case clubAdminConfigTypes.SAVE_UNAVAILABLE_TEETIME_SUCCESS:
    case clubAdminConfigTypes.DELETE_UNAVAILABLE_TEETIME_SUCCESS:
      return {
        ...state,
        unavailableTeetimes: action.data,
        unavailableTeetimesLoading: false,
        unavailableTeetimesError: null
      };
    case clubAdminConfigTypes.FETCH_UNAVAILABLE_TEETIMES_FAILED:
    case clubAdminConfigTypes.SAVE_UNAVAILABLE_TEETIME_FAILED:
    case clubAdminConfigTypes.DELETE_UNAVAILABLE_TEETIME_FAILED:
      return {
        ...state,
        unavailableTeetimesLoading: false,
        unavailableTeetimesError: action.error
      };

    case clubAdminConfigTypes.FETCH_PRODUCTS:
    case clubAdminConfigTypes.SAVE_PRODUCT:
    case clubAdminConfigTypes.DELETE_PRODUCT:
      return {
        ...state,
        productsLoading: true,
        productsError: null
      };
    case clubAdminConfigTypes.FETCH_PRODUCTS_SUCCESS:
    case clubAdminConfigTypes.SAVE_PRODUCT_SUCCESS:
    case clubAdminConfigTypes.DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: action.data,
        productsLoading: false,
        productsError: null
      };
    case clubAdminConfigTypes.FETCH_PRODUCTS_FAILED:
    case clubAdminConfigTypes.SAVE_PRODUCT_FAILED:
    case clubAdminConfigTypes.DELETE_PRODUCT_FAILED:
      return {
        ...state,
        productsLoading: false,
        productsError: action.error
      };
    case clubAdminConfigTypes.FETCH_CATEGORIES:
      return {
        ...state,
        productsLoading: true,
        productsError: null,
        priceCategoriesLoading: true,
        priceCategoriesError: null,
        accommodationTypesLoading: true,
        accommodationTypesError: null
      };
    case clubAdminConfigTypes.FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        products: action.data.products,
        productsLoading: false,
        productsError: null,
        priceCategories: action.data.priceCategories,
        rentalUnitPriceCategories: action.data.rentalUnitPriceCategories,
        priceCategoriesLoading: false,
        priceCategoriesError: null,
        accommodationTypes: action.data.accommodationTypes,
        accommodationTypesLoading: false,
        accommodationTypesError: null,
        rentalUnitTypes: action.data.rentalUnitTypes,
        rentalUnitTypesLoading: false,
        rentalUnitTypesError: null
      };
    case clubAdminConfigTypes.FETCH_CATEGORIES_FAILED:
      return {
        ...state,
        productsLoading: false,
        productsError: action.error,
        priceCategoriesLoading: false,
        priceCategoriesError: null,
        accommodationTypesLoading: false,
        accommodationTypesError: null
      };
    case clubAdminConfigTypes.FETCH_ACCOMMODATIONS:
    case clubAdminConfigTypes.SAVE_ACCOMMODATION:
    case clubAdminConfigTypes.SAVE_ACCOMMODATION_BULK:
    case clubAdminConfigTypes.DELETE_ACCOMMODATION:
      return {
        ...state,
        accommodationsLoading: true,
        accommodationsError: null
      };
    case clubAdminConfigTypes.FETCH_ACCOMMODATIONS_SUCCESS:
    case clubAdminConfigTypes.SAVE_ACCOMMODATION_SUCCESS:
    case clubAdminConfigTypes.SAVE_ACCOMMODATION_BULK_SUCCESS:
    case clubAdminConfigTypes.DELETE_ACCOMMODATION_SUCCESS:
      return {
        ...state,
        accommodations: action.data,
        accommodationsLoading: false,
        accommodationsLoaded: true,
        accommodationsError: null
      };
    case clubAdminConfigTypes.FETCH_ACCOMMODATIONS_FAILED:
    case clubAdminConfigTypes.SAVE_ACCOMMODATION_FAILED:
    case clubAdminConfigTypes.SAVE_ACCOMMODATION_BULK_FAILED:
    case clubAdminConfigTypes.DELETE_ACCOMMODATION_FAILED:
      return {
        ...state,
        accommodationsLoading: false,
        accommodationsError: action.error
      };
    case clubAdminConfigTypes.FETCH_RENTAL_UNITS:
    case clubAdminConfigTypes.SAVE_RENTAL_UNIT:
    case clubAdminConfigTypes.SAVE_RENTAL_UNIT_BULK:
    case clubAdminConfigTypes.DELETE_RENTAL_UNIT:
      return {
        ...state,
        rentalUnitsLoading: true,
        rentalUnitsError: null
      };
    case clubAdminConfigTypes.FETCH_RENTAL_UNITS_SUCCESS:
    case clubAdminConfigTypes.SAVE_RENTAL_UNIT_SUCCESS:
    case clubAdminConfigTypes.SAVE_RENTAL_UNIT_BULK_SUCCESS:
    case clubAdminConfigTypes.DELETE_RENTAL_UNIT_SUCCESS:
      return {
        ...state,
        rentalUnits: action.data,
        rentalUnitsLoading: false,
        rentalUnitsLoaded: true,
        rentalUnitsError: null
      };
    case clubAdminConfigTypes.FETCH_RENTAL_UNITS_FAILED:
    case clubAdminConfigTypes.SAVE_RENTAL_UNIT_FAILED:
    case clubAdminConfigTypes.SAVE_RENTAL_UNIT_BULK_FAILED:
    case clubAdminConfigTypes.DELETE_RENTAL_UNIT_FAILED:
      return {
        ...state,
        rentalUnitsLoading: false,
        rentalUnitsError: action.error
      };
    case clubAdminConfigTypes.FETCH_ACCOMMODATION_SCHEDULES:
    case clubAdminConfigTypes.SAVE_ACCOMMODATION_SCHEDULE:
    case clubAdminConfigTypes.DELETE_ACCOMMODATION_SCHEDULE:
      return {
        ...state,
        accommodationSchedulesLoading: true,
        accommodationSchedulesError: null,
        accommodationSchedulesValidationError: null
      };
    case clubAdminConfigTypes.FETCH_ACCOMMODATION_SCHEDULES_SUCCESS:
    case clubAdminConfigTypes.SAVE_ACCOMMODATION_SCHEDULE_SUCCESS:
    case clubAdminConfigTypes.DELETE_ACCOMMODATION_SCHEDULE_SUCCESS:
      return {
        ...state,
        accommodationSchedules: action.data,
        accommodationSchedulesLoading: false,
        accommodationSchedulesLoaded: true,
        accommodationSchedulesError: null,
        accommodationSchedulesValidationError: action.warning
      };
    case clubAdminConfigTypes.FETCH_ACCOMMODATION_SCHEDULES_FAILED:
    case clubAdminConfigTypes.SAVE_ACCOMMODATION_SCHEDULE_FAILED:
    case clubAdminConfigTypes.DELETE_ACCOMMODATION_SCHEDULE_FAILED:
      return {
        ...state,
        accommodationSchedulesLoading: false,
        accommodationSchedulesError: action.error,
        accommodationSchedulesValidationError: null
      };
    case clubAdminConfigTypes.FETCH_UNAVAILABLE_ACCOMMODATIONS:
    case clubAdminConfigTypes.SAVE_UNAVAILABLE_ACCOMMODATION:
    case clubAdminConfigTypes.DELETE_UNAVAILABLE_ACCOMMODATION:
      return {
        ...state,
        unavailableAccommodationsLoading: true,
        unavailableAccommodationsError: null
      };
    case clubAdminConfigTypes.FETCH_UNAVAILABLE_ACCOMMODATIONS_SUCCESS:
    case clubAdminConfigTypes.SAVE_UNAVAILABLE_ACCOMMODATION_SUCCESS:
    case clubAdminConfigTypes.DELETE_UNAVAILABLE_ACCOMMODATION_SUCCESS:
      return {
        ...state,
        unavailableAccommodations: action.data,
        unavailableAccommodationsLoading: false,
        unavailableAccommodationsLoaded: true,
        unavailableAccommodationsError: null
      };
    case clubAdminConfigTypes.FETCH_UNAVAILABLE_ACCOMMODATIONS_FAILED:
    case clubAdminConfigTypes.SAVE_UNAVAILABLE_ACCOMMODATION_FAILED:
    case clubAdminConfigTypes.DELETE_UNAVAILABLE_ACCOMMODATION_FAILED:
      return {
        ...state,
        unavailableAccommodationsLoading: false,
        unavailableAccommodationsError: action.error
      };

    default:
      return state;
  }
};

export default reducer;
