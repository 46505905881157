import map from 'lodash-es/map';
import orderBy from 'lodash-es/orderBy';
import groupBy from 'lodash-es/groupBy';
import { mapValues } from 'lodash-es';

const chainableFunctions = {
  map,
  orderBy,
  groupBy
};

export const customChain = input => {
  let value = input;
  const wrapper = {
    ...mapValues(chainableFunctions, f => (...args) => {
      // lodash always puts input as the first argument
      value = f(value, ...args);
      return wrapper;
    }),
    value: () => value
  };
  return wrapper;
};
