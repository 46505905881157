import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { Button, Dimmer, Icon, Loader, Modal, Popup, Table } from 'semantic-ui-react';
import { ProductAddonChargeType } from '../../core/enums/product-addon-charge-type.enum';
import {
  calculateAddonPrice,
  getProductAddonsForCheckinDate,
  IBookingProductAddon,
  IProductAddon
} from '../../core/services/product.service';
import CountSelector from '../CountSelector';

export interface ProductAddonModalProps {
  booking: any;
  golfClubCurrency: string;
  onClose(): void;
  onConfirm(addons: IBookingProductAddon[], price: number): void;
  show: boolean;
}

function ProductAddonModal(props: ProductAddonModalProps) {
  // const [productAddons, setProductAddons] = useState<IProductAddon[]>();
  const [activeRequest, setActiveRequest] = useState<boolean>(false);
  const [addonsPrice, setAddonsPrice] = useState<number>(0);
  const [bookingAddons, setBookingsAddons] = useState<IBookingProductAddon[]>();

  useEffect(() => {
    if (props.show) {
      setActiveRequest(true);

      // fetch product on show
      getProductAddonsForCheckinDate(props.booking.checkInDate).then(results => {
        const accommodationId = Number(props.booking.accommodation.id);
        const typeId = Number(props.booking.accommodation.typeId);

        // filter by accommodation
        const filteredAddonsByAccommodation = results.filter(item =>
          item.rentalUnitTypeIds.some(rentalUnit => rentalUnit.accommodationId === accommodationId)
        );

        // filter by unit type without accommodation or rental unit
        const filteredAddonsByUnitType = results.filter(item =>
          item.rentalUnitTypeIds.some(
            rentalUnit => rentalUnit.accommodationId === null && rentalUnit.rentalUnitTypeId === typeId
          )
        );

        const filteredProductAddons = filteredAddonsByUnitType.concat(filteredAddonsByAccommodation);

        // setProductAddons(filteredProductAddons);

        const addons = [] as IBookingProductAddon[];

        filteredProductAddons?.forEach((item: IProductAddon) => {
          const newLocal = {
            innerId: Math.random(),
            productId: item.id,
            product: item,
            accommodationBookingId: props.booking.id,
            chargeAmount: 0,
            chargeType: item.chargeType,
            itemsCount: 0
          } as IBookingProductAddon;

          // try to find addon in booking addons
          const existingAddon = props.booking.productAddons.find(
            (add: IBookingProductAddon) => add.productId === item.id
          );
          if (existingAddon) {
            // if exists, it's selected
            newLocal.chargeAmount = existingAddon.chargeAmount;
            newLocal.itemsCount = existingAddon.itemsCount;
          }

          addons.push(newLocal);
        });

        setBookingsAddons(addons);
        getAddonsPrice(addons);

        setActiveRequest(false);
      });
    }
  }, [props.show, props.booking]);

  const getAddonsPrice = (addons: IBookingProductAddon[]) => {
    let totalPrice = 0;

    addons?.forEach(addon => {
      totalPrice += addon.chargeAmount;
    });

    setAddonsPrice(totalPrice);
  };

  const onProductNumericValueChanged = (e: React.FormEvent<HTMLInputElement>, addon: IBookingProductAddon) => {
    // need to clone the addons
    if (bookingAddons) {
      const oldAddons = [...bookingAddons];

      const oldIndex = oldAddons.findIndex(a => a.innerId === addon.innerId);
      const oldAddon = oldAddons.find(a => a.innerId === addon.innerId);

      if (oldIndex !== -1 && oldAddon) {
        const newAddon = { ...oldAddon, [e.currentTarget.name]: Number(e.currentTarget.value) };

        newAddon.chargeAmount = calculateAddonPrice(newAddon);

        // replace the addon in the state, with a new value
        oldAddons.splice(oldIndex, 1, newAddon);

        setBookingsAddons(oldAddons);
      }

      getAddonsPrice(oldAddons);
    }
  };

  const onProductNumericValueIncreased = (addon: IBookingProductAddon) => {
    // need to clone the addons
    if (bookingAddons) {
      const oldAddons = [...bookingAddons];

      const oldIndex = oldAddons.findIndex(a => a.innerId === addon.innerId);
      const oldAddon = oldAddons.find(a => a.innerId === addon.innerId);

      if (oldIndex !== -1 && oldAddon) {
        const newAddon = { ...oldAddon, itemsCount: Number(oldAddon.itemsCount) + 1 };

        newAddon.chargeAmount = calculateAddonPrice(newAddon);

        // replace the addon in the state, with a new value
        oldAddons.splice(oldIndex, 1, newAddon);

        setBookingsAddons(oldAddons);
      }

      getAddonsPrice(oldAddons);
    }
  };

  const onProductNumericValueDecreased = (addon: IBookingProductAddon) => {
    // need to clone the addons
    if (bookingAddons) {
      const oldAddons = [...bookingAddons];

      const oldIndex = oldAddons.findIndex(a => a.innerId === addon.innerId);
      const oldAddon = oldAddons.find(a => a.innerId === addon.innerId);

      if (oldIndex !== -1 && oldAddon) {
        const newAddon = { ...oldAddon, itemsCount: Number(oldAddon.itemsCount) - 1 };

        newAddon.chargeAmount = calculateAddonPrice(newAddon);

        // replace the addon in the state, with a new value
        oldAddons.splice(oldIndex, 1, newAddon);

        setBookingsAddons(oldAddons);
      }

      getAddonsPrice(oldAddons);
    }
  };

  return (
    <Translate>
      {({ translate }) => (
        <Modal centered={true} open={props.show} className="modal-compact">
          <Modal.Header>
            <Translate id="product-addons.modal-title" />
          </Modal.Header>
          <Modal.Content>
            <Dimmer active={!!activeRequest} inverted>
              <Loader />
            </Dimmer>
            <Table>
              <Table.Header className="mobile-hidden">
                <Table.Row>
                  <Table.HeaderCell>
                    <Translate id="product-addons.headers.name" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Translate id="product-addons.headers.price" />
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1}>
                    <Translate id="product-addons.headers.count" />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {bookingAddons?.map((addon: IBookingProductAddon) => {
                  return (
                    <Table.Row key={addon.innerId}>
                      <Table.Cell>
                        {translate(`product-addons.type-names.${addon.product.name}`)}
                        {addon.product.description && (
                          <Popup trigger={<Icon name="info circle" />}>{addon.product.description}</Popup>
                        )}
                      </Table.Cell>
                      <Table.Cell singleLine>
                        {addon.product.chargeAmount.toFixed(2).replace('.', ',')} {props.golfClubCurrency}
                      </Table.Cell>
                      <Table.Cell computer={1} mobile={16}>
                        <CountSelector
                          item={addon}
                          minusClick={onProductNumericValueDecreased}
                          minusDisabled={addon.itemsCount === 0}
                          name="itemsCount"
                          plusClick={onProductNumericValueIncreased}
                          plusDisabled={
                            addon.chargeType === ProductAddonChargeType.PerBooking && addon.itemsCount === 1
                          }
                          value={addon.itemsCount}
                          valueChanged={onProductNumericValueChanged}
                          valueMin="0"
                          valueMax={addon.chargeType === ProductAddonChargeType.PerBooking ? '1' : ''}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Modal.Content>
          <Modal.Actions>
            <Button color="black" onClick={props.onClose}>
              <Translate id="default.close" />
            </Button>
            <Button
              primary
              onClick={() => {
                if (bookingAddons) {
                  props.onConfirm(bookingAddons, addonsPrice);
                }
              }}
            >
              <Translate
                id="product-addons.confirm-addons"
                data={{ amount: addonsPrice, currency: props.golfClubCurrency }}
              />
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Translate>
  );
}

export default ProductAddonModal;
