import { rentalUnitBookingService } from '../../core/services';
import * as Api from '../../utils/api';

import { UNAUTHORIZED, SIGN_OUT_REQUEST, WIDGET_SIGN_OUT_IN_REQUEST } from '../LoginForm/ducks';

export const rentalUnitTypes = {
  CREATE_BOOKING: 'rentalUnit/CREATE_BOOKING',
  CREATE_BOOKING_SUCCESS: 'rentalUnit/CREATE_BOOKING_SUCCESS',
  CREATE_BOOKING_FAILED: 'rentalUnit/CREATE_BOOKING_FAILED',
  CANCEL_BOOKING: 'rentalUnit/CANCEL_BOOKING',
  CANCEL_BOOKING_SUCCESS: 'rentalUnit/CANCEL_BOOKING_SUCCESS',
  CANCEL_BOOKING_FAILED: 'rentalUnit/CANCEL_BOOKING_FAILED',
  EDIT_BOOKING: 'rentalUnit/EDIT_BOOKING',
  EDIT_BOOKING_SUCCESS: 'rentalUnit/EDIT_BOOKING_SUCCESS',
  EDIT_BOOKING_FAILED: 'rentalUnit/EDIT_BOOKING_FAILED',
  SELECTED_DATE: 'rentalUnit/SELECTED_DATE',
  RENTAL_UNIT_SELECTED: 'rentalUnit/RENTAL_UNIT_SELECTED',
  RENTAL_UNIT_SELECTED_SUCCESS: 'rentalUnit/RENTAL_UNIT_SELECTED_SUCCESS',
  RENTAL_UNIT_SELECTED_FAILED: 'rentalUnit/RENTAL_UNIT_SELECTED_FAILED',
  RENTAL_UNIT_DESELECTED: 'rentalUnit/RENTAL_UNIT_DESELECTED',
  RENTAL_UNIT_DESELECTED_SUCCESS: 'rentalUnit/RENTAL_UNIT_DESELECTED_SUCCESS',
  RENTAL_UNIT_DESELECTED_FAILED: 'rentalUnit/RENTAL_UNIT_DESELECTED_FAILED',
  FETCH_RENTAL_UNIT_AVAILABILITY: 'rentalUnit/FETCH_RENTAL_UNIT_AVAILABILITY',
  FETCH_RENTAL_UNIT_AVAILABILITY_SUCCESS: 'rentalUnit/FETCH_RENTAL_UNIT_AVAILABILITY_SUCCESS',
  FETCH_RENTAL_UNIT_AVAILABILITY_FAILED: 'rentalUnit/FETCH_RENTAL_UNIT_AVAILABILITY_FAILED'
};

export const actions = {
  fetchRentalUnitAvailabilityAction: (golfClubId, dateFrom, isWidgetRequest) => ({
    type: rentalUnitTypes.FETCH_RENTAL_UNIT_AVAILABILITY,
    data: [golfClubId, dateFrom, isWidgetRequest],
    backendFunction: rentalUnitBookingService.getAvailableUnits,
    callSuccessType: rentalUnitTypes.FETCH_RENTAL_UNIT_AVAILABILITY_SUCCESS,
    callFailedType: rentalUnitTypes.FETCH_RENTAL_UNIT_AVAILABILITY_FAILED
  }),
  rentalUnitSelectedAction: (golfClubId, booking, acc) => ({
    type: rentalUnitTypes.RENTAL_UNIT_SELECTED,
    booking: [golfClubId, booking],
    acc
  }),
  rentalUnitDeselectedAction: (golfClubId, booking) => ({
    type: rentalUnitTypes.RENTAL_UNIT_DESELECTED,
    booking: [golfClubId, booking]
  }),
  createBookingAction: (golfClubId, booking, onSubmit) => ({
    type: rentalUnitTypes.CREATE_BOOKING,
    booking: [golfClubId, booking],
    onSubmit,
    backendFunction: Api.createBooking,
    callSuccessType: rentalUnitTypes.CREATE_BOOKING_SUCCESS,
    callFailedType: rentalUnitTypes.CREATE_BOOKING_FAILED
  }),
  cancelBookingAction: (golfClubId, booking, onSubmit) => ({
    type: rentalUnitTypes.CANCEL_BOOKING,
    booking: [golfClubId, booking],
    onSubmit
  }),
  editBookingAction: (golfClubId, booking, onSubmit) => ({
    type: rentalUnitTypes.EDIT_BOOKING,
    booking: [golfClubId, booking],
    onSubmit
  }),
  selectedDateAction: dateFrom => ({ type: rentalUnitTypes.SELECTED_DATE, dateFrom })
};

const initialState = {
  selectedDateFrom: '',
  rentalUnitsAvailabilities: [],
  activeRequest: null,
  createBookingActive: null,
  showBookingsActive: null,
  bookingCounter: 0,
  bookingView: false,
  selectedAcc: { rentalUnit: { name: '' }, bookings: [] },
  fetchAvailabilityError: null,
  rentalUnitError: null,
  createBookingError: null,
  showBookingsError: null,
  lastWidgetBooker: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UNAUTHORIZED:
    case SIGN_OUT_REQUEST:
    case WIDGET_SIGN_OUT_IN_REQUEST:
      return initialState;
    case rentalUnitTypes.SELECTED_DATE: {
      return {
        ...state,
        selectedDateFrom: action.dateFrom
      };
    }
    case rentalUnitTypes.FETCH_RENTAL_UNIT_AVAILABILITY: {
      return {
        ...state,
        activeRequest: true,
        fetchAvailabilityError: null
      };
    }
    case rentalUnitTypes.FETCH_RENTAL_UNIT_AVAILABILITY_SUCCESS: {
      return {
        ...state,
        rentalUnitsAvailabilities: action.data,
        activeRequest: false,
        fetchAvailabilityError: null
      };
    }
    case rentalUnitTypes.FETCH_RENTAL_UNIT_AVAILABILITY_FAILED: {
      return {
        ...state,
        activeRequest: false,
        fetchAvailabilityError: action.error
      };
    }
    case rentalUnitTypes.RENTAL_UNIT_SELECTED:
      return {
        ...state,
        selectedAcc: action.acc,
        bookingView: true,
        rentalUnitError: null,
        createBookingError: null
      };
    case rentalUnitTypes.RENTAL_UNIT_DESELECTED_SUCCESS:
    case rentalUnitTypes.RENTAL_UNIT_SELECTED_SUCCESS:
    case rentalUnitTypes.CREATE_BOOKING_SUCCESS: {
      let newRentalUnitsAvailabilities = state.rentalUnitsAvailabilities;
      let newSelectedAcc = state.selectedAcc;
      if (action.rentalUnit) {
        newRentalUnitsAvailabilities = action.rentalUnit;
        newSelectedAcc = newRentalUnitsAvailabilities.rentalUnits.find(
          x => x.rentalUnit.id === newSelectedAcc.rentalUnit.id
        );
      }
      return {
        ...state,
        rentalUnitsAvailabilities: newRentalUnitsAvailabilities,
        selectedAcc: newSelectedAcc,
        createBookingError: null,
        showBookingsError: null,
        showBookingsActive: false
      };
    }
    case rentalUnitTypes.RENTAL_UNIT_DESELECTED_FAILED:
    case rentalUnitTypes.RENTAL_UNIT_SELECTED_FAILED: {
      let newRentalUnitsAvailabilities = state.rentalUnitsAvailabilities;
      let newSelectedAcc = state.selectedAcc;
      if (action.rentalUnit) {
        newRentalUnitsAvailabilities = action.rentalUnit;
        newSelectedAcc = newRentalUnitsAvailabilities.rentalUnits.find(
          x => x.rentalUnit.id === newSelectedAcc.rentalUnit.id
        );
      }

      return {
        ...state,
        rentalUnitsAvailabilities: newRentalUnitsAvailabilities,
        selectedAcc: newSelectedAcc,
        createBookingError: action.error
      };
    }
    case rentalUnitTypes.RENTAL_UNIT_DESELECTED: {
      return {
        ...state,
        bookingView: false,
        rentalUnitError: null,
        createBookingError: null,
        showBookingsError: null
      };
    }
    case rentalUnitTypes.EDIT_BOOKING:
    case rentalUnitTypes.CANCEL_BOOKING:
    case rentalUnitTypes.CREATE_BOOKING: {
      const lastWidgetBooker = action.type === rentalUnitTypes.CREATE_BOOKING ? action.booking[1].bookingUser : null;
      return {
        ...state,
        showBookingsActive: true,
        lastWidgetBooker
      };
    }
    case rentalUnitTypes.EDIT_BOOKING_SUCCESS:
    case rentalUnitTypes.CANCEL_BOOKING_SUCCESS: {
      let newRentalUnitsAvailabilities = state.rentalUnitsAvailabilities;
      let newSelectedAcc = state.selectedAcc;
      if (action.rentalUnit) {
        newRentalUnitsAvailabilities = action.rentalUnit;
        newSelectedAcc = newRentalUnitsAvailabilities.rentalUnits.find(
          x => x.rentalUnit.id === newSelectedAcc.rentalUnit.id
        );
      }
      return {
        ...state,
        rentalUnitsAvailabilities: newRentalUnitsAvailabilities,
        selectedAcc: newSelectedAcc,
        showBookingsActive: false,
        bookingCounter: state.bookingCounter + 1,
        showBookingsError: null
      };
    }
    case rentalUnitTypes.EDIT_BOOKING_FAILED:
    case rentalUnitTypes.CANCEL_BOOKING_FAILED: {
      let newRentalUnitsAvailabilities = state.rentalUnitsAvailabilities;
      let newSelectedAcc = state.selectedAcc;
      if (action.rentalUnit) {
        newRentalUnitsAvailabilities = action.rentalUnit;
        newSelectedAcc = newRentalUnitsAvailabilities.rentalUnits.find(
          x => x.rentalUnit.id === newSelectedAcc.rentalUnit.id
        );
      }

      return {
        ...state,
        rentalUnitsAvailabilities: newRentalUnitsAvailabilities,
        selectedAcc: newSelectedAcc,
        showBookingsActive: false,
        showBookingsError: action.error
      };
    }

    case rentalUnitTypes.CREATE_BOOKING_FAILED: {
      let newRentalUnitsAvailabilities = state.rentalUnitsAvailabilities;
      let newSelectedAcc = state.selectedAcc;
      if (action.rentalUnit) {
        newRentalUnitsAvailabilities = action.rentalUnit;
        newSelectedAcc = newRentalUnitsAvailabilities.rentalUnits.find(
          x => x.rentalUnit.id === newSelectedAcc.rentalUnit.id
        );
      }

      return {
        ...state,
        rentalUnitsAvailabilities: newRentalUnitsAvailabilities,
        selectedAcc: newSelectedAcc,
        showBookingsActive: false,
        createBookingError: action.error
      };
    }

    default:
      return state;
  }
};

export default reducer;

// selectors
export const getRentalUnitSheet = state => state.rentalUnit;

export const getLastWidgetBooker = state => getRentalUnitSheet(state).lastWidgetBooker;
export const selectedDateFrom = state => getRentalUnitSheet(state).selectedDateFrom;
export const hasActiveRequest = state => getRentalUnitSheet(state).activeRequest;
export const getGolfClubs = state => state.clubAdminConfig.golfClubs;
