import React, { Component } from 'react';

import { Container } from 'semantic-ui-react';

import Helmet from '../components/Helmet';
import ResetPasswordForm from '../containers/ResetPasswordForm';

class ResetPasswordPage extends Component {
  render() {
    return (
      <div className="app app-reset-password">
        <Helmet title="OnTee reset password" description="OnTee on reset password steroid!" />
        <Container className="mw-900">
          <ResetPasswordForm />
        </Container>
      </div>
    );
  }
}

export default ResetPasswordPage;
