import React from 'react';
import { Translate } from 'react-localize-redux';
import { Modal, Segment, Table, Button, Icon, Form, CheckboxProps, InputOnChangeData } from 'semantic-ui-react';
import {
  getGolfClubAccess,
  getGolfClubDetails,
  getGolfClubInformation,
  IGolfClubDetailItem,
  IGolfClubInformation,
  updateGolfClubInformation
} from '../../../core/services/golfclub.service';

interface GolfClubDetailsTabProps {
  active: boolean;
}

export class GolfClubDetailsTab extends React.Component<GolfClubDetailsTabProps> {
  state = {
    isUnlockPasswordsOpen: false,
    isEdit: false,
    isValidName: true,
    loading: false,
    saving: false,
    decrypting: false,
    nameFilter: '',
    pin: '',
    selectedGolfClub: {} as IGolfClubInformation,
    golfClubs: [] as IGolfClubDetailItem[],
    filteredGolfClubs: [] as IGolfClubDetailItem[]
  };

  async componentDidMount() {
    await this.fetchData();
  }

  fetchData = async () => {
    this.setState({ loading: true });

    try {
      const golfClubDetails = await getGolfClubDetails();

      this.setState({
        loading: false,
        golfClubs: golfClubDetails,
        filteredGolfClubs: golfClubDetails
      });
    } catch (error) {
      this.setState({
        loading: false
      });
    }
  };

  closeDetail = () => {
    this.setState({
      selectedGolfClub: {} as IGolfClubInformation
    });
  };

  showDetail = async (golfClubId: number, isEdit: boolean) => {
    this.setState({ loading: true });

    try {
      const golfClubInfo = await getGolfClubInformation(golfClubId);

      this.setState({
        isEdit: isEdit,
        loading: false,
        selectedGolfClub: golfClubInfo
      });
    } catch (error) {
      this.setState({
        loading: false
      });
    }
  };

  saveGolfClub = async () => {
    this.setState({ saving: true });

    try {
      const golfClubInfo = await updateGolfClubInformation(this.state.selectedGolfClub);

      const gcIndex = this.state.golfClubs.findIndex(gc => gc.id === golfClubInfo.id);

      const { golfClubs } = this.state;

      golfClubs.splice(gcIndex, 1, golfClubInfo);

      this.setState({
        isEdit: false,
        saving: false,
        selectedGolfClub: {} as IGolfClubInformation,
        golfClubs: golfClubs
      });
    } catch (error) {
      this.setState({});
    }
  };

  showUnlockPasswords = () => {
    this.setState({
      isUnlockPasswordsOpen: true
    });
  };

  onChange = (_e: any, { name, value }: any) => {
    const changedClub = { ...this.state.selectedGolfClub, [name]: value };

    this.setState({ selectedGolfClub: changedClub });
  };

  onCompanyChange = (_e: any, { name, value }: any) => {
    const changedCompany = { ...this.state.selectedGolfClub.company, [name]: value };
    const changedClub = { ...this.state.selectedGolfClub, company: changedCompany };

    this.setState({ selectedGolfClub: changedClub });
  };

  onFilterChange = (_e: any, data: InputOnChangeData) => {
    this.setState({ nameFilter: data.value });
  };

  onPinChange = (_e: any, data: InputOnChangeData) => {
    this.setState({ pin: data.value });
  };

  filterClubs = () => {
    const nameFilter = (this.state.nameFilter || '').toLowerCase();

    let filteredClubs = [] as IGolfClubDetailItem[];

    if (nameFilter) {
      filteredClubs = this.state.golfClubs.filter(gc => gc.name.toLowerCase().indexOf(nameFilter) !== -1);
    } else {
      filteredClubs = this.state.golfClubs;
    }

    this.setState({ filteredGolfClubs: filteredClubs });
  };

  showAllClubs = () => {
    this.setState({ nameFilter: '', filteredGolfClubs: this.state.golfClubs });
  };

  onCheckedChange = (_e: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    if (data.name) {
      let checked = !!data.checked;

      if (data.name === 'widgetDisabled') {
        // we have 'widget enabled' in the form, need to negate the checked value
        checked = !checked;
      }

      const changedClub = { ...this.state.selectedGolfClub, [data.name]: checked };

      this.setState({ selectedGolfClub: changedClub });
    }
  };

  validateName = ({ value }: any) => {
    const isValidName = value && value.trim() !== '';

    const changedClub = { ...this.state.selectedGolfClub, name: value };

    this.setState({ selectedGolfClub: changedClub, isValidName });
  };

  validateConnectedAccount = (useOnlinePayment: boolean, connectedAccountId: string) => {
    const isValidConnectedAccount = !useOnlinePayment || (connectedAccountId && connectedAccountId.trim() !== '');

    this.setState({
      selectedGolfClub: {
        ...this.state.selectedGolfClub,
        useOnlinePayment,
        connectedAccountId,
        isValidConnectedAccount
      }
    });
    // this.props.dataIsValid(isValidConnectedAccount);
  };

  unlockPasswords = async () => {
    this.setState({ decrypting: true });

    try {
      const golfClubAccess = await getGolfClubAccess(this.state.selectedGolfClub.id, this.state.pin);

      this.setState({
        selectedGolfClub: {
          ...this.state.selectedGolfClub,
          adminSystemAccess: golfClubAccess?.admin,
          onTeexAccess: golfClubAccess?.onTeex
        },
        isUnlockPasswordsOpen: false,
        pin: '',
        decrypting: false
      });
    } catch (error) {
      this.setState({ isUnlockPasswordsOpen: false, decrypting: false, pin: '' });
    }
  };

  render() {
    const {
      loading,
      saving,
      decrypting,
      nameFilter,
      filteredGolfClubs,
      selectedGolfClub,
      isUnlockPasswordsOpen,
      isEdit
    } = this.state;

    if (this.props.active) {
      return (
        <Translate>
          {({ translate }) => (
            <React.Fragment>
              <Segment loading={loading}>
                <div className="p-1">
                  <Form>
                    <Form.Group>
                      <Form.Field width={8}>
                        <Form.Input
                          fluid
                          placeholder="Namn"
                          type="text"
                          name="bookingLink"
                          onChange={this.onFilterChange}
                          value={this.state.nameFilter || ''}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Button onClick={this.filterClubs} disabled={loading}>
                          {translate('golfclub-player.filter')}
                        </Button>
                      </Form.Field>
                      {nameFilter && (
                        <Form.Field>
                          <Button onClick={this.showAllClubs} disabled={loading}>
                            {translate('default.cancel')}
                          </Button>
                        </Form.Field>
                      )}
                    </Form.Group>
                  </Form>

                  <Segment>
                    <Table>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>
                            <Translate id="default.name" />
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <Translate id="golfclub.use-online-payment" />
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <Translate id="golfclub.disable-widget" />
                          </Table.HeaderCell>
                          <Table.HeaderCell textAlign="right">Commission</Table.HeaderCell>
                          <Table.HeaderCell textAlign="right">
                            <Translate id="golfclub.camping-commission" />
                          </Table.HeaderCell>
                          <Table.HeaderCell textAlign="right">Commission</Table.HeaderCell>
                          <Table.HeaderCell textAlign="right">
                            <Translate id="golfclub.lodge-commission" />
                          </Table.HeaderCell>
                          <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {filteredGolfClubs?.map((golfClub: IGolfClubDetailItem, i: number) => {
                          return (
                            <Table.Row key={i}>
                              <Table.Cell>{golfClub.name}</Table.Cell>
                              <Table.Cell textAlign="center">{golfClub.useOnlinePayment ? 'X' : ''}</Table.Cell>
                              <Table.Cell textAlign="center">{golfClub.widgetDisabled ? 'X' : ''}</Table.Cell>
                              <Table.Cell singleLine textAlign="right">
                                {golfClub.campingCommission}%
                              </Table.Cell>
                              <Table.Cell singleLine textAlign="right">
                                {golfClub.campingCount}
                              </Table.Cell>
                              <Table.Cell singleLine textAlign="right">
                                {golfClub.lodgeCommission}%
                              </Table.Cell>
                              <Table.Cell singleLine textAlign="right">
                                {golfClub.lodgeCount}
                              </Table.Cell>
                              <Table.Cell>
                                <Button
                                  compact
                                  size="tiny"
                                  icon
                                  className="m-0 p-0"
                                  onClick={() => this.showDetail(golfClub.id, false)}
                                >
                                  <Icon name="info circle" />
                                </Button>
                                <Button
                                  compact
                                  size="tiny"
                                  icon
                                  className="m-0 p-0"
                                  onClick={() => this.showDetail(golfClub.id, true)}
                                >
                                  <Icon name="edit" />
                                </Button>
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                    </Table>
                  </Segment>
                </div>
              </Segment>

              {!isEdit && selectedGolfClub && selectedGolfClub.id && (
                <Modal open centered size="large" className="position-unset">
                  <Modal.Header>{`${selectedGolfClub.name} (${selectedGolfClub.id})`}</Modal.Header>
                  <Modal.Content>
                    <h3>{translate('golfclub.company-header')}</h3>
                    <Table compact="very" basic="very" size="small">
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>
                            <div>{selectedGolfClub.company?.companyName}</div>
                            <div>{selectedGolfClub.company?.street}</div>
                            <div>
                              {selectedGolfClub.company?.postalCode} {selectedGolfClub.company?.city}
                            </div>
                            <p>{selectedGolfClub.company?.country}</p>
                          </Table.Cell>
                          <Table.Cell verticalAlign="top">
                            <div>
                              {translate('golfclub.company-regnr')}: {selectedGolfClub.company?.registrationNumber}
                            </div>
                            <div>
                              {translate('golfclub.company-vatnr')}: {selectedGolfClub.company?.vatNumber}
                            </div>
                          </Table.Cell>
                          <Table.Cell verticalAlign="top">
                            <div>
                              {translate('golfclub.company-reference')}: {selectedGolfClub.company?.reference}
                            </div>
                            <div>
                              {translate('golfclub.company-iban')}: {selectedGolfClub.company?.iBAN}
                            </div>
                            <div>
                              {translate('golfclub.company-bankaccount')}: {selectedGolfClub.company?.bankAccount}
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>

                    <h3>{translate('club-admin.report-tabs.commission')}</h3>
                    <Table compact="very" basic="very" size="small">
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell width={4}>{translate('golfclub.camping-commission')}</Table.HeaderCell>
                          <Table.HeaderCell width={4}>#</Table.HeaderCell>
                          <Table.HeaderCell width={4}>{translate('golfclub.lodge-commission')}</Table.HeaderCell>
                          <Table.HeaderCell>#</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>{selectedGolfClub.campingCommission}%</Table.Cell>
                          <Table.Cell>{selectedGolfClub.campingCount}</Table.Cell>
                          <Table.Cell>{selectedGolfClub.lodgeCommission}%</Table.Cell>
                          <Table.Cell>{selectedGolfClub.lodgeCount}</Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>

                    <h3>{translate('golfclub.online-payment')}</h3>
                    <Table compact="very" basic="very" size="small">
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell width={8}>{translate('golfclub.connected-account-id')}</Table.HeaderCell>
                          <Table.HeaderCell>{translate('golfclub.use-online-payment')}</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>{selectedGolfClub.connectedAccountId}</Table.Cell>
                          <Table.Cell>
                            {selectedGolfClub.useOnlinePayment ? (
                              <Icon name="check" color="green" />
                            ) : (
                              <Icon name="remove" color="red" />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>

                    <h3>Status</h3>
                    <Table compact="very" basic="very" size="small">
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell width={3}>Aktivera widget</Table.HeaderCell>
                          <Table.HeaderCell width={3}>Utbildning</Table.HeaderCell>
                          <Table.HeaderCell width={3}>Stripe länk distribuerad</Table.HeaderCell>
                          <Table.HeaderCell width={3}>Logo i bekr mail</Table.HeaderCell>
                          <Table.HeaderCell>Uppdaterad</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>
                            {selectedGolfClub.widgetDisabled ? (
                              <Icon name="remove" color="red" />
                            ) : (
                              <Icon name="check" color="green" />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {selectedGolfClub.hasTraining ? (
                              <Icon name="check" color="green" />
                            ) : (
                              <Icon name="remove" color="red" />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {selectedGolfClub.hasStripeLinkSent ? (
                              <Icon name="check" color="green" />
                            ) : (
                              <Icon name="remove" color="red" />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {selectedGolfClub.hasOwnLogoInConfirmationMail ? (
                              <Icon name="check" color="green" />
                            ) : (
                              <Icon name="remove" color="red" />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {selectedGolfClub.isUpdated ? (
                              <Icon name="check" color="green" />
                            ) : (
                              <Icon name="remove" color="red" />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>

                    <h3>Access</h3>
                    <Table compact="very" basic="very" size="small">
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell width={4}>&nbsp;</Table.HeaderCell>
                          <Table.HeaderCell width={4}>Länk</Table.HeaderCell>
                          <Table.HeaderCell width={4}>Inlogg</Table.HeaderCell>
                          <Table.HeaderCell>
                            <span>Lösenord</span>
                            <span className="p-1">
                              <Button compact size="tiny" positive onClick={this.showUnlockPasswords}>
                                <Icon name="lock open" /> låsa upp
                              </Button>
                            </span>
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>
                            <strong>OnTeeX admin system</strong>
                          </Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell>{selectedGolfClub.onTeexLogin}</Table.Cell>
                          <Table.Cell>{selectedGolfClub.onTeexAccess}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>
                            <strong>Golfklubbs admin system</strong>
                          </Table.Cell>
                          <Table.Cell>
                            {selectedGolfClub.adminSystemLink ? (
                              <a href={selectedGolfClub.adminSystemLink} rel="noopener noreferrer" target="_blank">
                                {selectedGolfClub.adminSystemLink}
                              </a>
                            ) : (
                              <React.Fragment />
                            )}
                          </Table.Cell>
                          <Table.Cell>{selectedGolfClub.adminSystemLogin}</Table.Cell>
                          <Table.Cell>{selectedGolfClub.adminSystemAccess}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>
                            <strong>Booking site</strong>
                          </Table.Cell>
                          <Table.Cell>
                            {selectedGolfClub.bookingLink ? (
                              <a href={selectedGolfClub.bookingLink} rel="noopener noreferrer" target="_blank">
                                {selectedGolfClub.bookingLink}
                              </a>
                            ) : (
                              <React.Fragment />
                            )}
                          </Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell></Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button color="black" onClick={this.closeDetail}>
                      <Translate id="default.close" />
                    </Button>
                  </Modal.Actions>

                  <Modal size="tiny" className="modal-compact" open={isUnlockPasswordsOpen}>
                    <Modal.Header>Ange lösenord för att låsa upp</Modal.Header>
                    <Modal.Content>
                      <Form>
                        <Form.Field>
                          <Form.Input
                            placeholder="Ange lösenord för att låsa upp"
                            type="password"
                            name="connectedAccountId"
                            onChange={this.onPinChange}
                            value={this.state.pin || ''}
                          />
                        </Form.Field>
                      </Form>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button positive loading={decrypting} onClick={this.unlockPasswords}>
                        <Icon name="lock open" /> Låsa upp
                      </Button>
                      <Button
                        color="black"
                        onClick={() => {
                          this.setState({ isUnlockPasswordsOpen: false });
                        }}
                      >
                        <Translate id="default.close" />
                      </Button>
                    </Modal.Actions>
                  </Modal>
                </Modal>
              )}

              {isEdit && selectedGolfClub && selectedGolfClub.id && (
                <Modal open centered size="large" className="position-unset">
                  <Modal.Header>Edit {`${selectedGolfClub.name} (${selectedGolfClub.id})`}</Modal.Header>
                  <Modal.Content scrolling>
                    <h3>Klubbdata</h3>
                    <Form>
                      <Table compact="very" basic="very" size="small">
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>{translate('default.golfclub')}</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>
                              <Form.Field>
                                <Form.Input
                                  required
                                  placeholder={`${translate('default.golfclub')}`}
                                  name="name"
                                  onChange={(_e, d) => this.validateName(d)}
                                  error={!this.state.isValidName}
                                  value={this.state.selectedGolfClub.name || ''}
                                />
                              </Form.Field>
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Form>

                    <h3>{translate('golfclub.company-header')}</h3>
                    <Form>
                      <Table compact="very" basic="very" size="small">
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell width={8}>{translate('golfclub.company-name')}</Table.HeaderCell>
                            <Table.HeaderCell width={4}>{translate('golfclub.company-regnr')}</Table.HeaderCell>
                            <Table.HeaderCell width={4}>{translate('golfclub.company-vatnr')}</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>
                              <Form.Field>
                                <Form.Input
                                  placeholder={`${translate('golfclub.company-name')}`}
                                  name="companyName"
                                  onChange={this.onCompanyChange}
                                  value={this.state.selectedGolfClub.company?.companyName || ''}
                                />
                              </Form.Field>
                            </Table.Cell>
                            <Table.Cell>
                              <Form.Input
                                placeholder={`${translate('golfclub.company-regnr')}`}
                                name="registrationNumber"
                                onChange={this.onCompanyChange}
                                value={this.state.selectedGolfClub.company?.registrationNumber || ''}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <Form.Input
                                placeholder={`${translate('golfclub.company-vatnr')}`}
                                name="vatNumber"
                                onChange={this.onCompanyChange}
                                value={this.state.selectedGolfClub.company?.vatNumber || ''}
                              />
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>

                      <Table compact="very" basic="very" size="small">
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell width={4}>{translate('golfclub.company-street')}</Table.HeaderCell>
                            <Table.HeaderCell width={4}>{translate('golfclub.company-city')}</Table.HeaderCell>
                            <Table.HeaderCell width={4}>{translate('golfclub.company-zip')}</Table.HeaderCell>
                            <Table.HeaderCell width={4}>{translate('golfclub.company-country')}</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>
                              <Form.Field>
                                <Form.Input
                                  placeholder={`${translate('golfclub.company-street')}`}
                                  name="street"
                                  onChange={this.onCompanyChange}
                                  value={this.state.selectedGolfClub.company?.street || ''}
                                />
                              </Form.Field>
                            </Table.Cell>
                            <Table.Cell>
                              <Form.Input
                                placeholder={`${translate('golfclub.company-city')}`}
                                name="city"
                                onChange={this.onCompanyChange}
                                value={this.state.selectedGolfClub.company?.city || ''}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <Form.Input
                                placeholder={`${translate('golfclub.company-zip')}`}
                                name="postalCode"
                                onChange={this.onCompanyChange}
                                value={this.state.selectedGolfClub.company?.postalCode || ''}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <Form.Input
                                placeholder={`${translate('golfclub.company-country')}`}
                                name="country"
                                onChange={this.onCompanyChange}
                                value={this.state.selectedGolfClub.company?.country || ''}
                              />
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>

                      <Table compact="very" basic="very" size="small">
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell width={8}>{translate('golfclub.company-iban')}</Table.HeaderCell>
                            <Table.HeaderCell width={4}>{translate('golfclub.company-bankaccount')}</Table.HeaderCell>
                            <Table.HeaderCell width={4}>{translate('golfclub.company-reference')}</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>
                              <Form.Field>
                                <Form.Input
                                  placeholder={`${translate('golfclub.company-iban')}`}
                                  name="iBAN"
                                  onChange={this.onCompanyChange}
                                  value={this.state.selectedGolfClub.company?.iBAN || ''}
                                />
                              </Form.Field>
                            </Table.Cell>
                            <Table.Cell>
                              <Form.Input
                                placeholder={`${translate('golfclub.company-bankaccount')}`}
                                name="bankAccount"
                                onChange={this.onCompanyChange}
                                value={this.state.selectedGolfClub.company?.bankAccount || ''}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <Form.Input
                                placeholder={`${translate('golfclub.company-reference')}`}
                                name="reference"
                                onChange={this.onCompanyChange}
                                value={this.state.selectedGolfClub.company?.reference || ''}
                              />
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Form>

                    <h3>Momssatser %</h3>
                    <Table compact="very" basic="very" size="small">
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell width={4}>{translate('golfclub.camping-vat')}</Table.HeaderCell>
                          <Table.HeaderCell width={4}>{translate('golfclub.lodge-vat')}</Table.HeaderCell>
                          <Table.HeaderCell width={4}>{translate('golfclub.golf-cart-vat')}</Table.HeaderCell>
                          <Table.HeaderCell>{translate('golfclub.golf-bike-vat')}</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>
                            <Form.Field>
                              <Form.Input
                                placeholder={`${translate('golfclub.camping-vat')}`}
                                type="number"
                                name="campingVat"
                                onChange={this.onChange}
                                value={this.state.selectedGolfClub.campingVat?.toString() || ''}
                              />
                            </Form.Field>
                          </Table.Cell>
                          <Table.Cell>
                            <Form.Field>
                              <Form.Input
                                placeholder={`${translate('golfclub.lodge-vat')}`}
                                type="number"
                                name="lodgeVat"
                                onChange={this.onChange}
                                value={this.state.selectedGolfClub.lodgeVat?.toString() || ''}
                              />
                            </Form.Field>
                          </Table.Cell>
                          <Table.Cell>
                            <Form.Field>
                              <Form.Input
                                placeholder={`${translate('golfclub.golf-cart-vat')}`}
                                type="number"
                                name="golfCartVat"
                                onChange={this.onChange}
                                value={this.state.selectedGolfClub.golfCartVat?.toString() || ''}
                              />
                            </Form.Field>
                          </Table.Cell>
                          <Table.Cell>
                            <Form.Field>
                              <Form.Input
                                placeholder={`${translate('golfclub.golf-bike-vat')}`}
                                type="number"
                                name="golfBikeVat"
                                onChange={this.onChange}
                                value={this.state.selectedGolfClub.golfBikeVat?.toString() || ''}
                              />
                            </Form.Field>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>

                    <h3>{translate('club-admin.report-tabs.commission')} %</h3>
                    <Table compact="very" basic="very" size="small">
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell width={4}>{translate('golfclub.camping-commission')}</Table.HeaderCell>
                          <Table.HeaderCell width={4}>{translate('golfclub.lodge-commission')}</Table.HeaderCell>
                          <Table.HeaderCell width={4}>{translate('golfclub.golf-cart-commission')}</Table.HeaderCell>
                          <Table.HeaderCell>{translate('golfclub.golf-bike-commission')}</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>
                            <Form.Field>
                              <Form.Input
                                placeholder={`${translate('golfclub.camping-commission')}`}
                                type="number"
                                name="campingCommission"
                                onChange={this.onChange}
                                value={this.state.selectedGolfClub.campingCommission?.toString() || ''}
                              />
                            </Form.Field>
                          </Table.Cell>
                          <Table.Cell>
                            <Form.Field>
                              <Form.Input
                                placeholder={`${translate('golfclub.lodge-commission')}`}
                                type="number"
                                name="lodgeCommission"
                                onChange={this.onChange}
                                value={this.state.selectedGolfClub.lodgeCommission?.toString() || ''}
                              />
                            </Form.Field>
                          </Table.Cell>
                          <Table.Cell>
                            <Form.Field>
                              <Form.Input
                                placeholder={`${translate('golfclub.golf-cart-commission')}`}
                                type="number"
                                name="golfCartCommission"
                                onChange={this.onChange}
                                value={this.state.selectedGolfClub.golfCartCommission?.toString() || ''}
                              />
                            </Form.Field>
                          </Table.Cell>
                          <Table.Cell>
                            <Form.Field>
                              <Form.Input
                                placeholder={`${translate('golfclub.golf-bike-commission')}`}
                                type="number"
                                name="golfBikeCommission"
                                onChange={this.onChange}
                                value={this.state.selectedGolfClub.golfBikeCommission?.toString() || ''}
                              />
                            </Form.Field>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>

                    <h3>{translate('golfclub.online-payment')}</h3>
                    <Table compact="very" basic="very" size="small">
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell width={8}>{translate('golfclub.connected-account-id')}</Table.HeaderCell>
                          <Table.HeaderCell>{translate('golfclub.use-online-payment')}</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>
                            <Form>
                              <Form.Field>
                                <Form.Input
                                  placeholder={translate('golfclub.connected-account-id')}
                                  type="text"
                                  name="connectedAccountId"
                                  onChange={(_e, { value }) =>
                                    this.validateConnectedAccount(this.state.selectedGolfClub.useOnlinePayment, value)
                                  }
                                  value={this.state.selectedGolfClub.connectedAccountId || ''}
                                  // error={!this.state.selectedGolfClub.isValidConnectedAccount}
                                />
                              </Form.Field>
                            </Form>
                          </Table.Cell>
                          <Table.Cell>
                            <Form.Field>
                              <Form.Checkbox
                                name="useOnlinePayment"
                                onChange={(_e, { checked }) =>
                                  this.validateConnectedAccount(
                                    !!checked,
                                    this.state.selectedGolfClub.connectedAccountId
                                  )
                                }
                                checked={this.state.selectedGolfClub.useOnlinePayment}
                              />
                            </Form.Field>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>

                    <h3>Status</h3>
                    <Table compact="very" basic="very" size="small">
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell width={3}>Aktivera widget</Table.HeaderCell>
                          <Table.HeaderCell width={3}>Utbildning</Table.HeaderCell>
                          <Table.HeaderCell width={3}>Stripe länk distribuerad</Table.HeaderCell>
                          <Table.HeaderCell width={3}>Logo i bekr mail</Table.HeaderCell>
                          <Table.HeaderCell>Uppdaterad</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>
                            <Form.Field>
                              <Form.Checkbox
                                name="widgetDisabled"
                                onChange={this.onCheckedChange}
                                checked={!this.state.selectedGolfClub.widgetDisabled}
                              />
                            </Form.Field>
                          </Table.Cell>
                          <Table.Cell>
                            <Form.Field>
                              <Form.Checkbox
                                name="hasTraining"
                                onChange={this.onCheckedChange}
                                checked={this.state.selectedGolfClub.hasTraining}
                              />
                            </Form.Field>
                          </Table.Cell>
                          <Table.Cell>
                            <Form.Field>
                              <Form.Checkbox
                                name="hasStripeLinkSent"
                                onChange={this.onCheckedChange}
                                checked={this.state.selectedGolfClub.hasStripeLinkSent}
                              />
                            </Form.Field>
                          </Table.Cell>
                          <Table.Cell>
                            <Form.Field>
                              <Form.Checkbox
                                name="hasOwnLogoInConfirmationMail"
                                onChange={this.onCheckedChange}
                                checked={this.state.selectedGolfClub.hasOwnLogoInConfirmationMail}
                              />
                            </Form.Field>
                          </Table.Cell>
                          <Table.Cell>
                            <Form.Field>
                              <Form.Checkbox
                                name="isUpdated"
                                onChange={this.onCheckedChange}
                                checked={this.state.selectedGolfClub.isUpdated}
                              />
                            </Form.Field>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>

                    <h3>Access</h3>
                    <Form>
                      <Table compact="very" basic="very" size="small">
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell width={4}>&nbsp;</Table.HeaderCell>
                            <Table.HeaderCell width={4}>Länk</Table.HeaderCell>
                            <Table.HeaderCell width={4}>Inlogg</Table.HeaderCell>
                            <Table.HeaderCell>Lösenord</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>
                              <strong>OnTeeX admin system</strong>
                            </Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell>
                              <Form.Field>
                                <Form.Input
                                  type="text"
                                  name="onTeexLogin"
                                  onChange={this.onChange}
                                  value={this.state.selectedGolfClub.onTeexLogin || ''}
                                />
                              </Form.Field>
                            </Table.Cell>
                            <Table.Cell>
                              <Form.Field>
                                <Form.Input
                                  type="password"
                                  name="onTeexAccess"
                                  onChange={this.onChange}
                                  value={this.state.selectedGolfClub.onTeexAccess || ''}
                                />
                              </Form.Field>
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>
                              <strong>Golfklubbs admin system</strong>
                            </Table.Cell>
                            <Table.Cell>
                              <Form.Input
                                type="text"
                                name="adminSystemLink"
                                onChange={this.onChange}
                                value={this.state.selectedGolfClub.adminSystemLink || ''}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <Form.Field>
                                <Form.Input
                                  type="text"
                                  name="adminSystemLogin"
                                  onChange={this.onChange}
                                  value={this.state.selectedGolfClub.adminSystemLogin || ''}
                                />
                              </Form.Field>
                            </Table.Cell>
                            <Table.Cell>
                              <Form.Field>
                                <Form.Input
                                  type="password"
                                  name="adminSystemAccess"
                                  onChange={this.onChange}
                                  value={this.state.selectedGolfClub.adminSystemAccess || ''}
                                />
                              </Form.Field>
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>
                              <strong>Booking site</strong>
                            </Table.Cell>
                            <Table.Cell>
                              <Form.Input
                                type="text"
                                name="bookingLink"
                                onChange={this.onChange}
                                value={this.state.selectedGolfClub.bookingLink || ''}
                              />
                            </Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Form>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button positive onClick={this.saveGolfClub} loading={saving}>
                      <Translate id="default.save" />
                    </Button>
                    <Button color="black" onClick={this.closeDetail}>
                      <Translate id="default.close" />
                    </Button>
                  </Modal.Actions>
                </Modal>
              )}
            </React.Fragment>
          )}
        </Translate>
      );
    } else {
      return <React.Fragment />;
    }
  }
}
