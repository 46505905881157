export const StatusColor = {
  PREBOOKED_SAME_USER: '#ffdf00',
  PREBOOKED_DIFFERENT_USER: '#ffa500',
  /** Booking is in an active order that has not yet been finished. */
  ACTIVE_ORDER_BOOKING: '#674ea7',
  PAID_FUTURE_BOOKING: '#66ddcc',
  FUTURE_BOOKING: '#7dbbee',
  ACTIVE_BOOKING: '#19c351',
  PASSED_INCOMPLETE_BOOKING: '#961E1E',
  PASSED_COMPLETE_BOOKING: '#E3E3E3',
  BLOCKED_BOOKING: '#E3E3E3',
  DEFAULT: '#800080'
};
