import { put } from 'redux-saga/effects';

import { UNAUTHORIZED } from '../containers/LoginForm/ducks';

export function* backendServiceCall(action) {
  try {
    const { onSubmit, data, backendFunction, callSuccessType, callFailedType } = action;
    const response = yield backendFunction(...data);
    // console.log('response', response);

    if (response.code === 200) {
      if (Array.isArray(response.data)) {
        const data = response.data;
        yield put({ type: callSuccessType, data });
      } else {
        const { warning, ...data } = response.data;
        yield put({ type: callSuccessType, data, warning });
      }
      if (onSubmit) {
        onSubmit();
      }
    } else if (response.code === 401) {
      yield put({ type: UNAUTHORIZED });
    } else {
      yield put({ type: callFailedType, error: response.error || 'Fel' });
    }
  } catch (error) {
    // console.log('Sage error', error);
    // yield put({ type: LOGIN_FAILED, error: error.message });
  }
}
