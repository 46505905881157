import React from 'react';
import { Segment, Item, Table, Label } from 'semantic-ui-react';
import { Translate } from 'react-localize-redux';
import moment from 'moment';
import { EditBooking, EditAccommodationBooking, EditRentalUnitBooking } from './EditBooking';
import { ErrorMessage } from './ErrorMessage';
import ViewEditDeleteComponent from './ViewEditDeleteComponent';
import { isTodayOrLater } from '../utils';
import { RentalUnitType } from '../core/constants';

class ShowBookings extends React.Component {
  render() {
    const { teetime, error } = this.props;
    if (!(teetime && teetime.bookings && teetime.bookings.filter(x => !x.currentUserPrebooking).length > 0)) {
      return null;
    }

    return (
      <div id="OtherBookings">
        {error && <ErrorMessage error={error} />}

        <Segment padded={false} style={{ padding: 0 }} basic loading={this.props.activeRequest}>
          <h1>
            <Translate id="show-bookings.this-teetime" />
          </h1>
          <Segment.Group>
            {teetime &&
              teetime.bookings
                .filter(x => !x.currentUserPrebooking)
                .sort((a, b) => {
                  const comp = b.created.localeCompare(a.created);
                  return comp === 0 ? b.id - a.id : comp;
                })
                .map(booking =>
                  isTodayOrLater(booking.startDate) ? (
                    <ViewEditDeleteComponent
                      key={booking.id}
                      id={'EditBooking' + booking.id}
                      {...this.props}
                      data={booking}
                      deleteAction={this.props.cancelBookingAction}
                      viewComponent={<BookingViewItem />}
                      editComponent={<EditBooking />}
                    />
                  ) : (
                    <Segment key={booking.id}>
                      <BookingViewItem data={booking} />
                    </Segment>
                  )
                )}
          </Segment.Group>
        </Segment>
      </div>
    );
  }
}

/**
 * props: deleteAction, viewComponent, editComponent, data
 */

const BookingViewItem = ({ data: booking }) => {
  const players = [...booking.players.filter(p => p.name !== null || p.email !== null)];
  const createdDate = moment.utc(booking.created, 'YYYY-MM-DD HH:mm');
  const now = moment();
  const createdColor = now.diff(createdDate) > 15 * 60 * 1000 ? null : 'green';
  return (
    <Item className="bookinginfo">
      <Item.Description>
        <Label color={createdColor}>
          <Translate id="default.created" />
          <Label.Detail>{createdDate.local().format('YYYY-MM-DD HH:mm')}</Label.Detail>
        </Label>
        <Label>
          <Translate id="default.nr-players" />
          <Label.Detail>{booking.numberOfPlayers}</Label.Detail>
        </Label>
        <Label>
          <Translate id="default.status" />
          <Label.Detail>
            <Translate id={'booking-status.' + booking?.status.toLowerCase()} />
          </Label.Detail>
        </Label>
        <Label>
          <Translate id="default.price" />
          <Label.Detail>{booking.totalPrice}</Label.Detail>
        </Label>
        <Table size="small" compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <Translate id="default.players" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Translate id="default.email" />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {[...players.keys()].map(slot => (
              <Table.Row key={slot}>
                <Table.Cell>{players[slot].name}</Table.Cell>
                <Table.Cell>{players[slot].email}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {/*
<Table.Body>
{booking.products.map(product => (
  <Table.Row key={product.id}>
    <Table.Cell>{product.quantity}</Table.Cell>
    <Table.Cell>{product.description}</Table.Cell>
  </Table.Row>
))}
</Table.Body>
*/}
      </Item.Description>
    </Item>
  );
};

class ShowAccommodationBookings extends React.Component {
  state = {
    currentlyEditingId: null
  };

  setCurrentlyEditingId = id => {
    this.setState({ currentlyEditingId: id });
  };

  render() {
    const { accommodation, golfClubId, golfClubCurrency, error } = this.props;
    if (!(accommodation?.bookings?.filter(x => !x.currentUserPrebooking).length > 0)) {
      return null;
    }

    const alwaysEnableEditing = true;

    return (
      <div id="OtherBookings">
        <Segment padded={false} style={{ padding: 0 }} basic loading={this.props.activeRequest}>
          <h1>
            {/* <Translate id="show-bookings.existing-bookings" /> */}
            Bokningsbekräftelse
          </h1>
          {error && <ErrorMessage error={error} />}
          <Segment.Group>
            {accommodation &&
              accommodation.bookings
                .filter(x => !x.currentUserPrebooking)
                .sort((a, b) => {
                  const compCheckin = a.checkInDate.localeCompare(b.checkInDate);
                  const comp = b.created.localeCompare(a.created);
                  return compCheckin === 0 ? (comp === 0 ? b.id - a.id : comp) : compCheckin;
                })
                .map(booking =>
                  // isTodayOrLater(booking.checkInDate) ? (
                  // Always allow changes
                  alwaysEnableEditing ? (
                    <ViewEditDeleteComponent
                      key={booking.id}
                      id={`EditBooking${booking.id}`}
                      {...this.props}
                      data={booking}
                      deleteParams={golfClubId}
                      deleteAction={this.props.cancelBookingAction}
                      setCurrentlyEditingId={this.setCurrentlyEditingId}
                      currentlyEditingId={this.state.currentlyEditingId}
                      viewComponent={<AccommodationBookingViewItem />}
                      editComponent={<EditAccommodationBooking golfClubId={golfClubId} />}
                      buttonsLocation="bottom"
                    />
                  ) : (
                    <Segment key={booking.id}>
                      <AccommodationBookingViewItem
                        data={booking}
                        currentlyEditingId={this.state.currentlyEditingId}
                        golfClubCurrency={golfClubCurrency}
                      />
                    </Segment>
                  )
                )}
          </Segment.Group>
        </Segment>
      </div>
    );
  }
}

const AccommodationBookingViewItem = ({ data: booking, currentlyEditingId, golfClubCurrency }) => {
  const createdDate = moment.utc(booking.created, 'YYYY-MM-DD HH:mm');
  const now = moment();
  const createdColor = now.diff(createdDate) > 15 * 60 * 1000 ? null : 'green';

  if (currentlyEditingId) {
    return null;
  }

  return (
    <Item className="bookinginfo">
      <Item.Description>
        <Label color={createdColor}>
          <Translate id="default.created" />
          <Label.Detail>{createdDate.local().format('YYYY-MM-DD HH:mm')}</Label.Detail>
        </Label>
        {booking.numberOfPeople && (
          <Label>
            <Translate id="show-bookings.existing-bookings" />
            <Label.Detail>{booking.numberOfPeople}</Label.Detail>
          </Label>
        )}
        <Label>
          <Translate id="default.status" />
          <Label.Detail>
            <Translate id={`booking-status.${booking?.status.toLowerCase()}`} />
          </Label.Detail>
        </Label>
        <Label>
          <Translate id="default.price" />
          <Label.Detail>
            {booking.priceInclVat} {golfClubCurrency}
          </Label.Detail>
        </Label>
        <Label>
          <Translate id="show-bookings.checkin-out" />
          <Label.Detail>
            {booking.checkInDate} - {booking.checkOutDate}
          </Label.Detail>
        </Label>

        <Table size="small" compact>
          <Table.Header className="hide-xs">
            <Table.Row>
              <Table.HeaderCell>
                <Translate id="default.players" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Translate id="default.email" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Translate id="default.golf-id" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Translate id="default.phone" />
              </Table.HeaderCell>
              {booking.accommodation.type?.id === RentalUnitType.CAMPING.id && (
                <Table.HeaderCell>
                  <Translate id="default.plate-number" />
                </Table.HeaderCell>
              )}
            </Table.Row>
            {/* <Table.Row>
              <Table.HeaderCell>
                <Translate id="default.comment" />
              </Table.HeaderCell>
            </Table.Row> */}
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{booking.bookingUser.name}</Table.Cell>
              <Table.Cell>{booking.bookingUser.email}</Table.Cell>
              <Table.Cell>{booking.bookingUser.golfId}</Table.Cell>
              <Table.Cell>{booking.bookingUser.phone}</Table.Cell>
              {booking.accommodation.type?.id === RentalUnitType.CAMPING.id && (
                <Table.Cell>{booking.bookingUser.plateNumber}</Table.Cell>
              )}
            </Table.Row>
            {/* <Table.Row>
              <Table.Cell colSpan="4" style={{ paddingTop: 20 }}>
                {booking.comment}
              </Table.Cell>
            </Table.Row> */}
          </Table.Body>
        </Table>
      </Item.Description>
    </Item>
  );
};

class ShowRentalUnitBookings extends React.Component {
  render() {
    const { rentalUnit, golfClubId, golfClubCurrency, error } = this.props;
    if (!(rentalUnit && rentalUnit.bookings && rentalUnit.bookings.filter(x => !x.currentUserPrebooking).length > 0)) {
      return null;
    }

    return (
      <div id="OtherBookings">
        <Segment padded={false} style={{ padding: 0 }} basic loading={this.props.activeRequest}>
          {!this.props.isWidgetRequest && (
            <h1>
              <Translate id="show-bookings.existing-bookings" />
            </h1>
          )}
          {error && <ErrorMessage error={error} />}
          <Segment.Group>
            {rentalUnit &&
              rentalUnit.bookings
                .filter(x => !x.currentUserPrebooking)
                .sort((a, b) => {
                  const comp = b.created.localeCompare(a.created);
                  return comp === 0 ? b.id - a.id : comp;
                })
                .map(booking =>
                  !this.props.isWidgetRequest ? (
                    <ViewEditDeleteComponent
                      key={booking.id}
                      id={`EditBooking${booking.id}`}
                      {...this.props}
                      data={booking}
                      deleteParams={golfClubId}
                      deleteAction={this.props.cancelBookingAction}
                      viewComponent={<RentalUnitBookingViewItem />}
                      editComponent={<EditRentalUnitBooking golfClubId={golfClubId} />}
                      buttonsLocation="bottom"
                    />
                  ) : (
                    <div key={booking.id}>
                      {this.props.lastWidgetBooker?.email === booking.bookingUser.email && (
                        <Segment>
                          <RentalUnitBookingViewItem data={booking} golfClubCurrency={golfClubCurrency} />
                        </Segment>
                      )}
                    </div>
                  )
                )}
          </Segment.Group>
        </Segment>
      </div>
    );
  }
}

const RentalUnitBookingViewItem = ({ data: booking, golfClubCurrency }) => {
  // var createdDate = moment.utc(booking.created, 'YYYY-MM-DD HH:mm');
  // var now = moment();
  // var createdColor = now.diff(createdDate) > 15 * 60 * 1000 ? null : 'green';
  return (
    <Item className="bookinginfo">
      <Item.Description>
        {/* <Label color={createdColor}>
          <Translate id="default.created" />
          <Label.Detail>{createdDate.local().format('YYYY-MM-DD HH:mm')}</Label.Detail>
        </Label> */}
        <Label>
          <Translate id="default.start-time" />
          <Label.Detail>{booking.nominalStart}</Label.Detail>
        </Label>
        <Label>
          <Translate id="default.status" />
          <Label.Detail>
            <Translate id={`booking-status.${booking?.status.toLowerCase()}`} />
          </Label.Detail>
        </Label>
        <Label>
          <Translate id="default.price" />
          <Label.Detail>
            {booking.priceInclVat} {golfClubCurrency}
          </Label.Detail>
        </Label>
        {booking.hasMedicalCertificate && (
          <Label>
            <Translate id="default.medical-cert" />: Yes
          </Label>
        )}

        <Table size="small" compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <Translate id="default.players" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Translate id="default.email" />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{booking.bookingUser.name}</Table.Cell>
              <Table.Cell>{booking.bookingUser.email}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Item.Description>
    </Item>
  );
};

export { ShowBookings, ShowAccommodationBookings, ShowRentalUnitBookings };
