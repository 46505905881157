import React, { useState } from 'react';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

function DatePicker({ fullSize, defaultDate, onClose, label, calendarInfo, placeholder }) {
  const [selectedDate, setSelectedDate] = useState(defaultDate);
  const [calendarFocused, setCalendarFocused] = useState(fullSize);

  const onDateChange = date => {
    setSelectedDate(date);
    onClose({ selectedDate: date });
  };

  return (
    <>
      {label && <label className="time-picker-label">{label}</label>}
      <SingleDatePicker
        date={selectedDate}
        id="date"
        displayFormat="YYYY-MM-DD"
        focused={calendarFocused}
        onFocusChange={({ focused }) => setCalendarFocused(focused)}
        placeholder={placeholder}
        hideKeyboardShortcutsPanel
        showClearDate
        showDefaultInputIcon
        reopenPickerOnClearDate
        orientation={fullSize ? 'horizontal' : 'vertical'}
        withPortal={!fullSize}
        withFullScreenPortal={!fullSize}
        daySize={fullSize ? 60 : 40}
        onDateChange={onDateChange}
        // onClose={onCalendarClose}
        renderCalendarInfo={() =>
          calendarInfo &&
          fullSize && <p style={{ background: '#e4e7e7', padding: '20px', fontWeight: 'bold' }}>{calendarInfo}</p>
        }
        calendarInfoPosition="top"
      />
    </>
  );
}

export default React.memo(DatePicker);
