import React from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { Segment, Grid, Table, Form, Button, Select, DropdownProps, Input, InputProps } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { getSurveys } from '../../../core/services/golfclub.service';
import { isValidDate, todaysDate, endOfMonth, startOfMonth } from '../../../utils';
import { CSVLink } from 'react-csv';

interface SurveysReportTabProps extends LocalizeContextProps {
  active: boolean;
  translate: any;
}

interface SurveyItem {
  checkInDate: string;
  checkOutDate: Date;
  bookingType: string;
  overallRating: number;
  recommendRating: number;
  onTeeApp: string;
  visitInfo: string;
  missing: string;
  improve: string;
  golfClubId: number;
  accommodationName: string;
}

class SurveysReportReportTab extends React.Component<SurveysReportTabProps> {
  state = {
    loading: false,
    surveys: [],
    scores: [],
    hasMissingText: false,
    hasImproveText: false,
    npsScore: null,
    overallRating: '',
    overallRatingOperator: '',
    commissionType: 0,
    startDate: startOfMonth(todaysDate()),
    startDateValid: true,
    endDate: endOfMonth(todaysDate()),
    endDateValid: true
  };

  operatorTypes = [
    { text: '', value: '' },
    { text: this.props.translate('club-admin.report-tabs.operator-equal'), value: 'E' },
    { text: this.props.translate('club-admin.report-tabs.operator-less'), value: 'LT' },
    { text: this.props.translate('club-admin.report-tabs.operator-less-equal'), value: 'LTE' },
    { text: this.props.translate('club-admin.report-tabs.operator-greater'), value: 'GT' },
    { text: this.props.translate('club-admin.report-tabs.operator-greater-equal'), value: 'GTE' }
  ];

  surveyHeaders = [
    { label: this.props.translate('club-admin.report-tabs.payout-id'), key: 'id' },
    { label: this.props.translate('club-admin.report-tabs.transaction-type'), key: 'bookingType' },
    { label: this.props.translate('club-admin.report-tabs.overall-rating'), key: 'overallRating' },
    { label: this.props.translate('club-admin.report-tabs.visit-info'), key: 'visitInfo' },
    { label: this.props.translate('club-admin.report-tabs.missing'), key: 'missing' },
    { label: this.props.translate('club-admin.report-tabs.ontee-app'), key: 'onTeeApp' },
    { label: this.props.translate('club-admin.report-tabs.recommend-rating'), key: 'recommendRating' },
    { label: this.props.translate('club-admin.report-tabs.improve'), key: 'improve' },
    { label: this.props.translate('default.golfclub'), key: 'golfClubId' },
    { label: this.props.translate('default.accommodation'), key: 'accommodationName' },
    { label: this.props.translate('default.checkin'), key: 'checkInDate' },
    { label: this.props.translate('default.checkout'), key: 'checkOutDate' }
  ];

  validateDatetimeChange = (_e: any, { name, value }: any) => {
    let { startDate, endDate, startDateValid, endDateValid } = this.state;

    if (name === 'startDate') {
      startDate = value;
      startDateValid = isValidDate(startDate);
    } else {
      endDate = value;
    }
    endDateValid = isValidDate(endDate) && endDate >= startDate;
    this.setState({ startDate, endDate, startDateValid, endDateValid });
  };

  fetchStats = async () => {
    const {
      startDate,
      endDate,
      startDateValid,
      endDateValid,
      overallRating,
      overallRatingOperator,
      hasImproveText,
      hasMissingText
    } = this.state;
    if (startDateValid && endDateValid) {
      this.setState({ loading: true });
      try {
        const surveysData = await getSurveys(
          startDate,
          endDate,
          overallRating,
          overallRatingOperator,
          hasImproveText,
          hasMissingText
        );

        if ('data' in surveysData) {
          this.setState({
            loading: false,
            surveys: surveysData.data.surveys,
            scores: surveysData.data.scores,
            npsScore: surveysData.data.npsScore
          });
        }
      } catch (error) {
        this.setState({ loading: false });
      }
    }
  };

  changeOperator = (_e: any, data: DropdownProps) => {
    this.setState({ overallRatingOperator: data.value });
  };

  onOverallRatingChanged = (_e: any, data: InputProps) => {
    this.setState({ overallRating: data.value });
  };

  render() {
    const {
      startDate,
      endDate,
      startDateValid,
      endDateValid,
      loading,
      surveys,
      scores,
      npsScore,
      overallRatingOperator,
      overallRating
    } = this.state;
    const { translate } = this.props;

    const surveysCsvReport = {
      data: surveys,
      headers: this.surveyHeaders,
      filename: `${this.props.translate('club-admin.surveys')}.csv`,
      separator: ';'
    };

    if (this.props.active) {
      return (
        <Segment>
          <div className="p-1">
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>
                    <Translate id="default.start-date" />
                  </label>
                  <DateInput
                    closable
                    dateFormat="YYYY-MM-DD"
                    name="startDate"
                    placeholder={translate('default.start-date')}
                    value={startDate}
                    error={!startDateValid}
                    onChange={this.validateDatetimeChange}
                    localization="sv"
                  />
                </Form.Field>
                <Form.Field>
                  <label>
                    <Translate id="default.end-date" />
                  </label>
                  <DateInput
                    closable
                    dateFormat="YYYY-MM-DD"
                    minDate={startDate}
                    name="endDate"
                    placeholder={translate('default.end-date')}
                    value={endDate}
                    error={!endDateValid}
                    onChange={this.validateDatetimeChange}
                    localization="sv"
                  />
                </Form.Field>
                <Form.Field width={3}>
                  <label>
                    <Translate id="club-admin.report-tabs.overall-rating-operator" />
                  </label>
                  <Select
                    options={this.operatorTypes}
                    name="overallRatingOperator"
                    value={overallRatingOperator}
                    onChange={this.changeOperator}
                  />
                </Form.Field>
                <Form.Field width={1}>
                  <label>
                    <Translate id="club-admin.report-tabs.overall-rating-value" />
                  </label>
                  <Input
                    control={Input}
                    type="text"
                    inputmode="numeric"
                    minValue={0}
                    maxValue={10}
                    name="overallRating"
                    value={overallRating}
                    onChange={this.onOverallRatingChanged}
                  />
                </Form.Field>
                <Form.Field style={{ paddingTop: '35px' }}>
                  <Form.Checkbox
                    inline
                    label={translate('club-admin.report-tabs.has-missing-text')}
                    onChange={(_e, { checked }) => {
                      this.setState({ hasMissingText: checked });
                    }}
                    checked={this.state.hasMissingText}
                  />
                </Form.Field>
                <Form.Field style={{ paddingTop: '35px' }}>
                  <Form.Checkbox
                    inline
                    label={translate('club-admin.report-tabs.has-improve-text')}
                    onChange={(_e, { checked }) => {
                      this.setState({ hasImproveText: checked });
                    }}
                    checked={this.state.hasImproveText}
                  />
                </Form.Field>
                <Form.Field>
                  <div style={{ position: 'absolute', bottom: '0px' }}>
                    <Button onClick={this.fetchStats} disabled={loading}>
                      {translate('default.fetch')}
                    </Button>
                  </div>
                </Form.Field>
              </Form.Group>
            </Form>

            <Segment loading={loading}>
              <Grid>
                <Grid.Column floated="left" width="5">
                  <b>NPS: {npsScore}</b>
                </Grid.Column>
              </Grid>
              <Table compact>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>&#x1F642;</Table.HeaderCell>
                    <Table.HeaderCell>#</Table.HeaderCell>
                    <Table.HeaderCell>%</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {scores?.map((stat: any, i: number) => (
                    <Table.Row key={i}>
                      <Table.Cell>{stat.rating}</Table.Cell>
                      <Table.Cell>{stat.count}</Table.Cell>
                      <Table.Cell>{stat.percentage}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Segment>

            <Segment loading={loading}>
              <Grid>
                <Grid.Column floated="left" width="5">
                  <Translate id="club-admin.surveys" />
                </Grid.Column>
                {surveys?.length > 0 && (
                  <Grid.Column floated="right" width="5" textAlign="right">
                    <CSVLink {...surveysCsvReport}>{translate('club-admin.export-to-csv')}</CSVLink>
                  </Grid.Column>
                )}
              </Grid>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      <Translate id="club-admin.report-tabs.transaction-type" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Translate id="club-admin.report-tabs.overall-rating" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Translate id="club-admin.report-tabs.missing" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Translate id="club-admin.report-tabs.recommend-rating" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Translate id="club-admin.report-tabs.improve" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Translate id="club-admin.report-tabs.visit-info" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Translate id="club-admin.report-tabs.ontee-app" />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {surveys?.map((stat: SurveyItem, i: number) => (
                    <Table.Row key={i}>
                      {/* <Table.Cell style={{ whiteSpace: "nowrap" }}>{stat.checkInDate}</Table.Cell>
                                        <Table.Cell style={{ whiteSpace: "nowrap" }}>{stat.checkOutDate}</Table.Cell>                                     */}
                      <Table.Cell>{stat.bookingType}</Table.Cell>
                      <Table.Cell>{stat.overallRating}</Table.Cell>
                      <Table.Cell>{stat.missing}</Table.Cell>
                      <Table.Cell>{stat.recommendRating}</Table.Cell>
                      <Table.Cell>{stat.improve}</Table.Cell>
                      <Table.Cell>{stat.visitInfo}</Table.Cell>
                      <Table.Cell>{stat.onTeeApp}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Segment>
          </div>
        </Segment>
      );
    }

    return null;
  }
}

export default withLocalize(SurveysReportReportTab);
