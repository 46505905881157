import React, { useState } from 'react';
import moment from 'moment';
import { Icon, Popup } from 'semantic-ui-react';
import Cell, { BlockedPeriodCell, BookingCell } from './Cell';
import { BookingStatus } from '../../core/enums';

function Row({
  accommodation,
  onClickAvailable,
  onClickOccupied,
  onDrop,
  cellWidth,
  overViewDate,
  translate,
  daysInMonth
}) {
  const currentDate = moment();
  const yearMonthIsCurrent = overViewDate.isSame(currentDate, 'month');
  const yearMonthInFuture = overViewDate.isAfter(currentDate);

  const [days, setDays] = useState({});
  const [active, setActive] = useState(false);

  const onMouseDown = day => {
    setDays({
      [day]: true
    });
    setActive(true);
  };

  const onMouseOver = day => {
    if (active) {
      setDays({
        ...days,
        [day]: true
      });
    }
  };

  const onMouseUp = () => {
    if (active) {
      const daysInSequence = Object.keys(days).sort((a, b) => a - b);
      const startDay = daysInSequence[0];
      let endDay = daysInSequence[daysInSequence.length - 1];

      if (startDay === endDay) {
        endDay = (parseInt(endDay, 10) + 1).toString();
      }

      setDays({});
      setActive(false);
      onClickAvailable(accommodation.id, { startDay, endDay });
    }
  };

  const onMouseLeaveRow = () => {
    if (active) {
      setDays({});
      setActive(false);
    }
  };

  const rows = [];
  const currentDay = parseInt(currentDate.format('DD'), 10);
  const currentStartOfDay = currentDate.startOf('day');

  const overViewMonth = parseInt(overViewDate.format('MM'), 10);
  const overViewYear = parseInt(overViewDate.format('YYYY'), 10);

  accommodation.scheme.forEach((s, idx) => {
    const { nrOfDays, booking, checkInDateInPrevMonth, day } = s;

    const bookingStartsMonth = idx === 0 && booking;
    const nextUpBooking = accommodation.scheme[idx + 1]?.booking;
    let prevBooking = accommodation.scheme[idx + -1]?.booking;

    if (booking) {
      const width = cellWidth * nrOfDays + (checkInDateInPrevMonth ? 15 : 0);
      if (bookingStartsMonth && !checkInDateInPrevMonth) {
        const dayNumberString = day < 10 ? `0${day}` : day.toString();
        const monthNumberString = overViewMonth < 10 ? `0${overViewMonth}` : overViewMonth.toString();
        const dayString = `${overViewYear}-${monthNumberString}-${dayNumberString}`;
        const cellDay = moment(dayString, 'YYYY-MM-DD');

        rows.push(
          <Cell
            key={`${accommodation.id}${day}${booking.id}H`}
            accommodationId={accommodation.id}
            cellWidth={cellWidth / 2}
            activeDays={days}
            accommodationName={accommodation.name}
            canBeDroppedTo={cellDay.isSameOrAfter(currentStartOfDay)}
            onDrop={onDrop}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseOver={onMouseOver}
          />
        );
      }
      if (booking.isBlockedPeriod) {
        rows.push(
          <BlockedPeriodCell
            key={`${accommodation.id}${day}${booking.id}`}
            cellWidth={width}
            text={booking.description}
            translate={translate}
          />
        );
      } else {
        const canBeDragged = moment(booking.checkInDate).isSameOrAfter(currentStartOfDay);

        rows.push(
          <BookingCell
            key={`${accommodation.id}${day}${booking.id}`}
            accommodationName={accommodation.name}
            canBeDragged={canBeDragged}
            cellWidth={width}
            booking={booking}
            onClick={(booking.status === BookingStatus.CONFIRMED || booking.currentUserPrebooking) && onClickOccupied}
          />
        );
      }
    } else {
      for (let i = day; i <= day + nrOfDays; i += 1) {
        const divideCell = prevBooking || (nextUpBooking && i === day + nrOfDays) ? 2 : 1;

        const dayNumberString = i < 10 ? `0${i}` : i.toString();
        const monthNumberString = overViewMonth < 10 ? `0${overViewMonth}` : overViewMonth.toString();
        const dayString = `${overViewYear}-${monthNumberString}-${dayNumberString}`;
        const cellDay = moment(dayString, 'YYYY-MM-DD');

        rows.push(
          <Cell
            key={`${accommodation.id}-${i}`}
            accommodationId={accommodation.id}
            accommodationName={accommodation.name}
            canBeDroppedTo={cellDay.isSameOrAfter(currentStartOfDay)}
            cellWidth={cellWidth / divideCell}
            day={i}
            dayString={dayString}
            activeDays={days}
            onDrop={onDrop}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseOver={onMouseOver}
            available={yearMonthInFuture || (yearMonthIsCurrent && i >= currentDay)}
          />
        );
        prevBooking = false;
      }
    }
  });

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'row', marginTop: '10px' }} onMouseLeave={onMouseLeaveRow}>
      <div
        className="accommodationNameCell"
        style={{
          display: 'flex',
          width: '130px',
          maxWidth: '130px',
          minWidth: '130px',
          alignItems: 'center',
          wordBreak: 'break-all',
          borderRight: '1pt solid #ccc'
        }}
      >
        <Popup
          trigger={
            // <Button color="red" size="mini" icon disabled={btnsDisabled}>
            <Icon name="info circle" />
            // </Button>
          }
          content={accommodation.info}
          // on="click"
          position="top right"
        />
        {accommodation.adminBookingOnly ? (
          <em style={{ color: '#FF3333' }}>{accommodation.name}</em>
        ) : (
          accommodation.name
        )}
      </div>
      {rows}
    </div>
  );
}

export default Row;
