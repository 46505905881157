import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Translate, withLocalize } from 'react-localize-redux';
import { Button, Form, Grid, Icon, Input, Segment, Table } from 'semantic-ui-react';
import moment from 'moment';
import DiscountView from './DiscountView';
import { ErrorMessage } from '../ErrorMessage';
import DiscountEdit from './DiscountEdit';
import * as api from '../../utils/api';
import { getGolfClubs } from '../../containers/AccommodationSheet/ducks';
import { RentalUnitType } from '../../core/constants';

const defaultDiscount = {
  name: '',
  code: '',
  applyStart: moment().format('YYYY-MM-DD'),
  applyEnd: moment().format('YYYY-MM-DD'),
  publishStart: moment().format('YYYY-MM-DD'),
  publishEnd: moment().format('YYYY-MM-DD'),
  discountType: null,
  amount: 0,
  rentalUnitTypeIds: [],
  info: '',
  active: true,
  canBeCombined: false,
  isSecret: false
};

let allDiscounts = [];
let accommodationTypes = [];
let golfclub = null;

function Discounts({ translate, golfClubs }) {
  const [discounts, setDiscounts] = useState([]);
  const [discount, setDiscount] = useState(null);
  const [filterData, setFilterData] = useState({ code: '' });
  const [apiLoading, setApiLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    document.body.classList.add('config-discounts');
    window.scrollTo(0, 0);

    [golfclub] = golfClubs;

    initializeData();

    return function cleanup() {
      document.body.classList.remove('config-discounts');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initializeData = async () => {
    const fetchCategories = async () => {
      const { code, data } = await api.fetchCategories();
      if (code === 200) {
        accommodationTypes = data.accommodationTypes.map(a => ({
          value: a.id,
          text: translate(`accommodation.${a.name}`)
        }));
        const golfCartType = {
          value: RentalUnitType.GOLF_CART.id,
          text: translate(`accommodation.${RentalUnitType.GOLF_CART.name}`)
        };
        accommodationTypes.push(golfCartType);
      }
    };

    const fetchDiscounts = async () => {
      const { data } = await api.fetchDiscounts();
      return data;
    };

    setApiLoading(true);
    Promise.all([fetchCategories(), fetchDiscounts()]).then(response => {
      if (response[1]) {
        [, allDiscounts] = response;
        setDiscounts(response[1]);
      }
      setApiLoading(false);
    });
  };

  const filteredDiscounts = () => {
    let filteredDisc = allDiscounts;

    if (filterData.code) {
      filteredDisc = filteredDisc.filter(a => a.code.includes(filterData.code));
    }

    return filteredDisc;
  };

  const filterByCode = (_e, { name, value }) => {
    setDiscounts(allDiscounts.filter(a => a.code.includes(value)));
    setFilterData({ [name]: value });
  };

  const onClickAddDiscount = () => {
    const dscnt = {
      ...defaultDiscount,
      golfClubId: golfclub.id
    };

    setDiscount(dscnt);
  };

  const onClickEditDiscount = acc => {
    setDiscount(acc);
  };

  const onClickCancelEditDiscount = () => {
    setDiscount(null);
  };

  const onClickSaveDiscount = async dcnt => {
    setApiLoading(true);
    setErrorMessage(null);

    const { code, data } = await api.saveDiscount(dcnt);
    if (code === 200) {
      allDiscounts = data;
      const dcnts = filteredDiscounts();
      ReactDOM.unstable_batchedUpdates(() => {
        setDiscount(null);
        setDiscounts(dcnts);
      });
    } else {
      setErrorMessage('Tyvärr kunde rabattkoden inte sparas');
    }
    setApiLoading(false);
  };

  const onClickDeleteDiscount = async dcnt => {
    setApiLoading(true);
    setErrorMessage(null);
    const { code } = await api.deleteDiscount(dcnt);
    if (code === 200) {
      // Delete don't return discounts
      // allDiscounts = data;
      // Remove deleted discount
      allDiscounts = allDiscounts.filter(d => d.id !== dcnt.id);
      const dcnts = filteredDiscounts();
      ReactDOM.unstable_batchedUpdates(() => {
        setDiscount(null);
        setDiscounts(dcnts);
      });
    } else {
      setErrorMessage('Tyvärr kunde rabattkoden inte tas bort');
    }
    setApiLoading(false);
  };

  return (
    <Segment loading={apiLoading}>
      <Grid colums={2}>
        <Grid.Row>
          <Grid.Column width={8}>
            <Form>
              <Form.Group inline>
                <Input
                  name="code"
                  icon="search"
                  placeholder={translate('discount.filter-placeholder')}
                  value={filterData.code}
                  onChange={filterByCode}
                  style={{ marginRight: '10px' }}
                />
                {/* <Select
                    name="accommodationType"
                    placeholder="Filtrera på typ"
                    options={accommodationTypes}
                    value={filterData.accommodationType}
                    onChange={filterByType}
                    clearable
                  /> */}
              </Form.Group>
            </Form>
          </Grid.Column>
          <Grid.Column width={8} textAlign="right">
            <Button color="green" onClick={onClickAddDiscount}>
              <Icon name="add" />
              <Translate id="discount.add" />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <ErrorMessage error={errorMessage} />
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <Translate id="discount.code" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Translate id="default.name" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Translate id="discount.amount" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Translate id="default.dates" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Translate id="discount.rental-type" />
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {discounts.map(d => (
            <DiscountView
              key={d.id}
              discount={d}
              onClickEdit={onClickEditDiscount}
              onClickDelete={onClickDeleteDiscount}
              accomodationTypes={accommodationTypes}
              golfClubCurrency={golfclub.currency}
            />
          ))}
        </Table.Body>
      </Table>

      {discount && (
        <DiscountEdit
          discount={discount}
          onClickCancel={onClickCancelEditDiscount}
          onClickSave={onClickSaveDiscount}
          accommodationTypes={accommodationTypes}
          loading={apiLoading}
          errorMessage={errorMessage}
        />
      )}
    </Segment>
  );
}

function mapStateToProps(state) {
  return {
    golfClubs: getGolfClubs(state)
  };
}

export default withLocalize(connect(mapStateToProps, null)(Discounts));
