import * as Api from '../../utils/api';

export const INVITE_CLUB_ADMIN_RESET = 'inhouseAdmin/INVITE_CLUB_ADMIN_RESET';
export const INVITE_CLUB_ADMIN_REQUEST = 'inhouseAdmin/INVITE_CLUB_ADMIN_REQUEST';
export const INVITE_CLUB_ADMIN_REQUEST_SUCCESS = 'inhouseAdmin/INVITE_CLUB_ADMIN_REQUEST_SUCCESS';
export const INVITE_CLUB_ADMIN_REQUEST_FAILED = 'inhouseAdmin/INVITE_CLUB_ADMIN_REQUEST_FAILED';

export const inviteClubAdminAction = (user) => ({
  type: INVITE_CLUB_ADMIN_REQUEST,
  data: [user],
  backendFunction: Api.inviteClubAdmin,
  callSuccessType: INVITE_CLUB_ADMIN_REQUEST_SUCCESS,
  callFailedType: INVITE_CLUB_ADMIN_REQUEST_FAILED
});
export const inviteClubAdminResetAction = () => ({ type: INVITE_CLUB_ADMIN_RESET });

const initialState = {
  email: {},
  activeRequest: false,
  error: null,
  invitationDone: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INVITE_CLUB_ADMIN_RESET:
      return {
        ...state,
        email: {},
        activeRequest: false,
        invitationDone: false,
        error: null
      };
    case INVITE_CLUB_ADMIN_REQUEST:
      return {
        ...state,
        email: action.data[0].email,
        activeRequest: true,
        invitationDone: false,
        error: null
      };
    case INVITE_CLUB_ADMIN_REQUEST_SUCCESS:
      return {
        ...state,
        activeRequest: false,
        invitationDone: action.data.email,
        email: {},
        error: null
      };
    case INVITE_CLUB_ADMIN_REQUEST_FAILED:
      return {
        ...state,
        activeRequest: false,
        invitationDone: false,
        error: action.error
      };
    default:
      return state;
  }
};

export default reducer;
