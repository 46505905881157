import React, { Component } from 'react';

import { Container } from 'semantic-ui-react';

import Helmet from '../components/Helmet';
import ChangePasswordForm from '../containers/ChangePasswordForm';

class ChangePasswordPage extends Component {
  render() {
    return (
      <div className="app app-change-password">
        <Helmet title="OnTee change password" description="OnTee on change password steroid!" />
        <Container className="mw-900">
          <ChangePasswordForm />
        </Container>
      </div>
    );
  }
}

export default ChangePasswordPage;
