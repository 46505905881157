import React from 'react';
import { CSVLink } from 'react-csv';
import { Translate } from 'react-localize-redux';
import { Segment, Grid, Table } from 'semantic-ui-react';
import { getAccommodationBookingAddons, IAddonReportItem } from '../../../core/services/product.service';
import { isValidDate, startOfMonth, endOfMonth, todaysDate, getFormattedPrice } from '../../../utils';
import { ReportDateComponent } from '../ReportDateComponent';

interface ProductAddonsReportTabProps {
  active: boolean;
  translate: any;
}

export class ProductAddonsReportTab extends React.Component<ProductAddonsReportTabProps> {
  state = {
    loading: false,
    addons: [] as IAddonReportItem[],
    startDate: startOfMonth(todaysDate()),
    startDateValid: true,
    endDate: endOfMonth(todaysDate()),
    endDateValid: true
  };

  addonHeaders = [
    { label: this.props.translate('product-addons.headers.name'), key: 'productName' },
    { label: '#', key: 'itemsCount' },
    { label: this.props.translate('default.accommodation'), key: 'accommodationName' },
    { label: this.props.translate('product-addons.headers.price'), key: 'chargeAmount' },
    { label: '', key: 'chargeCurrency' },
    { label: this.props.translate('club-admin.report-tabs.order-id'), key: 'accommodationBookingId' },
    { label: this.props.translate('default.checkin'), key: 'checkinDate' },
    { label: this.props.translate('default.checkout'), key: 'checkoutDate' }
  ];

  validateDatetimeChange = (_e: any, { name, value }: any) => {
    let { startDate, endDate, startDateValid, endDateValid } = this.state;

    if (name === 'startDate') {
      startDate = value;
      startDateValid = isValidDate(startDate);
      if (startDateValid) {
        endDate = endOfMonth(startDate);
      }
    } else {
      endDate = value;
    }
    endDateValid = isValidDate(endDate) && endDate >= startDate;
    this.setState({ startDate, endDate, startDateValid, endDateValid });
  };

  fetchAddons = async () => {
    const { startDate, endDate, startDateValid, endDateValid } = this.state;
    if (startDateValid && endDateValid) {
      this.setState({ loading: true });

      try {
        const payouts = await getAccommodationBookingAddons(startDate, endDate);

        if ('data' in payouts) {
          /*
          const groupedAddons = _(payouts.data)
            .groupBy(item => item.checkoutDate)
            .map((value: IAddonReportItem[], key: Date) => ({ date: key, count: value.length, items: value.map(obj => { return obj.categoryName }) } as CleaningReportItem))
            .value();
            */

          const newData = [] as IAddonReportItem[];

          payouts.data.forEach((item: IAddonReportItem) =>
            newData.push({
              ...item,
              productName: this.props.translate(`product-addons.type-names.${item.productName}`)
            })
          );

          this.setState({
            loading: false,
            addons: newData
          });
        } else {
          this.setState({
            loading: false
          });
        }
      } catch (error) {
        this.setState({ loading: false });
      }
    }
  };

  render() {
    const { startDate, endDate, startDateValid, endDateValid, loading, addons } = this.state;

    const addonsCsvReport = {
      data: addons,
      headers: this.addonHeaders,
      filename: `${this.props.translate('club-admin.product-addons')}.csv`,
      separator: ';'
    };

    if (this.props.active) {
      return (
        <Translate>
          {({ translate }) => (
            <Segment>
              <div className="p-1">
                <ReportDateComponent
                  startDate={startDate}
                  startDateValid={startDateValid}
                  endDate={endDate}
                  endDateValid={endDateValid}
                  loading={loading}
                  fetchBookings={this.fetchAddons}
                  validateDatetimeChange={this.validateDatetimeChange}
                />
                <Grid>
                  <Grid.Column floated="left" width="5">
                    <Translate id="product-addons.addons" />
                  </Grid.Column>
                  {addons?.length > 0 && (
                    <Grid.Column floated="right" width="5" textAlign="right">
                      <CSVLink {...addonsCsvReport}>{translate('club-admin.export-to-csv')}</CSVLink>
                    </Grid.Column>
                  )}
                </Grid>

                <Segment loading={loading}>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          <Translate id="product-addons.headers.name" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="accommodation.accommodation-type" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="club-admin.report-tabs.order-id" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="default.checkin" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="default.checkout" />
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                          <Translate id="product-addons.headers.price" />
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {addons?.map((addon, i) => {
                        return (
                          <Table.Row key={i}>
                            <Table.Cell>{addon.productName}</Table.Cell>
                            <Table.Cell>{addon.itemsCount}</Table.Cell>
                            <Table.Cell>{addon.accommodationName}</Table.Cell>
                            <Table.Cell>{addon.accommodationBookingId}</Table.Cell>
                            <Table.Cell>{addon.checkinDate}</Table.Cell>
                            <Table.Cell>{addon.checkoutDate}</Table.Cell>
                            <Table.Cell textAlign="right">
                              {getFormattedPrice(addon.chargeAmount * addon.itemsCount, addon.chargeCurrency)}
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                      {addons?.length > 0 && (
                        <Table.Row style={{ fontWeight: 700 }}>
                          <Table.Cell>{translate('club-admin.sum')}</Table.Cell>
                          <Table.Cell />
                          <Table.Cell />
                          <Table.Cell />
                          <Table.Cell />
                          <Table.Cell />
                          <Table.Cell textAlign="right">
                            {getFormattedPrice(
                              addons.reduce((acc, curr) => acc + curr.chargeAmount * curr.itemsCount, 0),
                              addons[0].chargeCurrency
                            )}
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Table.Body>
                  </Table>
                </Segment>
              </div>
            </Segment>
          )}
        </Translate>
      );
    }

    return <></>;
  }
}
