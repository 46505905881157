export const isClubAdmin = props =>
  props.login.isAuthenticated &&
  (props.login.role === 'Club Admin' || props.login.role === 'In-House Admin' || props.login.role === 'Super Admin');

export const isInHouseAdmin = props =>
  // console.log('isInHouseAdmin', props.login.role);
  props.login.isAuthenticated && (props.login.role === 'In-House Admin' || props.login.role === 'Super Admin');

export const isSuperAdmin = props => props.login.isAuthenticated && props.login.role === 'Super Admin';

export const parseJwt = token => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
