import { useState, useEffect } from 'react';

const useIsAppleMobileDevice = () => {
  const [isAppleMobileDevice, setIsAppleMobileDevice] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const appleDevice = /iPhone|iPad/i.test(userAgent);

    setIsAppleMobileDevice(appleDevice);
  }, []);

  return isAppleMobileDevice;
};

export default useIsAppleMobileDevice;
