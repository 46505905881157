import moment from 'moment';
import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { Button, Icon, Popup, Table } from 'semantic-ui-react';
import { ProductAddonChargeType } from '../../core/enums/product-addon-charge-type.enum';
import { IProductAddon } from '../../core/services/product.service';

export interface ProductAddonListItemProps extends LocalizeContextProps {
  addon: IProductAddon;
  currency?: string;
  loading?: boolean;
  onEditClick: (addon: IProductAddon) => void;
  onDeleteClick: (addon: IProductAddon) => void;
}

function ProductAddonListItem(props: ProductAddonListItemProps) {
  const { addon, currency, loading, onEditClick, onDeleteClick, translate } = props;

  return (
    <Table.Row>
      <Table.Cell>
        {translate(`product-addons.type-names.${addon.name}`)}
        {addon.description && <Popup trigger={<Icon name="info circle" />}>{addon.description}</Popup>}
      </Table.Cell>
      <Table.Cell singleLine textAlign="right">
        {addon.chargeAmount.toFixed(2).replace('.', ',')} {currency}
      </Table.Cell>
      <Table.Cell>{translate(`product-addons.charge-types.${ProductAddonChargeType[addon.chargeType]}`)}</Table.Cell>
      <Table.Cell>{moment(addon.validFrom).format('YYYY-MM-DD')}</Table.Cell>
      <Table.Cell>{moment(addon.validTo).format('YYYY-MM-DD')}</Table.Cell>
      <Table.Cell textAlign="right">
        <Button color="green" size="mini" icon onClick={() => onEditClick(addon)}>
          <Icon name="edit outline" />
        </Button>
        <Popup
          trigger={
            <Button color="red" size="mini" icon>
              <Icon name="trash alternate" />
            </Button>
          }
          content={
            <Button
              color="red"
              content={translate('default.confirm-remove')}
              onClick={() => onDeleteClick(addon)}
              loading={loading}
            />
          }
          on="click"
          position="top right"
        />
      </Table.Cell>
    </Table.Row>
  );
}

export default withLocalize(ProductAddonListItem);
