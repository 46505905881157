import React, { Component } from 'react';

import { Container } from 'semantic-ui-react';

import Helmet from '../components/Helmet';
import ClubAdminConfigureForm from '../containers/ClubAdminConfigureForm';

class ClubAdminConfigPage extends Component {
  render() {
    return (
      <div className="app app-configure">
        <Helmet title="Club admin configure" description="OnTee on club admin configure steroid!" />
        <Container>
          <ClubAdminConfigureForm />
        </Container>
      </div>
    );
  }
}

export default ClubAdminConfigPage;
