import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Translate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Container, Segment, Loader, Dimmer, Label, Icon, Grid, Message, Form, Button, Modal } from 'semantic-ui-react';
import moment from 'moment';
import qs from 'qs';

import TimePicker from '../components/DateTimePickers/TimePicker';
import { Source, BookingStatus, TransactionStatus, ActiveRequest, PriceType, OnteexStatus } from '../core/enums';
import Helmet from '../components/Helmet';
import { getGolfClubs } from '../containers/AccommodationSheet/ducks';
import * as api from '../utils/api';
import { createCallbackUrl, extractParentParams, stripePromise, CaravanIcon, isSuccessfulBooking } from '../utils';
import BookingModalViewRentals from '../components/BookingOverView/BookingModalViewRentals';
import DatePicker from '../components/DateTimePickers/DatePicker';
import { fetchTransactionByReferenceId, processOrder, rentalUnitBookingService } from '../core/services';
import RentalUnitBookingInformation from '../components/BookingOverView/RentalUnitBookingInformation';
import { BOOKING_NOT_FOUND, BOOKING_PROBLEM, ONGOING_WIDGET_BOOKING, PAYMENT_ERROR } from '../core/constants';
import { retryRequest } from '../utils/fetch';
import LanguageSwitcher from '../components/LanguageSwitcher';

// moment.locale('sv');

const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
const fullSize = width >= 992;

/**
 * Returns extracted data for easy population of the overview calendar
 *
 * @param {object} availability Availability object fetched from backend.
 */

const extractavailabilityData = (availability, translate) =>
  availability?.rentalUnits.map(t => ({
    id: t.rentalUnit.id,
    name: t.rentalUnit.name,
    available: t.available,
    rentalUnitTypeId: t.rentalUnit.type.id,
    rentalUnitTypeName: translate(`accommodation.${t.rentalUnit.type.name}`),
    timesAvailable: t.timesAvailable,
    price: getBookingPrice(availability, t.rentalUnit.priceCategory.id),
    disabledTimes: getDisabledTimes(t),
    bookings: t.bookings
      .sort((a, b) => moment(a.nominalStart) - moment(b.nominalStart))
      .map(b => ({ id: b.id, name: b.bookingUser?.name, nominalStart: b.nominalStart.substring(11) })),
    type: t.rentalUnit.type,
    priceCategory: t.rentalUnit.priceCategory
  }));

const minutesUpTo = t => {
  const minutes = [];
  let m = 0;
  while (m < t) {
    minutes.push(m);
    m += 5;
  }
  return minutes;
};

const minutesFrom = t => {
  const minutes = [];
  const i = 60;
  let m = t + 5;
  while (m < i) {
    minutes.push(m);
    m += 5;
  }
  return minutes;
};

const getDisabledTimes = a => {
  let tmp = a.timesAvailable.map(t => ({
    fromHour: moment(t.from).hour(),
    toHour: moment(t.to).hour(),
    fromMinute: moment(t.from).minute(),
    toMinute: moment(t.to).minute()
  }));

  let lastHour = 0;
  const minutess = {};
  const hours = [];
  tmp = tmp.map((t, idx) => {
    let i = lastHour;
    while (i < t.fromHour) {
      hours.push(i);
      i += 1;
    }
    let min = minutesUpTo(t.fromMinute);
    minutess[i] = min;
    i = t.toHour;
    min = minutesFrom(t.toMinute);
    minutess[i] = min;
    i += 1;
    const nextFromHour = tmp[idx + 1]?.fromHour || 24;
    while (i < nextFromHour) {
      hours.push(i);
      i += 1;
    }
    lastHour = i;
    return hours;
  });

  return { hours: tmp[0], minutes: minutess };
};
/**
 * Returns requested object (rentalUnitId) from passed availability or default (last fetched) availability
 *
 * @param {int} rentalUnitId .
 * @param {object} availability Availability object fetched from backend or if null default availability
 */

const findRentalUnit = (rentalUnitId, availability = null) =>
  (availability || originalAvailabilityData).rentalUnits.find(a => a.rentalUnit.id === rentalUnitId);

/**
 * Returns total price for a booking
 *
 * @param {object} availability Availability object fetched from backend.
 * @param {int} priceCategoryId Id of bookings rental unit price category.
 */
const getBookingPrice = (availability, priceCategoryId) =>
  availability.pricings[0].pricings.find(p => p.priceCategory.id === priceCategoryId)?.sellPriceInclVat;

/**
 * Normalize a booking to ensure we compare correct values
 *
 * @param {object} booking A booking object.
 */
const normalizeBooking = (booking, isConfirmed) => ({
  ...booking,
  days: moment(booking.checkOutDate).diff(booking.checkInDate, 'day'),
  paid: !!booking.paid,
  checkedIn: !!booking.checkedIn,
  isConfirmed,
  bookingUser: {
    userId: booking.bookingUser.userId || '',
    name: booking.bookingUser.name || '',
    email: booking.bookingUser.email || '',
    golfId: booking.bookingUser.golfId || '',
    phone: booking.bookingUser.phone || '',
    plateNumber: booking.bookingUser.plateNumber || ''
  }
});

/**
 * Filters all objects in data based on filters in filter object
 *
 * @param {object} filter.
 * @param {array} data.
 */
const filterRentalUnits = (filter, data) => {
  let filteredRentalUnits = data;
  if (Object.keys(filter).length) {
    if (filter.onlyAvailableObjects) {
      filteredRentalUnits = filteredRentalUnits.filter(a => a.available);
    }
    // if (filter.accommodationTypeCabin) {
    //   filteredRentalUnits = filteredRentalUnits.filter(a => a.accommodationTypeId === 2);
    // }
  }

  return filteredRentalUnits;
};

const findBooking = (rentalUnitId, bookingId) =>
  originalAvailabilityData.rentalUnits
    .find(r => r.rentalUnit.id === rentalUnitId)
    .bookings.find(b => b.id === bookingId);

let golfclub = null;
let originalAvailabilityData = null;
let formattedAvailabilityData = null;
let bookingHasChanged = false;
let calendarDate = null;

function RentalUnitsCalendar({ golfClubs, translate, isWidgetRequest }) {
  [golfclub] = golfClubs;

  const [availabilityData, setAvailabilityData] = useState(null);
  const [activeBooking, setActiveBooking] = useState(null);
  const [activeRequest, setActiveRequest] = useState(null);
  const [error, setError] = useState(null);
  const [rentalUnitTypesTitle, setRentalUnitTypesTitle] = useState('');
  const [filterData, setFilterData] = useState({ onlyAvailableObjects: isWidgetRequest });
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [confirmationBooking, setConfirmationBooking] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [pricings, setPricings] = useState(null);
  const [pendingTransaction, setPendingTransaction] = useState(null);
  const [bookingConflictError, setBookingConflictError] = useState('');

  // TODO: We might need to create a unique link that the customer can come back to an edit or cancel the booking
  // useEffect(() => {
  // (async () => {
  //   const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  //   const accommodationId = parseInt(params.accommodationId, 10);
  //   const bookingId = parseInt(params.bookingId, 10);
  //   const availability = await fetchRentalUnitAvailability(params.checkin, params.checkout);
  //   const foundBooking = availability.accommodations
  //     .find(acc => acc.accommodation.id === accommodationId)
  //     .bookings.find(b => b.id === bookingId);
  //   setActiveBooking(foundBooking);
  // })();
  // }, [golfclub]);

  useEffect(() => {
    const fetchCategories = async () => {
      const { data } = await api.fetchCategories();
      if (data) {
        const { rentalUnitTypes } = data;
        const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

        const rentalUnitTypesId = params['rental-unit-type']
          ? params['rental-unit-type'].split(',').map(t => parseInt(t, 10))
          : null;

        const bookingRentalUnitTypes = (
          rentalUnitTypesId?.length ? rentalUnitTypes.filter(x => rentalUnitTypesId.includes(x.id)) : rentalUnitTypes
        )
          .map(x => translate(`accommodation.${x.name}`))
          .join('/');

        setRentalUnitTypesTitle(bookingRentalUnitTypes);
      }
    };

    if (isWidgetRequest) {
      fetchCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [golfclub]);

  useEffect(() => {
    const getTransactionStatus = async () => {
      const searchParams = extractParentParams(window.location.search);
      if (searchParams?.onteexClientReferenceId) {
        setActiveRequest(ActiveRequest.FETCHING_ORDER);
        setOpenModal(true);

        const { data: transactionData } = await fetchTransactionByReferenceId(searchParams.onteexClientReferenceId);
        let { data: bookingData } = await rentalUnitBookingService.getById(searchParams.onteexBookingId);

        if (bookingData) {
          if (searchParams.onteexStatus === OnteexStatus.SUCCESS) {
            let isBookingSuccess = isSuccessfulBooking(bookingData);

            const result = await retryRequest(() => rentalUnitBookingService.getById(searchParams.onteexBookingId), {
              isSuccess: isBookingSuccess,
              condition: isSuccessfulBooking,
              retryAttempt: 3,
              interval: 2000
            });
            isBookingSuccess = result.isSuccess;

            bookingData = result?.data ?? bookingData;
            setPaymentStatus(isBookingSuccess ? TransactionStatus.COMPLETED : TransactionStatus.NOT_COMPLETED);
            setConfirmationBooking(bookingData);
            setBookingConflictError(isBookingSuccess ? null : translate(BOOKING_PROBLEM));
          } else {
            setOpenModal(false);
          }

          if (
            searchParams.onteexStatus === OnteexStatus.CANCEL &&
            bookingData.status === BookingStatus.PREBOOKED &&
            transactionData.status === TransactionStatus.NOT_COMPLETED
          ) {
            await fetchRentalUnitAvailability({
              date: moment(bookingData.nominalStart).format('YYYY-MM-DD'),
              currentRentalUnitBookingId: bookingData.id
            });
            setPaymentStatus(TransactionStatus.NOT_COMPLETED);
            setPendingTransaction(transactionData);
            setActiveBooking(normalizeBooking(bookingData, false));
          }
        } else {
          setBookingConflictError(translate(BOOKING_NOT_FOUND));
        }
        setActiveRequest(null);
      }
    };

    getTransactionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRentalUnitAvailability = async ({ date = null, currentRentalUnitBookingId = null } = {}) => {
    if (!calendarDate && !date) {
      return null;
    }

    ReactDOM.unstable_batchedUpdates(() => {
      setActiveRequest(ActiveRequest.FETCHING_OBJECTS);
      setAvailabilityData(null);
      setError(null);
    });

    formattedAvailabilityData = null;
    originalAvailabilityData = null;

    // TODO: update this when calendar will allow rental unit booking to span multiple days
    const startDate = date || calendarDate.format('YYYY-MM-DD');
    const endDate = startDate;
    const { error: fetchError, data: availability } = await rentalUnitBookingService.getAvailableUnits(
      golfclub.id,
      startDate,
      endDate,
      true,
      currentRentalUnitBookingId
    );

    if (fetchError) {
      setError(fetchError.error || 'Tillgänliga objekt kunde inte hämtas, vänligen försök på nytt.');
      setActiveRequest(null);
    } else {
      setPricings(availability.pricings);
      originalAvailabilityData = availability;
      formattedAvailabilityData = extractavailabilityData(availability, translate);
      filterAndShowRentalUnits();
    }

    setActiveRequest(null);

    return availability;
  };

  // TODO: Keep this since we need it for later to open up for the possibilut to change date on a booking
  // const getAvailabilityObjectsAndPrice = async (dateTime, booking) => {
  //   setActiveRequest('objects');
  //   const availability = await fetchRentalUnitAvailability(dateTime, false);
  //   const { price, pricing } = getBookingPrice(availability, booking.rentalUnit.priceCategory.id, booking.priceType);

  //   const avilableObjects = getAvailableObjects(availability, booking);
  //   setActiveRequest(null);
  //   return { price, objects: avilableObjects, pricing, availability };
  // };

  const updateBooking = async (status, booking, customer = null) => {
    if (
      isWidgetRequest &&
      status === BookingStatus.CONFIRMED &&
      pendingTransaction?.sessionId &&
      pendingTransaction?.amount === booking.priceInclVat
    ) {
      setActiveRequest(ActiveRequest.PROCESSING_ORDER);
      setIsRedirecting(true);
      const stripe = await stripePromise(golfclub.connectedAccountId);
      stripe.redirectToCheckout({ sessionId: pendingTransaction.sessionId });
      return;
    }

    setActiveRequest(status);
    setError('');

    const bStatus = status === BookingStatus.UPDATED ? BookingStatus.CONFIRMED : status;
    // Fetch original rental unit data.
    let rentalUnit = findRentalUnit(booking.rentalUnit.id);
    const confirmBooking = {
      ...booking,
      accommodationBookingDiscounts: [],
      bookingUser: customer,
      status: bStatus,
      source: isWidgetRequest ? Source.WIDGET : Source.ADMIN,
      rentalUnit: rentalUnit.rentalUnit
    };

    const { error: bookingError, data: availability } = await rentalUnitBookingService.create(confirmBooking);
    if (bookingError) {
      if (bookingError.errorCode === 'conflict') {
        setError('Tiden är upptagen av en annan bokning, hämta data på nytt för att se tillgängliga objekt');
      } else if (bookingError.error) {
        setError(bookingError.error);
      } else {
        setError(translate(`booking-status-error.${status?.toLowerCase()}`));
      }
    } else {
      bookingHasChanged = true;
      rentalUnit = findRentalUnit(booking.rentalUnit.id, availability);
      // Id doesn't exist before a prebook is made, then check against nominal start time since it should be unique
      // for that rental unit and day
      const confirmedBooking = rentalUnit.bookings.find(
        b => confirmBooking.id === b.id || moment(b.nominalStart).isSame(confirmBooking.nominalStart)
      );

      if (bStatus === BookingStatus.CANCELLED) {
        onCloseBookingView();
        return;
      }

      if (bStatus === BookingStatus.CONFIRMED) {
        await processBookingOrder(confirmBooking, customer);
      }

      if (confirmedBooking) {
        setActiveBooking(normalizeBooking(confirmedBooking, bStatus === BookingStatus.CONFIRMED));
      }
    }
    setActiveRequest(null);
  };

  const processBookingOrder = async (booking, customer) => {
    setActiveRequest(ActiveRequest.PROCESSING_ORDER);

    const orderData = await processOrder({
      rentalUnitBookingId: booking.id,
      customer,
      golfClubId: golfclub.id,
      isProcessCharge: false, // isWidgetRequest // we do not charge for rental units for now,
      isOrderConfirmed: true,
      returnUrl: createCallbackUrl(window.location.href)
    });

    if (orderData?.sessionId) {
      setIsRedirecting(true);
      const stripe = await stripePromise(golfclub.connectedAccountId);
      stripe.redirectToCheckout({ sessionId: orderData.sessionId });
    } else {
      setIsRedirecting(false);
    }

    if (!orderData) {
      setError(translate(PAYMENT_ERROR));
    }

    setActiveRequest(null);
  };

  const onClickRentalUnit = async (rentalUnit, selectedTime) => {
    setActiveRequest(true);
    setError('');

    const acc = findRentalUnit(rentalUnit.id);

    const dateTime = calendarDate.clone();
    dateTime.set('hour', selectedTime.hour());
    dateTime.set('minute', selectedTime.minute());

    const booking = {
      rentalUnit: acc.rentalUnit,
      golfClubId: golfclub.id,
      hasMedicalCertificate: false,
      hasAgreedToTerms: false,
      nominalStart: dateTime.format('YYYY-MM-DDTHH:mm:00'),
      priceType: PriceType.SELL
    };

    updateBooking(BookingStatus.PREBOOKED, booking);
  };

  const onClickBooking = async (rentalUnitId, bookingId) => {
    setError('');
    const booking = findBooking(rentalUnitId, bookingId);

    if (booking.status === BookingStatus.PREBOOKED && Number(booking.source) === Source.WIDGET) {
      setError(translate(ONGOING_WIDGET_BOOKING));
      return;
    }
    await fetchRentalUnitAvailability({ currentRentalUnitBookingId: bookingId });
    setActiveBooking(booking);
  };

  const onCloseBookingView = async () => {
    ReactDOM.unstable_batchedUpdates(() => {
      setActiveRequest(ActiveRequest.FETCHING_OBJECTS);
      setActiveBooking(null);
      setError('');
    });

    if (activeBooking.status === BookingStatus.PREBOOKED) {
      await updateBooking(BookingStatus.PREBOOK_CANCELLED, activeBooking);
    }

    if (bookingHasChanged) {
      await fetchRentalUnitAvailability();
      bookingHasChanged = false;
    }

    setActiveRequest(null);
  };

  const onCalendarClose = ({ selectedDate }) => {
    if (selectedDate) {
      calendarDate = selectedDate;
      fetchRentalUnitAvailability({ date: selectedDate.format('YYYY-MM-DD') });
    }
  };

  const filterAndShowRentalUnits = (_e, data) => {
    if (_e === undefined) {
      setAvailabilityData(filterRentalUnits(filterData, formattedAvailabilityData));
    } else {
      let newFilterData = {};
      const { name, checked } = data;

      newFilterData = { ...filterData, [name]: checked };
      setFilterData(newFilterData);
      setAvailabilityData(filterRentalUnits(newFilterData, formattedAvailabilityData));
    }
  };

  // console.log('', JSON.stringify(activeBooking));

  // console.log('availabilityData', availabilityData);
  // console.log('formattedAvailabilityData', formattedAvailabilityData);

  return (
    <>
      <div className="app overview-calendar">
        <Helmet title="" description="" />
        <Container>
          {paymentStatus && (
            <Message
              success={paymentStatus === TransactionStatus.COMPLETED}
              error={paymentStatus === TransactionStatus.NOT_COMPLETED}
            >
              {paymentStatus === TransactionStatus.COMPLETED ? (
                <Translate id="order.payment-completed" />
              ) : (
                <Translate id="order.payment-not-completed" />
              )}
            </Message>
          )}

          <Segment className="p-computer-3">
            <Dimmer active={!!activeRequest} inverted>
              <Loader />
            </Dimmer>
            <Grid>
              <Grid.Column mobile={16}>
                <h2>
                  {golfclub?.name} - <Translate id="accommodation-sheet.booking" /> {rentalUnitTypesTitle}
                </h2>
              </Grid.Column>

              <Grid.Column mobile={16} computer={11}>
                <DatePicker
                  fullSize={fullSize}
                  onClose={onCalendarClose}
                  calendarInfo={translate('rental-unit.select-date')}
                  placeholder={translate('rental-unit.choose-booking-date')}
                />
              </Grid.Column>

              <Grid.Column mobile={16} computer={5}>
                <LanguageSwitcher />
              </Grid.Column>

              {formattedAvailabilityData?.length > 0 && (
                <>
                  <Grid.Column mobile={16}>
                    <div className="filter">
                      <h4>FILTER</h4>
                      <Form.Group>
                        <Form.Checkbox
                          name="onlyAvailableObjects"
                          label={translate('rental-unit.available-only')}
                          checked={filterData.onlyAvailableObjects}
                          onClick={filterAndShowRentalUnits}
                        />
                      </Form.Group>
                    </div>
                  </Grid.Column>
                </>
              )}

              {rentalUnitTypesTitle?.includes('Golfbike') && availabilityData?.length > 0 && (
                <Grid.Column mobile={16} className="information mb-1 d-flex">
                  <Message color="green">
                    <Translate id="default.golfhaftet-discount" />
                  </Message>
                </Grid.Column>
              )}

              {error && (
                <Grid.Column mobile={16} className="information mb-1 d-flex">
                  <Message color="red">{error}</Message>
                </Grid.Column>
              )}

              {availabilityData?.length === 0 && (
                <Grid.Column mobile={16} className="information mb-1 d-flex">
                  <Message color="yellow">
                    <Translate id="default.no-units-available" />
                  </Message>
                </Grid.Column>
              )}
            </Grid>

            {availabilityData?.length > 0 && (
              <Grid className="mt-1">
                <Grid.Column>
                  <div className="accommodations-list">
                    <Grid>
                      {availabilityData.map(d => (
                        <RentalUnit
                          key={d.id}
                          data={d}
                          onClick={onClickRentalUnit}
                          onClickBooking={onClickBooking}
                          isWidgetRequest={isWidgetRequest}
                          golfClubCurrency={golfclub.currency}
                          translate={translate}
                        />
                      ))}
                    </Grid>
                  </div>
                </Grid.Column>
              </Grid>
            )}
          </Segment>
        </Container>
      </div>
      {activeBooking && (
        <BookingModalViewRentals
          booking={activeBooking}
          rentalUnits={availabilityData}
          updateBooking={updateBooking}
          // fetchObjects={getAvailabilityObjectsAndPrice}
          // findRentalUnit={findRentalUnit}
          golfclub={golfclub}
          onClose={onCloseBookingView}
          activeRequest={activeRequest}
          setActiveRequest={request => setActiveRequest(request)}
          error={error}
          setError={setError}
          isWidgetRequest={isWidgetRequest}
          fetchRentalUnitAvailability={fetchRentalUnitAvailability}
          pricings={pricings}
          isCalendarView
        />
      )}

      <Modal open={isRedirecting} size="tiny">
        <Modal.Content>
          <Dimmer active inverted>
            <Loader inverted>
              <Translate id="default.payment-page-redirect" />
            </Loader>
          </Dimmer>
        </Modal.Content>
      </Modal>

      <Modal open={openModal} size="tiny" onClose={() => setOpenModal(false)}>
        {!activeRequest ? (
          <>
            <Modal.Header>
              {!bookingConflictError && confirmationBooking ? (
                <>
                  <Icon name="check circle outline" color="green" size="large" />
                  <span className="green">{translate('default.booking-confirmed')}</span>
                </>
              ) : (
                <Grid columns={2}>
                  <Grid.Row>
                    <Grid.Column width={2}>
                      <Icon name="exclamation circle" color="red" size="large" />
                    </Grid.Column>
                    <Grid.Column width={13}>
                      <span className="red">{bookingConflictError}</span>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
            </Modal.Header>
            {!bookingConflictError && confirmationBooking && (
              <Modal.Content>
                <RentalUnitBookingInformation booking={confirmationBooking} golfClubCurrency={golfclub.currency} />
              </Modal.Content>
            )}
            <Modal.Actions>
              <Button onClick={() => setOpenModal(false)}>{translate('default.close')}</Button>
            </Modal.Actions>
          </>
        ) : (
          <Loader active>{translate('default.fetching-payment')} </Loader>
        )}
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return {
    golfClubs: getGolfClubs(state)
  };
}

export default withLocalize(connect(mapStateToProps, null)(RentalUnitsCalendar));

function RentalUnit({ data, golfClubCurrency, onClick, onClickBooking, isWidgetRequest, translate }) {
  const [time, setTime] = useState(null);

  const onTimeClose = ({ selectedTime }) => {
    setTime(selectedTime);
  };

  return (
    <Grid.Column mobile={16} tablet={8} computer={8}>
      <div className="unit">
        <Grid>
          <Grid.Column mobile={10}>
            <h5 className="mb-0">{data.name}</h5>
            {data.rentalUnitTypeId === 1 && <CaravanIcon />}
            {data.rentalUnitTypeId === 2 && <Icon name="home" />}
            <h6 className="mt-0 mb-1">{`${data.rentalUnitTypeName}`}</h6>
          </Grid.Column>
          <Grid.Column mobile={6} textAlign="right">
            <Label color="grey">
              {data.price} {golfClubCurrency}
            </Label>
          </Grid.Column>

          {data.timesAvailable.map(ta => (
            <Grid.Column key={ta.from} mobile={16} computer={8}>
              <Label color="green" key={ta.from}>
                {moment(ta.from).format('HH:mm')} - {moment(ta.to).format('HH:mm')}
              </Label>
            </Grid.Column>
          ))}
        </Grid>
        <Grid>
          <Grid.Column mobile={16} computer={8}>
            <TimePicker
              value={time}
              disabledTimes={data.disabledTimes}
              onClose={onTimeClose}
              disabled={!data.available}
              placeholder={translate('rental-unit.teetime-select')}
              buttonText={translate('rental-unit.teetime-confirm')}
            />
          </Grid.Column>
          <Grid.Column mobile={16} computer={8}>
            <Button
              fluid
              disabled={!time}
              onClick={() => data.available && onClick(data, time)}
              color={data.available ? 'green' : 'red'}
            >
              {data.available
                ? `${translate('rental-unit.book')} ${time?.format('HH:mm') || ''}`
                : translate('booking-availability.booked')}
            </Button>
          </Grid.Column>
        </Grid>
        {!isWidgetRequest && (
          <>
            <hr className="mt-1" />
            <h3>
              <Translate id="show-bookings.existing-bookings" />
            </h3>
            {data.bookings.map(b => (
              <Button basic key={b.nominalStart} onClick={() => onClickBooking(data.id, b.id)}>
                {b.nominalStart} {b.name}
              </Button>
            ))}
          </>
        )}
      </div>
    </Grid.Column>
  );
}
