export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// export const golfIdRegex = /\d{2}(0{1}[1-9]{1}|[1]{1}[0-2]{1})(0{1}[1-9]{1}|([1-2]\d{1}|3[0-1]))[-]\d{3}$/;
// export const golfIdRegex = /\d{6}[-]\d{3}$/;

export const golfIdRegex = /.{5,}$/;

export const isPositiveInteger = val => {
  const reg = /^\d+$/;
  return reg.test(val) && val > 0;
};

export const isValidSwedishPhoneNumber = phoneNumber => {
  console.log('validating :>> ', phoneNumber);
  const swedishPhonePattern = /^46(7\d{8}|1\d{6,8}|[2-9]\d{5,8})$/;
  return swedishPhonePattern.test(phoneNumber);
};

export const formatSwedishPhoneNumber = phoneNumber => {
  if (phoneNumber) {
    // Remove all white-space characters
    const cleaned = phoneNumber.replace(/\s+/g, '');

    // Ensure the number starts with '46'
    if (cleaned.startsWith('46')) {
      return cleaned;
    }

    // If it starts with '0', replace '0' with '46'
    if (cleaned.startsWith('0')) {
      return '46' + cleaned.substring(1);
    }

    // If it does not start with 46 nor 0, add 46 and return
    return '46' + cleaned;
  }

  return phoneNumber;
};
