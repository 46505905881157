import React from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { Segment, Grid, Table, Form, Button, Select, DropdownProps } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { getStatistics } from '../../../core/services/golfclub.service';
import { isValidDate, todaysDate, startOfYear, endOfYear } from '../../../utils';

interface StatisticsReportTabProps extends LocalizeContextProps {
  active: boolean;
  golfClubs: any[];
  translate: any;
}

interface StatsItem {
  year: string;
  nights: Date;
  bookings: number;
  price: number;
  commission: number;
}

class StatisticsReportTab extends React.Component<StatisticsReportTabProps> {
  state = {
    loading: false,
    stats: [],
    commissionType: 0,
    startDate: startOfYear(todaysDate()),
    startDateValid: true,
    endDate: endOfYear(todaysDate()),
    endDateValid: true
  };

  commissionTypes = [
    { text: this.props.translate('club-admin.report-tabs.clubs-all'), value: 0 },
    { text: this.props.translate('club-admin.report-tabs.clubs-commissioned'), value: 1 },
    { text: this.props.translate('club-admin.report-tabs.clubs-non-commissioned'), value: 2 }
  ];

  validateDatetimeChange = (_e: any, { name, value }: any) => {
    let { startDate, endDate, startDateValid, endDateValid } = this.state;

    if (name === 'startDate') {
      startDate = value;
      startDateValid = isValidDate(startDate);
    } else {
      endDate = value;
    }
    endDateValid = isValidDate(endDate) && endDate >= startDate;
    this.setState({ startDate, endDate, startDateValid, endDateValid });
  };

  changeClubType = (_e: any, data: DropdownProps) => {
    this.setState({ commissionType: data.value });
  };

  fetchStats = async () => {
    const { startDate, endDate, startDateValid, endDateValid, commissionType } = this.state;
    if (startDateValid && endDateValid) {
      this.setState({ loading: true });
      try {
        const payoutsData = await getStatistics(startDate, endDate, commissionType);

        if ('data' in payoutsData) {
          this.setState({
            loading: false,
            stats: payoutsData.data
          });
        }
      } catch (error) {
        this.setState({ loading: false });
      }
    }
  };

  render() {
    const { startDate, endDate, startDateValid, endDateValid, loading, stats, commissionType } = this.state;
    const { translate } = this.props;

    if (this.props.active) {
      return (
        <Segment>
          <div className="p-1">
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>
                    <Translate id="default.start-date" />
                  </label>
                  <DateInput
                    closable
                    dateFormat="YYYY-MM-DD"
                    name="startDate"
                    placeholder={translate('default.start-date')}
                    value={startDate}
                    error={!startDateValid}
                    onChange={this.validateDatetimeChange}
                    localization="sv"
                  />
                </Form.Field>
                <Form.Field>
                  <label>
                    <Translate id="default.end-date" />
                  </label>
                  <DateInput
                    closable
                    dateFormat="YYYY-MM-DD"
                    minDate={startDate}
                    name="endDate"
                    placeholder={translate('default.end-date')}
                    value={endDate}
                    error={!endDateValid}
                    onChange={this.validateDatetimeChange}
                    localization="sv"
                  />
                </Form.Field>
                <Form.Field width={3}>
                  <label>
                    <Translate id="club-admin.report-tabs.clubs" />
                  </label>
                  <Select
                    options={this.commissionTypes}
                    name="nonProvisionedOnly"
                    value={commissionType}
                    onChange={this.changeClubType}
                  />
                </Form.Field>
                <Form.Field>
                  <div style={{ position: 'absolute', bottom: '0px' }}>
                    <Button onClick={this.fetchStats} disabled={loading}>
                      {translate('default.fetch')}
                    </Button>
                  </div>
                </Form.Field>
              </Form.Group>
            </Form>
            <Grid>
              <Grid.Column floated="left" width="5">
                <Translate id="club-admin.statistics" />
              </Grid.Column>
            </Grid>

            <Segment loading={loading}>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      <Translate id="club-admin.report-tabs.year" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Translate id="club-admin.report-tabs.bookings" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Translate id="club-admin.report-tabs.nights" />
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                      <Translate id="club-admin.report-tabs.price" />
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                      <Translate id="club-admin.report-tabs.commission" />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {stats?.map((stat: StatsItem, i: number) => (
                    <Table.Row key={i}>
                      <Table.Cell>{stat.year}</Table.Cell>
                      <Table.Cell>{stat.bookings}</Table.Cell>
                      <Table.Cell>{stat.nights}</Table.Cell>
                      <Table.Cell singleLine textAlign="right">
                        {stat.price.toFixed(2).replace('.', ',')}
                      </Table.Cell>
                      <Table.Cell singleLine textAlign="right">
                        {stat.commission.toFixed(2).replace('.', ',')}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Segment>
          </div>
        </Segment>
      );
    }

    return <></>;
  }
}

export default withLocalize(StatisticsReportTab);
