import { combineReducers } from 'redux';

import LoginForm from '../containers/LoginForm/ducks';
import Teesheet from '../containers/Teesheet/ducks';
import SignupForm from '../containers/SignupForm/ducks';
import ResetPasswordForm from '../containers/ResetPasswordForm/ducks';
import ChangePasswordForm from '../containers/ChangePasswordForm/ducks';
import ClubAdminRegisterForm from '../containers/ClubAdminRegisterForm/ducks';
import ClubAdminConfigureForm from '../containers/ClubAdminConfigureForm/ducks';
import InHouseConfigureForm from '../containers/InHouseConfigureForm/ducks';
import AccommodationSheet from '../containers/AccommodationSheet/ducks';
import RentalUnitSheet from '../containers/RentalUnitSheet/ducks';

const appReducer = combineReducers({
  login: LoginForm,
  teesheet: Teesheet,
  signup: SignupForm,
  resetPassword: ResetPasswordForm,
  changePassword: ChangePasswordForm,
  clubAdminRegistration: ClubAdminRegisterForm,
  clubAdminConfig: ClubAdminConfigureForm,
  inHouseConfig: InHouseConfigureForm,
  accommodation: AccommodationSheet,
  rentalUnit: RentalUnitSheet
});

const rootReducer = (state, action) => {
  if (action.type === 'sign-out/SIGN_OUT_REQUEST') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
