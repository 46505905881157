import { getRequest, postRequest, postRequestMultipart } from '../../utils/fetch';

export interface IGolfClubDetailItem {
  id: number;
  name: string;
  useOnlinePayment: boolean;
  connectedAccountId: string;
  campingCount: number;
  campingCommission: number;
  currency: string;
  lodgeCount: number;
  lodgeCommission: number;
  golfCartCommission: number;
  golfBikeCommission: number;
  widgetDisabled: boolean;
  isUsingGoogleSites: boolean;
  receiveConfirmationEmails: boolean;
  sendCheckinReminderEmail: boolean;
  checkinReminderEmailDays: number;
  checkinReminderEmailText: string;
  company?: IGolfClubCompany;
}

export interface IGolfClubCompany {
  street: string;
  companyName: string;
  registrationNumber: string;
  vatNumber: string;
  city: string;
  postalCode: string;
  country: string;
  iBAN: string;
  bankAccount: string;
  reference: string;
}

export interface IGolfClubInformation extends IGolfClubDetailItem {
  campingVat: number;
  lodgeVat: number;
  golfCartVat: number;
  golfBikeVat: number;
  adminSystemLink: string;
  bookingLink: string;
  adminSystemLogin: string;
  onTeexLogin: string;
  hasTraining: boolean;
  hasStripeLinkSent: boolean;
  hasOwnLogoInConfirmationMail: boolean;
  isUpdated: boolean;
  note: string;
  adminSystemAccess: string;
  onTeexAccess: string;
}

export interface IGolfClubAccess {
  admin: string;
  onTeex: string;
}

/** Gets all golf clubs details. */
export const getGolfClubDetails = async (): Promise<IGolfClubDetailItem[]> => {
  let result = [] as IGolfClubDetailItem[];

  await getRequest('GolfClubs/GetGolfClubDetails').then((res: any) => {
    if ('data' in res) {
      result = res.data as IGolfClubDetailItem[];
    }
  });

  return result;
};

/**
 * Gets a detailed information for a single golf club.
 * @param golfClubId Golf club id.
 * @returns
 */
export const getGolfClubInformation = async (golfClubId: number): Promise<IGolfClubInformation> => {
  let result = {} as IGolfClubInformation;

  await getRequest('GolfClubs/GetGolfClubInformation', { golfClubId: golfClubId }).then((res: any) => {
    if ('data' in res) {
      result = res.data as IGolfClubInformation;
    }
  });

  return result;
};

/**
 * Gets the access data for the specified golf club if the pin is correct.
 * @param golfClubId Golf club id.
 * @param pin Pin to access data.
 * @returns
 */
export const getGolfClubAccess = async (golfClubId: number, pin: string): Promise<IGolfClubAccess> => {
  let result = {} as IGolfClubAccess;

  await getRequest('GolfClubs/GetGolfClubAccess', { golfClubId: golfClubId, pin: pin }).then((res: any) => {
    if ('data' in res) {
      result = res.data as IGolfClubAccess;
    }
  });

  return result;
};

/**
 * Updates a single golf club detail information.
 * @param golfClubInformation  Golf club data.
 * @param password Password needed when saving login passwords into a golf club data.
 * @returns Updated golf club information.
 */
export const updateGolfClubInformation = async (
  golfClubInformation: IGolfClubInformation
): Promise<IGolfClubInformation> => {
  let result = {} as IGolfClubInformation;

  await postRequest('GolfClubs/UpdateGolfClubInformation', golfClubInformation).then((res: any) => {
    if ('data' in res) {
      result = res.data as IGolfClubInformation;
    }
  });

  return result;
};

/**
 * Gets a statistics of bookings confirmed for a whole year grouped by month.
 * @param {*} startDate Start date.
 * @param {*} endDate End date.
 * @param {*} commissionType Type of commission to fetch specific clubs.
 * @returns List of month and number of bookings.
 */
export const getStatistics = (startDate: string, endDate: string, commissionType: number) =>
  getRequest('/Bookings/GetStatistics', { startDate, endDate, commissionType });

/**
 * Gets a list of filled in booking surveys including data for calculating NPS score.
 * @param {*} startDate Start date.
 * @param {*} endDate End date.
 * @returns List of surveys and scores together with calculated NPS score.
 */
export const getSurveys = (
  startDate: string,
  endDate: string,
  overallRating: string,
  overallRatingOperator: string,
  hasImproveText: boolean,
  hasMissingText: boolean
) =>
  postRequest('/AccommodationBookings/GetSurveys', {
    dateFrom: startDate,
    dateTo: endDate,
    overallRating,
    overallRatingOperator,
    hasImproveText,
    hasMissingText
  });

/**
 * Gets a guest list from bookings confirmed with a checking date
 * within specified period.
 * @param {*} startDate Start date.
 * @param {*} endDate End date.
 * @returns List of guests.
 */
export const getGuestList = (startDate: string, endDate: string) =>
  getRequest('/AccommodationBookings/GetGuestList', { startDate, endDate });

/**
 * Updates a single golf club detail information.
 * @param golfClubInformation  Golf club data.
 * @param password Password needed when saving login passwords into a golf club data.
 * @returns Updated golf club information.
 */
export const uploadLogotype = async (formData: FormData): Promise<string> => {
  let result = '';

  await postRequestMultipart('GolfClubs/UploadLogotype', formData).then((res: any) => {
    if ('response' in res) {
      result = res.response as string;
    } else if ('error' in res) {
      throw new Error(`Failed to upload logo (${res.error.code} - ${res.error.errorData?.title})`);
    }
  });

  return result;
};
