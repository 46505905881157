import { loadStripe } from '@stripe/stripe-js';
import qs from 'qs';

export const stripePromise = connectedAccountId =>
  loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
    // disable connected account - reverting back to destination charges
    // stripeAccount: connectedAccountId
  });

export const extractParentParams = queryString => {
  const params = qs.parse(queryString, { ignoreQueryPrefix: true });
  const parentUrl = params['parent-url'];
  const parentUrlQueryString = parentUrl?.indexOf('?') >= 0 ? parentUrl.substring(parentUrl.indexOf('?')) : null;
  return qs.parse(parentUrlQueryString, { ignoreQueryPrefix: true });
};

export const createCallbackUrl = url => {
  const params = qs.parse(url, { ignoreQueryPrefix: true });
  const fullParentUrl = params['parent-url'];
  const parentUrl =
    fullParentUrl?.indexOf('?') >= 0 ? fullParentUrl.substring(0, fullParentUrl.indexOf('?')) : fullParentUrl;
  const parentUrlQueryString =
    fullParentUrl?.indexOf('?') >= 0 ? fullParentUrl.substring(fullParentUrl.indexOf('?')) : null;
  const parentParams = qs.parse(parentUrlQueryString, { ignoreQueryPrefix: true });
  delete parentParams.onteexBackFromStripe;
  delete parentParams.onteexStatus;
  delete parentParams.onteexBookingId;
  delete parentParams.onteexOrderId;
  delete parentParams.onteexClientReferenceId;
  return `${parentUrl}?${qs.stringify(parentParams)}`;
};
