import moment from 'moment';
import { getRequest, postRequest } from '../../utils/fetch';

const getAvailableAccommodations = (golfClubId, dateFrom, dateTo, isWidgetRequest, rentalUnitTypeIds = null) =>
  getRequest('/AccommodationBookings/AvailableAccommodationsOverview', {
    golfClubId,
    checkinDate: dateFrom,
    checkoutDate: dateTo,
    isWidgetRequest,
    rentalUnitTypeIds
  });

const getAvailableAccommodationsOverview = (golfClubId, dateFrom, dateTo, isWidgetRequest, rentalUnitTypeIds = null) =>
  getRequest('/AccommodationBookings/AvailableAccommodationsOverview', {
    golfClubId,
    checkinDate: dateFrom,
    checkoutDate: dateTo,
    isWidgetRequest,
    rentalUnitTypeIds
  });

const getAll = (golfClubId, dateFrom, dateTo) =>
  getRequest('/AccommodationBookings', {
    golfClubId,
    withinDateStart: dateFrom,
    withinDateEnd: dateTo
  });

/**
 * Get a survey data for displaying in the survey form.
 * @param {*} id
 * @param {*} token
 * @returns
 */
const getSurvey = (id, token) =>
  getRequest('/AccommodationBookings/GetSurvey', {
    id,
    token
  });

/**
 * Creates a customer survey from provided data.
 * @param {*} data
 * @returns
 */
const createSurvey = data => postRequest('/AccommodationBookings/CreateSurvey', data);

const optOutSurvey = (id, token) => {
  const data = {
    id,
    token
  };

  return postRequest('/AccommodationBookings/SurveyOptOut', data);
};

const create = data => postRequest(`/AccommodationBookings?language=${moment.locale()}`, data);
const getById = id => getRequest(`/AccommodationBookings/GetById/${id}`);

const moveBooking = data => postRequest(`/AccommodationBookings/MoveBooking?language=${moment.locale()}`, data);

export const accommodationBookingService = {
  getAvailableAccommodations,
  getAvailableAccommodationsOverview,
  getAll,
  create,
  getById,
  moveBooking,
  getSurvey,
  createSurvey,
  optOutSurvey
};
