import React from 'react';
import { Translate } from 'react-localize-redux';

import { Modal, Table } from 'semantic-ui-react';
import Sidebar from './Sidebar';

class BookingSidebar extends React.Component {
  componentDidUpdate(prevProps, prevState) {
    // when the menu becomes visible, setup some handlers so we can close the menu easily
    if (this.props.visible === true) {
      document.addEventListener('keydown', this.handleKeyPress);
      document.querySelector('.ot-sidebar-mask').addEventListener('click', this.handleClick);
    } else {
      document.removeEventListener('keydown', this.handleKeyPress);
      document.querySelector('.ot-sidebar-mask').removeEventListener('click', this.handleClick);
    }
  }

  handleClick = () => {
    if (this.props.visible) {
      this.props.closeClick();
    }
  };
  handleKeyPress = e => {
    if (e.keyCode === 27 && this.props.visible) {
      this.props.closeClick();
    }
  };

  render() {
    const { children, visible, style, closeClick, date, teetime } = this.props;
    return (
      <Sidebar visible={visible} closeClick={closeClick} style={style} onHide={this.onHide}>
        <h1>
          <Translate id="default.teetime" />
        </h1>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <Translate id="default.date" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Translate id="default.time" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Translate id="default.available-spots" />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>{date}</Table.Cell>
              <Table.Cell>{teetime ? teetime.startTime : ''}</Table.Cell>
              <Table.Cell>{teetime ? teetime.availableSlots : ''}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        {children}
      </Sidebar>
    );
  }
}

class AccommodationBookingSidebar extends React.Component {
  // componentDidMount() {
  //   // when the menu becomes visible, setup some handlers so we can close the menu easily
  //   if (this.props.visible === true) {
  //     document.addEventListener('keydown', this.handleKeyPress);
  //     document.querySelector('.ot-sidebar-mask').addEventListener('click', this.handleClick);
  //   } else {
  //     document.removeEventListener('keydown', this.handleKeyPress);
  //     document.querySelector('.ot-sidebar-mask').removeEventListener('click', this.handleClick);
  //   }
  // }

  handleClick = () => {
    if (this.props.visible) {
      this.props.closeClick(this.props.editProps.acc);
    }
  };

  handleKeyPress = e => {
    if (e.keyCode === 27 && this.props.visible) {
      this.props.closeClick(this.props.editProps.acc);
    }
  };

  render() {
    const { children, visible, style, closeClick, priceInclVat, golfClubCurrency } = this.props;
    const { acc, accommodation, checkinDate, checkoutDate } = this.props.editProps;

    return (
      <Modal
        open={visible}
        closeIcon
        onClose={() => closeClick(acc)}
        // onClose={this.onHide}
        style={style}
        className="modal-xs-top"
      >
        <div className="p-1 p-sm-3">
          <Modal.Content>
            <Modal.Description>
              <h1>
                <Translate id="default.accommodation" />
              </h1>
              <Table>
                <Table.Header className="hide-xs">
                  <Table.Row>
                    <Table.HeaderCell>
                      <Translate id="default.name" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Translate id="club-admin.category" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Translate id="default.date" />
                    </Table.HeaderCell>
                    {!this.props.priceWithDiscount && priceInclVat > 0 && (
                      <Table.HeaderCell>
                        <Translate id="default.price" />
                      </Table.HeaderCell>
                    )}
                    {this.props.priceWithDiscount && (
                      <>
                        <Table.HeaderCell>
                          <Translate id="default.price" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="default.original-price" />
                        </Table.HeaderCell>
                      </>
                    )}
                    <Table.HeaderCell>
                      {accommodation.numberOfBeds ? <Translate id="default.beds" /> : ''}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>{accommodation.name}</Table.Cell>
                    <Table.Cell>
                      {accommodation.priceCategory && (
                        <Translate id={`accommodation.${accommodation.priceCategory.name}`} />
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {checkinDate} - {checkoutDate}
                    </Table.Cell>
                    {!this.props.priceWithDiscount && priceInclVat > 0 && (
                      <Table.Cell>
                        {priceInclVat} {golfClubCurrency}{' '}
                      </Table.Cell>
                    )}
                    {this.props.priceWithDiscount && (
                      <>
                        <Table.Cell>
                          {this.props.priceWithDiscount.discountedPrice} {golfClubCurrency}{' '}
                        </Table.Cell>
                        <Table.Cell>
                          {this.props.priceWithDiscount.originalPrice} {golfClubCurrency}{' '}
                        </Table.Cell>
                      </>
                    )}
                    <Table.Cell>{accommodation.numberOfBeds ?? ''}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              {children}
            </Modal.Description>
          </Modal.Content>
        </div>
      </Modal>
    );
  }
}

class RentalUnitBookingSidebar extends React.Component {
  // componentDidUpdate(prevProps, prevState) {
  //   // when the menu becomes visible, setup some handlers so we can close the menu easily
  //   if (this.props.visible === true) {
  //     document.addEventListener('keydown', this.handleKeyPress);
  //     document.querySelector('.ot-sidebar-mask').addEventListener('click', this.handleClick);
  //   } else {
  //     document.removeEventListener('keydown', this.handleKeyPress);
  //     document.querySelector('.ot-sidebar-mask').removeEventListener('click', this.handleClick);
  //   }
  // }

  handleClick = () => {
    if (this.props.visible) {
      this.props.closeClick(this.props.editProps.acc);
    }
  };

  handleKeyPress = e => {
    if (e.keyCode === 27 && this.props.visible) {
      this.props.closeClick(this.props.editProps.acc);
    }
  };

  render() {
    const { children, visible, style, closeClick, golfClubCurrency } = this.props;
    const { acc, rentalUnit, checkinDate, priceInclVat } = this.props.editProps;
    return (
      <Modal open={visible} closeIcon onClose={() => closeClick(acc)} style={style} className="modal-xs-top">
        <Modal.Content>
          <h1>
            <Translate id="default.rental-units" />
          </h1>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <Translate id="default.name" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Translate id="default.date" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Translate id="default.price" />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>{rentalUnit.name}</Table.Cell>
                <Table.Cell>{checkinDate}</Table.Cell>
                <Table.Cell>
                  {priceInclVat} {golfClubCurrency}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          {children}
        </Modal.Content>
      </Modal>
    );
  }
}

export { BookingSidebar, AccommodationBookingSidebar, RentalUnitBookingSidebar };
