import React, { useEffect, useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { Grid, Form, Button, Label, Icon, Input, Message } from 'semantic-ui-react';
import { fetchDiscountByCode, fetchDiscounts } from '../../utils/api';
import { todaysDate } from '../../utils/date-time.util';
import { DiscountTypes } from '../../core/enums';

function DiscountSelection({
  isAdminAccess = false,
  appliedDiscounts,
  checkinDate,
  createdDate,
  onSelect,
  onClear,
  rentalUnitTypeId,
  golfClubId,
  golfClubCurrency,
  translate
}) {
  const [discountCode, setDiscountCode] = useState('');
  const [discounts, setDiscounts] = useState([]);
  const [discountId, setDiscountId] = useState(null);
  const [discountError, setDiscountError] = useState('');

  useEffect(() => {
    // console.log('DiscountSelection useEffect', rentalUnitTypeId);

    /**
     * Checks if the specified discount is valid for specified check in date and rental unit type id.
     * @param {Date} checkin The date of check in.
     * @param {number} rentalUnitType The rental unit type id that the discount should be valid for.
     * @param {*} discount The discount to check.
     * @returns True if the discount is valid for specified check in date.
     */
    const isValidActiveDiscount = (checkin, createdAt, rentalUnitType, discount) => {
      const created = createdAt ? new Date(createdAt) : new Date();
      const check = new Date(checkin);

      return (
        discount.active &&
        new Date(discount.applyStart) <= check &&
        new Date(discount.applyEnd) >= check && // check against check in date
        new Date(discount.publishStart) <= created &&
        new Date(discount.publishEnd) >= created && // check against the date of creation
        (discount.rentalUnitTypeIds.includes(rentalUnitType) || discount.rentalUnitTypeIds.length === 0)
      );
    };

    const getDiscounts = async () => {
      const result = await fetchDiscounts();
      if (result.data) {
        const activeAndValidDiscounts = result.data.filter(x =>
          isValidActiveDiscount(checkinDate, createdDate, rentalUnitTypeId, x)
        );

        setDiscounts(activeAndValidDiscounts);
      }
    };
    if (isAdminAccess) {
      getDiscounts();
    }
  }, [isAdminAccess, rentalUnitTypeId, checkinDate, createdDate]);

  const setDiscount = async e => {
    e.preventDefault(); // Important since form in widget otherwise submits a booking

    if (isAdminAccess && discountId) {
      const discount = discounts.find(x => x.id === discountId);
      onSelect(discount);
    } else if (!isAdminAccess && discountCode.trim()) {
      setDiscountError('');
      const result = await fetchDiscountByCode(
        golfClubId,
        discountCode,
        rentalUnitTypeId,
        checkinDate,
        createdDate || todaysDate()
      );

      if (result.error) {
        setDiscountError(result.error.error);
      } else if (result.data) {
        const discount = result.data;
        onSelect(discount);
      } else if (result.code === 204) {
        setDiscountError(translate('discount.invalid-code'));
      }
    }
  };

  return (
    <>
      <Grid>
        <Grid.Column mobile={8} computer={4} className="pb-0">
          {isAdminAccess ? (
            <Form.Select
              name="discount"
              // label={translate('discount.select-a-discount')}
              placeholder={translate('discount.select-a-discount')}
              onChange={(_e, data) => setDiscountId(data.value)}
              options={discounts?.map(x => ({
                text: `${x.name && `${x.name} - `} ${x.code} - ${x.amount}${
                  x.discountType === DiscountTypes.PERCENT ? '%' : ` ${golfClubCurrency}`
                }`,
                value: x.id
              }))}
            />
          ) : (
            <>
              <Form.Field
                control={Input}
                type="text"
                name="code"
                // label={translate('discount.enter-discount-code')}
                placeholder={translate('discount.code')}
                onChange={(_e, data) => setDiscountCode(data.value)}
                error={!!discountError}
                value={discountCode}
              />
              {!!discountError && (
                <Message visible error>
                  {discountError}
                </Message>
              )}
            </>
          )}
        </Grid.Column>
        <Grid.Column mobile={8} computer={4}>
          <Button positive onClick={setDiscount}>
            <Translate id="discount.add" />
          </Button>
        </Grid.Column>
      </Grid>
      <Grid>
        {appliedDiscounts?.length > 0 && (
          <Grid.Column className="pt-0">
            <Grid.Row>
              {appliedDiscounts?.map(d => {
                const discount = d.discount || d;
                return (
                  <Grid.Column key={discount.id}>
                    <Label color="green">
                      {`${discount.code} - ${discount.amount}${
                        discount.discountType === DiscountTypes.PERCENT ? '%' : ` ${golfClubCurrency}`
                      }`}
                      {/* TODO: remove only the selected discount */}
                      <Icon color="red" name="delete" onClick={onClear} />
                    </Label>
                  </Grid.Column>
                );
              })}
            </Grid.Row>
            {/* {!appliedDiscounts?.length && <Label>{translate('discount.no-discount-added')}</Label>} */}
          </Grid.Column>
        )}
      </Grid>
    </>
  );
}

export default withLocalize(DiscountSelection);
