import React from 'react';
import { Translate } from 'react-localize-redux';
import { Button, Form } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';

export interface ReportDateValidityChange {
  name: string;
  value: string;
}

interface ReportDateComponentProps {
  startDate: string;
  startDateValid: boolean;
  endDate: string;
  endDateValid: boolean;
  validateDatetimeChange(e: any, values: ReportDateValidityChange): any;
  fetchBookings(): any;
  loading: boolean;
}

export class ReportDateComponent extends React.Component<ReportDateComponentProps> {
  render() {
    return (
      <Translate>
        {({ translate }) => (
          <Form>
            <Form.Group>
              <Form.Field>
                <label>
                  <Translate id="default.start-date" />
                </label>
                <DateInput
                  closable
                  dateFormat="YYYY-MM-DD"
                  name="startDate"
                  placeholder={translate('default.start-date')}
                  value={this.props.startDate}
                  error={!this.props.startDateValid}
                  onChange={this.props.validateDatetimeChange}
                  localization="sv"
                />
              </Form.Field>
              <Form.Field>
                <label>
                  <Translate id="default.end-date" />
                </label>
                <DateInput
                  closable
                  dateFormat="YYYY-MM-DD"
                  minDate={this.props.startDate}
                  name="endDate"
                  placeholder={translate('default.end-date')}
                  value={this.props.endDate}
                  error={!this.props.endDateValid}
                  onChange={this.props.validateDatetimeChange}
                  localization="sv"
                />
              </Form.Field>
              <Form.Field>
                <div style={{ position: 'absolute', bottom: '0px' }}>
                  <Button onClick={this.props.fetchBookings} disabled={this.props.loading}>
                    {translate('default.fetch')}
                  </Button>
                </div>
              </Form.Field>
            </Form.Group>
          </Form>
        )}
      </Translate>
    );
  }
}
