import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Translate, withLocalize } from 'react-localize-redux';
import { Button, Form, Grid, Icon, Input, Message, Segment, Select, Table } from 'semantic-ui-react';
import RentalUnitView from './RentalUnitView';
import RentalUnitEdit from './RentalUnitEdit';
import { GolfCartIcon, GolfBikeIcon } from '../../utils/icons';
import * as api from '../../utils/api';
import { getGolfClubs } from '../../containers/AccommodationSheet/ducks';

const nrOfBeds = Array.from(Array(10), (_, i) => i + 1).map(i => ({ value: i, text: i }));
let allRentalUnits = [];
let categories = {};
let accommodationTypes = [];
let priceCategories = [];
let golfclub = null;

function RentalUnits({ golfClubs, translate }) {
  const [rentalUnits, setRentalUnits] = useState(null);
  const [rentalUnit, setRentalUnit] = useState(null);
  const [apiLoading, setApiLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [filterData, setFilterData] = useState({ name: '', accommodationType: '' });

  useEffect(() => {
    document.body.classList.add('config-rental-units');
    window.scrollTo(0, 0);

    [golfclub] = golfClubs;

    initializeData();

    return function cleanup() {
      document.body.classList.remove('config-rental-units');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initializeData = async () => {
    const fetchCategories = async () => {
      const { code, data } = await api.fetchCategories();
      if (code === 200) {
        categories = data;
        accommodationTypes = data.rentalUnitTypes.map(a => ({
          value: a.id,
          text: translate(`accommodation.${a.name}`),
          icon: a.id === 3 ? <GolfCartIcon /> : <GolfBikeIcon />
        }));
        priceCategories = data.rentalUnitPriceCategories.map(p => ({
          value: p.id,
          text: translate(`accommodation.${p.name}`),
          accommodationtypeid: p.accommodationTypeId // lowercase letters otherwise dom throws exception in form select element
        }));
      }
    };

    const fetchRentalUnits = async () => {
      const { data } = await api.fetchRentalUnits(golfclub.id);
      return data;
    };

    setApiLoading(true);
    Promise.all([fetchCategories(), fetchRentalUnits()]).then(response => {
      if (response[1]) {
        [, allRentalUnits] = response;
        setRentalUnits(response[1]);
      }
      setApiLoading(false);
    });
  };

  const filteredRentalUnits = () => {
    let filteredRU = allRentalUnits;

    if (filterData.name) {
      filteredRU = filteredRU.filter(a => a.name.includes(filterData.name));
    }

    if (filterData.accommodationType) {
      filteredRU = filteredRU.filter(a => a.type.id === filterData.accommodationType);
    }

    return filteredRU;
  };

  const filterByName = (_e, { name, value }) => {
    setRentalUnits(allRentalUnits.filter(a => a.name.includes(value)));
    setFilterData({ accommodationType: '', [name]: value });
  };

  const filterByType = (_e, { name, value }) => {
    if (value) {
      setRentalUnits(allRentalUnits.filter(a => a.type.id === value));
    } else {
      setRentalUnits(allRentalUnits);
    }
    setFilterData({ name: '', [name]: value });
  };

  const onClickAddRentalUnit = () => {
    const acc = {
      new: `R${Math.random()}`,
      golfClubId: golfclub.id,
      name: '',
      // info: '',
      type: {},
      priceCategory: {},
      attributes: [{ key: 'AllowedBookingsPerDay', value: '' }]
    };
    setRentalUnit(acc);
    setRentalUnits([acc, ...rentalUnits]);
    setErrorMessage(null);
  };

  const onClickEditRentalUnit = acc => {
    setRentalUnit(acc);
  };

  const onClickDeleteRentalUnit = async rntUnit => {
    setApiLoading(true);
    const { code, data, error } = await api.deleteRentalUnit(golfclub.id, rntUnit);
    if (code === 200) {
      allRentalUnits = data;
      const rus = filteredRentalUnits();
      ReactDOM.unstable_batchedUpdates(() => {
        setRentalUnit(null);
        setRentalUnits(rus);
      });
    } else {
      const message =
        error.errorCode === 'activeBookings'
          ? 'club-admin.delete-error-active-booking'
          : 'default.something-went-wrong';
      setErrorMessage(message);
    }
    setApiLoading(false);
  };

  const onClickCancelEditRentalUnit = () => {
    setRentalUnits(allRentalUnits);
    setRentalUnit(null);
  };

  const onClickSaveRentalUnit = async rntUnit => {
    setApiLoading(true);
    const nRntUnit = {
      id: rntUnit.id,
      golfClubId: rntUnit.golfClubId,
      name: rntUnit.name,
      // info: rntUnit.info,
      type: categories.rentalUnitTypes.find(a => a.id === rntUnit.rentalUnitTypeId),
      priceCategory: categories.rentalUnitPriceCategories.find(p => p.id === rntUnit.priceCategoryId),
      attributes: rntUnit.attributes.map(a =>
        a.key === 'AllowedBookingsPerDay' ? { ...a, value: rntUnit.allowedBookingsPerDay } : a
      )
    };
    const { code, data } = await api.saveRentalUnit(golfclub.id, nRntUnit);
    if (code === 200) {
      allRentalUnits = data;
      const rus = filteredRentalUnits();
      ReactDOM.unstable_batchedUpdates(() => {
        setRentalUnit(null);
        setRentalUnits(rus);
      });
    } else {
      setErrorMessage('something-went-wrong');
    }
    setApiLoading(false);
  };

  // console.log('rentalUnit', rentalUnit);
  // console.log('allRentalUnits', allRentalUnits);

  return (
    <Segment loading={apiLoading}>
      <Grid colums={2}>
        <Grid.Row>
          <Grid.Column width={8}>
            <Form>
              <Form.Group inline>
                <Input
                  name="name"
                  icon="search"
                  placeholder="Filtrera på namn"
                  value={filterData.name}
                  onChange={filterByName}
                  style={{ marginRight: '10px' }}
                />
                <Select
                  name="accommodationType"
                  placeholder="Filtrera på typ"
                  options={accommodationTypes}
                  value={filterData.accommodationType}
                  onChange={filterByType}
                  clearable
                />
              </Form.Group>
            </Form>
          </Grid.Column>
          <Grid.Column width={8} textAlign="right">
            <Button color="green" onClick={onClickAddRentalUnit}>
              <Icon name="add" />
              Lägg till ny
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {errorMessage && (
        <Message negative>
          <Translate id={errorMessage} />
        </Message>
      )}
      {/* {rentalUnit?.new && (
        <Message positive>
          <Translate id="club-admin.accommodations-add" />
        </Message>
      )} */}
      <Table striped style={{ maxWidth: '100%' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <Translate id="accommodation.name" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Translate id="accommodation.type" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Translate id="accommodation.category" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Translate id="rental-units.max-day-bookings" />
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rentalUnits?.map(a =>
            a.id === rentalUnit?.id ? (
              <RentalUnitEdit
                key={a.id || a.new}
                rentalUnit={rentalUnit}
                onClickSave={onClickSaveRentalUnit}
                onClickCancel={onClickCancelEditRentalUnit}
                loading={apiLoading}
                defaultNrOfBeds={nrOfBeds}
                rentalUnitTypes={accommodationTypes}
                priceCategories={priceCategories}
                errorMessage={errorMessage}
              />
            ) : (
              <RentalUnitView
                rentalUnit={a}
                key={a.id || a.new}
                onClickEdit={onClickEditRentalUnit}
                onClickDelete={onClickDeleteRentalUnit}
                btnsDisabled={!!rentalUnit?.id}
                loading={apiLoading}
              />
            )
          )}
        </Table.Body>
      </Table>
      {/* <Loader inline active={apiLoading} /> */}
      {!apiLoading && !rentalUnits && (
        <Message negative>
          <Message.Header>Data kunde inte hämtas</Message.Header>
          <p>Försök hämta på nytt genom att klicka på knappen</p>
          <Button icon color="red" onClick={initializeData}>
            <Icon name="redo alternate" />
          </Button>
        </Message>
      )}
    </Segment>
  );
}

function mapStateToProps(state) {
  return {
    golfClubs: getGolfClubs(state)
  };
}

export default withLocalize(connect(mapStateToProps, null)(RentalUnits));
