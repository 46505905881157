import React, { useState, useEffect } from 'react';
import { Button } from 'semantic-ui-react';
import moment from 'moment';
import RcTimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

const timePickerMinutesStep = 5;

function TimePicker({
  id = null,
  value = null,
  defaultValue = null,
  onClose,
  disabledTimes,
  disabled,
  label,
  error,
  placeholder,
  buttonText
}) {
  // console.log('DisabledTimes', value, disabledTimes);
  const [selectedTime, setSelectedTime] = useState(value);

  useEffect(() => {
    setSelectedTime(value);
  }, [value]);

  const defaultOpenValue = value || defaultValue || moment().hour(0).minute(0);

  const getDisabledHours = () => disabledTimes?.hours;
  const getDisabledMinutes = hour => disabledTimes?.minutes[hour] || null;

  const onTimeChanged = time => {
    const disabledMinutesForHour = disabledTimes?.minutes[time.hour()];
    if (disabledMinutesForHour?.includes(time.minute())) {
      const firstDisabledMinute = disabledMinutesForHour[0];
      const lastDisabledMinute = disabledMinutesForHour[disabledMinutesForHour.length - 1];
      const newMinute = firstDisabledMinute > 0 ? firstDisabledMinute : lastDisabledMinute;
      time.set('minute', newMinute + timePickerMinutesStep);
    }

    setSelectedTime(time);
  };

  const onTimeClose = panel => {
    if (panel) {
      panel.close();
    }
    onClose({ id, selectedTime });
  };

  return (
    <>
      {label && <label className="time-picker-label">{label}</label>}
      <RcTimePicker
        placeholder={placeholder}
        showSecond={false}
        disabled={disabled}
        minuteStep={timePickerMinutesStep}
        value={selectedTime}
        defaultValue={defaultValue}
        defaultOpenValue={defaultOpenValue}
        onChange={onTimeChanged}
        onClose={() => onTimeClose(null)}
        disabledHours={getDisabledHours}
        disabledMinutes={h => getDisabledMinutes(h)}
        hideDisabledOptions
        addon={panel => (
          <Button compact fluid color="green" onClick={() => onTimeClose(panel)} style={{ borderRadius: 0 }}>
            {buttonText}
          </Button>
        )}
        className={`time-picker ${error ? 'time-picker-error' : ''}`}
        popupClassName="time-picker-popup"
      />
    </>
  );
}

// export default TimePicker;
export default React.memo(TimePicker);
