import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { connect } from 'react-redux';

import { SIGN_OUT_REQUEST } from '../containers/LoginForm/ducks';

class LogoutPage extends Component {
  UNSAFE_componentWillMount = () => {
    delete localStorage.authToken;
    this.props.dispatch({ type: SIGN_OUT_REQUEST });
  };

  render() {
    return <Redirect to="/sign-in" />;
  }
}

export default connect()(LogoutPage);
