import React from 'react';
import { Button, Form } from 'semantic-ui-react';

function CountSelector({
  item,
  minusClick,
  minusDisabled,
  name,
  plusClick,
  plusDisabled,
  value,
  valueChanged,
  valueMin,
  valueMax
}) {
  const onChange = e => {
    if (valueChanged) {
      valueChanged(e, item);
    }
  };

  const onIncrease = () => {
    if (plusClick) {
      plusClick(item);
    }
  };

  const onDecrease = () => {
    if (minusClick) {
      minusClick(item);
    }
  };

  return (
    <div className="d-flex" style={{ flexDirection: 'row' }}>
      <Button color="green" icon="minus circle" size="mini" disabled={minusDisabled} onClick={onDecrease} />

      <Form.Input
        type="number"
        name={name}
        size="mini"
        min={valueMin || '0'}
        max={valueMax || ''}
        value={value}
        onChange={onChange}
        style={{ textAlign: 'center', fontWeight: 'bold', width: 75 }}
        className="count-selector-input"
      />

      <Button
        color="green"
        icon="plus circle"
        size="mini"
        style={{ marginLeft: 3 }}
        onClick={onIncrease}
        disabled={plusDisabled}
      />
    </div>
  );
}

export default CountSelector;
