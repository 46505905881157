import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Translate } from 'react-localize-redux';

function RentalUnitBookingInformation({ booking, golfClubCurrency }) {
  const [nominalStart, setNominalStart] = useState(null);
  useEffect(() => {
    setNominalStart(moment(booking.nominalStart).format('YYYY-MM-DDTHH:mm:00'));
  }, [booking]);

  return (
    <table>
      <tbody>
        <tr>
          <td>
            <b>
              <Translate id="default.booked-by" />:
            </b>
          </td>
          <td>{booking.bookingUser.name}</td>
        </tr>
        <tr>
          <td>
            <b>
              <Translate id="edit-booking.booking-number" />:
            </b>
          </td>
          <td>{booking.id}</td>
        </tr>
        <tr>
          <td>
            <b>
              <Translate id="default.product" />:
            </b>
          </td>
          <td>{booking.rentalUnit.name}</td>
        </tr>
        <tr>
          <td>
            <b>
              <Translate id="default.time" />:
            </b>
          </td>
          <td>{nominalStart}</td>
        </tr>
        <tr>
          <td>
            <b>
              <Translate id="edit-booking.price" />:
            </b>
          </td>
          <td>
            {booking.priceInclVat} {golfClubCurrency}
          </td>
        </tr>
        <tr>
          <td colSpan="2" className="pt-1">
            <Translate id="default.confirmation-sent-to" /> <b>{booking.bookingUser.email}</b>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default RentalUnitBookingInformation;
