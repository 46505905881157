import React, { Component } from 'react';

import { Container } from 'semantic-ui-react';

import Helmet from '../components/Helmet';

import SignupForm from '../containers/SignupForm';

class SignUpPage extends Component {
  render() {
    return (
      <div className="app app-sign-up">
        <Helmet title="OnTee sign up" description="OnTee on sign up steroid!" />
        <Container>
          <SignupForm />
        </Container>
      </div>
    );
  }
}

export default SignUpPage;
