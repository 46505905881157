import { accommodationBookingService } from '../../core/services';
import * as Api from '../../utils/api';

import { UNAUTHORIZED, SIGN_OUT_REQUEST, WIDGET_SIGN_OUT_IN_REQUEST } from '../LoginForm/ducks';

export const accommodationTypes = {
  CREATE_BOOKING: 'accommodation/CREATE_BOOKING',
  CREATE_BOOKING_SUCCESS: 'accommodation/CREATE_BOOKING_SUCCESS',
  CREATE_BOOKING_FAILED: 'accommodation/CREATE_BOOKING_FAILED',
  CANCEL_BOOKING: 'accommodation/CANCEL_BOOKING',
  CANCEL_BOOKING_SUCCESS: 'accommodation/CANCEL_BOOKING_SUCCESS',
  CANCEL_BOOKING_FAILED: 'accommodation/CANCEL_BOOKING_FAILED',
  EDIT_BOOKING: 'accommodation/EDIT_BOOKING',
  EDIT_BOOKING_SUCCESS: 'accommodation/EDIT_BOOKING_SUCCESS',
  EDIT_BOOKING_FAILED: 'accommodation/EDIT_BOOKING_FAILED',
  SELECTED_DATE: 'accommodation/SELECTED_DATE',
  ACCOMMODATION_SELECTED: 'accommodation/ACCOMMODATION_SELECTED',
  ACCOMMODATION_SELECTED_SUCCESS: 'accommodation/ACCOMMODATION_SELECTED_SUCCESS',
  ACCOMMODATION_SELECTED_FAILED: 'accommodation/ACCOMMODATION_SELECTED_FAILED',
  ACCOMMODATION_DESELECTED: 'accommodation/ACCOMMODATION_DESELECTED',
  ACCOMMODATION_DESELECTED_SUCCESS: 'accommodation/ACCOMMODATION_DESELECTED_SUCCESS',
  ACCOMMODATION_DESELECTED_FAILED: 'accommodation/ACCOMMODATION_DESELECTED_FAILED',
  FETCH_ACCOMMODATION_AVAILABILITY: 'accommodation/FETCH_ACCOMMODATION_AVAILABILITY',
  FETCH_ACCOMMODATION_AVAILABILITY_SUCCESS: 'accommodation/FETCH_ACCOMMODATION_AVAILABILITY_SUCCESS',
  FETCH_ACCOMMODATION_AVAILABILITY_FAILED: 'accommodation/FETCH_ACCOMMODATION_AVAILABILITY_FAILED',
  RESET_DATA: 'accommodation/RESET_DATA'
};

export const actions = {
  fetchAccommodationAvailabilityAction: (golfClubId, dateFrom, dateTo, isWidgetRequest, rentalUnitType) => ({
    type: accommodationTypes.FETCH_ACCOMMODATION_AVAILABILITY,
    data: [golfClubId, dateFrom, dateTo, isWidgetRequest, rentalUnitType],
    backendFunction: accommodationBookingService.getAvailableAccommodations,
    callSuccessType: accommodationTypes.FETCH_ACCOMMODATION_AVAILABILITY_SUCCESS,
    callFailedType: accommodationTypes.FETCH_ACCOMMODATION_AVAILABILITY_FAILED
  }),
  accommodationSelectedAction: (golfClubId, booking, acc) => ({
    type: accommodationTypes.ACCOMMODATION_SELECTED,
    booking: [golfClubId, booking],
    acc
  }),
  accommodationDeselectedAction: (golfClubId, booking) => ({
    type: accommodationTypes.ACCOMMODATION_DESELECTED,
    booking: [golfClubId, booking]
  }),
  createBookingAction: (golfClubId, booking, onSubmit) => ({
    type: accommodationTypes.CREATE_BOOKING,
    booking: [golfClubId, booking],
    onSubmit,
    backendFunction: Api.createBooking,
    callSuccessType: accommodationTypes.CREATE_BOOKING_SUCCESS,
    callFailedType: accommodationTypes.CREATE_BOOKING_FAILED
  }),
  cancelBookingAction: (golfClubId, booking, onSubmit) => ({
    type: accommodationTypes.CANCEL_BOOKING,
    booking: [golfClubId, booking],
    onSubmit
  }),
  editBookingAction: (golfClubId, booking, onSubmit) => ({
    type: accommodationTypes.EDIT_BOOKING,
    booking: [golfClubId, booking],
    onSubmit
  }),
  selectedDateAction: (dateFrom, dateTo) => ({ type: accommodationTypes.SELECTED_DATE, dateFrom, dateTo }),
  resetAllData: () => ({ type: accommodationTypes.RESET_DATA })
};

const initialState = {
  selectedDateFrom: '',
  selectedDateTo: '',
  accommodationsAvailabilities: [],
  activeRequest: null,
  createBookingActive: null,
  showBookingsActive: null,
  bookingCounter: 0,
  bookingView: false,
  selectedAcc: { accommodation: { name: '' }, bookings: [] },
  fetchAvailabilityError: null,
  accommodationError: null,
  createBookingError: null,
  showBookingsError: null,
  availabilityFromGet: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UNAUTHORIZED:
    case SIGN_OUT_REQUEST:
    case WIDGET_SIGN_OUT_IN_REQUEST:
      return initialState;
    case accommodationTypes.SELECTED_DATE: {
      return {
        ...state,
        selectedDateFrom: action.dateFrom,
        selectedDateTo: action.dateTo,
        type: action.type
      };
    }
    case accommodationTypes.FETCH_ACCOMMODATION_AVAILABILITY: {
      return {
        ...state,
        activeRequest: true,
        fetchAvailabilityError: null,
        type: action.type
      };
    }
    case accommodationTypes.FETCH_ACCOMMODATION_AVAILABILITY_SUCCESS: {
      return {
        ...state,
        accommodationsAvailabilities: action.data,
        activeRequest: false,
        fetchAvailabilityError: null,
        availabilityFromGet: true,
        type: action.type
      };
    }
    case accommodationTypes.FETCH_ACCOMMODATION_AVAILABILITY_FAILED: {
      return {
        ...state,
        activeRequest: false,
        fetchAvailabilityError: action.error,
        type: action.type
      };
    }
    case accommodationTypes.ACCOMMODATION_SELECTED:
      return {
        ...state,
        selectedAcc: action.acc,
        bookingView: true,
        accommodationLoading: true,
        accommodationError: null,
        createBookingError: null,
        type: action.type
      };
    case accommodationTypes.ACCOMMODATION_DESELECTED_SUCCESS:
    case accommodationTypes.ACCOMMODATION_SELECTED_SUCCESS:
    case accommodationTypes.CREATE_BOOKING_SUCCESS: {
      let newAccommodationsAvailabilities = state.accommodationsAvailabilities;
      let newSelectedAcc = state.selectedAcc;
      if (action.accommodation) {
        newAccommodationsAvailabilities = action.accommodation;
        newSelectedAcc = newAccommodationsAvailabilities.accommodations.find(
          x => x.accommodation.id === newSelectedAcc.accommodation.id
        );
      }
      return {
        ...state,
        accommodationsAvailabilities: newAccommodationsAvailabilities,
        selectedAcc: newSelectedAcc,
        accommodationLoading: false,
        createBookingActive: false,
        createBookingError: null,
        showBookingsError: null,
        availabilityFromGet: false,
        type: action.type
      };
    }
    case accommodationTypes.ACCOMMODATION_DESELECTED_FAILED:
    case accommodationTypes.ACCOMMODATION_SELECTED_FAILED: {
      let newAccommodationsAvailabilities = state.accommodationsAvailabilities;
      let newSelectedAcc = state.selectedAcc;
      if (action.accommodation) {
        newAccommodationsAvailabilities = action.accommodation;
        newSelectedAcc = newAccommodationsAvailabilities.accommodations.find(
          x => x.accommodation.id === newSelectedAcc.accommodation.id
        );
      }

      return {
        ...state,
        accommodationsAvailabilities: newAccommodationsAvailabilities,
        selectedAcc: newSelectedAcc,
        accommodationLoading: false,
        createBookingError: action.error,
        availabilityFromGet: false,
        type: action.type
      };
    }
    case accommodationTypes.ACCOMMODATION_DESELECTED: {
      return {
        ...state,
        bookingView: false,
        accommodationError: null,
        createBookingError: null,
        showBookingsError: null,
        type: action.type
      };
    }
    case accommodationTypes.EDIT_BOOKING:
    case accommodationTypes.CANCEL_BOOKING: {
      return {
        ...state,
        showBookingsActive: true,
        type: action.type
      };
    }
    case accommodationTypes.EDIT_BOOKING_SUCCESS:
    case accommodationTypes.CANCEL_BOOKING_SUCCESS: {
      let newAccommodationsAvailabilities = state.accommodationsAvailabilities;
      let newSelectedAcc = state.selectedAcc;
      if (action.accommodation) {
        newAccommodationsAvailabilities = action.accommodation;
        newSelectedAcc = newAccommodationsAvailabilities.accommodations.find(
          x => x.accommodation.id === newSelectedAcc.accommodation.id
        );
      }
      return {
        ...state,
        accommodationsAvailabilities: newAccommodationsAvailabilities,
        selectedAcc: newSelectedAcc,
        showBookingsActive: false,
        bookingCounter: state.bookingCounter + 1,
        showBookingsError: null,
        availabilityFromGet: false,
        type: action.type
      };
    }
    case accommodationTypes.EDIT_BOOKING_FAILED:
    case accommodationTypes.CANCEL_BOOKING_FAILED: {
      let newAccommodationsAvailabilities = state.accommodationsAvailabilities;
      let newSelectedAcc = state.selectedAcc;
      if (action.accommodation) {
        newAccommodationsAvailabilities = action.accommodation;
        newSelectedAcc = newAccommodationsAvailabilities.accommodations.find(
          x => x.accommodation.id === newSelectedAcc.accommodation.id
        );
      }

      return {
        ...state,
        accommodationsAvailabilities: newAccommodationsAvailabilities,
        selectedAcc: newSelectedAcc,
        showBookingsActive: false,
        showBookingsError: action.error,
        availabilityFromGet: false,
        type: action.type
      };
    }

    case accommodationTypes.CREATE_BOOKING: {
      return {
        ...state,
        createBookingActive: true,
        type: action.type
      };
    }

    case accommodationTypes.CREATE_BOOKING_FAILED: {
      let newAccommodationsAvailabilities = state.accommodationsAvailabilities;
      let newSelectedAcc = state.selectedAcc;
      if (action.accommodation) {
        newAccommodationsAvailabilities = action.accommodation;
        newSelectedAcc = newAccommodationsAvailabilities.accommodations.find(
          x => x.accommodation.id === newSelectedAcc.accommodation.id
        );
      }

      return {
        ...state,
        accommodationsAvailabilities: newAccommodationsAvailabilities,
        selectedAcc: newSelectedAcc,
        createBookingActive: false,
        showBookingsActive: false,
        createBookingError: action.error,
        availabilityFromGet: false,
        type: action.type
      };
    }
    case accommodationTypes.RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;

// selectors
export const getAccommodationSheet = state => state.accommodation;

export const selectedDateFrom = state => getAccommodationSheet(state).selectedDateFrom;
export const selectedDateTo = state => getAccommodationSheet(state).selectedDateTo;
export const hasActiveRequest = state => getAccommodationSheet(state).activeRequest;
export const getPublicHolidays = state => state.clubAdminConfig.publicHolidays;
export const getGolfClubs = state => state.clubAdminConfig.golfClubs;
export const getGolfClubsLoading = state => state.clubAdminConfig.golfClubsLoading;
