import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Translate, withLocalize } from 'react-localize-redux';

import { Button, Grid, Form, Message, Popup } from 'semantic-ui-react';
import queryString from 'query-string';

import { changePasswordAction, changePasswordContinueAction } from './ducks';
import { ErrorMessage } from '../../components/ErrorMessage';

class ChangePasswordForm extends React.Component {
  constructor() {
    super();

    this.state = {
      user: {
        email: '',
        oldPassword: '',
        password: '',
        confirmPassword: ''
      },
      valid: {
        email: null,
        oldPassword: true,
        password: true,
        confirmPassword: true
      }
    };
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    const { user, valid } = this.state;
    user.email = query.email;
    user.code = query.code;
    if (user.code) {
      valid.oldPassword = true;
      this.setState({ valid });
    }
    this.setState({ user });
    // console.log(query);
  }

  validateField = (e, validator) => {
    const val = e.target.value;
    const field = e.target.name;
    const { user, valid } = this.state;

    user[field] = val;
    valid[field] = validator(val, e);
    valid.all = valid.oldPassword === true && valid.password === true && valid.confirmPassword === true;

    this.setState({
      user,
      valid
    });
    // console.log(user);
    // console.log(valid);
  };

  validatePassword = e => {
    this.validateField(e, this.passwordValidator);
    this.validateConfirmPassword({
      target: {
        value: this.state.user.confirmPassword,
        name: 'confirmPassword'
      }
    });
  };
  validateConfirmPassword = e => this.validateField(e, this.confirmPasswordValidator);
  validateOldPassword = e => this.validateField(e, val => val.length > 0);

  passwordValidator = (val, e) => {
    const field = e.target.name;
    const { user } = this.state;
    user[field] = val;

    const valid = val.length > 4 && /\d/.test(val) && /[a-zA-Z]/.test(val);
    return valid;
  };

  confirmPasswordValidator = (val, e) => {
    const field = e.target.name;
    const { user } = this.state;
    user[field] = val;

    const valid = user.password === user.confirmPassword;
    return valid;
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.changePasswordAction(this.state.user);
  };

  handleContinue = e => {
    this.props.changePasswordContinueAction();
  };

  render() {
    const { valid } = this.state;

    const { login, translate } = this.props;
    if (login.isAuthenticated && this.props.passwordChangeDone) {
      return (
        <div className="p-3 bg-white">
          <h1>
            <Translate id="password.change" />
          </h1>
          <Message success content={translate('password.changed')} />
          <div className="p-3 text-center text-white">
            <Link to="/">
              <Button onClick={this.handleContinue} color="blue" size="massive" className="mt-3 px-5">
                Continue
              </Button>
            </Link>
          </div>
        </div>
      );
    }

    return (
      <Grid columns="1" stackable>
        <Grid.Column className="bg-white">
          <div className="p-3">
            <h1>
              <Translate id="password.change" />
            </h1>
            {!this.state.user.code && !this.props.login.isAuthenticated ? (
              <Message color="orange" content={'Hmm... how did you end up here.'} />
            ) : (
              <Fragment>
                <p className="text-muted">
                  <Translate id="password.new" />
                </p>
                <Form onSubmit={this.handleSubmit} loading={this.props.activeRequest} error={!!this.props.error}>
                  {!this.state.user.code && (
                    <Form.Field>
                      <Form.Input
                        label={translate('password.current')}
                        type="password"
                        name="oldPassword"
                        placeholder={translate('password.current')}
                        onChange={this.validateOldPassword}
                        error={!valid.oldPassword}
                      />
                    </Form.Field>
                  )}
                  <Form.Field>
                    <Popup
                      trigger={
                        <Form.Input
                          label={translate('password.new')}
                          type="password"
                          name="password"
                          placeholder={translate('password.new')}
                          onChange={this.validatePassword}
                          error={!valid.password}
                        />
                      }
                      content={translate('password.rule-length')}
                      wide="very"
                      position="top right"
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      label={translate('password.new-confirm')}
                      type="password"
                      name="confirmPassword"
                      placeholder={translate('password.new-confirm')}
                      onChange={this.validateConfirmPassword}
                      error={!valid.confirmPassword}
                    />
                  </Form.Field>
                  <ErrorMessage error={this.props.error} />
                  <Form.Button fluid color="teal" disabled={!valid.all}>
                    <Translate id="default.submit" />
                  </Form.Button>
                </Form>
              </Fragment>
            )}
          </div>
        </Grid.Column>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    error: state.changePassword.error,
    passwordChangeDone: state.changePassword.passwordChangeDone,
    activeRequest: state.changePassword.activeRequest
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changePasswordAction: user => dispatch(changePasswordAction(user)),
    changePasswordContinueAction: () => dispatch(changePasswordContinueAction())
  };
}

export default withLocalize(withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm)));
