import React, { useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { Button, Icon, Popup, Table } from 'semantic-ui-react';
import { GolfBikeIcon, GolfCartIcon } from '../../utils/icons';

const RentalUnitView = ({ rentalUnit, onClickEdit, onClickDelete, btnsDisabled, loading }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    onClickDelete(rentalUnit);
    setIsOpen(false);
  };

  return (
    <Table.Row>
      <Table.Cell>{rentalUnit.name}</Table.Cell>
      <Table.Cell>
        {rentalUnit.type.id === 3 && <GolfCartIcon />}
        {rentalUnit.type.id === 4 && <GolfBikeIcon />}
        <Translate id={`accommodation.${rentalUnit.type.name}`} />
      </Table.Cell>
      <Table.Cell>
        <Translate id={`accommodation.${rentalUnit.priceCategory.name}`} />
      </Table.Cell>
      <Table.Cell>{rentalUnit.attributes?.find(a => a.key === 'AllowedBookingsPerDay')?.value}</Table.Cell>
      <Table.Cell textAlign="right">
        <Button color="green" size="mini" icon onClick={() => onClickEdit(rentalUnit)} disabled={btnsDisabled}>
          <Icon name="edit outline" />
        </Button>
        <Popup
          trigger={
            <Button color="red" size="mini" icon disabled={btnsDisabled}>
              <Icon name="trash alternate" />
            </Button>
          }
          content={<Button color="red" content="Bekräfta borttagning?" onClick={handleClick} loading={loading} />}
          on="click"
          position="top right"
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default withLocalize(RentalUnitView);
