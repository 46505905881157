import React from 'react';
import { Translate } from 'react-localize-redux';
import { Button, Form, List, Message, Segment, Popup } from 'semantic-ui-react';
import { ErrorMessage } from './ErrorMessage';

/**
 * data, subComponent, itemName, listLabel, stateUpdated, error, validate, allValid emptyItem, emptyValidationItem
 */
class EditableSubListComponent extends React.Component {
  state = {
    dataList: [],
    dataListValid: [],
    allValid: false
  };

  componentDidMount = () => {
    if (!this.props.data) {
      return;
    }
    const { data, emptyValidationItem } = this.props;
    const { dataList, dataListValid } = this.state;
    data.forEach((x, i) => {
      dataList[i] = x;
      dataListValid[i] = emptyValidationItem();
    });
    if (!dataList.every(x => x.id < 0)) {
      this.props.validate(dataList, dataListValid);
    }

    this.updateState(dataList, dataListValid);
  };

  subcomponentStateUpdated = (dList, dListValid, i) => {
    const { dataList, dataListValid } = this.state;
    dataList[i][this.props.subComponentListName] = dList;
    dataListValid[i][this.props.subComponentListName] = dListValid;
    this.updateState(dataList, dataListValid);
  };

  updateState = (dataList, dataListValid) => {
    const allValid = this.props.allValid(dataList, dataListValid);
    this.setState({ dataList, dataListValid, allValid });
    this.props.stateUpdated(dataList, allValid);
  };

  insertItem = () => {
    const { dataList, dataListValid } = this.state;
    const { emptyItem, emptyValidationItem } = this.props;

    dataList.push(emptyItem(dataList));
    dataListValid.push(emptyValidationItem());
    //    this.props.validate(dataList, dataListValid);
    this.updateState(dataList, dataListValid);
  };

  removeItem = i => {
    const { dataList, dataListValid } = this.state;
    dataList.splice(i, 1);
    dataListValid.splice(i, 1);
    this.updateState(dataList, dataListValid);
  };

  addItem = evt => {
    evt.preventDefault();
    this.insertItem();
  };

  render() {
    const items = this.state.dataList.map((item, i) => {
      const subComponent = React.cloneElement(this.props.subComponent, {
        key: item.id,
        dataList: this.state.dataList,
        dataListValid: this.state.dataListValid,
        index: i,
        updateState: this.updateState,
        stateUpdated: this.subcomponentStateUpdated,
        removeItem: this.removeItem
      });
      return subComponent;
    });

    return (
      <div>
        {this.props.listLabel && (
          <List.Item>
            <Form.Field>
              <h3>{this.props.listLabel}</h3>
            </Form.Field>
          </List.Item>
        )}
        <List.Item>{items && items.length > 0 && <Segment.Group>{items}</Segment.Group>}</List.Item>
        <List.Item>
          <Form.Field style={{ marginTop: 15, marginBottom: 0 }}>
            <Popup
              style={{ opacity: this.props.addHelpText ? '1' : '0' }}
              content={this.props.addHelpText}
              trigger={<Button icon="add" onClick={this.addItem} />}
            />
            <Translate id="club-admin.click-to-add" data={{ item: this.props.itemName }} />
          </Form.Field>
        </List.Item>
        {this.state.dataList.length === 0 && (
          <Message color="orange" visible content={'You need to have at least one ' + this.props.itemName + '.'} />
        )}
        <ErrorMessage error={this.props.error} />
        {this.props.listValidationStatus &&
          this.props.listValidationStatus.map((x, i) => {
            const valid = x.validationFn(this.state.dataList, this.state.dataListValid);
            return valid ? null : <Message key={i} color="orange" visible content={x.text} />;
          })}
      </div>
    );
  }
}

export { EditableSubListComponent };
