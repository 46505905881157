import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Translate, withLocalize } from 'react-localize-redux';

import { Menu } from 'semantic-ui-react';

import { actions } from '../AccommodationSheet/ducks';
import { clubAdminConfigActions } from '../ClubAdminConfigureForm/ducks';

import { SummationPeriodReportTab } from '../../components/Reports/Tabs/SummationPeriodReportTab';
import { RentalUnitTransactionsPeriodReportTab } from '../../components/Reports/Tabs/RentalUnitTransactionsPeriodReportTab';
import AccomodationTransactionsPeriodReportTab from '../../components/Reports/Tabs/AccomodationTransactionsPeriodReportTab';
import { TransactionsSummaryReportTab } from '../../components/Reports/Tabs/TransactionsSummaryReportTab';
import { PayoutReportTab } from '../../components/Reports/Tabs/PayoutReportTab';
import { ProductAddonsReportTab } from '../../components/Reports/Tabs/ProductAddonsReportTab';
import { isSuperAdmin } from '../../utils';
import StatisticsReportTab from '../../components/Reports/Tabs/StatisticsReportTab';
import PackagesReportTab from '../../components/Reports/Tabs/PackagesReportTab';
import ServicesReportTab from '../../components/Reports/Tabs/ServicesReportTab';
import GuestListReportTab from '../../components/Reports/Tabs/GuestListReportTab';
import SurveysReportTab from '../../components/Reports/Tabs/SurveysReportTab';

class ReportsForm extends React.Component {
  // tab constants
  accommodationTransPeriod = 'accommodation-report-trans-period';

  rentalUnitTransPeriod = 'rental-unit-report-trans-period';

  transactionsPeriod = 'transactions-period';

  sumPeriod = 'report-sum-period';

  payouts = 'payouts';

  productAddons = 'product-addons';

  packages = 'packages';

  services = 'services';

  statistics = 'statistics';

  surveys = 'surveys';

  guestList = 'guestlist';

  state = {
    activeItem: this.sumPeriod
  };

  componentDidMount = () => {
    this.props.fetchGolfClubsAction();
    this.props.fetchHolidaysAction();
  };

  handleItemClick = name => this.setState({ activeItem: name });

  render() {
    const { activeItem } = this.state;
    return (
      <>
        <Translate>
          {({ translate }) => (
            <>
              <Menu className="p-05" pointing secondary>
                <Menu.Item
                  content={translate(`club-admin.${this.sumPeriod}`)}
                  name={this.sumPeriod}
                  active={activeItem === this.sumPeriod}
                  onClick={() => this.handleItemClick(this.sumPeriod)}
                />
                <Menu.Item
                  content={translate(`club-admin.${this.accommodationTransPeriod}`)}
                  name={this.accommodationTransPeriod}
                  active={activeItem === this.accommodationTransPeriod}
                  onClick={() => this.handleItemClick(this.accommodationTransPeriod)}
                />
                <Menu.Item
                  content={translate(`club-admin.${this.rentalUnitTransPeriod}`)}
                  name={this.rentalUnitTransPeriod}
                  active={activeItem === this.rentalUnitTransPeriod}
                  onClick={() => this.handleItemClick(this.rentalUnitTransPeriod)}
                />
                <Menu.Item
                  content={translate('club-admin.report-tabs.transactions')}
                  name={this.transactionsPeriod}
                  active={activeItem === this.transactionsPeriod}
                  onClick={() => this.handleItemClick(this.transactionsPeriod)}
                />
                <Menu.Item
                  content={translate(`club-admin.${this.payouts}`)}
                  name={this.payouts}
                  active={activeItem === this.payouts}
                  onClick={() => this.handleItemClick(this.payouts)}
                />
                <Menu.Item
                  content={translate(`club-admin.${this.productAddons}`)}
                  name={this.productAddons}
                  active={activeItem === this.productAddons}
                  onClick={() => this.handleItemClick(this.productAddons)}
                />
                <Menu.Item
                  content={translate(`club-admin.${this.services}`)}
                  name={this.services}
                  active={activeItem === this.services}
                  onClick={() => this.handleItemClick(this.services)}
                />
                <Menu.Item
                  content={translate(`club-admin.${this.packages}`)}
                  name={this.packages}
                  active={activeItem === this.packages}
                  onClick={() => this.handleItemClick(this.packages)}
                />
                {isSuperAdmin(this.props) && (
                  <Menu.Item
                    content={translate(`club-admin.${this.statistics}`)}
                    name={this.statistics}
                    active={activeItem === this.statistics}
                    onClick={() => this.handleItemClick(this.statistics)}
                  />
                )}
                {isSuperAdmin(this.props) && (
                  <Menu.Item
                    content={translate(`club-admin.${this.surveys}`)}
                    name={this.surveys}
                    active={activeItem === this.surveys}
                    onClick={() => this.handleItemClick(this.surveys)}
                  />
                )}
                <Menu.Item
                  content={translate(`club-admin.report-tabs.${this.guestList}`)}
                  name={this.guestList}
                  active={activeItem === this.guestList}
                  onClick={() => this.handleItemClick(this.guestList)}
                />
              </Menu>
              <SummationPeriodReportTab
                translate={translate}
                golfClubs={this.props.golfClubs}
                active={activeItem === this.sumPeriod}
              />
              <AccomodationTransactionsPeriodReportTab
                translate={translate}
                golfClubs={this.props.golfClubs}
                active={activeItem === this.accommodationTransPeriod}
              />
              <RentalUnitTransactionsPeriodReportTab
                translate={translate}
                golfClubs={this.props.golfClubs}
                active={activeItem === this.rentalUnitTransPeriod}
              />
              <TransactionsSummaryReportTab
                translate={translate}
                golfClubs={this.props.golfClubs}
                active={activeItem === this.transactionsPeriod}
              />
              <PayoutReportTab
                translate={translate}
                golfClubs={this.props.golfClubs}
                active={activeItem === this.payouts}
                login={this.props.login}
              />
              <ProductAddonsReportTab translate={translate} active={activeItem === this.productAddons} />
              <ServicesReportTab translate={translate} active={activeItem === this.services} />
              <PackagesReportTab translate={translate} active={activeItem === this.packages} />
              <StatisticsReportTab translate={translate} active={activeItem === this.statistics} />
              <SurveysReportTab translate={translate} active={activeItem === this.surveys} />
              <GuestListReportTab translate={translate} active={activeItem === this.guestList} />
            </>
          )}
        </Translate>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    golfClubs: getGolfClubs(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAccommodationBookings: () => dispatch(actions.fetchAccommodationBookingAction()),
    ...bindActionCreators(clubAdminConfigActions, dispatch)
  };
}

export default withLocalize(withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportsForm)));

export const getGolfClubs = state => state.clubAdminConfig.golfClubs;
